.editor.assessment.qz-scoring-levels {
  @include flexbox;
  width: 100%;
  .level {
    width: 69%;
    .levels {
      @include flexbox;
      .scale {
        margin-top: 10px;
        .line {
          border-left: 1px solid $gray-light;
          min-height: 134px;
          margin-left: 15px;
        }
        .arrow-down {
          margin-left: 10px;
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid $gray-light;
        }
      }
      .level-list {
        width: 100%;
        margin-left: 16px;
        input {
          font-size: $font-size-base;
        }
      }
    }
  }
  .points {
    margin-left: 30px;
    .point-list {
      padding-left: 45px;
      div {
        @include flexbox;
        .gru-input.point {
          width: 45%;
        }
        .btn {
          margin-left: 27%;
          background-color: transparent;
          font-size: 24px;
          line-height: 1;
          padding: 7px 5px;
        }
      }
    }
  }
  .btn-new-level {
    margin-left: 65px;
    font-size: $font-size-base;
    i {
      font-size: $font-size-h4;
      vertical-align: bottom;
    }
  }
}
