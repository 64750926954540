.gru-performance-filter-panel {
  background-color: $dark-400;
  height: auto;
  max-height: 890px;
  color: $dark-100;
  padding: 0;
  font-size: $font-size-lead;

  @media (min-width: $screen-sm) {
    height: 1100px;
    max-height: 1100px;
  }

  .header {
    padding: 5px 15px;
    .title {
      color: $dark-100;
    }
  }
  .body {
    border-top: 1px solid $dark-300;
    .filter-buttons {
      padding: 15px 10px;
      button {
        color: $white;
        font-size: $font-size-base;
        line-height: $font-size-base;
        background-color: $dark-200;
        border: 1px solid $dark-200;
        width: 90px;
        height: 30px;
        &.selected {
          border-color: $light-500;
        }
      }
    }
    .filter-type {
      height: 37px;
      width: 100%;
      background-color: $dark-200;
      padding: 5px 10px;
      color: $white;
      margin: 1px 0 0;
      span {
        float: $tab-float-dir;
        line-height: $font-size-h4;
        font-size: $font-size-large;
      }
      a.arrow {
        float: $menu-float-dir;
        height: 25px;
        i {
          color: $light-400;
          font-size: $expand-arrow;
        }
      }
      .filter-category {
        text-transform: uppercase;
      }
    }
    .content-panel {
      height: 190px;
      overflow: hidden;
      .items {
        font-size: $font-size-small;
        padding: 15px 15px 0;
        height: 190px;
        overflow-x: hidden;
        overflow-y: auto;
        .activity-option {
          @include flexbox;
          @include align-items(flex-end);
          cursor: pointer;
          span {
            margin: 0 0 0 10px;
          }
        }
        .activity-option.active {
          color: $white;
        }
      }
    }
    .actions {
      margin: 30px 0;
      text-align: center;
      .update-report {
        i {
          width: 13px;
          height: 13px;
          background-color: $white;
          color: $blue-400;
          border-radius: 2px;
          padding: 1px 0 0;
          margin: 0 3px 0 0;
        }
      }
    }
  }
}
