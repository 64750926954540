.domain-competency-report {
  overflow: hidden;

  .individual-domain-competency-report {
    .student-competencies {
      display: flex;
      margin-top: 8px;
      width: max-content;

      .students {
        margin-top: 166px;

        &.competency-slider-visible {
          margin-top: 166px;
        }

        .name-filter {
          display: flex;
          height: 37px;

          .first-header,
          .last-header {
            display: flex;
            width: 50%;
            cursor: pointer;

            &.active {
              cursor: default;
            }
          }
        }

        .student-info {
          width: 260px;
          min-width: 260px;
          max-width: 260px;
          margin-top: 4px;
          display: flex;
          height: 39px;
          border-bottom: 1px solid $student-container;

          .thumbnail {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-size: cover;
            margin-bottom: 0;
          }

          .name {
            $font-size: 10px;
            $line-height: 1.2;
            $lines-to-show: 2;
            color: $dark-400;
            text-transform: capitalize;
            margin: 0 5px;
            @include ellipsis(
              $font-size: $font-size,
              $line-height: $line-height,
              $lines-to-show: $lines-to-show
            );
            line-height: 3.2;
          }
        }
      }

      .student-competencies-list {
        width: 455px;
        position: relative;

        .competencies-list {
          max-width: 455px;
          margin-top: 23px;
          overflow-x: auto;

          .competency-codes {
            margin-left: 10px;
            display: flex;

            .rotate-vertical {
              line-height: 1;
              writing-mode: vertical-rl;
              height: 180px;
              min-width: 30px;
              display: grid;
              font-weight: bold;
              transform: rotate(180deg);
              /* Saf3.1+, Chrome */
              -moz-transform: rotate(180deg);
              /* FF3.5+ */
              -o-transform: rotate(180deg);
              /* Opera 10.5 */
              -webkit-transform: rotate(180deg);

              &:not(:first-child) {
                margin-left: 16px;
              }

              .code {
                margin-top: 4px;
              }

              .desc {
                cursor: default;
                margin: 4px auto auto;
                word-spacing: 2px;
                line-height: 1.2em;
              }
            }
          }

          .competencies {
            display: flex;

            .competency {
              padding: 0 8px;
              height: 37px;
              margin-top: 6px;
              border-bottom: 1px solid $student-container;

              rect {
                cursor: pointer;
              }
            }
          }

          &::-webkit-scrollbar {
            background: $table-bg;
            width: 0;
          }
        }

        .next,
        .previous {
          position: absolute;
          top: 0;
          cursor: pointer;

          i {
            font-size: 30px;
          }

          &.disabled {
            cursor: default;
            opacity: 0.5;
          }
        }

        .previous {
          left: 0;
        }

        .next {
          right: 0;
        }
      }
    }
  }
}
