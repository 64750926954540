.taxonomy.gru-taxonomy-tag {
  display: inline-block;
  border: 1px solid $gray-light;
  padding: 0 3px 2px;
  border-radius: 4px;
  background-color: $white;
  margin-bottom: 3px;
  vertical-align: top;
  overflow: hidden;
  max-width: 100px;

  .standard-tag {
    .data-label {
      max-width: 300px;
    }
  }

  .added-tag {
    opacity: 0.5;
  }

  div {
    padding: 4px 2px 0;

    b,
    span {
      display: block;
    }

    b {
      color: $gray;
      font-weight: normal;
      line-height: 1.2;
      max-width: 75px;
      @include text-overflow;
    }

    span {
      color: $font-gray-light-dark-400;
      text-transform: uppercase;
      font-size: inherit;
      line-height: 1.3;
    }
    .text-color-white {
      color: $white;
    }
  }

  button {
    border: 0 none;
    margin: 0;
    background-color: $table-bg;
    overflow: visible;
    height: auto;

    &.toggle {
      padding: 0 2px;
      text-align: $tab-float-dir;
    }

    &:focus {
      outline: none;
    }

    &.remove {
      i {
        font-size: 13px;
        vertical-align: top;
        color: $gray;
      }
    }

    &.add {
      i {
        font-size: 13px;
        vertical-align: top;
        color: $gray;
      }
    }
  }

  .tag-tooltip {
    display: none;
  }

  &.read-only {
    .popover.fade {
      background-color: $white;

      .arrow:after {
        border-top-color: $white;
        border-bottom-color: $white;
        border-left-color: $table-bg;
        border-right-color: $table-bg;
      }

      .popover-content {
        padding: 5px 10px 10px;

        span {
          color: $gray;
          text-transform: none;
          font-size: $font-size-base;
        }
      }
    }
  }

  &.remove {
    position: relative;
    padding-right: 18px;

    button.remove {
      position: absolute;
      top: 5px;
      right: 2px;
      padding: 0;
    }
  }

  &.add {
    position: relative;
    padding-right: 18px;

    button.add {
      position: absolute;
      top: 5px;
      right: 2px;
      padding: 0;
    }
  }

  &.active {
    border-color: $dark-300;
    background-color: $dark-300;

    div > b {
      color: $white;
    }

    button.remove {
      i {
        color: $gray-lighter;
      }
    }

    button.add {
      i {
        color: $gray-lighter;
      }
    }
  }
}

.panel-body {
  .gru-taxonomy-tag-list {
    .gru-taxonomy-tag {
      .content.standard-tag {
        width: 100%;
        height: 100%;
      }
    }
  }
}
