.controller.content.questions.play {
  > article {
    min-width: 0;
    $content-md-width: 90%;

    > header {
      padding: 0;
      width: 100%;
      margin-bottom: 20px;

      @media (min-width: $screen-md) {
        padding-bottom: 0 !important;
      }

      * {
        color: $white;
      }

      .header-content {
        padding: 15px 5%;
        @media (min-width: $screen-md) {
          padding: 15px 0;
          width: $content-md-width;
          margin: 0 auto;
        }

        .btn-back {
          border: 0;
          padding: 0;
          background-color: $table-bg;
          font-size: $font-size-large;
          outline: none;

          .gru-icon {
            vertical-align: text-top;
          }
        }

        .heading {
          @media (min-width: $screen-sm) {
            @include flexbox();
            @include justify-content(space-between);
          }

          .information {
            width: 100%;
            @media (min-width: $screen-sm) {
              width: 85%;
            }
            h1 {
              $line-height: 1.2;
              $lines-to-show: 1;
              text-transform: capitalize;
              @include ellipsis(
                $font-size: $font-size-h4,
                $line-height: $line-height,
                $lines-to-show: $lines-to-show,
                $excerpt-bg: $dark-400
              );
              @media (min-width: $screen-xs) {
                @include ellipsis(
                  $font-size: $font-size-h3,
                  $line-height: $line-height,
                  $lines-to-show: $lines-to-show,
                  $excerpt-bg: $dark-400
                );
              }
            }

            .details {
              margin-top: 10px;
              @include flexbox();

              .type {
                @include flexbox();
                @include align-items(center);

                i {
                  display: block;
                  height: 36px;
                  width: 28px;
                  margin: 0 auto;
                  vertical-align: top;
                  &.MC-icon {
                    background: url("gooru/images/multiple-choice-green.svg");
                    background-size: 50px 49px;
                    height: 50px;
                    width: 49px;
                  }
                  &.MTF-icon {
                    background: url("gooru/images/match-the-following.svg");
                    background-size: 50px 49px;
                    height: 50px;
                    width: 49px;
                  }
                  &.MA-icon {
                    background: url("gooru/images/multiple-answer-green.svg");
                    background-size: 50px 49px;
                    height: 50px;
                    width: 49px;
                  }
                  &.T_F-icon {
                    background: url("gooru/images/true-false-green.svg");
                    background-size: 50px 49px;
                    height: 50px;
                    width: 49px;
                    vertical-align: top;
                    margin: 5px 0 0 0;
                  }
                  &.FIB-icon {
                    background: url("gooru/images/fill-in-the-blank-green.svg");
                    background-size: 50px 49px;
                    height: 50px;
                    width: 49px;
                  }
                  &.HS_IMG-icon {
                    background: url("gooru/images/hs-image-green.svg");
                    background-size: 50px 49px;
                    height: 50px;
                    width: 49px;
                  }
                  &.HS_TXT-icon {
                    background: url("gooru/images/hs-text-green.svg");
                    background-size: 54px 53px;
                    height: 54px;
                    width: 53px;
                  }
                  &.HT_RO-icon {
                    background: url("gooru/images/hot-text-reorder-green.svg");
                    background-size: 50px 49px;
                    height: 50px;
                    width: 49px;
                  }
                  &.HT_HL-icon {
                    background: url("gooru/images/hot-text-green.svg");
                    background-size: 50px 49px;
                    height: 50px;
                    width: 49px;
                  }
                  &.OE-icon {
                    //TODO Add the correct icon
                    background: url("gooru/images/hot-text-green.svg");
                    background-size: 50px 49px;
                    height: 50px;
                    width: 49px;
                  }
                }

                span {
                  padding-left: 27px;
                }
              }

              .tags {
                @include flexbox();
                @include align-items(stretch);
                margin-left: 10px;
                padding-left: 10px;
                border-left: 2px solid $dark-300;

                .taxonomy.gru-taxonomy-tag {
                  border-color: $dark-300;
                  background-color: $dark-300;
                  color: $white;
                }
              }
            }
          }

          .publisher {
            margin-top: 10px;

            img {
              @include border-top-radius(50%);
              @include border-bottom-radius(50%);
              height: 1.7rem;
              width: 1.7rem;
              vertical-align: middle;
              margin: 0 5px;
            }

            @media (min-width: $screen-sm) {
              @include flexbox();
              @include flex-direction(column);
              @include align-items(center);
              @include justify-content(center);
              border-left: 2px solid $dark-300;
              padding-left: 20px;
              margin: 0 0 0 10px;
              min-width: 125px;
              max-width: 115px;

              span.owner {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 115px;
              }

              img {
                height: 2.3rem;
                width: 2.3rem;
                margin: 3px 0;
              }
            }
          }
        }
      }

      @media (min-width: $screen-md) {
        top: 63px;
        padding-bottom: 30px;
      }
    }
  }
}
