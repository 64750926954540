.gru-slide-up-menu {
  position: relative;
  .disabled {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $gray-base;
    opacity: 0.5;
  }
  .slide-menu {
    position: fixed;
    left: 0;
    width: 100%;
    margin-bottom: 0;
  }
}
.disabled-screen {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: fixed;
}
