.student-competency-progress-report-card {
  $line-height: 1.2;
  $lines-to-show: 2;

  .student-competency-progress-report-card-container {
    .student-card-panel {
      display: grid;
      width: 360px;
      margin: 8px;
      border-radius: 4px;
      background-color: $white;
      grid-template-rows: auto 1fr;
      box-shadow: 0 2px 4px 0 $navbar-default-toggle-icon-bar-bg;
      height: max-content;

      .code-info {
        display: grid;
        grid-template-columns: auto 45px;
        column-gap: 4px;
        align-items: center;
        padding: 8px 8px 0;
        height: max-content;
        padding-bottom: 10px;

        .display-code {
          display: grid;
          grid-column: 1;
          font-size: 11px;
          color: $dark-400;

          .code {
            font-weight: bold;
          }

          .short-desc {
            font-size: 12px;
            color: $dark-400;
          }
        }

        .competency-status {
          text-align: center;

          .status {
            width: 24px;
            height: 24px;
            justify-self: right;
            display: block;
            grid-column: 2;
            margin: auto;
          }

          .status-value {
            font-size: 8px;
            line-height: 1.3;
          }
        }
      }

      .competency-name {
        padding: 0 8px 8px;
        color: $dark-400;
        font-size: 12px;
      }

      .activity-row {
        display: grid;
        height: 48px;
        border-top: 1px solid $gray-ultra-light;
        grid-template-columns: 48px 1fr 48px 48px;

        .type {
          background-repeat: no-repeat;
          width: 20px;
          height: 20px;
          background-size: 20px 20px;
          display: block;
          margin: auto;

          &.assessment,
          .assessment-external {
            background-image: url("gooru/images/add_assessment.svg");
          }

          &.collection,
          .collection-external {
            background-image: url("gooru/images/add_collection.svg");
          }

          &.offline-activity {
            background-image: url("gooru/images/offline.svg");
          }
        }

        .title {
          display: grid;

          .title-text {
            font-size: 12px;
            color: $dark-400;
            padding-top: 5px;
          }

          .date {
            opacity: 0.7;
            font-size: 11px;
            line-height: 1.45;
            color: $dark-400;
            padding-bottom: 5px;
          }
        }

        .timespent {
          background-color: $gray-submarine;
          text-align: center;
          cursor: pointer;
          color: $dark-400;
          line-height: 1;
          display: flex;
          align-items: center;
          justify-content: center;

          &.no-performance {
            pointer-events: none;
          }
        }

        .percentage {
          padding-top: 15px;
          text-align: center;
          cursor: pointer;
          color: $white;

          &.grade-range-not-started,
          &.score-range-not-started {
            color: $dark-400;
            pointer-events: none;
          }
        }
      }

      .inferred-competency-panel {
        .competency-header-section {
          display: grid;
          grid-template-columns: 64px 1fr;
          align-items: center;
          padding: 8px 0;
          height: auto;

          .info-row {
            display: flex;
            align-items: center;
            justify-content: space-evenly;

            .competency-status {
              width: 8px;
              height: 8px;
            }

            .icon {
              height: 24px;
            }
          }

          .inferred-competency-title {
            font-size: 11px;
            font-weight: bold;
            font-style: italic;
            color: $dark-400;
            border-bottom: solid 1px #dfdfdf;
          }
        }

        .inferred-competency-list {
          padding-top: 8px;
          padding-bottom: 16px;
          display: grid;
          grid-template-columns: 64px 1fr;
          grid-row-gap: 10px;

          .row-item {
            grid-column-start: 2;

            .competency-code {
              font-size: 11px;
              font-weight: bold;
              color: $dark-400;
              text-decoration: underline;
              float: left;
              padding-right: 5px;
            }

            .competency-desc {
              line-height: 1.3;
              text-align: justify;
              color: $dark-400;
              padding: 0 8px 0 5px;
              @include ellipsis(
                $font-size: $font-size-base,
                $line-height: $line-height,
                $lines-to-show: $lines-to-show,
                $excerpt-bg: $white
              );
            }
          }
        }
      }
    }
  }
}
