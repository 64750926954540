.ps-dropdown {
  position: relative;

  &.disabled {
    .dropdown-toggle {
      background-color: $grey-background;
      border-color: $btn-border-grey;
      pointer-events: none;
      white-space: nowrap; /* prevent the selected text from wrapping */
      overflow: hidden; /* hide any overflowing text */
      text-overflow: ellipsis; /* display an ellipsis (...) when text is truncated */
    }

    .dropdown-toggle:hover {
      pointer-events: none;
    }

    .dropdown-menu {
      display: none;
    }
  }

  .dropdown-toggle {
    display: inline-block;
    text-align: left;
    background-color: $white;
    border: 1px solid $link-water;
    border-radius: 4px;
    padding: 6px 12px;
    cursor: pointer;

    .separator {
      border-right: 1px solid $link-water;
      height: inherit;
      margin-right: 10px;
      float: right;
      margin-top: -8px;
    }

    .caret {
      float: right;
      margin-top: 8px;
      color: $gray-4d5557;
    }
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: $white;
    border: 1px solid $gray-80;
    border-radius: 4px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    max-height: 300px;
    overflow-y: auto;
    padding: 5px;
    width: 26%;

    li {
      padding: 0px 10px;
      cursor: pointer;

      &:hover {
        background-color: $pre-bg;
      }

      div {
        color: $kbd-bg;
        text-decoration: none;
        display: block;
        position: relative;
        padding: 0px 20px;
        cursor: pointer;

        input[type="checkbox"] {
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          margin: 0px 3px 0px 0px;
          cursor: pointer;
        }

        input[type="checkbox"]:after {
          border-radius: 4px;
        }

        &:hover {
          border-radius: 2px;
          background-color: $pre-bg;
        }
      }
    }
  }

  .pills-container {
    display: flex;
    flex-wrap: wrap;

    .pill {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 3px 8px;
      gap: 6px;
      box-sizing: border-box;
      border: 1px solid $btn-border-grey;
      border-radius: 12px;
      background: $white-bold;
      margin: 4px;
      color: $blue-bg;
      font-weight: 400;
      font-size: 12px;
    }

    .pill-text {
      flex: 1;
    }

    .pill-close {
      cursor: pointer;
      font-size: 14px;
      text-align: end;
      height: 9px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
    }
  }
}
