.reports_collection .container-fluid {
  min-width: $screen-md-min;
}

.app-container {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
  height: 100vh;
}

.app-container > .controller.reports {
  $gutter-width: 30px;
  $on-air-icon: 40px;
  color: $gray-base;

  display: block;
  padding: 0 15px 30px 15px;
  background-color: $white;

  .header {
    display: grid;
    grid-template-columns: auto 1fr auto;
    margin-top: 15px;
    align-items: center;

    .title {
      > i {
        display: inline-block;
        margin: 0 5px 0 15px;
        width: $on-air-icon;
        height: $on-air-icon;
        vertical-align: middle;
        background-image: url("gooru/images/on-air-icon.svg");
        background-size: $on-air-icon $on-air-icon;
        vertical-align: 28px;
        @include text-hide();
      }
      > span {
        display: inline-block;
        font-size: $font-size-h5-5;
        margin: 6px 0;
        @media screen and (max-width: $screen-sm - 1) {
          font-size: 15px;
        }
      }

      .gru-tour {
        display: inline-block;
      }
    }

    .back {
      position: relative;
      > i {
        font-size: 33px;
        line-height: 50px;
        cursor: pointer;
      }
      .close-txt {
        text-transform: uppercase;
        line-height: 4;
        font-size: 16px;
        font-weight: 300;
        display: inline-block;
      }
      // dummy changes for build111
      .close-icon-1 {
        display: inline-block;
        // dummy changes for build1
        i {
          color: $white;
          font-size: 25px;
          position: absolute;
        }
        // dummy changes for build1
      }
    }
    .actions {
      .btn-info {
        i {
          font-size: $font-size-base;
          vertical-align: -2px;
          margin-right: 6px;
        }
      }
    }

    &.anonymous {
      background-color: $dark-400;
      color: $white;
      width: 102vw;
      margin-left: -30px;
      grid-template-columns: auto auto 1fr;

      margin-left: -$gutter-width;
      @include clearfix();
      .back {
        display: inline-block;
        margin-left: 15px;
        padding-left: 27px;
      }

      .title {
        float: $tab-float-dir;
        margin-left: $gutter-width;

        > i {
          background-image: url("gooru/images/on-air-icon-anonymous.svg");
        }
      }
      .mode {
        font-size: $font-size-base;
        float: $menu-float-dir;
        margin-right: $gutter-width + 20px;
        margin-top: 18px;

        > i {
          font-size: $font-size-large;
          vertical-align: text-bottom;
          margin-right: 10px;
        }
      }
    }
  }

  .head-menu {
    width: 100vw;
    color: $white;
    height: 30px;
    box-shadow: 0 3px 3px 2px $box-shadow-bg-color;
    background-color: $sky-400;
    display: grid;
    grid-template-columns: auto 1fr 200px;
    margin-left: -13px;
    padding-left: 13px;

    .anon-actions {
      cursor: pointer;
    }

    .anon-icon {
      display: inline-block;
      vertical-align: 3px;

      padding: 0 4px;
      i {
        font-size: 20px;
        margin-top: 2px;
      }
    }
    .anon-title {
      display: inline-block;
      text-transform: uppercase;
      /* vertical-align: 5px; */
      line-height: 2.2;
      font-size: 13px;
    }
  }
  .gru-class-assessment-report {
    padding-top: 10px;
  }
}
