#usm-standards-tags {
  margin-top: 20px;
  .usm-standards-tags-container {
    .usm-standards-tags-panel {
      display: flex;
      align-items: flex-end;
      .usm-standards-tags-visible-parts {
        .usm-standards-tags-blk {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          list-style: none;
          padding: 0;
          margin: 0;
          .usm-standards-tags {
            border: 1px solid $light-350;
            border-radius: 100px;
            padding: 3px 8px;
            background-color: $light-800;
            color: $dark-850;
            margin: 2px;
            line-height: 18px;
            display: flex;
            align-items: center;
            margin-right: 8px;
          }
          .usm-standards-tags span {
            font-weight: 400;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal;
            font-size: 12px;
            line-height: 1;
            max-height: 18px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
