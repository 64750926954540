.new-gru-bookmark-card {
  .panel {
    width: 220px;
    height: 210px;
    .panel-heading {
      padding: 0;
      .card-heading {
        .title {
          margin: 0 8px;
          color: $white;
          $lines-to-show: 2;
          $font-size: 13px;
          $line-height: 1.3;
          font-weight: bold;
          @include ellipsis(
            $font-size: $font-size,
            $line-height: $line-height,
            $lines-to-show: $lines-to-show
          );
          max-height: 40px;
          bottom: 0;
          position: absolute;
          border-bottom: none;
          z-index: 99;
        }
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
        background-color: $table-bg;
        height: 131px;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        &::after {
          display: block;
          position: absolute;
          z-index: 9;
          background-image: linear-gradient(
            to bottom,
            $bg-left-box-shadow 0,
            $gray-base 100%
          );
          margin-top: 52px;
          height: 60%;
          width: 100%;
          content: "";
        }
        .current-activity {
          position: absolute;
          bottom: 0;
          z-index: 99;
          .resume {
            font-size: 10px;
            font-weight: bold;
            color: $white;
            margin-left: 8px;
          }
          .title {
            border-bottom: none;
            padding-top: 0;
            a {
              color: $white;
              $font-size: 13px;
              $line-height: 1.3;
              $lines-to-show: 2;
              font-weight: bold;
              @include ellipsis(
                $font-size: $font-size,
                $line-height: $line-height,
                $lines-to-show: $lines-to-show
              );
            }
          }
          .usage-summary {
            a {
              color: $white;
              font-size: 13px;
              margin-left: 8px;
              font-weight: bold;
            }
          }
          .not-applicable {
            color: $white;
            font-size: 13px;
            margin-left: 8px;
            font-weight: bold;
          }
        }
      }
    }
    .panel-body {
      height: 22px;
      padding: 0;
      vertical-align: middle;
      display: flex;
      .items-icon {
        width: 50%;
        display: flex;
        span {
          display: flex;
          font-size: 12px;
        }
      }

      i.resources,
      i.questions,
      i.units {
        background-size: 13px 12px;
        display: block;
        width: 13px;
        height: 12px;
        margin: 5px 3px 5px 8px;
      }
      i.resources {
        background-image: url("gooru/images/collections_resource_icon.svg");
      }
      i.questions {
        background-image: url("gooru/images/collection-question-gray.svg");
      }
      i.units {
        background-image: url("gooru/images/units-gray.svg");
      }
      .bookmark-icon > i {
        height: 10px;
        width: 8px;
        font-size: 14px;
      }
      .timestamp {
        margin-left: 6px;
        font-size: 12px;
        font-style: italic;
        font-weight: bold;
      }
    }
    .panel-footer {
      background-color: $white;
      display: flex;
      height: 59px;
      padding: 0;
      .first-half {
        background-color: $gray-very-dark !important;
        width: 162px;
        height: 59px;
        display: flex;
        box-shadow: 1px 1px 6px 0 $navbar-default-toggle-icon-bar-bg;
        border-bottom-left-radius: 4px;
        span {
          width: 59px;
          i.assessment {
            background: url("gooru/images/navigation_assessment.svg") no-repeat;
            background-size: 52px 36px;
          }
          i.collection {
            background: url("gooru/images/collection-gray.svg") no-repeat;
            background-size: 52px 36px;
          }
          i.course {
            background: url("gooru/images/navigation_course.svg") no-repeat;
            background-size: 52px 36px;
          }
          i {
            margin: 12px 0 0 8px;
            width: 51px;
            height: 39px;
            object-fit: contain;
            display: block;
          }
        }
      }
      .second-half {
        width: calc(100% - 162px);
        border-bottom-right-radius: 4px;
        box-shadow: 1px 1px 6px 0 $navbar-default-toggle-icon-bar-bg;
        color: $gray-very-dark;
        span {
          cursor: pointer;
          i {
            font-size: 28px;
            margin: 15px;
          }
        }
      }
    }
  }
}
