.reports.pull-up-course-report {
  position: fixed;
  top: 100%;
  z-index: 1031;
  width: 768px;
  left: 0;
  right: 0;
  margin: auto;
  color: $suggestion-panel-color;

  .backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    cursor: pointer;
    opacity: 0.8;
    background-color: $gray-base;
  }

  .backdrop-pull-ups {
    .backdrop {
      background: none !important;
    }
  }

  .loading-spinner {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
  }

  .custom-text {
    width: 184px;
    text-align: center;
  }

  .font-small {
    font-size: 13px;
  }

  .font-small-avg-score {
    font-size: 13px;
    margin-top: -20px;
  }

  .course-report-container {
    background: $white-bold;
    width: 768px;
    max-height: 90vh;
    height: 90vh;

    .report-header {
      display: flex;
      height: 60px;
      box-shadow: 0 2px 5px 0 $disabled-bg-color;
      position: relative;
      z-index: 1;

      .report-breadcrumb {
        width: calc(100% - 50px);
        display: flex;

        .course-title {
          display: flex;
          align-items: center;
          max-width: 100%;
          padding-left: 15px;

          .course-title-value {
            width: 320px;
            float: left;
          }

          p {
            max-width: calc(100% - 45px);
            margin: 0;
            @include ellipsis(
              $font-size: 14px,
              $line-height: 1.2,
              $lines-to-show: 2
            );
          }

          .course-icon {
            width: 50px;
            height: 50px;
            background-image: url("../assets/gooru/images/course.svg");
            background-repeat: no-repeat;
            background-size: 30px 30px;
            background-position: 12px 12px;
          }
        }
      }

      .report-close-pullup {
        width: 50px;

        .close {
          color: $breadcrumb-color;
          margin-top: 20px;
          margin-right: 20px;
          font-size: 24px;
        }
      }
    }

    .report-content {
      max-height: calc(90vh - 60px);
      height: calc(90vh - 60px);
      overflow-y: auto;
      width: 100%;

      .report-carousel {
        position: relative;

        #report-carousel-wrapper {
          background-image: linear-gradient($dark-600, $dark-600);
          background-blend-mode: multiply;

          .course-background-cover {
            height: 251px;
            background-image: linear-gradient($dark-600, $dark-600);
            background-blend-mode: multiply;
          }
        }

        .report-carousel-content-container {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          display: flex;
          align-items: center;

          .report-carousel-content {
            width: 640px;
            margin: auto;
            position: relative;
            display: flex;

            .performance-display-container {
              margin-right: 15px;
              width: 190px;

              .course-performance-average {
                width: 185px;
                height: 185px;
                border-radius: 50%;
                font-weight: bold;
                text-indent: 10px;
                font-size: 60px;
                color: $white;
                position: relative;
              }
            }

            .course-report-chart {
              display: flex;
              align-items: center;
              width: 445px;
              position: relative;

              .report-bar-chart {
                margin-left: 28px;
              }
            }
          }
        }
      }

      .report-performance-tabular {
        width: 100%;

        .report-header-container {
          width: 100%;
          height: 50px;
          box-shadow: 0 2px 5px 0 $disabled-bg-color;
          display: inline-grid;
          position: relative;
          z-index: 1;
          align-items: center;

          .filter-btns {
            display: inline-grid;
            width: 120px;
            justify-self: end;

            span {
              display: block;
              margin-top: -10px;
            }

            i {
              margin-top: 3px;
            }

            .performance-icon {
              width: 80px;
              text-align: center;
              opacity: 1;

              &.active {
                opacity: 1;
              }
            }
          }
        }

        .report-view-container {
          margin-bottom: 20px;
        }
      }
    }
  }
}
