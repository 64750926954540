.gru-audio-player {
  .audio-player {
    display: flex;

    > span {
      padding: 0 15px;
    }

    .record-actions {
      .record-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 28px;
        height: 28px;
        cursor: pointer;
        display: block;

        i {
          font-size: 24px;
          width: inherit;
          height: inherit;
          color: $nobel-gray;
          display: block;
          text-align: center;
          line-height: 30px;
          border: 1px solid $nobel-gray;
          border-radius: 50%;
        }
      }
    }

    .audio-play {
      width: 24px;
      height: 24px;
      display: block;

      i {
        width: inherit;
        height: inherit;
        background-color: $gray-harder;
        color: $white;
        border-radius: 50%;
        display: block;
        text-align: center;
        line-height: 24px;
        cursor: pointer;
      }
    }

    .audio-progress {
      width: 96px;
      height: 8px;
      background-color: rgba($color: $gray-harder, $alpha: 0.8);
      display: block;
      position: relative;
      border-radius: 5px;
      margin-top: 8px;

      .audio-wave {
        position: absolute;
        left: 30px;
        top: 6px;
        display: none;

        span {
          display: block;
          bottom: 0px;
          width: 3px;
          height: 5px;
          background: $violet-b6;
          position: absolute;
          animation: audio-wave 1.5s infinite ease-in-out;
        }

        span:nth-child(2) {
          left: 8px;
          animation-delay: 0.2s;
        }

        span:nth-child(3) {
          left: 16px;
          animation-delay: 0.4s;
        }

        span:nth-child(4) {
          left: 24px;
          animation-delay: 0.6s;
        }

        span:nth-child(5) {
          left: 32px;
          animation-delay: 0.8s;
        }

        @keyframes audio-wave {
          0% {
            height: 5px;
            transform: translateY(0px);
            background: $blue-db;
          }

          25% {
            height: 40px;
            transform: translateY(20px);
            background: $blue-db;
          }

          /*effect is to animate the height of each span from 5px to 30px*/
          /*translateY makes Y axis move down to give the effect that it is growing from the center*/

          50% {
            height: 5px;
            transform: translateY(0px);
            background: $blue-db;
          }

          100% {
            height: 5px;
            transform: translateY(0px);
            background: $blue-db;
          }
        }
      }

      .progress-filling {
        width: 0;
        position: absolute;
        left: 0;
        height: inherit;
        border-radius: 5px;
        background-color: $sky-400;

        .progress-pointer {
          width: 16px;
          height: 16px;
          background-color: $sky-400;
          right: -8px;
          top: -4px;
          display: block;
          border-radius: 50%;
          position: absolute;
        }
      }
    }

    &.recording {
      .record-actions {
        .record-icon {
          i {
            color: $white;
            border-color: $bg-red-bar;
            background-color: $bg-red-bar;
          }
        }
      }

      .audio-play {
        i {
          background-color: $gray-harder;
          color: $white;
        }
      }
      .audio-progress {
        background-color: rgba($color: $gray-harder, $alpha: 0.3);
      }
      .progress-filling {
        width: 0;
        .progress-pointer {
          background-color: $sky-400;
        }
        .audio-wave {
          display: block;
        }
      }
    }

    &.enable {
      .audio-play {
        i {
          cursor: pointer;
          background-color: $sky-400;
        }
      }
      .audio-progress {
        background-color: $gray-harder;
        .progress-filling {
          background-color: $bg-progress-filling;
          .progress-pointer {
            background-color: $blue-400;
          }
        }
      }
    }
  }
}
