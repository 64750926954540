.suggestion-panel {
  height: auto;
  display: grid;
  align-items: center;

  .suggestion-header {
    display: grid;
    height: 49px;
    grid-template-columns: 49px 1fr;

    .suggestion-icon {
      width: 100%;
      background-position: center;
      height: 100%;
      background-repeat: no-repeat;

      &.assessment {
        background-image: url("/assets/gooru/images/assessment-orange.svg");
      }

      &.collection {
        background-image: url("/assets/gooru/images/collection-orange.svg");
      }
    }

    .suggestion {
      border-bottom: solid 1px $dark-50;
      height: 100%;
      display: grid;
      grid-template-columns: 1fr max-content 49px 49px;
      align-items: center;

      &.hide-play {
        grid-template-columns: 1fr max-content 49px;
      }

      .title {
        height: auto;
        font-size: 15px;
        font-weight: bold;
        color: $font-sun-color-orange-100;
        cursor: pointer;
      }

      .content-count {
        display: flex;
        margin: 0 5px;
        align-items: center;
        justify-content: space-around;

        span {
          opacity: $opacity-light;
          display: flex;

          &.invisible {
            width: 0;
          }
        }
      }

      .action {
        align-items: center;
        display: flex;
        justify-content: center;
        color: $bg-tan-hide-orange-100;
        height: 49px;
        border-left: 1px solid $breadcrumb-color;
        border-right: 1px solid $breadcrumb-color;
        cursor: pointer;

        i {
          height: 24px;
        }
      }

      .teacher-suggestion {
        display: flex;
        width: 100%;
        height: 100%;

        .icon {
          width: 100%;
          background-position: center;
          height: 100%;
          background-repeat: no-repeat;
          background-image: url("/assets/gooru/images/account-cirlce-orange.svg");
        }
      }

      .suggestion-icon {
        display: flex;
        justify-content: center;
        color: $bg-tan-hide-orange-100;
        cursor: default;

        &.suggest {
          cursor: pointer;
        }

        i {
          width: max-content;
          height: max-content;
          margin: auto;
        }
        &.disableSuggestion {
          pointer-events: none;
          opacity: 0.5;
        }
      }
    }
  }
}
