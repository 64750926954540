.controller.content.courses.play {
  > article {
    > section {
      margin-top: 150px;
      @media (min-width: $screen-sm) {
        margin-top: 120px;
      }
      @media (min-width: $screen-md) {
        margin-top: 100px;
      }
    }
    > header {
      .close-icon {
        width: 55px;
        float: left;
        padding: 0;
        cursor: pointer;
        i {
          color: $white;
          font-size: 37px;
        }
      }
      padding: 20px 20px 8px 70px;
      position: fixed;
      width: 100%;
      @media (min-width: $screen-md) {
        padding: 20px 20px 30px 70px;
        width: 82%;
      }
      nav {
        height: 25px;
        padding: 10px 0 0;
        a {
          color: $white;
          border-bottom: none;
          margin: 0 5px;
          padding: 0;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      .actions {
        text-align: $menu-float-dir;
      }
    }

    #content {
      margin: auto;
    }
    #information {
      padding-top: 15px;
      margin-top: 150px;

      > .content {
        padding-bottom: 10px;

        .description p {
          line-height: 1.6;
          margin-bottom: 0;
        }
      }

      > .panel.aside {
        margin-top: 20px;

        .col-sm-4 {
          border-right: 1px solid $gray-lighter;

          @media (max-width: $screen-xs-max) {
            border-right: none;
          }

          strong {
            display: block;
            padding-bottom: 15px;
          }

          ul {
            @media (max-width: $screen-xs-max) {
              border-bottom: 1px solid $gray-lighter;
              padding: 0 0 30px;
              margin: 0 0 30px;
            }
          }

          &:last-child {
            border-right: none;
          }
        }

        .gru-user-icons {
          text-align: $tab-float-dir;

          .item {
            margin-right: 10px;
            .img-circle {
              height: 30px;
              width: 30px;
              margin-bottom: 5px;
            }
          }
        }

        .col-sm-12 {
          strong {
            display: block;
            margin-top: 25px;
            padding-top: 20px;
            padding-bottom: 10px;
            border-top: 1px solid $gray-lighter;
          }
        }
      }
    }
    &.content-view {
      #information {
        display: none;
      }
    }
    &.information-view {
      #content {
        display: none;
      }
    }
  }

  > aside .row {
    position: relative;
    margin-left: 0;

    > div {
      float: none;
      width: auto;
      padding-bottom: 20px;
      border-bottom: 1px solid $gray-lighter;

      strong {
        display: block;
        margin: 15px 0 10px 0;
        font-size: $font-size-large;
        font-weight: 300;
      }

      &.gru-user-icons {
        text-align: $tab-float-dir;
        ul {
          text-align: center;
        }
        .item {
          margin: 0 30px 20px 0;
          .user-name {
            font-size: $font-size-base;
          }
          .img-circle {
            height: 30px;
            width: 30px;
            margin-bottom: 5px;
          }
        }
      }
    }

    &:last-child {
      > div:last-child {
        padding-bottom: 30px;
        border-bottom: 0 none;
      }
    }
  }

  .accordion-course,
  .accordion-unit,
  .accordion-lesson {
    > li {
      padding: 10px 0;
    }
  }

  .accordion-course {
    margin-top: 20px;

    // Start with the styles in common,
    // then continue with a hierarchy of styles
    .gru-accordion-lesson > .view > .panel-heading,
    .gru-accordion-lesson-item .panel-heading {
      > .detail {
        .icons {
          background-image: url("/assets/gooru/share.png");
          width: 22px;
          height: 16px;
          display: block;
          background-size: cover;
          margin-top: 15px;
        }
        > div:first-of-type {
          > span {
            display: block;
            line-height: 3.5;
            text-align: $menu-float-dir;

            @media (min-width: $screen-lg) {
              display: inline;
            }
          }

          &.mixed {
            @media (max-width: $screen-lg-min) {
              vertical-align: top;
            }

            > span {
              line-height: 1.4;

              @media (min-width: $screen-lg) {
                line-height: 3.5;
              }
            }

            > span:last-child {
              @media (min-width: $screen-lg) {
                border-left: 1px solid $gray;
                padding-left: 4px;
                margin-left: 2px;
              }
            }
          }
        }
      }
    }

    .panel-heading {
      position: relative;
      border-bottom: 1px solid $gray-light;

      h3 > a,
      strong > a {
        color: $text-color;
      }

      h3 {
        display: inline-block;
        margin: 0 0 10px;
        padding: 0 20px 0 0;
        font-weight: bold;
        border-right: 1px solid $gray-light;
        line-height: 2.5;
      }

      strong {
        margin-left: 15px;
        font-weight: normal;
        font-size: 16px;
      }
    }

    .gru-accordion {
      &.view {
        > .edit {
          display: none;
        }

        > .view {
          .panel-heading {
            padding-right: 20px;
            margin-bottom: 0;

            .detail {
              float: $menu-float-dir;

              > span {
                line-height: 3.5;
                vertical-align: middle;
                margin-right: 2px;
                margin-left: 2px;
              }
            }
          }

          &.collapsed {
            .panel-heading {
              margin-bottom: 0;
              border-bottom: 0 none;
            }
            .panel-body {
              display: none;
            }
          }
        }
      }
    }

    .gru-accordion-unit {
      .panel-body {
        padding: 10px 15px 15px;

        .overview {
          padding: 10px 10px 40px 10px;
          margin-bottom: 10px;

          .big-ideas,
          .essential-questions {
            line-height: 1.4;

            span,
            p {
              color: $white;
            }
            p {
              margin-top: 5px;
              font-size: $font-size-base;
              margin-bottom: 0;
            }
          }

          .tags {
            margin-top: 5px;
            color: $white;
          }
        }

        .details {
          width: auto;
        }

        ol.accordion-unit {
          clear: both;
        }

        .col-sm-6 {
          label {
            width: 100%;

            span {
              display: block;
            }

            textarea {
              font-size: $font-size-base;
              height: 80px;
              resize: none;
              width: 100%;
              margin-top: 6px;

              &:focus {
                outline: 0 none;
              }
            }
          }
        }

        .data-row {
          > span {
            display: block;
            margin: 10px 0;
          }
        }
      }

      &.view {
        > .view > .panel-heading {
          > strong {
            display: inline-block;
            vertical-align: middle;
            margin-top: -6px;

            @include text-overflow();
            max-width: 34%;

            @media (min-width: $screen-lg) {
              max-width: 45%;
            }
          }
        }

        > .expanded > .panel-body {
          background-color: $dark-400;
          .details {
            text-align: center;
            a {
              color: $white;
            }
          }
        }
      }
    }

    .gru-accordion-lesson {
      &.view {
        > .view > .panel-heading {
          > strong {
            display: inline-block;
            vertical-align: middle;
            margin-top: -6px;

            @include text-overflow();
            max-width: calc(100% - 35%);

            @media (min-width: $screen-lg) {
              max-width: 31%;
            }
          }
        }
        > .expanded > .panel-body {
          background-color: $dark-300;

          .overview {
            padding: 10px 10px 40px 10px;
            margin-bottom: 10px;

            .description,
            .quiding-questions {
              line-height: 1.4;

              span,
              p {
                color: $white;
              }
              p {
                margin-top: 5px;
                font-size: $font-size-base;
                margin-bottom: 0;
              }
            }

            .tags {
              margin-top: 5px;
              color: $white;
            }
          }
        }

        .accordion-lesson {
          > li.gru-accordion-lesson-item .panel-heading {
            border-bottom: 0 none;
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 3px;
            padding-bottom: 8px;

            > h3 {
              border-right: 0 none;
              padding-right: 10px;
              margin-bottom: 0;
              vertical-align: top;
            }

            > .title {
              > img,
              > .image-placeholder {
                display: inline-block;
                width: 50px;
                height: 40px;
                vertical-align: top;
              }

              > .image-placeholder {
                border-radius: 3px;
                background-color: $gray-lighter;
              }

              .lesson-title-container {
                max-width: calc(100% - 30%);
              }

              > div {
                border-left: 1px solid $gray-light;
                display: inline-block;
                margin-left: 10px;
                line-height: 1.4;
                max-width: 40%;

                > strong {
                  color: $text-color;
                  display: block;
                  line-height: 1.2;
                  margin-bottom: 5px;
                  @include text-overflow();
                }

                > span {
                  color: $gray-light;
                  margin-left: 15px;
                  text-transform: capitalize;
                }
              }
            }
          }

          + div {
            padding: 10px 0 5px;

            button {
              font-size: $font-size-base;
              padding: 5px 10px;
            }
          }
        }
      }
    }
  }

  .details {
    margin-top: 10px;
    display: table;
    width: 330px;
    padding: 0;
    border-bottom: 2px solid $light-300;
    i,
    span {
      vertical-align: middle;
    }
  }
}

.iframe-panel {
  .controller.content.courses.play {
    header {
      position: absolute;
    }
  }
}
