.gru-confetti {
  height: 94%;
  width: 100%;
  margin: 0;
  top: 0;
  bottom: 0;
  padding: 0;
  display: block;
  z-index: 999999;
  position: fixed;

  #confetti {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }
}
