.reports.pull-up-dca-external-collection-report {
  position: fixed;
  top: 100%;
  z-index: 1031;
  width: 768px;
  left: 0;
  right: 0;
  margin: auto;
  color: $suggestion-panel-color;

  .backdrop {
    background-color: $backdrop-color;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    cursor: pointer;
    opacity: 0.8;
    background-color: $gray-base;
  }

  .backdrop-pull-ups {
    .backdrop {
      background: none !important;
    }
  }

  .loading-spinner {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
  }

  .dca-external-collection-report-container {
    background: $white-bold;
    width: 768px;
    max-height: 90vh;
    height: 90vh;

    .report-header {
      display: flex;
      height: 60px;
      box-shadow: 0 2px 5px 0 $disabled-bg-color;
      position: relative;
      z-index: 1;

      .report-header-info {
        width: calc(100% - 50px);
        display: flex;
        justify-content: space-between;

        .collection-title {
          display: flex;
          align-items: center;
          max-width: 450px;
          padding-left: 20px;

          p {
            margin: 0;
            max-width: 410px;
            @include ellipsis(
              $font-size: 14px,
              $line-height: 1.2,
              $lines-to-show: 2
            );
          }

          .collection-icon {
            width: 40px;
            height: 50px;
            background-repeat: no-repeat;
            background-size: 30px 30px;
            background-position: 0 12px;

            &.assessment {
              background-image: url("../assets/gooru/images/assessment-gray.svg");
            }

            &.collection {
              background-image: url("../assets/gooru/images/collection_gray.svg");
            }
          }
        }

        .dca-activity-date {
          font-size: 15px;
          font-weight: bold;
          align-self: center;
          padding-right: 20px;
        }
      }

      .report-close-pullup {
        width: 50px;

        .close {
          color: $breadcrumb-color;
          margin-top: 20px;
          margin-right: 20px;
          font-size: 24px;
        }
      }
    }

    .report-content {
      max-height: calc(90vh - 60px);
      height: calc(90vh - 60px);
      overflow-y: auto;
      width: 100%;

      .report-carousel {
        position: relative;

        #report-carousel-wrapper {
          background-image: linear-gradient($dark-600, $dark-600);
          background-blend-mode: multiply;

          .collection-background-cover {
            height: 251px;
            background-image: linear-gradient($dark-600, $dark-600);
            background-blend-mode: multiply;
          }

          .carousel-control {
            opacity: 1;
            background: none;

            &.in-active {
              opacity: 0.3;
              pointer-events: none;
            }

            &.left {
              z-index: 2;
              width: 45px;

              .arrow-left {
                width: 25px;
                height: 56px;
                display: block;
                background-image: url("../assets/gooru/images/arrow-left.svg");
                background-repeat: no-repeat;
                margin-left: 15px;
                position: relative;
                top: 42%;
              }
            }

            &.right {
              z-index: 2;
              width: 45px;

              .arrow-right {
                width: 25px;
                height: 56px;
                display: block;
                background-image: url("../assets/gooru/images/arrow-right.svg");
                background-repeat: no-repeat;
                margin-left: 10px;
                position: relative;
                top: 42%;
              }
            }
          }
        }

        .report-carousel-content-container {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          display: flex;
          align-items: center;

          .report-carousel-content {
            width: 640px;
            margin: auto;
            position: relative;
            display: flex;

            .performance-display-container {
              margin-right: 15px;
              margin-top: 15px;
              width: 190px;

              .collection-performance-average {
                width: 185px;
                height: 185px;
                border-radius: 50%;
                line-height: 185px;
                text-align: center;
                font-weight: bold;
                text-indent: 10px;
                font-size: 60px;
                color: $white;
              }

              .collection-performance-timespent {
                width: 185px;
                height: 185px;
                border-radius: 50%;
                line-height: 185px;
                text-align: center;
                font-weight: bold;
                text-indent: 10px;
                font-size: 30px;
                color: $white;
                background-color: $blue-400;
              }
            }

            .collection-report-chart {
              display: flex;
              align-items: center;
              width: 445px;
              position: relative;

              .report-bar-chart {
                margin-left: 28px;
              }
            }
          }
        }
      }

      .report-performance-tabular {
        width: 100%;

        .report-header-container {
          width: 100%;
          height: 50px;
          box-shadow: 0 2px 5px 0 $disabled-bg-color;
          display: flex;
          position: relative;
          z-index: 1;

          .taxonomy-details {
            width: 278px;
            height: 50px;

            .gru-taxonomy-tag-list {
              .gru-taxonomy-tag {
                max-width: 200px;
                width: 200px;
                border: none;
                background-color: $suggestion-panel-color;
                border-radius: 0;
                color: $white;
                height: 50px;

                .standard-tag {
                  width: 200px;
                  padding-left: 10px;
                  padding-right: 10px;
                  padding-top: 13px;

                  b {
                    color: $white;
                  }
                }
              }

              .non-visible-tags {
                margin-top: 10px;
                margin-left: 10px;
                background: $suggestion-panel-color;
                width: 35px;
                color: $white;
                border-radius: 4px;
              }

              .popover {
                background: $white;
                margin-left: 5px;

                .arrow::after {
                  border-left-color: $white;
                  border-right-color: $white;
                }

                .popover-content {
                  .gru-taxonomy-tag {
                    max-width: 300px;
                    width: 270px;
                    border-radius: 4px;

                    .standard-tag {
                      width: 270px;
                    }
                  }
                }
              }
            }
          }

          .report-action-btns {
            display: flex;

            .view-btn-actions {
              display: flex;
              width: 220px;

              i {
                margin-top: 5px;
              }

              span {
                display: block;
                margin-top: -10px;
              }

              .grid-view-icon,
              .list-view-icon {
                width: 40px;
                text-align: center;
                opacity: 0.5;
                cursor: pointer;

                &.active {
                  color: #00a1e7;
                  opacity: 1;
                }
              }
            }

            .filter-btns {
              display: flex;

              span {
                display: block;
                margin-top: -10px;
              }

              i {
                margin-top: 5px;
              }

              .performance-icon,
              .reaction-icon,
              .timespent-icon {
                width: 80px;
                text-align: center;
                opacity: 0.5;
                cursor: pointer;

                &.active {
                  opacity: 1;
                }
              }

              .timespent-icon {
                &.collection {
                  pointer-events: none;
                  cursor: default;
                }
              }

              .performance-icon {
                pointer-events: none;
                cursor: default;

                &.collection {
                  visibility: hidden;
                }
              }
            }
          }
        }

        .report-view-container {
          margin-bottom: 20px;
        }

        &.grid {
          .report-header-container {
            .report-action-btns {
              .filter-btns {
                .reaction-icon,
                .timespent-icon {
                  visibility: hidden;
                }
              }
            }
          }
        }
      }
    }
  }
}
