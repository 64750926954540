.oca-summary-report-pull-up {
  position: fixed;
  top: 100%;
  z-index: 1031;
  width: 768px;
  left: 0;
  right: 0;
  margin: auto;
  color: $suggestion-panel-color;
  @media screen and (max-width: $screen-sm - 1) {
    width: 100%;
  }

  .backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    cursor: pointer;
    opacity: 0.8;
    background-color: $gray-base;
  }

  .oca-report-container {
    background: $white;
    max-height: 90vh;
    height: 90vh;
    display: grid;
    grid-template-rows: 56px auto 1fr;

    .report-header {
      box-shadow: 0 1px 3px 0 $navbar-box-shadow;
      display: grid;
      grid-template-columns: 1fr 56px;
      .title {
        padding: 10px;
        font-weight: bold;
      }

      .close {
        align-items: center;
        display: flex;
        justify-content: center;

        i {
          font-size: 24px;
        }
      }
    }

    .performance-summary {
      display: grid;
      grid-template-rows: 20px 1fr;
      position: relative;
      height: 240px;

      &:before {
        content: "";
        position: absolute;
        opacity: 0.3;
        width: 100%;
        height: 100%;
        background-color: rgb(242, 235, 235);
      }

      %webkit-container {
        -webkit-mask-size: cover;
        -webkit-mask-repeat: no-repeat;
        background: currentColor;
        -webkit-mask-image: url("gooru/images/combined-shape.svg");
      }

      %container {
        mask-size: cover;
        mask-repeat: no-repeat;
        background: currentColor;
        mask-image: url("gooru/images/combined-shape.svg");
      }

      .performance {
        text-align: center;
        display: grid;
        font-weight: bold;
        color: $white;
        justify-content: center;
        z-index: 1;

        .score {
          font-size: 90px;
          width: max-content;
          height: max-content;
        }

        .time-spent {
          font-size: 20px;
          width: max-content;
          height: max-content;
          justify-self: center;
        }
      }

      .performance-img {
        grid-row-start: 2;
        @extend %webkit-container;
        @extend %container;
        display: grid;
        align-self: end;
      }
    }

    .report-summary-container {
      overflow-y: auto;
      padding-bottom: 10px;

      .summary-row-container {
        height: 56px;
        box-shadow: 0 1px 3px 0 $navbar-box-shadow;
        background-color: $white;
        display: grid;
        cursor: pointer;
        grid-template-columns: 1fr 56px;

        .summary-info {
          display: flex;
          flex-direction: column;
          padding-left: 20px;
          padding-top: 10px;

          .time-spent {
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            line-height: 1.2;
            letter-spacing: normal;
            color: $dark-400;
          }

          .period {
            font-size: 15px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.33;
            letter-spacing: normal;
            color: $dark-400;
          }
        }

        .performance {
          font-size: 20px;
          font-weight: bold;
          color: $white-bold;
          align-items: center;
          display: flex;
          justify-content: center;
        }
      }
    }

    &.multi-class {
      .report-header {
        grid-template-columns: auto 1fr 56px;
        .title {
          display: grid;
          grid-template-rows: 1fr 1fr;
          align-items: center;
          padding: 0;
          padding-left: 10px;
        }
        .secondary-classes {
          display: grid;
          position: relative;
          align-items: center;
          justify-self: center;
          .active-class {
            display: grid;
            align-items: center;
            grid-template-columns: auto auto;
            justify-content: left;
            font-weight: bold;
            cursor: pointer;
            text-transform: capitalize;
          }
          .class-container {
            position: absolute;
            align-items: center;
            display: none;
            z-index: 99;
            background-color: $white;
            box-shadow: 0 1px 3px 0 $navbar-box-shadow;
            top: 55px;
            width: max-content;
            .secondary-class {
              height: 35px;
              line-height: 35px;
              padding: 0 8px;
              text-transform: capitalize;
              cursor: pointer;
              &.active,
              &:hover {
                background-color: $light-200;
                font-weight: bold;
                border: 1px solid $light-100;
              }
            }
          }
        }
      }
    }
  }
}
