.player .gru-navigation {
  // temporary for GG-92
  height: $player-navigation-height;
  float: $menu-float-dir;
  width: 100%;
  z-index: 10;
  @media (min-width: $screen-md) {
    padding-left: 250px;
    height: $player-navigation-md-height;
  }
  .content {
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    -webkit-align-content: stretch;
    align-content: stretch;
    display: -webkit-flex;
    display: flex;
    @media (min-width: $screen-md) {
      overflow: hidden;

      .hamburger-icon {
        display: none;
      }
    }
    .navigation-bar {
      width: 100%;
      height: 57px;
      background-color: $dark-400;
      padding: 0 30px;
      span {
        width: 100%;
        height: 100%;
        display: inline-block;
        -webkit-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-justify-content: flex-start;
        justify-content: flex-start;
        -webkit-align-items: center;
        align-items: center;
        -webkit-align-content: stretch;
        align-content: stretch;
        display: -webkit-flex;
        display: flex;
        cursor: pointer;

        > * {
          color: $white;
        }
      }
    }
    .reaction-bar {
      margin-top: 20px;
      padding-left: 60px;
      padding-right: 60px;
      @media (max-width: $screen-sm-max) {
        padding-left: 15px;
        padding-right: 15px;
      }

      .indicators {
        text-align: $menu-float-dir;
        width: 16px;
        display: inline-flex;
        span {
          width: 3px;
          height: 2px;
          margin-right: 2px;
          display: inline-block;
          &.context.off {
            background-color: $brand-danger;
          }
          &.context.on {
            background-color: $brand-success;
          }
          &.role.teacher {
            background-color: $brand-danger;
          }
          &.role.student {
            background-color: $brand-success;
          }
          &.rt.off {
            background-color: $brand-danger;
          }
          &.rt.on {
            background-color: $brand-success;
          }
        }
      }

      > div > div {
        margin-bottom: 0;
        width: 100%;
        -webkit-flex-direction: column;
        flex-direction: column;
        -webkit-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-align-items: flex-start;
        align-items: flex-start;
        -webkit-align-content: stretch;
        align-content: stretch;
        display: -webkit-flex;
        display: flex;
        padding: 0;
        @media (min-width: $screen-md) {
          margin-bottom: 16px;
          -webkit-flex-direction: row;
          flex-direction: row;
          -webkit-align-items: center;
          align-items: center;
          -webkit-justify-content: space-between;
          justify-content: space-between;
          padding: 15px;
        }
        > * {
          width: 100%;
          &.btn {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
          }
        }
        @media (min-width: $screen-md) {
          button {
            width: initial;
          }
        }
      }
    }
    .hamburger-icon,
    .x-icon {
      font-size: 2.2rem;
      cursor: pointer;
    }
  }
}
