.player-pull-up {
  width: 100%;
  height: 100vh;
  height: -webkit-fill-available;
  position: fixed;
  z-index: 1050;
  background: $grey-background;
  left: 0;
  right: 0;
  bottom: 100%;
  margin-left: auto;
  margin-right: auto;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @media screen and (max-width: 767px) {
    height: 100%;
  }

  .iframe-player-container {
    height: 100%;

    .iframe-container {
      visibility: hidden;

      &.not-loading {
        visibility: visible;
      }
      width: 100%;
      height: 100%;

      iframe {
        width: 100%;
        height: 100%;
        border: 0;
      }
      @media screen and (max-width: 767px) {
        height: 100%;
        iframe {
          height: 100%;
        }
      }
    }

    .loading-container {
      height: 100%;

      .player-container {
        display: grid;
        grid-template-columns: 368px auto;
        height: 100%;
        width: 100%;

        .loading-panel {
          .loading-content {
            height: calc(100% - 35px);
            margin: 5px;
            border-radius: 5px;
            background-color: $white;
            color: $dark-500;

            .header-panel {
              height: 56px;
              display: grid;
              grid-template-columns: 56px auto;

              .icon-container {
                display: flex;
                justify-content: center;
                align-items: center;
                background-repeat: no-repeat;
                background-position: center;

                &.collection,
                &.collection-external {
                  background-image: url("gooru/images/add_collection.svg");
                }
                &.assessment,
                &.assessment-external {
                  background-image: url("gooru/images/add_assessment.svg");
                }
                &.offline-activity {
                  background-image: url("/assets/gooru/offline-activity-default.png");
                }
                &.meeting {
                  background-image: url("/assets/gooru/video-conference-ico.png");
                }
              }

              .title-container {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                .collection-title {
                  color: $dark-400;
                  font-size: 14px;
                  @include ellipsis(
                    $font-size: 14px,
                    $line-height: 1.4,
                    $lines-to-show: 1
                  );
                }
              }
            }

            .body-panel {
              box-shadow: 0 -3px 4px $box-shadow-bg-color;
              position: relative;
              height: calc(100% - 56px);
              justify-content: center;
              align-items: center;
              display: flex;
              flex-direction: column;

              .message {
                padding: 10px;
              }

              .loading-icon {
                background: url("gooru/loader.svg") no-repeat center;
                display: inline-block;
                width: 180px;
                height: 60px;
              }
            }
          }
        }
        @media screen and (min-width: $screen-320) and(max-width: $screen-568) {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
