// #note-tool{
.note-tool {
  background-color: $white;
  width: inherit;
  height: inherit;
}

.height-width-parent {
  width: inherit;
  height: inherit;
}
.relative-position {
  position: relative;
}
.frame-height-width {
  height: calc(100% - 12px);
  width: inherit;
}
.note-header-box {
  border-right: 2px solid $gray-border;
  border-top: 2px solid $gray-border;
  border-left: 2px solid $gray-border;
  height: 25px;
}
// }
