.gru-content-suggestion {
  top: 50px;
  width: 320px;
  height: max-content;
  object-fit: contain;
  border-radius: 4px;
  background-color: $white;
  z-index: 1111;
  position: fixed;
  box-shadow: 0 0 6px 1px $table-box-shadow-07,
    0 2px 4px 0 $navbar-default-toggle-icon-bar-bg;
  margin: 0 auto;
  right: 10px;
  border-radius: 5px;

  .modal-header {
    justify-content: center;
    height: 50px;
    align-items: center;
    background-color: $blue-bg;
    display: flex;
    padding: 0;
    border-radius: 5px 5px 0px 0px;

    .suggestion-title {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;
      color: $white;
      text-transform: capitalize;
    }
  }

  .modal-body {
    border: none;
    display: flex;
    margin-right: 1px;
    padding: 0;
    flex-direction: column;

    .image-container {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 10px;

      .suggestion-img {
        background-repeat: no-repeat;
        width: 60.28px;
        height: 60.31px;
        display: block;
        background-size: contain;
      }

      &.show-mastery {
        .suggestion-img {
          background-image: url("gooru/reroute-suggestion.png");
        }
      }

      &.show-suggestion {
        .suggestion-img {
          background-image: url("gooru/reroute-suggestion.png");
        }
      }
    }

    .suggestion-box {
      margin: auto;
      top: 10px;
      right: 0;
      left: 0;
      background-color: $antique-white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      width: 120px;
      height: 120px;
    }

    .action {
      display: grid;
      grid-template-columns: auto auto;
      height: 40px;
      justify-content: flex-end;
      padding: 0 10px;

      .accept {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        padding: 5px;
        width: 100px;
        letter-spacing: -0.24px;
        text-align: right;
        color: $blue-bg;
        cursor: pointer;
        height: 40px;
        position: relative;
      }

      .ignore {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        padding: 5px;
        width: 100px;
        letter-spacing: -0.24px;
        color: $blue-bg;
        cursor: pointer;
        height: 40px;
        order: 0;
        text-align: left;
        position: absolute;
      }

      .ignore:hover,
      .accept:hover {
        text-decoration-line: underline;
        color: $blue-hover-bg;
      }

      .ignore:focus,
      .accept:focus {
        background: $white-backgraound;
        box-shadow: 0px 0px 0px 1px $white-backgraound, 0px 0px 0px 2px $blue-bg,
          0px 0px 0px 4px $blue-pale;
        text-decoration-line: underline;
        color: $blue-focus-bg;
      }
    }

    .suggestion-container {
      display: grid;
      grid-template-columns: max-content 1fr;
      margin-left: 12px;
      margin-right: 14px;
      border-left: 0;
      border-right: 0;
      border: 1px solid $breadcrumb-color;
      border-radius: 8px;

      .thumbnail-img {
        width: 54px;
        height: 54px;
        margin-top: 6px;
        margin-left: 6px;
        margin-bottom: 6px;
        border-radius: 5px;
        background-image: url("gooru/thumbnail.png");

        img {
          width: 100%;
          height: 100%;
        }
      }

      .suggestion-info-panel {
        display: grid;
        grid-template-columns: 1fr;

        .suggestion-info {
          display: grid;
          align-items: center;
          margin: auto;

          .title {
            font-size: 15px;
            font-weight: bold;
            color: $black-94b;
          }
        }

        // .icons {
        //   display: flex;
        //   align-items: center;
        //   justify-content: center;
        //   position: relative;
        //
        //   .suggestion {
        //     position: absolute;
        //     right: 5px;
        //     top: 10px;
        //     background: $table-bg;
        //
        //     i {
        //       color: $tan-hide;
        //       background: $white;
        //       width: 20px;
        //       height: 15px;
        //       border-radius: 50px;
        //     }
        //   }
        //
        //   i {
        //     &.collection {
        //       width: 30px;
        //       height: 30px;
        //       background-size: 100%;
        //       background-repeat: no-repeat;
        //       background-image: url("/assets/gooru/images/collection-orange.svg");
        //     }
        //     &.assessment {
        //       width: 30px;
        //       height: 30px;
        //       background-size: 100%;
        //       background-repeat: no-repeat;
        //       background-image: url("/assets/gooru/images/assessment-orange.svg");
        //     }
        //   }
        // }
      }
    }

    .suggestion-details {
      text-align: center;

      .title {
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        padding: 10px 0;
        color: $dark-night;
        padding-bottom: 0;
      }

      .description {
        font-size: 13px;
        text-align: center;
        color: $dark-700;
        padding: 10px;
        line-height: 1.5;

        span {
          font-weight: bold;
          color: $orange-153;
        }
      }

      .taxonomy {
        .gru-taxonomy-tag-list {
          .gru-taxonomy-tag {
            background-color: $dark-400;
            border-radius: 50px;

            .tag-label {
              color: $white;
              font-size: 12px;
            }

            .tag-caption {
              display: none;
            }
          }
        }
      }
    }
  }
}
