.struggling-competency-report {
  background-color: $white;
  position: fixed;
  bottom: 0;
  z-index: 1000;
  width: 50vw;
  height: calc(100vh - 10vh);
  left: 0;
  right: 0;
  margin: 0 auto;
  display: none;
  @media screen and (max-width: $screen-md) {
    width: 70%;
  }
  @media screen and (max-width: $screen-sm) {
    width: 100%;
  }

  .backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    cursor: pointer;
    opacity: 0.8;
    background-color: $gray-base;
  }

  %scroll-style {
    &::-webkit-scrollbar {
      height: 6px;
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: $gray-light;
      border: 1px solid $gray-light;
      border-radius: 5px;
    }
  }

  .struggling-competency-report-container {
    background-color: $white;
    height: 100%;

    .struggling-competency-report-header {
      display: grid;
      grid-template-columns: 30px 1fr;
      align-items: center;
      border-bottom: 1px solid $gray-80;
      box-shadow: 0 0 5px $gray-80;
      padding: 10px;
      cursor: pointer;

      .struggling-competency-report-header-panel {
        .struggling-competency-report-title {
          font-size: 15px;
          font-weight: bold;
          display: grid;
          grid-template-columns: 1fr;

          span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .struggling-competency-report-sub-titles {
          display: inline-flex;
          list-style: none;
          margin: 0;
          line-height: 1;
          padding: 0;

          li {
            padding: 0 5px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            .lo-content {
              cursor: pointer;
              .tag-tooltip {
                display: none;
              }
            }

            &:last-child {
              border: none;
            }

            &:first-child {
              border-right: 1px solid $gray-80;
              padding-left: 0;
            }
          }
        }
      }
    }

    .suggestion-header {
      height: 50px;
      background: $tan-hide;
      display: inline-flex;
      width: 100%;

      .search-filter-container {
        width: 130px;
        display: flex;
        align-items: center;
        font-size: 15px;
        color: #3c4452;
        font-weight: bold;
        position: relative;
        margin-left: 30px;

        span {
          height: 24px;
          color: $white;
          cursor: pointer;
        }

        .arrow_drop_down {
          padding-top: 3px;
          padding-left: 2px;
          cursor: pointer;
        }

        .search-filter-container-list {
          position: absolute;
          z-index: 100;
          box-shadow: 0 2px 7px 2px $disabled-bg-color;
          display: none;
          top: 40px;
          background-color: $white;
          left: -30px;
          top: -3px;
          white-space: nowrap;

          p {
            padding: 0;
            margin: 0;
            font-weight: normal;
            font-size: 15px;
            padding: 5px 20px;

            &.selected {
              background-color: $dark-50;
              font-weight: bold;
            }

            &:hover {
              background-color: $dark-50;
              cursor: pointer;
            }
          }

          &.active {
            visibility: visible;
            opacity: 1;
            transition-delay: 0s, 0s, 0.3s;
          }
        }
      }

      .suggestion-explore-icon {
        width: 42px;
        height: 42px;
        border-radius: 50%;
        background: $suggestion-explore-bg;
        margin-top: 4px;
        box-shadow: 0 0 10px 2px $explore-box-shadow;

        .explore {
          font-size: 50px;
          color: $white;
          margin-left: -4px;
          margin-top: -4px;
          transform: rotate(90deg);
        }
      }

      .suggestion-filter-container {
        width: 150px;
        margin-left: 15px;
        display: flex;
        align-items: center;
        font-size: 15px;
        color: $white;
        font-weight: bold;
      }

      .suggestion-right-menu {
        width: 100%;
        display: flex;

        .search-input-container {
          grid-template-columns: auto 190px 40px;
          display: grid;
          position: relative;
          border: none;
          background: $white;
          outline: none;
          border-radius: 5px;
          width: 100%;
          margin: 2px 0;

          .filter-items {
            display: flex;
            align-items: center;
            margin-right: 10px;
            justify-content: flex-end;

            .more-pointer {
              color: $gray-light;
              cursor: pointer;
            }

            .popover-content {
              .filter-item {
                margin: 5px;
                width: 80px;
              }
            }

            .filter-item {
              border-radius: 9px;
              justify-content: space-around;
              display: flex;
              height: 20px;
              width: 80px;
              padding: 10px;
              margin-right: 5px;
              align-items: center;
              background-color: #898b8e;
              .name {
                font-size: 12px;
                font-weight: bold;
                line-height: 1.25;
                text-align: right;
                color: $white-bold;
              }

              .clear {
                height: auto;
                margin-left: 5px;

                i {
                  height: 100%;
                  font-size: 12px;
                  color: $white;
                  opacity: 1;
                }
              }
            }
          }

          #suggestion-search {
            outline: none;
            border-radius: 5px;
            width: 100%;
            text-indent: 10px;
            margin-top: 4px;
            margin-bottom: 3px;
            cursor: text;
            box-shadow: none;
          }

          .search-icon {
            width: 38px;
            height: 50px;
            color: #fa9053;
            cursor: pointer;
            position: absolute;
            right: 0;

            .search {
              font-size: 24px;
              margin-top: 10px;
            }
          }
        }

        .search-filter-icon {
          color: $white;
          width: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
      }
    }

    .suggestion-content-type-filter {
      height: 50px;
      background-color: $white-bold;
      box-shadow: 0 2px 4px 0 $disabled-bg-color;
      position: relative;
      width: 100%;

      .search-by-filter {
        z-index: 11;
      }

      .filter-content-types {
        display: flex;
        align-items: center;

        .filter-item {
          width: 100px;
          display: grid;
          justify-items: center;
          text-align: center;
          margin-top: 11px;
          margin-right: 15px;
          cursor: pointer;
          color: $breadcrumb-color;
          border-bottom-width: 2px;
          border-color: $white;
          border-bottom-style: solid;
          transition: border-width 0.1s ease-in-out;

          i {
            width: 20px;
            height: 15px;
            background-repeat: no-repeat;
          }

          span {
            font-size: 12px;
            font-weight: bold;
            display: block;
            width: 100px;
          }

          &.collection {
            i {
              background-image: url("../assets/gooru/images/navigation_collection.svg");
            }
          }

          &.assessment {
            i {
              background-image: url("../assets/gooru/images/navigation_assessment.svg");
            }
          }

          &.active,
          &:hover {
            border-bottom: 2px solid #fa9053;
            color: #fa9053;

            &.collection {
              i {
                background-image: url("/assets/gooru/images/collection-orange.svg");
              }
            }

            &.assessment {
              i {
                background-image: url("/assets/gooru/images/assessment-orange.svg");
              }
            }
          }
        }
      }
    }
    .search-list-container {
      max-height: calc(90vh - 100px);
      height: calc(90vh - 100px);
      overflow-y: auto;
      width: 100%;
      padding-top: 30px;
      padding-bottom: 30px;
      background: #f1f3f7;

      .search-list {
        display: grid;
        grid-template-columns: repeat(3, 220px);
        grid-gap: 20px;
        margin: 0 auto;
        width: 700px;

        .cards.no-suggest-result-found {
          grid-column-start: 2;
        }
      }
    }
    .selected-tenant-library {
      display: grid;
      grid-template-columns: 42px max-content 1fr;
      align-items: center;
      justify-content: center;
      box-shadow: 0 2px 4px 0 $disabled-bg-color;
      margin-bottom: 1px;
      height: 45px;

      .back {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      img {
        width: 35px;
        height: 35px;
        border-radius: 50px;
      }

      .library-name {
        font-weight: bold;
        font-size: 14px;
        padding-left: 5px;
      }
    }

    .struggling-competency-report-student-list-container {
      height: calc(100% - 71px);
      overflow-y: auto;

      .struggling-competency-report-student-list-panel {
        display: grid;
        grid-template-columns: 1fr 50px 50px;
        align-items: center;
        padding: 10px;
        cursor: pointer;

        .sc-report-student-list {
          .sc-report-student-profile {
            list-style: none;
            display: inline-flex;
            align-items: center;
            padding: 0;
            margin: 0;

            li {
              margin: 10px;
              width: 40px;
              height: 40px;
              border-radius: 50%;
              background-color: $gray-light;
              border: $gray-80;
              display: grid;
              align-items: center;
              text-align: center;

              img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
              }
            }
          }
        }

        .sc-report-student-count {
          font-size: 16px;
        }

        .sc-report-student-list-icon {
          display: grid;
          align-items: center;

          i {
            font-size: 30px;
          }
        }
      }

      .sc-student-dropdown-list-container {
        display: none;

        &.active {
          display: block;
        }

        .sc-student-dropdown-list {
          display: grid;
          grid-template-columns: 70px 1fr 70px;
          align-items: center;
          padding: 5px 20px;
          font-weight: bold;
          font-size: 15px;

          .sc-student-profile-thumbnail-container {
            .sc-student-thumbnail {
              width: 40px;
              height: 40px;
              background-color: $gray-80;
              border-radius: 50%;

              img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
              }
            }
          }

          .sc-student-performance-container {
            .sc-student-performance {
              padding: 5px;
              color: $white;
              text-align: center;
            }
          }
        }
      }

      .sc-student-content-section-container {
        .search-container {
          text-align: center;

          .search-filter-container {
            display: flex;
            align-items: center;
            font-size: 17px;
            font-weight: bold;
            padding: 5px 20px;
            position: relative;

            span {
              display: flex;
              cursor: pointer;
            }

            .arrow_drop_down {
              padding-top: 3px;
              padding-left: 2px;
              cursor: pointer;
            }

            .search-filter-container-list {
              position: absolute;
              z-index: 100;
              box-shadow: 0 2px 7px 2px $disabled-bg-color;
              display: none;
              top: 40px;
              background-color: $white;

              p {
                padding: 0;
                margin: 0;
                font-weight: normal;
                font-size: 15px;
                padding: 5px 20px;

                &.selected {
                  background-color: $dark-50;
                  font-weight: bold;
                }

                &:hover {
                  background-color: $dark-50;
                  cursor: pointer;
                }
              }
            }
          }
        }

        .sc-library-title {
          padding: 5px 20px;
          display: grid;
          grid-template-columns: 30px 1fr;
          box-shadow: 0 0 4px $gray-80;

          span {
            display: grid;
            align-items: center;

            &:first-child {
              cursor: pointer;
            }
          }
        }

        .sc-student-content-section-container {
          .sc-student-content-section {
            display: grid;
            grid-template-columns: auto auto;
            align-items: center;
            grid-row-gap: 20px;
            padding: 10px;
            justify-items: center;
            @media screen and (max-width: $screen-xs) {
              grid-template-columns: auto;
            }

            &.no-content {
              grid-template-columns: auto;

              .suggest-panel {
                margin: auto;
                width: 230px;
                display: flex;

                .panel-body {
                  background: $white;
                  border-radius: 4px;
                  box-shadow: 0 1px 4px $navbar-box-shadow;

                  .no-suggest-result-content {
                    .title {
                      font-size: 15px;
                      text-align: center;
                    }

                    .icon {
                      text-align: center;
                      padding: 10px;
                      cursor: default;

                      i {
                        color: $gray-base;
                        font-size: 86px;
                        text-align: center;
                      }
                    }

                    .suggest-message {
                      text-align: center;
                      width: 200px;
                    }

                    .message {
                      text-align: justify;
                      width: 200px;
                    }
                  }
                }
              }
            }
            &.stuggle-archived-class {
              .dca-search-collection-card {
                .card-panel-container {
                  .card-panel-footer {
                    .buttons-container {
                      .dca-suggested-content,
                      .schedule-dca-content,
                      .dca-add-btn {
                        pointer-events: none;
                        opacity: 0.5;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .sc-show-more {
          width: 100%;
          text-align: center;
          padding: 20px;

          span {
            padding: 10px;
            width: fit-content;
            font-weight: bold;
            font-size: 16px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
