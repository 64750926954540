.oca-students-summary-report-pull-up {
  position: fixed;
  z-index: 1032;
  width: 768px;
  left: 0;
  right: 0;
  margin: auto;
  color: $suggestion-panel-color;
  bottom: 0;

  .backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    cursor: pointer;
    opacity: 0.8;
    background-color: $gray-base;
  }

  .oca-students-summary-report-container {
    background: $white;
    max-height: 90vh;
    height: 90vh;
    display: grid;
    grid-template-rows: 56px auto auto 1fr;

    .report-header {
      display: grid;
      grid-template-columns: auto 56px;

      .close {
        display: flex;
        align-items: center;
        justify-content: center;

        i {
          font-size: 24px;
        }
      }

      .header-title {
        display: grid;
        grid-template-columns: 56px 1fr 50px;
        height: 100%;

        .icon {
          width: 20px;
          height: 20px;
          background-size: 20px 20px;
          opacity: 0.8;
          justify-self: center;
          align-self: center;
          background-repeat: no-repeat;

          &.assessment-external-icon,
          &.collection-external-icon {
            background-image: url("gooru/share.png");
          }

          &.assessment {
            background-image: url("gooru/images/add_assessment.svg");
          }

          &.assessment-external {
            background-image: url("gooru/images/add_assessment.svg");
          }

          &.collection-external {
            background-image: url("gooru/images/add_collection.svg");
          }

          &.collection {
            background-image: url("gooru/images/add_collection.svg");
          }
        }

        .title {
          align-items: center;
          display: flex;
        }
      }
    }

    .collection-performance {
      display: grid;
      grid-template-rows: 20px 1fr;
      position: relative;
      height: 240px;
      background-color: $gray-67;

      .performance {
        grid-row-start: 2;
        text-align: center;
        display: grid;
        font-weight: bold;
        grid-template-columns: max-content 1fr max-content;
        color: $white;

        &.not-show-carousal {
          grid-template-columns: 1fr;
        }

        .left,
        .right {
          align-self: center;
          cursor: pointer;

          &.disable {
            pointer-events: none;
            opacity: 0.5;
          }

          i {
            font-size: 35px;
          }
        }

        .time-spent {
          font-size: 90px;
          margin: auto;
        }
      }
    }
    .performance-view {
      overflow-y: auto;
      height: 100%;
      .performance-activity {
        display: grid;
        grid-template-rows: 20px 1fr;
        position: relative;
        height: 240px;

        &:before {
          content: "";
          position: absolute;
          opacity: 0.3;
          width: 100%;
          height: 100%;
          background-color: rgb(242, 235, 235);
        }

        %webkit-container {
          -webkit-mask-size: cover;
          -webkit-mask-repeat: no-repeat;
          background: currentColor;
          -webkit-mask-image: url("gooru/images/combined-shape.svg");
        }

        %container {
          mask-size: cover;
          mask-repeat: no-repeat;
          background: currentColor;
          mask-image: url("gooru/images/combined-shape.svg");
        }

        .performance {
          text-align: center;
          display: grid;
          font-weight: bold;
          color: $white;
          justify-content: center;
          z-index: 1;
          grid-template-columns: max-content 1fr max-content;
          height: 240px;

          &.not-show-carousal {
            grid-template-columns: 1fr;
          }

          .left,
          .right {
            align-self: center;
            cursor: pointer;

            &.disable {
              pointer-events: none;
              opacity: 0.5;
            }

            i {
              font-size: 35px;
            }
          }

          .score-container {
            display: grid;
            justify-content: center;

            .score {
              font-size: 90px;
              width: max-content;
              height: max-content;
            }

            .time-spent {
              font-size: 20px;
              width: max-content;
              height: max-content;
              justify-self: center;
            }
          }
        }

        .performance-img {
          grid-row-start: 2;
          @extend %webkit-container;
          @extend %container;
          display: grid;
          align-self: end;
        }
      }
    }

    .activity-info {
      display: grid;
      grid-template-columns: 56px 1fr 100px 100px 110px;
      height: 48px;
      align-items: center;

      .circle-img {
        width: 29px;
        height: 29px;
        background-color: #d8d8d8;
        align-self: center;
        justify-self: center;
        border-radius: 50%;
      }
      .selected-type {
        .dropdown {
          .dropdown-toggle {
            width: 100px;
            height: 35px;
          }
        }
      }

      .conducted-on {
        justify-self: center;

        .date {
          font-weight: bold;
          font-size: 14px;
        }

        .title {
          font-size: 10px;
          display: block;
        }
      }
      .sort-score {
        display: flex;
        font-weight: bold;
        align-items: center;
        font-size: 13px;
        color: $gray-cf;
        i {
          cursor: pointer;
        }
      }

      .students-count {
        align-self: center;
      }

      .student-performance-update {
        background-color: $dark-500;
        color: $white;
        font-size: 13px;
        padding: 10px;
        font-weight: bold;
        text-align: center;
        cursor: pointer;
        text-transform: capitalize;
        &.disabled {
          pointer-events: none;
          opacity: 0.5;
        }
      }
      .student-check {
        label {
          input {
            padding: 5px 0px 0px 17px;
          }
        }
      }
    }
    .suggest-bar {
      width: 100%;
      height: 48px;
      background: $orange-49;
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.3s, visibility 0.3s;

      &.active {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.3s, visibility 0.3s;
      }

      .suggest-bar-container {
        display: grid;
        grid-template-columns: 56px 1fr 200px 200px;
        height: 48px;

        &.taxonomy-not-tagged {
          grid-template-columns: 56px 1fr auto;
        }

        &.diagnostic-content {
          grid-template-columns: 56px 1fr 125px;
        }

        .close-all-suggestion {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          .close {
            font-size: 24px;
            color: $white;
            opacity: 1;
            font-weight: 600;
          }
        }

        .no-of-student-selected,
        .taxonomy-not-tagged-message {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          font-size: 16px;
          color: $white;
          font-weight: 600;
        }

        .taxonomy-not-tagged-message {
          padding-right: 10px;
        }

        .suggestion-count-container {
          display: flex;
          align-items: center;
          cursor: pointer;

          &.regular-workflow {
            grid-column-start: 4;
            justify-content: center;
          }

          span {
            text-transform: uppercase;
            color: $white;
            font-size: 12px;
            font-weight: 600;
            margin-right: 10px;
          }

          .suggestion-explore {
            width: 20px;
            border-radius: 50%;
            background: $suggestion-explore-bg;
            height: 20px;
            box-shadow: 0 0 5px 1px $explore-box-shadow;
            margin-top: 2px;
            color: $white;
            position: relative;

            .explore {
              transform: rotate(90deg);
              margin-top: -2px;
              margin-left: -2px;
            }

            .suggestion-count {
              width: 16px;
              border-radius: 50%;
              background: $white;
              position: absolute;
              line-height: 14px;
              top: -5px;
              height: 16px;
              left: 14px;
              color: $gray-4a;
              border: 1px solid $gray-4a;
              font-size: 9px;
              text-indent: 4px;
            }
          }
        }
      }
    }

    .students-report-container {
      padding-bottom: 10px;
      min-height: 200px;

      .student-row-container {
        height: 56px;
        box-shadow: 0 1px 3px 0 $navbar-box-shadow;
        background-color: $white-bold;
        display: grid;
        cursor: pointer;
        grid-template-columns: 1fr 56px;

        &.not-started {
          pointer-events: none;
        }

        .student-info {
          display: grid;
          grid-template-columns: 56px 1fr;
          &.student-info-gpf {
            display: grid;
            grid-template-columns: 25px 50px 1fr;
            align-items: center;
            padding: 10px 10px 10px 17px;
            label {
              margin: 0;
            }
          }
          .username {
            text-transform: capitalize;
            align-items: center;
            display: flex;
          }

          .user-profile-img {
            display: flex;
            align-items: center;
            justify-content: center;

            .selected-user-for-suggestion {
              width: 29px;
              background: $tan-hide;
              border-radius: 50%;
              line-height: 19px;
              box-shadow: 0 0 7px 0 $box-shadow-bg-color;
              color: $white;
              height: 29px;

              .done_all {
                position: relative;
                top: 3px;
                left: 3px;
              }

              .de-select-user {
                position: relative;
                top: -29px;
                width: 29px;
                height: 29px;
                background: $box-shadow-bg-color;
                border-radius: 50%;
                line-height: 19px;
                visibility: hidden;
                opacity: 0;
                transition: opacity 0.3s, visibility 0.3s;

                .close {
                  font-size: 16px;
                  margin-right: 6px;
                  margin-top: 3px;
                }
              }

              &:hover {
                .de-select-user {
                  visibility: visible;
                  opacity: 1;
                  transition: opacity 0.3s, visibility 0.3s;
                }
              }
            }

            img {
              width: 29px;
              border-radius: 50%;
              border-color: $table-bg;
              height: 29px;

              &.selected {
                visibility: hidden;
              }
            }
          }
          .student-icon-col {
            .student-icon {
              background-image: url(/assets/gooru/profile.png);
              background-size: contain;
              border-radius: 50%;
              width: 30px;
              height: 30px;
              margin: auto;
            }
          }
          label {
            &.disabled {
              pointer-events: none;
            }
          }
        }

        .not-started {
          display: flex;
          align-items: center;
          background-color: $white-ea;
          justify-content: center;
          font-size: 10px;
          height: 54px;
          font-weight: 700;
        }

        .timespent {
          display: flex;
          align-items: center;
          background-color: $white-ea;
          justify-content: center;
          font-size: 12px;
          height: 54px;
          font-weight: 700;
        }

        .grading-icon {
          background-image: url("../assets/gooru/images/grade-pending.png");
          width: 22px;
          height: 22px;
          background-size: 22px;
          background-position: center;
          background-repeat: no-repeat;
          display: block;
          align-self: center;
          margin: auto;
          cursor: pointer;
        }

        .score {
          font-size: 20px;
          font-weight: bold;
          color: $white-bold;
          align-items: center;
          display: flex;
          justify-content: center;
          cursor: pointer;
          height: 54px;
        }
      }

      .external-collection-link {
        text-align: center;
        font-size: 14px;
      }
    }
  }
  @media screen and (max-width: $screen-sm - 1) {
    .oca-students-summary-report-container {
      .collection-performance {
        .performance {
          .time-spent {
            font-size: 50px;
          }
        }
      }
    }
    width: 100%;
  }
}
