.search-by-filter {
  height: 0;
  transition: height 0.45s ease-out;
  overflow: hidden;
  background: $white;
  max-height: calc(90vh - 205px);
  position: relative;
  width: 100%;

  &.active {
    height: 100vh;
    transition: height 0.45s ease-in;
  }

  .backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -9;
    cursor: pointer;
    opacity: 0.8;
    background-color: $gray-base;
  }

  .search-panel {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;

    .apply-filter {
      height: 48px;
      width: 100%;
      box-shadow: 0 0 4px 0 $container-box-shadow;
      background-color: $dark-400;
      color: $white;
      font-size: 15px;
      font-weight: 700;
      left: 0;
      text-align: center;
      text-transform: uppercase;
      line-height: 48px;
      cursor: pointer;
      display: block;
      position: absolute;
      bottom: 0;
    }

    .info-text {
      padding: 0 10px;
      color: $dark-400;
      font-size: 16px;
      background-color: $breadcrumb-color;
    }

    .search-content {
      height: auto;
      overflow-y: auto;
      padding: 10px 10px 48px;

      .bottom-panel-container {
        display: flex;
        grid-gap: 10px;

        .filter-container {
          width: 50%;
          padding-right: 10px;
        }

        .audience-list-container,
        .author-publisher-list-container,
        .educational-list-container,
        .language-list-container,
        .resource-list-container {
          margin-top: 15px;
          padding-bottom: 10px;
          border-bottom: 1px solid $breadcrumb-color;

          .label-container {
            font-size: 15px;
            font-weight: bold;
            height: 20px;
            cursor: pointer;
            color: $font-dark-400-black;
            display: flex;
            text-transform: uppercase;
            justify-content: space-between;

            &.collapsed {
              .icons {
                span {
                  .arrow_drop_down {
                    display: block;
                  }

                  .arrow_drop_up {
                    display: none;
                  }
                }
              }
            }

            .icons {
              background-color: $breadcrumb-color;
              border-radius: 50%;
              display: flex;
              align-items: center;
              width: 20px;
              height: 20px;
              opacity: $opacity-light;
              justify-content: center;

              span {
                width: auto;
                height: 20px;

                i {
                  font-size: 18px;

                  &.arrow_drop_down {
                    display: none;
                  }
                }
              }
            }
          }

          .audience-container,
          .author-publisher-container,
          .educational-container,
          .language-container,
          .resource-container {
            margin-top: 10px;

            .publisher-container {
              margin-top: 10px;
            }

            .author-container,
            .publisher-container {
              display: grid;
              border-radius: 2px;
              background-color: #d8d8d8;
              padding: 5px;
              height: 100%;
              color: $dark-400;

              input {
                background-color: #d8d8d8;
                outline: none;
                font-style: italic;
                color: $dark-400;
              }
            }

            #publisher-suggestions {
              position: relative;

              .ui-autocomplete {
                z-index: 1048;
                box-shadow: 0 0 5px 2px $breadcrumb-color;
                border: none;

                .ui-menu-item {
                  color: $dark-400;

                  &.ui-state-focus {
                    color: $white;
                    background-color: $dark-400;
                  }
                }
              }
            }
          }
        }
      }

      .top-panel-container {
        display: flex;
        flex-direction: column;
        position: relative;
        border-bottom: 1px solid $breadcrumb-color;
        min-height: 50px;
        padding-bottom: 10px;

        .label-container {
          position: relative;
          height: auto;
          cursor: pointer;
          color: $font-dark-400-black;
          display: grid;
          font-size: 15px;
          font-weight: bold;
          text-transform: uppercase;

          .sub-title {
            opacity: 0.8;
          }

          .icons {
            position: absolute;
            right: 10px;
          }

          &.collapsed {
            .title {
              display: block;
            }

            .icons {
              span {
                .arrow_drop_down {
                  display: block;
                }

                .arrow_drop_up {
                  display: none;
                }
              }
            }
          }

          .icons {
            background-color: $breadcrumb-color;
            border-radius: 50%;
            display: flex;
            align-items: center;
            width: 20px;
            height: 20px;
            opacity: $opacity-light;
            justify-content: center;

            span {
              width: auto;
              height: 20px;

              i {
                font-size: 18px;

                &.arrow_drop_down {
                  display: none;
                }
              }
            }
          }
        }

        .standard-container {
          .sub-title {
            color: $dark-400;
            display: grid;
            font-size: 15px;
            font-weight: bold;
            text-transform: uppercase;
            opacity: 0.8;
          }

          .taxonomy.gru-taxonomy-selector > div {
            margin-top: 0;
          }
        }

        .gru-taxonomy-picker {
          .actions,
          .selected-list {
            display: none;
          }

          .browse-selector {
            .close-picker,
            .title {
              display: none;
            }

            .taxonomy.gru-browse-selector {
              margin-top: 10px;

              .breadcrumb-levels {
                display: flex;

                .breadcrumb-level {
                  background-color: $gray-67;
                  color: $white;
                  margin-right: 5px;
                  padding: 5px;
                  border-radius: 4px;
                  cursor: pointer;
                }

                .separator-symbol {
                  color: $gray-67;
                  font-size: 15px;
                }
              }
            }

            .gru-browse-selector,
            .taxonomy {
              .gru-browser-selector-container {
                padding: 0;
                list-style: none;
                margin-top: 10px;
                width: 100%;
                grid-template-columns: auto auto auto;
                display: grid;

                .hierarchy-1-container,
                .hierarchy-2-container,
                .hierarchy-3-container {
                  min-width: 150px;

                  strong {
                    font-size: 13px;
                    font-weight: bold;
                    color: $dark-400;
                    padding-left: 10px;
                  }

                  span {
                    padding-left: 10px;
                    display: block;
                  }

                  .browse-panel {
                    padding: 0;
                    list-style: none;
                    border: solid 1px $nobel-gray;
                    position: relative;
                    overflow-y: auto;
                    height: 225px;

                    &.level-2 {
                      border-right: 0;
                      border-left: 0;
                    }

                    &.level-3 {
                      li {
                        .standard {
                          position: relative;

                          .level-panel {
                            padding: 0;
                            list-style: none;
                          }

                          button {
                            background-color: $table-bg;
                            border: 0 none;
                            position: absolute;
                            top: -7px;
                            right: 5px;

                            i.keyboard_arrow_up {
                              display: none;
                            }

                            &.collapsed {
                              i.keyboard_arrow_up {
                                display: inline-block;
                              }

                              i.keyboard_arrow_down {
                                display: none;
                              }
                            }
                          }

                          label {
                            display: flex;

                            strong {
                              display: block;
                            }

                            span {
                              text-align: justify;
                            }

                            input[type="checkbox"] {
                              &:checked::after {
                                background: url("/assets/gooru/images/tick-inside-circle-blue.svg")
                                  no-repeat center;
                                background-size: contain;
                                border: none;
                                display: block;
                                position: relative;
                              }

                              &:after {
                                content: "";
                                display: block;
                                width: 18px;
                                height: 18px;
                                border-radius: 50%;
                                -webkit-transition: 240ms;
                                -o-transition: 240ms;
                                transition: 240ms;
                              }
                            }
                          }
                        }
                      }
                    }

                    li {
                      padding-left: 5px;

                      a {
                        font-size: 13px;
                        line-height: normal;
                        color: $dark-400;
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        padding: 5px;
                      }

                      &.active {
                        background-color: $dark-400;

                        a {
                          color: $white-bold;
                          font-weight: bold;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .gru-taxonomy-selector {
        .category {
          display: inline-grid;
          label {
            span {
              display: none;
            }

            .categories {
              margin-top: 10px;

              .btn-primary {
                background: $dark-400;
              }

              .btn-info {
                opacity: $opacity-light;
                color: $dark-400;
                background: $white;
                border-radius: 3px;
                border: solid 1px $dark-400;
              }
            }
          }
        }

        .subject {
          label {
            span {
              font-size: 15px;
              font-weight: bold;
              color: $dark-400;
              text-transform: uppercase;
              height: 20px;
              opacity: 0.8;

              &.no-subject {
                display: none;
              }
            }
          }

          .not-selected {
            display: none;
          }

          .gru-subject-picker {
            margin-top: 5px;

            .dropdown-toggle,
            .selected-subject {
              background: $dark-400;
            }
          }
        }
      }
    }

    .search-filter {
      width: 100%;
      box-shadow: 0 2px 4px 0 $navbar-box-shadow;
      background-color: $white-bold;
      display: grid;
      grid-template-columns: 100px 1fr;
      height: auto;
      align-items: center;

      span {
        font-weight: bold;
        text-align: center;
        color: #4b5667;
        font-size: 12px;
        text-transform: uppercase;
      }

      .filters {
        width: 80%;
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        height: auto;
        align-items: center;
        text-align: center;
        padding: 5px;

        li {
          display: grid;
          justify-content: center;
          list-style: none;
          cursor: pointer;
          opacity: 0.5;
          font-size: 12px;
          color: $dark-400;

          &.active {
            .icon {
              color: #00a7e4;
            }

            span {
              color: #00a7e4;
              border-bottom: 2px solid #00a7e4;
            }
          }

          %bg-container {
            height: 24px;
            width: 24px;
            display: flex;
            background: currentColor;
            mask-repeat: no-repeat;
            -webkit-mask-position: center;
            mask-position: center;
            justify-self: center;
            -webkit-mask-repeat: no-repeat;
          }

          &.collections {
            .icon {
              -webkit-mask-image: url("gooru/images/add_collection.svg");
              mask-image: url("gooru/images/add_collection.svg");
              @extend %bg-container;
            }
          }

          &.assessments {
            .icon {
              -webkit-mask-image: url("gooru/images/add_assessment.svg");
              mask-image: url("gooru/images/add_assessment.svg");
              @extend %bg-container;
            }
          }

          &.resources {
            .icon {
              -webkit-mask-image: url("gooru/images/collections_resource_icon.svg");
              mask-image: url("gooru/images/collections_resource_icon.svg");
              @extend %bg-container;
            }
          }

          &.questions {
            .icon {
              -webkit-mask-image: url("gooru/images/question-icon.svg");
              mask-image: url("gooru/images/question-icon.svg");
              @extend %bg-container;
            }
          }

          &.rubrics {
            .icon {
              -webkit-mask-image: url("gooru/images/add_rubric.svg");
              mask-image: url("gooru/images/add_rubric.svg");
              @extend %bg-container;
            }
          }

          &.courses {
            .icon {
              -webkit-mask-image: url("gooru/images/add_course.svg");
              mask-image: url("gooru/images/add_course.svg");
              @extend %bg-container;
            }
          }
        }
      }
    }
  }
}
