.gru-identify-digraph {
  ul {
    padding-left: 8px;
    li {
      list-style: none;
      .exercise-seq {
        color: $placeholder-color;
        font-size: 15px;
        &.in-visible {
          visibility: hidden;
        }
      }

      .answer-underline {
        .correct {
          border-bottom: 1px solid $green-700;
          color: $green-700;
          font-weight: bold;
        }
        .error {
          border-bottom: 1px solid $red-47;
          color: $red-47;
          font-weight: bold;
        }
        p {
          letter-spacing: 7px;
        }
      }
    }
  }
}
