.student-featured-courses {
  clear: both;
  @include flexbox;
  margin: 0 0 20px;

  .courses {
    display: grid;
    grid-template-columns: auto auto auto auto;
    margin: auto;

    .gru-standard-card {
      margin: 0 25px 0 0;
      @include inline-flex;

      &:last-child {
        margin: 0;
      }

      .panel {
        .cca-description {
          .remixed-by {
            float: $tab-float-dir;

            .owner {
              max-width: 75px;
            }
          }

          .publish-icon {
            width: 20px;
            float: $menu-float-dir;
          }
        }
      }
    }
  }
}
