.activities-performance {
  .activities-performance-container {
    a {
      text-decoration: none;
    }

    .performance-panel {
      background-color: $white;
      border-radius: 10px;
      overflow: hidden;

      .performance-panel-header {
        display: grid;
        grid-template-columns: 40px 1fr 40px;
        align-items: center;
        border-bottom: 1px solid $light-200;
        box-shadow: 0 2px 5px $light-200;
        padding: 10px;
        background: $green-light;

        i {
          text-align: center;
          color: $white;
        }

        .performance-panel-title {
          line-height: 1.4;
          display: grid !important;
          grid-template-columns: auto 30px;
          color: $white;

          .panel-title {
            display: grid;
            cursor: pointer;

            span {
              display: block;
            }

            .sub-title {
              font-weight: bold;
              font-size: 12px;
            }
          }

          .showHelpIcon {
            padding-top: 5px;
          }
        }

        .action-buton {
          text-align: center;
          color: $white;
          display: flex;
          justify-content: center;
        }
      }

      .performance-panel-body {
        .performance-chart-list {
          padding: 10px;

          .performance-title {
            display: grid !important;
            grid-template-columns: auto 30px;
            width: fit-content;
            margin: auto;
            color: $font-bg-progress-pointer-dark-100;
          }

          .overall-score {
            display: block;
            text-align: center;
            font-size: 16px;
          }

          .chart-list-container {
            display: grid;
            grid-template-columns: 1fr 1fr;
            align-items: center;
            grid-column-gap: 10px;
            justify-content: center;

            &.disable-class-activity {
              grid-template-columns: 1fr;
            }

            .class-activities-performance,
            .jounery-performance {
              span {
                display: block;
                text-align: center;
                color: $font-bg-progress-pointer-dark-100;
              }
            }
          }
        }

        .progress-container {
          padding: 10px;

          .progress-title {
            display: grid !important;
            grid-template-columns: auto 30px;
            width: fit-content;
            margin: auto;
            color: $font-bg-progress-pointer-dark-100;
          }

          .progress-section-list {
            span {
              font-weight: bold;
              display: block;
              font-size: 15px;
              text-align: center;

              &.detail {
                font-size: 18px;
              }
            }

            .lesson-timespent {
              display: grid;
              grid-template-columns: 1fr 1fr;
              align-items: center;
              padding: 10px 0;
            }
          }
        }
      }

      .suggestion-card-section {
        display: grid !important;
        grid-template-columns: 40px 1fr;
        align-items: center;
        padding: 10px;
        margin-top: 10px;
        border-top: 1px solid $light-200;
        box-shadow: 0 -2px 5px $light-200;

        .suggestion-icon {
          text-align: center;
          position: relative;

          i {
            color: $bg-orange-500-gray-base;
            font-size: 27px;
          }

          .suggestion-count {
            position: absolute;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            right: 0;
            top: -10px;
            color: $bg-orange-500-gray-base;
            border: 1px solid $bg-orange-500-gray-base;
            background-color: $white;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 11px;
          }
        }

        .suggestion-content {
          .suggestion-title {
            font-weight: bold;
            font-size: 14px;
          }

          p {
            font-size: 15px;
            line-height: 1.4;
          }
        }
      }

      .activities-listing-container {
        .activities-card-section {
          .card-panel-item {
            display: grid;
            grid-template-columns: 40px 1fr;
            align-items: center;
            padding: 10px;

            .activity-icon {
              width: 25px;
              height: 25px;
              margin: 0 auto;
              background-position: center;
              background-size: 100% 100%;
              display: block;

              &.congrts {
                background-image: url("gooru/congrats-msg.png");
              }

              &.trophy {
                background-image: url("gooru/wow-ion.png");
              }

              &.badge-icon {
                background-image: url("gooru/streak-icon.svg");
              }
            }

            .activity-badge-status,
            .activity-status {
              display: grid !important;
              grid-template-columns: auto 30px;
              line-height: 1.4;

              span {
                display: block;
                font-size: 15px;
              }

              .showHelpIcon {
                span {
                  font-size: 20px;
                }
              }
            }
          }
        }
      }
    }
  }
}
