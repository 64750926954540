.charts.gru-performance-chart {
  color: $font-black-bold-white;
  text-align: center;
  height: 100%;
  min-width: 76px;

  .container-box {
    height: 60px;
    cursor: pointer;

    .percentage {
      font-size: $font-size-lead;
      font-weight: 900;
      cursor: pointer;
      line-height: 24px;
      margin: 15px 0 0;
    }

    .not-started {
      color: $light-400;
      font-size: $font-size-xxsmall;
      font-weight: bold;
      margin: 15px 0 5px;
    }

    .not-started-text {
      font-size: 12px;
      color: $black-bold;
      line-height: 15px;
      margin-top: 8px;
    }

    .completion-bar {
      border-radius: 5px;
      height: 5px;
      background-color: $white;
      margin: 0 auto;
      min-width: 35px;
      width: 32%;

      .segment {
        height: 5px;

        &:first-child {
          border-bottom-left-radius: 4px;
          border-top-left-radius: 4px;
        }

        &.full {
          max-width: 100%;

          &:last-child {
            border-bottom-right-radius: 5px;
            border-top-right-radius: 5px;
          }
        }
      }
    }

    .tooltip {
      width: 130px;
      line-height: $font-size-h6;
      font-size: $font-size-base;

      .tooltip-arrow {
        border-right-color: $white;
      }

      .tooltip-inner {
        color: $dark-400;
        font-weight: bold;
        background-color: $white;
        text-align: $tab-float-dir;
        box-shadow: 0 0 4px 0 $navbar-box-shadow;
      }
    }
  }
}
