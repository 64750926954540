.reports.assessment.qz-resources {
  // margin-top: 20px;

  .resources-header {
    margin: 30px 20px;
    display: none;
    .title {
      h4 {
        margin-top: 0;
      }
    }
  }

  table {
    width: 100%;
    background: $white;
    box-shadow: 0 2px 5px 0 $ruberic-box-shadow;
    border-radius: 5px;
    padding: 20px 0 40px;
    display: none;
    @media (min-width: $screen-md) {
      padding: 20px 15px 40px;
    }

    th {
      color: $gray-base;
      font-size: $font-size-h6;
      font-weight: normal;
    }

    td,
    th {
      height: 30px;
      text-align: center;
      border-bottom: 1px solid $gray-lighter;
    }

    .header {
      .title {
        display: block;
      }

      %background-container {
        background-size: contain !important;
        width: 20px;
        height: 23px;
      }

      .icon {
        display: none;

        &.resource {
          background: url("gooru/images/navigation_resource_selected_icon.svg")
            no-repeat;
          @extend %background-container;
        }
      }
    }

    th.number {
      width: 2%;
    }

    th.resource-type {
      width: 6%;
    }

    th.resource {
      width: 37%;
    }

    th.timeSpent {
      width: 24%;
    }

    .resource-type-icon {
      .image {
        display: inline-block;
        margin-right: 10px;
      }

      i {
        display: block;
        height: 36px;
        width: 28px;
        vertical-align: top;
        margin: 5px 0 0;

        &.video_resource-icon {
          background: url("quizzes-addon/quizzes/images/video-resource-green.svg");
          -webkit-background-size: 31px 30px;
          background-size: 31px 30px;
          height: 30px;
          width: 31px;
        }

        &.webpage_resource-icon {
          background: url("quizzes-addon/quizzes/images/website-resource-green.svg");
          -webkit-background-size: 29px 30px;
          background-size: 29px 30px;
          height: 30px;
          width: 29px;
        }

        &.interactive_resource-icon {
          background: url("quizzes-addon/quizzes/images/interactive-resource-green.svg");
          -webkit-background-size: 34px 30px;
          background-size: 34px 30px;
          height: 30px;
          width: 34px;
          vertical-align: top;
          margin: 5px 0 0;
        }

        &.html_resource-icon {
          background: url("quizzes-addon/quizzes/images/html-icon.png");
          -webkit-background-size: 34px 30px;
          background-size: 34px 30px;
          height: 30px;
          width: 34px;
          vertical-align: top;
          margin: 5px 0 0;
        }

        &.image_resource-icon {
          background: url("quizzes-addon/quizzes/images/image-resource-green.svg");
          -webkit-background-size: 30px 30px;
          background-size: 30px 30px;
          height: 30px;
          width: 30px;
        }

        &.text_resource-icon {
          background: url("quizzes-addon/quizzes/images/text-resource-green.svg");
          -webkit-background-size: 31px 30px;
          background-size: 31px 30px;
          height: 30px;
          width: 31px;
        }

        &.audio_resource-icon {
          background: url("quizzes-addon/quizzes/images/audio-resource-green.svg");
          -webkit-background-size: 34px 30px;
          background-size: 34px 30px;
          height: 30px;
          width: 34px;
        }
      }
    }

    .top-left {
      text-align: left;
      vertical-align: top;
      padding: 10px 15px;
    }
  }

  .resource-container {
    display: grid;
    grid-template-columns: 56px auto 56px 56px;
    grid-template-rows: auto;
    padding: 8px 0;

    .resource-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      .resource-type {
        width: 24px;
        height: 24px;
        background-size: 24px 24px;
        background-repeat: no-repeat;
        display: block;
        &.video_resource {
          background-image: url("gooru/images/video-resource-dark.svg") !important;
        }
        &.html_resource {
          background-image: url("quizzes-addon/quizzes/images/html-icon.png") !important;
        }
        &.webpage_resource {
          background-image: url("gooru/images/website-resource-dark.svg") !important;
        }
        &.interactive_resource {
          background-image: url("gooru/images/interactive-resource-dark.svg") !important;
        }
        &.question_resource {
          background-image: url("gooru/images/question-resource-dark.svg") !important;
        }
        &.rubric_resource {
          background-image: url("gooru/images/rubric-dark.svg") !important;
        }
        &.image_resource {
          background-image: url("gooru/images/image-resource-dark.svg") !important;
        }
        &.text_resource {
          background-image: url("gooru/images/text-resource-dark.svg") !important;
        }
        &.audio_resource {
          background-image: url("gooru/images/audio-resource-dark.svg") !important;
        }
        &.h5p_interactive_slide {
          background-image: url("gooru/images/interactive-slides.png");
          background-size: contain;
        }
        &.h5p_interactive_video {
          background-image: url("gooru/images/interactive-video.png");
          background-size: contain;
        }
        &.h5p_drag_and_drop_resource {
          background-image: url("gooru/images/drag-and-drop-icon.png");
          background-size: contain;
        }
        &.h5p_interactive_personality_quiz {
          background-image: url("gooru/images/personality-quiz-icon.png");
          background-size: contain;
        }
        &.started {
          &.interactive_resource {
            background-image: url("gooru/images/interactive-resource-green.svg") !important;
          }
          &.video_resource {
            background-image: url("gooru/images/video-resource-green.svg") !important;
          }
          &.html_resource {
            height: 25px;
            background-image: url("gooru/images/html-icon-green.svg") !important;
          }
          &.webpage_resource {
            height: 25px;
            background-image: url("gooru/images/website-resource-green.svg") !important;
          }
          &.image_resource {
            background-image: url("gooru/images/image-resource-green.svg") !important;
          }
          &.audio_resource {
            background-image: url("gooru/images/audio-resource-green.svg") !important;
          }
          &.text_resource {
            height: 25px;
            background-image: url("gooru/images/text-resource-green.svg") !important;
          }
          &.h5p_interactive_slide {
            background-image: url("gooru/images/interactive-slides-green.svg") !important;
          }
          &.h5p_interactive_video {
            background-image: url("gooru/images/interactive-video-green.svg") !important;
          }
          &.question {
            background-image: url("gooru/images/question-resource-green.svg") !important;
          }
        }
      }
    }

    .resource-title {
      display: flex;
      flex-direction: column;
      font-family: "Montserrat";
      font-size: 11px;
      color: $dark-400;
      @media screen and (max-width: 500px) {
        width: calc(100vw - 112px);
      }

      .desc {
        font-weight: bold;
        opacity: $opacity-light;
        line-height: 1.3;
      }
    }

    .resource-reaction {
      display: flex;
    }

    .resource-timespent {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    .resource-header-container {
      display: grid;
      grid-template-columns: 90% auto;
      column-gap: 10px;
      .resource-title-container {
        display: grid;
        grid-template-columns: 16px 20px 1fr;
        align-items: center;
        column-gap: 8px;
        padding-bottom: 8px;
        .resource-order {
          font-weight: bold;
          color: $graye-67;
          font-size: 15px;
        }
        .title {
          font-weight: bold;
          color: $graye-67;
          font-size: 15px;
        }
      }
      .resource-performance-container {
        display: grid;
        grid-template-columns: auto auto;
        .resource-reaction {
          .emotion {
            width: 25px;
            height: 25px;
            cursor: auto;
          }
        }
        .resource-timespent {
          font-size: 13px;
          color: $graye-67;
        }

        &.skipped-resource {
          grid-template-columns: auto auto;
        }
      }
    }

    .resource-taxonomies-container {
      padding-bottom: 8px;
      .gru-taxonomy-tag-list {
        .gru-taxonomy-tag {
          min-width: 90px;
          max-width: 100px;
          height: 23px;
          border-radius: 12px;
          background-color: $tag-bg;
          .standard-tag {
            .tag-label {
              font-size: 14px;
              font-weight: bold;
              color: $white;
              text-align: center;
            }
            .tag-caption {
              display: none;
            }
          }
        }
        .non-visible-tags {
          width: 81px;
          height: 23px;
          border-radius: 12px;
          background-color: $tag-bg;
          color: $white;
          padding: 0;
          span {
            font-weight: bold;
            font-size: 14px;
            margin-left: 10px;
          }
        }
      }
    }
    .resource-description-container {
      $font-size: 14px;
      $line-height: 1.5;
      $lines-to-show: 4;
      @include ellipsis(
        $font-size: $font-size,
        $line-height: $line-height,
        $lines-to-show: $lines-to-show
      );
      max-height: 92px;
      color: $gray-66;
    }
    .resource-url-container {
      .url-label {
        font-size: 14px;
        font-weight: bold;
        color: $graye-67;
      }
      .url-content {
        font-size: 14px;
        font-style: italic;
        color: $graye-67;
      }
    }
  }

  .resource-cards {
    width: 100%;
    background: $white;
    box-shadow: 0 2px 5px 0 $ruberic-box-shadow;
    border-radius: 5px;
    padding: 20px;
    display: block;

    .header {
      color: $gray-base;
      font-size: $font-size-h6;
      padding: 0 0 15px;
      border-bottom: 1px solid $gray-lighter;
    }

    .resource-number {
      width: 15%;
      display: inline-block;
      padding: 0 0 0 5px;
    }

    .resource-text {
      width: 82%;
      display: inline-block;
      padding: 0 5px 0 0;
    }

    ul {
      padding: 0;
      list-style: none;
    }
  }
}
