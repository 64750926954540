.competency-info-content {
  background-color: $dark-700;
  height: auto;

  #lm-no-content {
    margin: 0;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 15px;
    background-color: $white;
  }

  .header-tab-panel {
    padding-top: 10px;

    .nav-tabs {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      width: 100%;
      height: 40px;
      background-color: $white;
      border-bottom: 1px solid $gray-harder;
      align-items: center;

      &.col-2 {
        grid-template-columns: repeat(2, 1fr);
      }

      .tab {
        height: 100%;
        display: flex;
        align-items: center;
        font-size: 13px;
        cursor: pointer;
        font-weight: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: $dark-300;
        justify-content: center;

        &.active {
          color: $font-blue-500-blue-fe7;
          box-shadow: 0 3px 0 0 $font-blue-500-blue-fe7;
        }
      }
    }
  }

  .panel-content {
    .disable-student-performance {
      padding: 10px;
      line-height: 1.5;
      font-size: 15px;
      background-color: $white;
    }
    .learning-map-content {
      .activity-list-pull-up {
        .activity-list-body {
          .activity-contents {
            grid-template-columns: 1fr;

            > div {
              margin: auto;
            }
          }
        }
      }
    }
  }
}
