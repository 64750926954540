/* Content of this file is moved to respective css goorultr.scss and goorurtl.scss where
variable specific to rtl laguage are specified and should be used where direction need to
be specified. Mainly for float, text-align and borders */
* {
  font-family: "Roboto";
}

.overflow-hidden {
  overflow: hidden;
}

@import "goorultr";
