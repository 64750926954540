.add-data.collection-performance-data {
  height: 100%;
  .collection-performance-container {
    &.collection-performance {
      &.desktop-view {
        display: grid;
        grid-template-columns: 410px auto;
        background-color: #f1f3f7;
        padding: 15px;
        column-gap: 15px;
        height: 100%;
      }

      &.mobile-view {
        width: 100%;
        .resource-list-container {
          .resources-container {
            height: calc(100vh - 151px);

            @media screen and (max-width: $screen-xs) {
              height: calc(100vh - 206px);
            }
          }
        }
      }

      %scroll-style {
        &::-webkit-scrollbar {
          height: 6px;
          width: 8px;
        }

        &::-webkit-scrollbar-thumb {
          background: $gray-light;
          border: 1px solid $gray-light;
          border-radius: 5px;
        }
      }

      %desktop-height {
        height: calc(100vh - 135px);
        overflow-y: auto;
        @extend %scroll-style;
      }

      .resource-list-container {
        height: 100%;
        background-color: $white;
        .search-resource {
          height: 45px;
          display: flex;
          padding: 0 15px;
          align-items: center;
          position: relative;
          background-color: $white;
          margin-bottom: 8px;
          box-shadow: 0 2px 4px 0 $disabled-bg-color;
          .clear-search-resource {
            cursor: pointer;
            i.clear {
              opacity: 0.7;
              color: $gray-f9f;
              line-height: 45px;
            }
          }

          input.search-resource-input {
            height: 45px;
            line-height: 45px;
            border: none;
            outline: none;
            width: calc(100% - 64px);
            margin: 0 8px;
            &::placeholder {
              font-size: 14px;
              color: #a6a6a6;
              text-transform: uppercase;
            }

            &:focus {
              outline: none;
            }
          }

          .search-icon {
            width: 32px;
            height: 32px;
            background-color: $search-icon-bg-color;
            border-radius: 50%;
            text-align: center;
            position: absolute;
            right: 15px;
            i {
              color: $gray-f9f;
              font-size: 18px;
              line-height: 32px;
            }
          }
        }
        .resources-container {
          @extend %desktop-height;

          .resource-details {
            opacity: 0.5;
            .resource-container {
              display: grid;
              grid-template-columns: 1fr auto;
              height: 45px;
              box-shadow: 0 2px 4px 0 $disabled-bg-color;
              background-color: $white;
              cursor: pointer;

              .resource-title {
                font-size: 14px;
                font-weight: bold;
                color: $placeholder-color;
                padding-left: 15px;
                line-height: 45px;
              }

              .resource-timespent {
                height: 45px;
                color: $white;
                width: 56px;
                font-size: 14px;
                font-weight: bold;
                text-align: center;
                line-height: 45px;
                background-color: $gray-67;
              }
            }
            &.active-resource,
            &:hover {
              opacity: 1;
            }
          }
        }
      }

      .timespent-container {
        .timespent-card {
          width: 100%;
          height: 100%;
          box-shadow: 0 0 4px 0 $box-shadow-bg-color;
          background-color: $white;
          display: grid;
          justify-content: center;
          .timespent-title {
            font-size: 12px;
            text-align: center;
            color: #4c4c4e;
            align-self: center;
          }
          .timespent-action-container {
            .next-action {
              width: 240px;
              height: 48px;
              background-color: $blue-400;
              font-size: 14px;
              font-weight: bold;
              text-align: center;
              color: $white;
              line-height: 48px;
              cursor: pointer;

              &.disable-event {
                opacity: 0.5;
              }
            }
          }
        }
      }
    }

    &.external-collection-performance {
      height: 100%;

      &.mobile-view {
        .external-collection-timespent-container {
          padding: 0;
          .external-timespent-card {
            width: 100%;
            height: 100%;
            min-width: unset;
            max-width: unset;
            padding: 0;
          }
        }
      }

      .external-collection-timespent-container {
        background-color: #f1f3f7;
        padding: 15px;
        height: 100%;
        .external-timespent-card {
          background-color: $white;
          min-width: 350px;
          max-width: 400px;
          display: grid;
          justify-content: center;
          padding: 15px;
          margin: auto;
          box-shadow: 0 0 4px 0 $box-shadow-bg-color;
          .timespent-title {
            font-size: 12px;
            text-align: center;
            color: #4c4c4e;
            align-self: center;
          }
          .timespent-action-container {
            align-self: center;
            margin: auto;
            .next-action {
              width: 240px;
              height: 48px;
              background-color: $blue-400;
              font-size: 14px;
              font-weight: bold;
              text-align: center;
              color: $white;
              line-height: 48px;
              cursor: pointer;

              &.disable-event {
                opacity: 0.5;
              }
            }
          }
        }
      }
    }
  }
}
