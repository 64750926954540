.library.gru-browse-library {
  @include flexbox;
  @include flex-wrap(wrap);
  height: 100%;

  .content-container {
    @include flexbox;
    @include flex-wrap(wrap);
    margin: 133px 0 40px;
    padding: 0 8%;

    .subject-container {
      width: 100%;

      .subject-title {
        border-bottom: 1px solid $light-200;
        color: $gray-base;
        font-size: $font-size-h5;
        padding: 20px 0 0;
      }
    }

    .featured-description {
      width: 100%;
      margin-bottom: 0;
      text-align: $tab-float-dir;
    }

    .library-cards {
      @include flexbox;
      @include flex-wrap(wrap);
      @include justify-content(space-between);
      width: 100%;

      &::after {
        content: "";
        @include flex-basis(0);
        @media (min-width: $screen-sm-min) {
          @include flex-basis(49%);
        }
        @media (min-width: $screen-sm-max) {
          @include flex-basis(32%);
        }
      }

      .gru-collection-card {
        width: 100%;
        margin: 0;
        @media (min-width: $screen-sm-min) {
          width: 49%;
        }
        @media (min-width: $screen-sm-max) {
          width: 32%;
        }
      }
    }
  }

  .navbar {
    position: fixed;
    box-shadow: 0 2px 4px 0 $box-shadow-bg-color;
    margin: 55px 0 0;
    min-height: 50px;
    width: 100%;
    z-index: $zindex-navbar-fixed;
    @media (min-width: $screen-md) {
      margin: 56px 0 0;
    }

    &.affix {
      background-color: $dark-400;
      left: 0;
      top: 55px;
      @media (min-width: $screen-md-min) {
        top: 56px;
      }
    }
  }

  .subject-filters {
    @include flexbox;
    float: none;
    padding: 0 20px 0 5%;
    min-height: 50px;
    width: 95%;
    @media (min-width: $screen-sm-min) {
      width: 75%;
    }

    .subject-menu-option {
      &.selected {
        a {
          background: none;
        }
      }

      a {
        background: none;
        color: $green-300;

        &:hover {
          color: $green-300;
        }
      }
    }
  }

  .subject-menu-option {
    @media (min-width: $screen-sm-min) {
      margin-right: 40px;
    }

    &.selected {
      a {
        background: none;
        border-bottom: 2px solid $green-300;
        color: $green-300;
        font-size: $font-size-h6;
        height: 100%;
      }
    }

    a {
      background: $table-bg;
      line-height: 1.3;
      padding: 7px 10px;
    }

    .category {
      display: inline-block;
      min-width: 30px;
    }
  }

  .affix {
    .subject-menu-option {
      &.selected {
        a {
          border-bottom-color: $white;
          color: $white;
        }
      }

      a {
        color: $white;

        &:hover {
          color: $white;
        }
      }
    }
  }
}

.library-cards {
  margin: 40px 0 0;
  justify-content: left;

  .new-cards.gru-standard-card {
    width: 300px;
    margin: 10px;
    height: auto;

    .panel {
      margin-bottom: 23px;
    }

    .panel .panel-heading {
      background-color: $table-bg;
      height: 168px;
      background-size: cover;
      background-repeat: no-repeat;
      padding: 0;
      position: relative;

      .info {
        .question-resources {
          visibility: hidden;
        }

        .title-section {
          margin: 50px 0 10px;

          .title {
            width: 209px;
            height: 35px;
            font-weight: bold;
            text-align: $tab-float-dir;
            $line-height: 1.5;
            $lines-to-show: 2;
            @include ellipsis(
              $font-size: 13px,
              $line-height: $line-height,
              $lines-to-show: 2
            );
          }
        }
      }

      &:after {
        margin-top: 67px !important;
        height: 60%;
      }
    }

    .panel {
      .panel-body {
        border-top: 1px solid $gray-lighter;

        .standards {
          margin: 3px 0;

          .gru-taxonomy-tag-list {
            .gru-taxonomy-tag {
              width: auto;
              height: 36px;
              object-fit: contain;
              border-radius: 3px;
              background-color: $light-300;

              .content {
                margin: 0;
              }
            }
          }
        }

        .remixed-by {
          span.title {
            width: 76px;
            line-height: 1.69;
            font-style: italic;
          }

          .owner {
            margin-left: 4px;
          }
        }

        .description {
          width: 260px;
          height: 75px;
          object-fit: contain;
          text-align: $tab-float-dir;
          color: $gray;

          .truncate {
            $line-height: 1.5;
            $lines-to-show: 3;
            @include ellipsis(
              $font-size: 13px,
              $line-height: $line-height,
              $lines-to-show: 3
            );
          }
        }

        .cca-icon-btn {
          cursor: auto;

          .units-icon {
            float: $tab-float-dir;
            margin-top: 5px;

            i {
              background: url("gooru/images/units-gray.svg");
              width: 15px;
              height: 15px;
              display: block;
            }
          }

          .unit-text {
            margin-left: 25px;
            line-height: 25px;
          }

          .visibility {
            float: $menu-float-dir;

            .cca-eye-icon {
              margin-top: 2px;
            }
          }
        }

        .publish-icon {
          width: 30px;
          float: $menu-float-dir;

          .badge-icon {
            margin-top: 5px;
          }
        }
      }

      .cca-first {
        padding: 4px 15px;
        height: 35px;

        .visible_false {
          color: $lite-gray-medium;
          float: $menu-float-dir;
        }
      }

      .panel-footer {
        padding: 0;

        .actions {
          width: auto;

          .course-icon-btn {
            border-left: solid 1px $gray-ultra-light;
            width: 45px;
            text-align: center;
            display: block;

            > a {
              color: $dark-gray-4c;
              display: block;
              margin-top: 12px;
            }

            .course-mode-edit > i {
              font-size: 21px;
            }
          }
        }

        .options {
          width: 100%;
          padding: 0;

          .preview-btn {
            width: 74px;
            height: 44px;
            background-color: $blue-400;
          }

          .course-icon-btn {
            border-right: solid 1px $gray-ultra-light;
            width: 45px;
            text-align: center;
            display: block;

            .course-mode-edit > i {
              font-size: 21px;
            }
          }

          .course-add-btn {
            font-size: 21px;
          }
        }
      }

      .cca-footer {
        padding: 0;
      }

      .cca-description {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        height: 100px;
        padding-bottom: 2px;
      }

      .cca-standards {
        height: 52px;
      }
    }
  }
}
