.student-collection {
  overflow: hidden;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: $grey-background;
  .hide-report-header {
    .navbar-fixed-top {
      display: none;
    }
  }

  .qz-student-report {
    padding-top: 8px;
    padding-bottom: 40px;
  }

  .player-title-panel {
    height: 64px;
    background-color: $blue-400;
    margin: auto;
    color: $white;
    position: relative;

    .back-to-collection {
      width: 75px;
      height: 64px;
      border-right: 2px solid $light-200;
      cursor: pointer;

      .nav-icon-container {
        i {
          margin-left: -47px;
          margin-top: 19px;
          color: $white;
        }
      }
    }

    .content-title {
      width: calc(100% - 60px);
      @media screen and (max-width: $screen-375) {
        grid-template-columns: 120px auto;
      }

      .title-info {
        height: 64px;

        i {
          background: url("gooru/images/navigation_collection_selected.svg");
          display: inline-block;
          width: 20px;
          height: 15px;
          margin-left: 20px;
          margin-right: 20px;
          margin-top: 25px;
        }
      }

      &.assessment {
        .title-info {
          i {
            -webkit-mask-image: url("gooru/images/navigation_assessment.svg");
            mask-image: url("gooru/images/navigation_assessment.svg");
            background-image: none;
            background-color: $white;
          }
        }
      }

      .title {
        width: calc(100% - 60px);
        position: relative;
        @include translateY(-50%);
        top: 50%;
        $line-height: 1.3;
        $lines-to-show: 2;
        @include ellipsis(
          $font-size: 16px,
          $line-height: $line-height,
          $lines-to-show: 2,
          $excerpt-bg: $blue-400
        );
      }
    }
    @media only screen and (max-width: $sm-width-max) {
      margin: 0;
      width: 100%;
    }
  }

  .back-button {
    height: 1px;
    z-index: 999;
    margin-left: 150px;
    position: absolute !important;
    padding: 10px 9px 12px 33px;
    @media (min-width: $screen-sm-min) {
      padding: 10px 9px 12px 33px;
    }
    @media (min-width: $screen-md-min) {
      padding: 10px 9px 12px 33px;
    }
  }
}

.iframe-panel {
  .app-container {
    overflow-y: auto;
  }
}

.resource-content {
  width: 100%;
  display: none;
  &.resource-back-drop {
    position: absolute;
    height: auto;
    width: 100%;
    display: grid;
    grid-template-columns: auto auto;
    justify-content: center;
    align-items: flex-end;
    column-gap: 7px;
    bottom: 50px;
    @media screen and (max-width: 767px) {
      grid-template-columns: auto;
      bottom: 85px;
    }
  }
}
