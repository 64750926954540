// Based on Bootswatch Paper 3.3.6
// Variables
// --------------------------------------------------
//== Colors
//
//## Gooru Palette
//** Green
$green-100: #d1e2ce !default;
$green-200: #a3ca9f !default;
$green-300: #76b072 !default;
$green-400: #2bcc8d !default;
$green-500: #00793e !default;
$green-600: #4c9639 !default;
$green-700: $green-400;
$green-800: #408325 !default;
$green-900: #438439 !default;
$green-light: #2bcc8d !default;
$green-52: #8fd383 !default;
$green-dark: #32642b !default; //** Blue
$blue-100: #c1dbee !default;
$blue-200: #84b7dd !default;
$blue-300: #4795cc !default;
$blue-400: #0072bc !default;
$blue-500: $blue-400 !default;
$blue-600: #00458a !default;
$blue-700: #43acdb !default;
$blue-light: #4b8fe2 !default;
$blue-800: $blue-light;
$blue-dark: #005c99 !default;
$navy-blue: #0071bc !default;
$cyan-blue: #7fb8dd !default; //** Dark
$dark-50: #cacaca !default;
$dark-100: #949a9f !default;
$dark-200: #535e67 !default;
$dark-250: #677274 !default;
$dark-300: #535e67 !default;
$dark-400: #37424b !default;
$dark-500: #29323a !default;
$dark-600: $dark-400;
$dark-700: #36424b !default;
$dark-800: #4a4a4a !default;
$dark-850: #4d5557 !default;
$dark-900: #35424b !default; //** Light
$dark-950: #333333 !default;
$light-ps: #f1f4f4 !default;
$light-100: #f1f3f7 !default;
$light-200: #e3e5ea !default;
$light-300: #cdd2d6 !default;
$light-350: #a8b1b2 !default;
$light-400: #b8bfc4 !default;
$light-450: #a6adb1 !default;
$light-500: #959a9e !default;
$light-600: #63727d !default; //** Teal
$light-800: #f1f4f4 !default;
$teal-100: #bbe1dd !default;
$teal-200: #91cdc7 !default;
$teal-300: #57b2a9 !default;
$teal-400: #159488 !default;
$teal-500: #0e8075 !default; //** Sky
$sky-50: #ecf4f9 !default;
$sky-100: #bddeed !default;
$sky-200: #92cbe4 !default;
$sky-300: #58beeb !default;
$sky-400: $blue-light !default;
$sky-500: #0c8fcb !default;
$sky-600: #0072c0 !default; //** Yellow
$yellow-100: #ece0ce !default;
$yellow-200: #eec87d !default;
$yellow-300: #ebb958 !default;
$yellow-400: #f8ba41 !default;
$yellow-500: #eba41a !default;
$yellow: #ff0 !default; //** Orange
$orange-100: #e4ceb9 !default;
$orange-200: #e9ad75 !default;
$orange-300: #e8a15f !default;
$orange-500: #de7a1d !default; //** Red
$orange-400: $orange-500;
$red-100: #f0cccb !default;
$red-200: #e28d8b !default;
$red-300: #e86f6d !default;
$red-400: #f46360 !default;
$red-500: #e05552 !default;
$red-800: #ea1410 !default;
$red-900: #ff4c5c !default;
$red-dark: #f56d6b !default; //** Pink
$pink-100: #e5c1cf !default;
$pink-200: #e59cb9 !default;
$pink-300: #e577a3 !default;
$pink-400: #da2a6f !default;
$pink-500: #bf1d5d !default; //## Gray and brand colors for use across Bootstrap.
$gray-base: #000 !default;
$gray-darker: lighten($gray-base, 13.5%) !default; // #222
$gray-dark: $dark-400;
$gray-very-dark: #474747;
$gray-icon: #535e67 !default;
$gray: #666 !default;
$gray-light: #bbb !default;
$gray-lighter: lighten($gray-base, 93.5%) !default; // #eee
$gray-sharper: #f1f1f1 !default;
$gray-ultra-light: #f2f2f2 !default;
$gray-shaft: #222222;
$gray-oslo: #9a9d9f;
$gray-harder: #d8d8d8 !default;
$gray-80: #cccccc;
$bluish-gray: #2b2d2e;
$white: #fff;
$gray-68: #adadad;
$gray-29: #4a4a4a;
$gray-submarine: #b8bfc4;
$gray-dove: #666666;
$tan-hide: #f98e4e;
$danube: $blue-light;
$brand-primary: $blue-400 !default;
$brand-success: $green-400 !default;
$brand-info: $dark-300 !default;
$brand-warning: $yellow-400 !default;
$brand-danger: $red-400 !default;
$brand-incorrect: $red-400 !default;
$brand-anonymous: $blue-400 !default;
$brand-muted: $light-400 !default;
$picton-blue: #2cafed !default;
$gray-97: #f7f7f7 !default; //== Scaffolding
$gooru-green: #418137 !default;
$hover-light: #ecebeb !default;
$gray-dark-1: #525659 !default;
$caret-color: #565f61 !default;
$lime-green: #58a058 !default;
$strong-blue: #0072c8 !default;
$light-yellow: #ffffb0 !default;
$bright-orange: #f6a444 !default;
$dark-moderate-blue: #5792ad !default;
$lite-gray: #eeeeee;
//
//## Settings for some of the most global styles.
//** Background color for `<body>`.
$body-bg: $light-100 !default; //** Global text color on `<body>`.
$text-color: $gray !default; //** Global textual link color.
$link-color: $brand-primary !default; //** Link hover color set via `darken()` function.
$link-hover-color: $sky-500 !default; //** Link hover decoration.
$link-hover-decoration: underline !default; //== Typography
//
//## Font, line-height, and color for body text, headings, and more.
$font-size-base: 13px !default;
$font-size-large: ceil($font-size-base * 1.25) !default; // ~18px
$font-size-small: ceil($font-size-base * 0.85) !default; // ~12px
$font-size-xsmall: ceil($font-size-base * 0.77) !default; // ~11px
$font-size-xxsmall: 10px !default;
$font-size-xxxsmall: 8px !default;
$font-size-large-icon: 86px !default;
$font-size-arrow-icon: 27px !default;
$font-size-tour-icon: 32px !default;
$font-size-tour-icon-small: 25px !default;
$font-size-rubric-icon: 22px !default;
$font-size-units-icon: 20px !default;
$font-size-rubric-chat-icon: 15px !default;
$font-size-h1: 56px !default;
$font-size-h2: 20px !default;
$font-size-h3: 34px !default;
$font-size-h4: 24px !default;
$font-size-h5: 22px !default;
$font-size-h5-5: 20px !default;
$font-size-h6: 14px !default;
$font-size-lead: 19px !default; //** Unit-less `line-height` for use in components like buttons.
$line-height-base: 1.846 !default; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed: floor(
  $font-size-base * $line-height-base
) !default; // ~20px
//** By default, this inherits from the `<body>`.
$headings-font-family: inherit !default;
$headings-font-weight: 400 !default;
$headings-line-height: 1.1 !default;
$headings-color: $gray-base; //== Components
//
//## Define common padding and border radius sizes and more. Values based on 14px text and 1.428 line-height (~20px to start).
$padding-base-vertical: 6px !default;
$padding-base-horizontal: 16px !default;
$padding-large-vertical: 10px !default;
$padding-large-horizontal: 16px !default;
$padding-small-vertical: 5px !default;
$padding-small-horizontal: 10px !default;
$padding-xs-vertical: 1px !default;
$padding-xs-horizontal: 5px !default;
$line-height-large: 1.3333333 !default; // extra decimals for Win 8.1 Chrome
$line-height-small: 1.5 !default;
$border-radius-base: 3px !default;
$border-radius-large: 3px !default;
$border-radius-small: 3px !default; //** Global color for active items (e.g., navs or dropdowns).
$component-active-color: #fff !default; //** Global background color for active items (e.g., navs or dropdowns).
$component-active-bg: $brand-primary !default; //** Width of the `border` for generating carets that indicator dropdowns.
$caret-width-base: 4px !default; //** Carets increase slightly in size for larger components.
$caret-width-large: 5px !default; //== Tables
//
//## Customizes the `.table` component with basic values, each used across all table variations.
//** Padding for `<th>`s and `<td>`s.
$table-cell-padding: 8px !default; //** Padding for cells in `.table-condensed`.
$table-condensed-cell-padding: 5px !default; //** Default background color used for all tables.
$table-bg: transparent !default; //** Background color used for `.table-striped`.
$table-bg-accent: #f9f9f9 !default; //** Background color used for `.table-hover`.
$table-bg-hover: #f5f5f5 !default;
$table-bg-active: $table-bg-hover !default; //** Border color for table and cell borders.
$table-border-color: #ddd !default; //== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.
$btn-font-weight: normal !default;
$btn-default-color: #444 !default;
$btn-default-bg: #fff !default;
$btn-default-bg-hover: $dark-300 !default;
$btn-default-border: transparent !default;
$btn-primary-color: #fff !default;
$btn-primary-bg: $brand-primary !default;
$btn-primary-border: transparent !default;
$btn-success-color: #fff !default;
$btn-success-bg: $brand-success !default;
$btn-success-border: transparent !default;
$btn-info-color: #fff !default;
$btn-info-bg: $brand-info !default;
$btn-info-border: transparent !default;
$btn-warning-color: #fff !default;
$btn-warning-bg: $brand-warning !default;
$btn-warning-border: transparent !default;
$btn-danger-color: #fff !default;
$btn-danger-bg: $brand-danger !default;
$btn-danger-border: transparent !default;
$btn-link-disabled-color: $gray-light !default; // Allows for customizing button radius independently from global border radius
$btn-border-radius-base: $border-radius-base !default;
$btn-border-radius-large: $border-radius-large !default;
$btn-border-radius-small: $border-radius-small !default; //== Forms
//
//##
//** `<input>` background color
$input-bg: transparent !default; //** `<input disabled>` background color
$input-bg-disabled: transparent !default; //** Text color for `<input>`s
$input-color: $gray !default; //** `<input>` border color
$input-border: transparent !default; // TODO: Rename `$input-border-radius` to `$input-border-radius-base` in v4
//** Default `.form-control` border radius
// This has no effect on `<select>`s in some browsers, due to the limited stylability of `<select>`s in CSS.
$input-border-radius: $border-radius-base !default; //** Large `.form-control` border radius
$input-border-radius-large: $border-radius-large !default; //** Small `.form-control` border radius
$input-border-radius-small: $border-radius-small !default; //** Border color for inputs on focus
$input-border-focus: #66afe9 !default; //** Placeholder text color
$input-color-placeholder: $gray-light !default; //** Default `.form-control` height
$input-height-base: $line-height-computed + $padding-base-vertical * 2 + 2 !default; //** Large `.form-control` height
$input-height-large: ceil($font-size-large * $line-height-large) +
  $padding-large-vertical * 2 + 2 !default; //** Small `.form-control` height
$input-height-small: floor($font-size-small * $line-height-small) +
  $padding-small-vertical * 2 + 2 !default; //** `.form-group` margin
$form-group-margin-bottom: 15px !default;
$legend-color: $gray-dark !default;
$legend-border-color: #e5e5e5 !default; //** Background color for textual input addons
$input-group-addon-bg: transparent !default; //** Border color for textual input addons
$input-group-addon-border-color: $input-border !default; //** Disabled cursor for form controls and buttons.
$cursor-disabled: not-allowed !default; //== Dropdowns
//
//## Dropdown menu container and contents.
//** Background for the dropdown menu.
$dropdown-bg: #fff !default; //** Dropdown menu `border-color`.
$dropdown-border: rgba(
  0,
  0,
  0,
  0.15
) !default; //** Dropdown menu `border-color` **for IE8**.
$dropdown-fallback-border: #ccc !default; //** Divider color for between dropdown items.
$dropdown-divider-bg: #e5e5e5 !default; //** Dropdown link text color.
$dropdown-link-color: $text-color !default; //** Hover color for dropdown links.
$dropdown-link-hover-color: darken(
  $gray-dark,
  5%
) !default; //** Hover background for dropdown links.
$dropdown-link-hover-bg: $gray-lighter !default; //** Active dropdown menu item text color.
$dropdown-link-active-color: $component-active-color !default; //** Active dropdown menu item background color.
$dropdown-link-active-bg: $component-active-bg !default; //** Disabled dropdown menu item background color.
$dropdown-link-disabled-color: $gray-light !default; //** Text color for headers within dropdown menus.
$dropdown-header-color: $gray-light !default; //** Deprecated `$dropdown-caret-color` as of v3.1.0
$dropdown-caret-color: $gray-light !default; //-- Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.
//
// Note: These variables are not generated into the Customizer.
$zindex-navbar: 1000 !default;
$zindex-dropdown: 1000 !default;
$zindex-popover: 1060 !default;
$zindex-tooltip: 1070 !default;
$zindex-navbar-fixed: 1030 !default;
$zindex-modal-background: 1040 !default;
$zindex-modal: 1050 !default; //== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.
// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs: 480px !default; //** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min: $screen-xs !default; //** Deprecated `$screen-phone` as of v3.0.1
$screen-phone: $screen-xs-min !default; // Small screen / tablet
$screen-in-680: 680px;
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm: 768px !default;
$screen-sm-min: $screen-sm !default; //** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet: $screen-sm-min !default; // Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md: 992px !default;
$screen-md-min: $screen-md !default; //** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop: $screen-md-min !default; // Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg: 1200px !default;
$screen-lg-min: $screen-lg !default; //** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop: $screen-lg-min !default; // Varibale screen size
$screen-in-1350: 1350px;
$screen-in-1150: 1150px;
$screen-in-1300: 1300px; // So media queries don't overlap when required, provide a maximum
$screen-in-1024: 1024px;
$screen-xs-max: $screen-sm-min - 1 !default;
$screen-sm-max: $screen-md-min - 1 !default;
$screen-md-max: $screen-lg-min - 1 !default; //== Grid system
$screen-lptp-hidpi: 1440px; //
//## Define your custom responsive grid.
//** Number of columns in the grid.
$grid-columns: 12 !default; //** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width: 30px !default; // Navbar collapse
//** Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint: $screen-sm-min !default; //** Point at which the navbar begins collapsing.
$grid-float-breakpoint-max: $grid-float-breakpoint - 1 !default; //== Container sizes
//
//## Define the maximum width of `.container` for different screen sizes.
// Small screen / tablet
$container-tablet: 720px + $grid-gutter-width !default; //** For `$screen-sm-min` and up.
$container-sm: $container-tablet !default; // Medium screen / desktop
$container-desktop: 940px + $grid-gutter-width !default; //** For `$screen-md-min` and up.
$container-md: $container-desktop !default; // Large screen / wide desktop
$container-large-desktop: 1140px + $grid-gutter-width !default; //** For `$screen-lg-min` and up.
$container-lg: $container-large-desktop !default; //== Navbar
//
//##
// Basics of a navbar
$navbar-height: 64px !default;
$navbar-margin-bottom: $line-height-computed !default;
$navbar-border-radius: $border-radius-base !default;
$navbar-padding-horizontal: floor($grid-gutter-width / 2) !default;
$navbar-padding-vertical: ($navbar-height - $line-height-computed) / 2 !default;
$navbar-collapse-max-height: 340px !default;
$navbar-default-color: $gray-light !default;
$navbar-default-bg: #fff !default;
$navbar-default-border: transparent !default; // Navbar links
$navbar-default-link-color: $gray !default;
$navbar-default-link-hover-color: $gray-dark !default;
$navbar-default-link-hover-bg: transparent !default;
$navbar-default-link-active-color: $gray-dark !default;
$navbar-default-link-active-bg: darken($navbar-default-bg, 6.5%) !default;
$navbar-default-link-disabled-color: #ccc !default;
$navbar-default-link-disabled-bg: transparent !default; // Navbar brand label
$navbar-default-brand-color: $navbar-default-link-color !default;
$navbar-default-brand-hover-color: $navbar-default-link-hover-color !default;
$navbar-default-brand-hover-bg: transparent !default; // Navbar toggle
$navbar-default-toggle-hover-bg: transparent !default;
$navbar-default-toggle-icon-bar-bg: rgba(0, 0, 0, 0.5) !default;
$navbar-default-toggle-border-color: transparent !default; //=== Inverted navbar
// Reset inverted navbar basics
$navbar-inverse-color: $gray-light !default;
$navbar-inverse-bg: $brand-primary !default;
$navbar-inverse-border: transparent !default; // Inverted navbar links
$navbar-inverse-link-color: $sky-100 !default;
$navbar-inverse-link-hover-color: #fff !default;
$navbar-inverse-link-hover-bg: transparent !default;
$navbar-inverse-link-active-color: $navbar-inverse-link-hover-color !default;
$navbar-inverse-link-active-bg: darken($navbar-inverse-bg, 10%) !default;
$navbar-inverse-link-disabled-color: #444 !default;
$navbar-inverse-link-disabled-bg: transparent !default; // Inverted navbar brand label
$navbar-inverse-brand-color: $navbar-inverse-link-color !default;
$navbar-inverse-brand-hover-color: #fff !default;
$navbar-inverse-brand-hover-bg: transparent !default; // Inverted navbar toggle\
$navbar-inverse-toggle-hover-bg: transparent !default;
$navbar-inverse-toggle-icon-bar-bg: rgba(0, 0, 0, 0.5) !default;
$navbar-inverse-toggle-border-color: transparent !default; //== Navs
//
//##
//=== Shared nav styles
$nav-link-padding: 10px 15px !default;
$nav-link-hover-bg: $gray-lighter !default;
$nav-disabled-link-color: $gray-light !default;
$nav-disabled-link-hover-color: $gray-light !default; //== Tabs
$nav-tabs-border-color: transparent !default;
$nav-tabs-link-hover-border-color: $gray-lighter !default;
$nav-tabs-active-link-hover-bg: transparent !default;
$nav-tabs-active-link-hover-color: $gray !default;
$nav-tabs-active-link-hover-border-color: transparent !default;
$nav-tabs-justified-link-border-color: $nav-tabs-border-color !default;
$nav-tabs-justified-active-link-border-color: $body-bg !default; //== Pills
$nav-pills-border-radius: $border-radius-base !default;
$nav-pills-active-link-hover-bg: $component-active-bg !default;
$nav-pills-active-link-hover-color: $component-active-color !default; //== Pagination
//
//##
$pagination-color: $link-color !default;
$pagination-bg: #fff !default;
$pagination-border: #ddd !default;
$pagination-hover-color: $link-hover-color !default;
$pagination-hover-bg: $gray-lighter !default;
$pagination-hover-border: #ddd !default;
$pagination-active-color: #fff !default;
$pagination-active-bg: $brand-primary !default;
$pagination-active-border: $brand-primary !default;
$pagination-disabled-color: $gray-light !default;
$pagination-disabled-bg: #fff !default;
$pagination-disabled-border: #ddd !default; //== Pager
//
//##
$pager-bg: $pagination-bg !default;
$pager-border: $pagination-border !default;
$pager-border-radius: 15px !default;
$pager-hover-bg: $pagination-hover-bg !default;
$pager-active-bg: $pagination-active-bg !default;
$pager-active-color: $pagination-active-color !default;
$pager-disabled-color: $pagination-disabled-color !default; //== Jumbotron
//
//##
$jumbotron-padding: 30px !default;
$jumbotron-color: inherit !default;
$jumbotron-bg: #f9f9f9 !default;
$jumbotron-heading-color: $headings-color !default;
$jumbotron-font-size: ceil($font-size-base * 1.5) !default;
$jumbotron-heading-font-size: ceil(
  $font-size-base * 4.5
) !default; //== Form states and alerts
//
//## Define colors for form feedback states and, by default, alerts.
$state-success-text: $brand-success !default;
$state-success-bg: $green-100 !default;
$state-success-border: darken(adjust-hue($state-success-bg, -10), 5%) !default;
$state-info-text: $sky-400 !default;
$state-info-bg: $sky-100 !default;
$state-info-border: darken(adjust-hue($state-info-bg, -10), 7%) !default;
$state-warning-text: $brand-warning !default;
$state-warning-bg: $yellow-100 !default;
$state-warning-border: darken(adjust-hue($state-warning-bg, -10), 5%) !default;
$state-danger-text: $brand-danger !default;
$state-danger-bg: $red-100 !default;
$state-danger-border: darken(
  adjust-hue($state-danger-bg, -10),
  5%
) !default; //== Tooltips
//
//##
//** Tooltip max width
$tooltip-max-width: 200px !default; //** Tooltip text color
$tooltip-color: #fff !default; //** Tooltip background color
$tooltip-bg: $light-500 !default;
$tooltip-opacity: 1 !default; //** Tooltip arrow width
$tooltip-arrow-width: 5px !default; //** Tooltip arrow color
$tooltip-arrow-color: $tooltip-bg !default; //== Popovers
//
//##
//** Popover body background color
$popover-bg: #fff !default; //** Popover maximum width
$popover-max-width: 276px !default; //** Popover border color
$popover-border-color: transparent !default; //** Popover fallback border color
$popover-fallback-border-color: transparent !default; //** Popover title background color
$popover-title-bg: darken($popover-bg, 3%) !default; //** Popover arrow width
$popover-arrow-width: 10px !default; //** Popover arrow color
$popover-arrow-color: $popover-bg !default; //** Popover outer arrow width
$popover-arrow-outer-width: $popover-arrow-width + 1 !default; //** Popover outer arrow color
$popover-arrow-outer-color: fadein(
  $popover-border-color,
  7.5%
) !default; //** Popover outer arrow fallback color
$popover-arrow-outer-fallback-color: darken(
  $popover-fallback-border-color,
  20%
) !default; //== Labels
//
//##
//** Default label background color
$label-default-bg: $gray-light !default; //** Primary label background color
$label-primary-bg: $brand-primary !default; //** Success label background color
$label-success-bg: $brand-success !default; //** Info label background color
$label-info-bg: $brand-info !default; //** Warning label background color
$label-warning-bg: $brand-warning !default; //** Danger label background color
$label-danger-bg: $brand-danger !default; //** Default label text color
$label-color: #fff !default; //** Default text color of a linked label
$label-link-hover-color: #fff !default; //== Modals
//
//##
//** Padding applied to the modal body
$modal-inner-padding: 15px !default; //** Padding applied to the modal title
$modal-title-padding: 15px !default; //** Modal title line-height
$modal-title-line-height: $line-height-base !default; //** Background color of modal content area
$modal-content-bg: #fff !default; //** Modal content border color
$modal-content-border-color: transparent !default; //** Modal content border color **for IE8**
$modal-content-fallback-border-color: #999 !default; //** Modal backdrop background color
$modal-backdrop-bg: #000 !default; //** Modal backdrop opacity
$modal-backdrop-opacity: 0.5 !default; //** Modal header border color
$modal-header-border-color: transparent !default; //** Modal footer border color
$modal-footer-border-color: $modal-header-border-color !default;
$modal-lg: 900px !default;
$modal-md: 600px !default;
$modal-sm: 300px !default;
$font-size-anonymous: 32px !default; //== Alerts
//
//## Define alert colors, border radius, and padding.
$alert-padding: 15px !default;
$alert-border-radius: $border-radius-base !default;
$alert-link-font-weight: bold !default;
$alert-success-bg: $state-success-bg !default;
$alert-success-text: $state-success-text !default;
$alert-success-border: $state-success-border !default;
$alert-info-bg: $state-info-bg !default;
$alert-info-text: $state-info-text !default;
$alert-info-border: $state-info-border !default;
$alert-warning-bg: $state-warning-bg !default;
$alert-warning-text: $state-warning-text !default;
$alert-warning-border: $state-warning-border !default;
$alert-danger-bg: $state-danger-bg !default;
$alert-danger-text: $state-danger-text !default;
$alert-danger-border: $state-danger-border !default; //== Progress bars
//
//##
//** Background color of the whole progress component
$progress-bg: $light-200 !default; //** Progress bar text color
$progress-bar-color: #fff !default; //** Variable for setting rounded corners on progress bar.
$progress-border-radius: $border-radius-base !default; //** Default progress bar color
$progress-bar-bg: $brand-primary !default; //** Success progress bar color
$progress-bar-success-bg: $brand-success !default; //** Warning progress bar color
$progress-bar-warning-bg: $brand-warning !default; //** Danger progress bar color
$progress-bar-danger-bg: $brand-danger !default; //** Info progress bar color
$progress-bar-info-bg: $sky-400 !default; //== List group
//
//##
//** Background color on `.list-group-item`
$list-group-bg: #fff !default; //** `.list-group-item` border color
$list-group-border: #ddd !default; //** List group border radius
$list-group-border-radius: $border-radius-base !default; //** Background color of single list items on hover
$list-group-hover-bg: #f5f5f5 !default; //** Text color of active list items
$list-group-active-color: $component-active-color !default; //** Background color of active list items
$list-group-active-bg: $component-active-bg !default; //** Border color of active list elements
$list-group-active-border: $list-group-active-bg !default; //** Text color for content within active list items
$list-group-active-text-color: lighten(
  $list-group-active-bg,
  40%
) !default; //** Text color of disabled list items
$list-group-disabled-color: $gray-light !default; //** Background color of disabled list items
$list-group-disabled-bg: $gray-lighter !default; //** Text color for content within disabled list items
$list-group-disabled-text-color: $list-group-disabled-color !default;
$list-group-link-color: #555 !default;
$list-group-link-hover-color: $list-group-link-color !default;
$list-group-link-heading-color: #333 !default; //== Panels
//
//##
$panel-bg: #fff !default;
$panel-body-padding: 15px !default;
$panel-heading-padding: 10px 15px !default;
$panel-footer-padding: $panel-heading-padding !default;
$panel-border-radius: $border-radius-base !default; //** Border color for elements within panels
$panel-inner-border: #ddd !default;
$panel-footer-bg: #f5f5f5 !default;
$panel-default-text: $gray-dark !default;
$panel-default-border: #ddd !default;
$panel-default-heading-bg: #f5f5f5 !default;
$panel-primary-text: #fff !default;
$panel-primary-border: $brand-primary !default;
$panel-primary-heading-bg: $brand-primary !default;
$panel-success-text: #fff !default;
$panel-success-border: $state-success-border !default;
$panel-success-heading-bg: $brand-success !default;
$panel-info-text: #fff !default;
$panel-info-border: $state-info-border !default;
$panel-info-heading-bg: $brand-info !default;
$panel-warning-text: #fff !default;
$panel-warning-border: $state-warning-border !default;
$panel-warning-heading-bg: $brand-warning !default;
$panel-danger-text: #fff !default;
$panel-danger-border: $state-danger-border !default;
$panel-danger-heading-bg: $brand-danger !default; //== Thumbnails
//
//##
//** Padding around the thumbnail image
$thumbnail-padding: 4px !default; //** Thumbnail background color
$thumbnail-bg: $body-bg !default; //** Thumbnail border color
$thumbnail-border: #ddd !default; //** Thumbnail border radius
$thumbnail-border-radius: $border-radius-base !default; //** Custom text color for thumbnail captions
$thumbnail-caption-color: $text-color !default; //** Padding around the thumbnail caption
$thumbnail-caption-padding: 9px !default; //== Wells
//
//##
$well-bg: $light-100 !default;
$well-border: transparent !default; //== Badges
//
//##
$badge-color: #fff !default; //** Linked badge text color on hover
$badge-link-hover-color: #fff !default;
$badge-bg: $gray-light !default; //** Badge text color in active nav link
$badge-active-color: $link-color !default; //** Badge background color in active nav link
$badge-active-bg: #fff !default;
$badge-font-weight: normal !default;
$badge-line-height: 1 !default;
$badge-border-radius: 10px !default; //== Breadcrumbs
//
//##
$breadcrumb-padding-vertical: 8px !default;
$breadcrumb-padding-horizontal: 15px !default; //** Breadcrumb background color
$breadcrumb-bg: #f5f5f5 !default; //** Breadcrumb text color
$breadcrumb-color: #ccc !default; //** Text color of current page in the breadcrumb
$breadcrumb-active-color: $gray-light !default; //** Textual separator for between breadcrumb elements
$breadcrumb-separator: "/" !default; //== Carousel
//
//##
$carousel-text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6) !default;
$carousel-control-color: #fff !default;
$carousel-control-width: 15% !default;
$carousel-control-opacity: 0.5 !default;
$carousel-control-font-size: 20px !default;
$carousel-indicator-active-bg: #fff !default;
$carousel-indicator-border-color: #fff !default;
$carousel-caption-color: #fff !default; //== Close
//
//##
$close-font-weight: normal !default;
$close-color: #000 !default;
$close-text-shadow: none !default; //== Code
//
//##
$code-color: #da2a6f !default;
$code-bg: #f9f2f4 !default;
$kbd-color: #fff !default;
$kbd-bg: #333 !default;
$pre-bg: #f5f5f5 !default;
$pre-color: $gray-dark !default;
$pre-border-color: #ccc !default;
$pre-scrollable-max-height: 340px !default; //== Type
//
//##
//** Horizontal offset for forms and lists.
$component-offset-horizontal: 180px !default; //** Text muted color
$text-muted: $brand-muted !default; //** Abbreviations and acronyms border color
$abbr-border-color: $gray-light !default; //** Headings small color
$headings-small-color: $gray-light !default; //** Blockquote small color
$blockquote-small-color: $gray-light !default; //** Blockquote font size
$blockquote-font-size: $font-size-base * 1.25 !default; //** Blockquote border color
$blockquote-border-color: $gray-lighter !default; //** Page header border color
$page-header-border-color: $gray-lighter !default; //** Width of horizontal description list titles
$dl-horizontal-offset: $component-offset-horizontal !default; //** Point at which .dl-horizontal becomes horizontal
$dl-horizontal-breakpoint: $grid-float-breakpoint !default; //** Horizontal line color.
$hr-border: $gray-lighter !default; //== Gooru specific
/* === COLORS === */
$gru-category-panel-bg: #dcddde;
/*colors for the performance scale indicator and metrics table*/
$gru-scale-indicator-bad: #e08282;
$gru-scale-indicator-regular: #fec956;
$gru-scale-indicator-good: #edf167;
$gru-scale-indicator-very-good: #a2de81;
$gru-scale-indicator-excellent: #3fc380;
/*colors for the performance score and completion circle*/
$performance-circle-background-color: #dedede;
/*colors for the gru-century-skills*/
$gru-century-skills-column-header: #f2f3f7;
$gru-century-skills-legend-hewlett: #76b073;
$gru-century-skills-legend-conley: #58bfec;
$gru-century-skills-legend-framework: #ebb958;
$gru-century-skills-legend-national: #e96f6e;
$gru-century-skills-item-selected: #45515d;
/* === DIMENSIONS === */
$player-navigation-height: 163px;
$player-navigation-md-height: 105px;
$user-card-avatar-width: 60px;
$narration-avatar-width: 32px;
$user-card-avatar-height: 60px;
$narration-avatar-height: 32px;
$performance-circle-size: 20px;
$emotion-icon-size: 31px;
$emotion-icon-size-small: 22px;
/*student-performance-box*/
$in-progress-icon-size: 20px;
/* Goals*/
$expand-arrow-size: 30px;
/* Student-landing*/
//class-page
$font-navigator: 16px; //my-performance-page
$performance-table-header: #637989;
$expand-arrow: 26px;
/* Teacher-landing*/
//teacher-class-card
$student-icon: #ccc;
/* New cards*/
//Collection
$font-title: 16px; //independent-learning
$font-add-bookmark: 52px;
$font-unBookmark: 28px; // student class card
$font-percentage: 50px; //matrix
/** Custom color */
$light-cyan: #2ea9e9;
$snow-gray: #fbfbfb;
$elephant-dark: #535e66;
$in-progress: #00ace9;
$not-started: #cdcdcd;
$nobel-gray: #979797;
$sun-color: #ef8f2f;
$light-green: #4a9b3c;
$heathered-gray: #8d8c8b;
$charcoal-grey: #303a42;
$charcoal-grey-two: #2a333a;
$dark: #252d34;
$pale-grey-two: #dfe1e7;
$soft-blue: #6092e0;
$grey-light: #78797b;
$copper-rust: #984a4a;
/* Custom Color couse map */
$sep-border-color: #d7d7d7; //  ATC view color codes
$greyish: #b2b2b2;
/*mobile and tablets responsive width*/
$sm-width-max: 600px !default; //Extra small devices (phones)
$lg-width-min: 600px !default; //Small devices (portrait tablets and large phones)
// Custom screen sizes
$screen-380: 380px !default;
$screen-376: 376px;
$screen-359: 359px;
$screen-568: 568px !default;
$screen-800: 800px !default;
$screen-375: 375px !default;
$screen-465: 465px !default;
$screen-824: 824px !default;
$screen-320: 320px !default;
$screen-705: 705px !default;
$screen-720: 720px !default;
$screen-1024: 1024px !default;
$screen-1025: 1025px !default;
$screen-1366: 1366px !default;
$screen-600: 600px !default;
$screen-360: 360px !default;
$screen-875: 875px !default;
$screen-411: 411px !default;
$screen-414: 414px !default;
$screen-481: 481px !default;
$screen-640: 640px !default;
$screen-791: 791px !default;
$screen-780: 780px !default;
$screen-814: 814px !default;
$screen-675: 675px !default;
$screen-400: 400px !default;
$bg-dark: #323232;
$screen-639: 639px !default;
$screen-569: 569px !default;
$screen-669: 669px !default;
$screen-1035: 1035px !default;
$btn-blue: #0076ba;
$screen-1280: 1280px !default;
$screen-767: 767px !default;
$tab-float-dir: left;
$menu-float-dir: right;
$border-right-side: 1px solid;
$border-left-side: 0;
$header-menu-rt-pos: 10px;
$box-shadow: 1px -1px; // 2) import style file
$blue-mastery: $blue-light;
$blue-in-progress: #1aa9eb;
$gray-no-started: #e7e8e9;
$not-in-framework: #f0f0f0;
$grade-range-70-79-bg: #f8ba41;
$grade-range-80-89-bg: $green-light;
$disabled-bg-color: rgba(0, 0, 0, 0.1) !default;
$disabled-color: rgba(44, 33, 33, 0.4) !default;
$white-bold: #ffffff;
$black-bold: #000000;
$black-ticker-wrap: rgba(#000, 0.9);
$box-shadow-bg-color: rgba(0, 0, 0, 0.2);
$bg-red-bar: #da1111;
$bg-progress-pointer: #6e6e6e;
$bg-progress-filling: #42b8ef;
$exampler-box-shadow: rgba(0, 0, 0, 0.22);
$bg-report-period: #35424a;
$has-active-bg-color: #d2e3fc;
$has-active-color: $blue-light;
$today-hover: #fde19a;
$bg-today-hover: $blue-light;
$backdrop-color: #434c5a;
$suggestion-panel-color: #37434d;
$orange-49: #f88c49;
$gray-e68: #4e5e68;
$gray-73: #737373;
$gray-4b: #32404b;
$gray-3e: #29353e;
$upload-container: #007ec1;
$timer-active-icon: #ff0000;
$scope-filter-container-color: #3c4452;
$input-card-bg-color: #a7c5c5;
$input-label: $blue-light;
$default-title-color: #5d5d5d;
$placeholder-color: #3e5e67;
$blue-bc: #0172bc;
$oa-score-bg-color: #5d676f;
$blue-text: #03a9f4;
$default-green: $green-light;
$green-50: $green-light;
$schedule-updated: #4d9840;
$gray-4a: #37424a;
$join-class-room-bg-color: #f1f1f1f2;
$default-gray: $dark-300;
$dark-gray: #2d2d2d;
$ans-text: #4f95f6;
$math-text: #3c99f7;
$incorrect-color: #f36360;
$dark-block-with-blue: #02030a;
$title-color: #545e66;
$header-bg-color: #425e67;
$close-pull-up-bg: rgba(216, 216, 216, 0.1);
$video-icon-bg: #1aa9eb;
$red-30: #ff3b30;
$blue-f3: $blue-light;
$lite-gray: #eee;
$filter-bg: #898b8e;
$info-icon-bg: #c6cad4;
$orange-50: #ffa500;
$gray-ab: #ababab;
$confirm-button-bg: #0000ff;
$gray-96: #969696;
$red-69: #ef8d69;
$exampler-expand-bc: rgba(241, 243, 247, 0.38);
$green-80: #80c080;
$gray-c9: #c3c6c9;
$violet-b6: #9b59b6;
$blue-db: #3498db;
$frq-text: #e6e6e6;
$green-496: #8dc496;
$input-gray: #6d6d6d;
$frq-gray: #5d666f;
$selected-green: #4b9147;
$white-minimum: #ffff;
$lite-gray-medium: #eee;
$dark-gray-4c: #4c4c4c;
$right-panel-bg: #30424e;
$blue-ccc: #008ccc;
$non-visible-tag: #6f6666;
$account-circle: $blue-light;
$class-list-bg: #d9d9d9;
$icon-bg-color: #aaa;
$orange-b00: #e58b00;
$yellow-f00: #f4bf00;
$gray-88: #888;
$show-more-text: #1fa9e8;
$not-graded: #525e65;
$home-bg-color: #30424a;
$suggestion-explore-bg: #ffffff57;
$blue-2be: #0072be;
$panel-border: #ffffff3b;
$gray-cf: #cfcfcf;
$gray-e7: #e7e7e7;
$link-blue-color: #00a9e2;
$gray-82: #687782;
$green-35: $green-light;
$gray-94: #8f8e94;
$blue-e5: #97c5e5;
$red-47: #a24847;
$red-05: #f73605;
$white-ea: #e4e5ea;
$gray-67: #4e5e67;
$grade-d8: #ccd2d8;
$grade-b9: #afb4b9;
$blue-e0: #00abe0;
$blue-eo: #4190e0;
$bule-8c: #476d8c;
$gray-c3: #a5c4c3;
$gray-f7: #f1f2f7;
$gray-e67: #4b5e67;
$black-4d: #3a444d;
$gray-1a5: #9aa1a5;
$blue-9d2: #0039d2;
$place-holder-bg: #d6d6d6;
$picker-color: #888888;
$blue-bdf: #00abdf;
$dark-gray-54: #3f4c54;
$resource-bg: #bcd0d1;
$source-divider: #ccd9db;
$ornge-03: #ffb303;
$ok-action-color: #d83939;
$orange-462: #ffd462;
$orage-f3f: #e88f3f;
$black-94b: #2f394b;
$orange-153: #dd9153;
$blue-290: #0e1290;
$blue-8e7: #47a8e7;
$guest-account-bg: #f1f1f1d9;
$blue-ce0: #6b9ce0;
$green-a32: #538a32;
$red-939: #d93939;
$arrow-color: #a1a1a1;
$blue-5f2: #9ad5f2;
$orage-fdd: #fdd49a;
$lite-yellow: #fdf59a;
$blue-ebo: #006eb0;
$no-image-color: #e8e9ea;
$dark-black: #00001f;
$blue-5ba: #0675ba;
$black-24a: #33424a;
$black-517: #242517;
$orange-aob: #fd8a0b;
$white-ff4: #efeff4;
$blue-bcd: #2eabcd;
$green-83c: #48983c;
$blue-8ec: #3898ec;
$gray-9c: #9c9c9c;
$gray-3b3: #b3b3b3;
$blue-5d7: #4c95d7;
$gray-e47: #353e47;
$gray-252: #525252;
$blue-7bf: #2b67bf;
$green-43c: #24a43c;
$black-111: #111;
$arsenic-grey: #3a414a;
$black-pearl-blue: #202e32;
$black-light: #0a0909;
$date-picker-box-shadow: rgba(0, 0, 0, 0.13);
$navbar-box-shadow: rgba(0, 0, 0, 0.3);
$xz-design: rgba(50, 50, 50, 0.5);
$slider-box-shadow: rgba(0, 0, 0, 0.24);
$slider-left-box-shadow: rgba(0, 0, 0, 0.12);
$table-box-shadow-07: rgba(0, 0, 0, 0.07);
$bg-box-shadow: rgba(0, 0, 0, 0);
$bg-left-box-shadow: rgba(255, 255, 255, 0);
$border-box-shadow: rgba(151, 151, 151, 0.4);
$border-bottom-shadow: rgba(102, 102, 102, 0.5);
$grade-range-0-59: rgba(244, 99, 96, 0.3);
$grade-range-60-69: rgba(237, 142, 54, 0.3);
$grade-range-70-79: rgba(250, 186, 54, 0.3);
$grade-range-80-89: rgba(168, 201, 156, 0.3);
$grade-range-90-100: rgba(75, 151, 64, 0.3);
$container-box-shadow: rgba(102, 102, 102, 0.3);
$explore-box-shadow: rgba(255, 255, 255, 0.5);
$layout-container: rgba(165, 196, 195, 1);
$adding-layout: (165, 196, 195, 0);
$scope-sequence: rgba(225, 225, 225, 0);
$green-box-shadow-75: rgba(75, 151, 65, 1);
$constant-modal: rgba(112, 112, 112, 0.13);
$content-popup-box-shadow: rgba(0, 0, 0, 0.8);
$white-6f4: #f7f6f4;
$fraction-of-box-shadow: rgba(151, 151, 151, 0.3);
$li-background: rgba(0, 0, 0, 0.17);
$completed-background: rgba(0, 95, 189, 0.49);
$collection-border-bottom: rgba(109, 65, 65, 0.2);
$cancel-action: rgba(83, 94, 103, 0.5);
$remove-file-box-shadow: rgba(204, 204, 204, 0.5);
$input-border-color: rgba(71, 81, 89, 0.3);
$header-label: rgba(55, 66, 75, 0.1);
$selected-student: rgba(55, 66, 75, 0.7);
$headr-content-label: rgba(83, 94, 103, 0.3);
$header-container: rgba(83, 94, 103, 0.9);
$timespent-label: rgba(184, 191, 196, 0.5);
$activity-reef-value: rgba(0, 114, 188, 0.5);
$popover-box-shadow: rgba(0, 0, 0, 0.7);
$left-arrow-box-shadow: rgba(0, 0, 0, 0.25);
$student-container: (151, 151, 151, 0.2);
$scroll-left-back-ground: rgba(160, 203, 243, 0.5);
$completion-bg-color: rgba(191, 191, 191, 0.5);
$user-list-box-shadow: rgba(0, 0, 0, 0.9);
$selected-bg-color: rgba(35, 234, 249, 0.25);
$search-icon-bg-color: rgba(216, 216, 216, 0.2);
$block-shadow: rgba(0, 0, 0, 0.02);
$performance-bg-color: rgba(204, 204, 204, 0.8);
$collection-panel: rgba(204, 204, 204, 0.6);
$text-shadow: rgba(0, 0, 0, 0.87);
$gray-f9f: #9f9f9f;
$desc-text-shadow: rgba(0, 0, 0, 0.91);
$table-box-shadow: rgba(203, 204, 208, 1);
$body-bg-color: rgba(151, 222, 236, 0.1);
$loading-spinner-bg-color: rgba(255, 255, 255, 0.3);
$class-static-box-shadow: rgba(0, 0, 0, 0.3);
$active-bg-color: rgba(44, 66, 74, 0.4);
$tenant-info-border-color: rgba(151, 151, 151, 0.5);
$profile-box-shadow: rgba(0, 0, 0, 0.15);
$hover-box-shadow: rgba(66, 133, 244, 0.3);
$layout-home-bc-image: rgba(12, 38, 71, 0.81);
$layout-left-container: rgba(12, 38, 71, 0.59);
$layout-right-container: rgba(12, 38, 71, 0.47);
$button-text-shadow: rgba(0, 0, 0, 0.46);
$coursel-text-shadow: rgba(93, 93, 93, 0.3);
$mask-box-shadow: rgba(0, 0, 0, 0.52);
$label-border-color: rgba(151, 151, 151, 0.1);
$ruberic-box-shadow: rgba(0, 0, 0, 0.1);
$navigation-box-shadow: rgba(0, 0, 0, 0.5);
//quizzes
$default-red: #ff0000;
$blue-ef: #42b8ef;
$graye-67: #3e5e67;
$tag-bg: #2d2d2d;
$gray-66: #666666;
$feedback-form-color: #737373;
$blue-cc: #6badcc;
$blue-do: #7dd0d0;
$blue-caf: #799caf;
$blue-bf: #7ebfbf;
$half-white: #f2f2f2;
$white-e1: #e1e1e1;
$gray-e4: #e8e4e4;
$gray-border: #868585;
$brown: #463423;
$icon-bg: #f7f8f9;
$icon-border-color: #eeeff1;
$violet-a2: #576ca2;
$green-51: #00c451;
$attempt-color: #29333c;
$info-color: #646b71;
$gray-f9: #e2eaf9;
$gray-fa: #eaeffa;
$gray-f6: #dde5f6;
$blue-c8: #91a1c8;
$blue-af: #6f81af;
$gray-a9: #a9a9a9;
$black-15: #131415;
$gray-42: #3e4042;
$blue-a9: #0153a9;
$blue-b5: #3671b5;
$gray-d5: #d5d5d5;
$dogger-blue: #1e90ff;
$gains-boro: #dcdcdc;
$gray-500: #545d69;
$blue-5d8: #5295d8;
$gray-4c9: #bdc4c9;
$dark-gray-59: #475159;
$gray-2f3: #cfe2f3;
$gray-dbe: #bcbdbe;
$blue-2f4: #a4c2f4;
$gray-c40: #393c40;
$blue-fe7: #00afe7;
$gray-e9e: #9e9e9e;
$black-d89: #6e7d89;
$red-463: #f36463;
$brown-c3c: #543c3c;
$gray-0b0: #b0b0b0;
$blue-7d7: #4a97d7;
$blue-8e6: #add8e6;
$orange-800: #ff9800;
$gray-f93: #798f93;
$orange-e1b: #f89e1b;
$yellow-e34: #e5be34;
$gray-24b: #34424b;
$black-a0a: #0a0a0a;
$blue-ee2: #5c8ee2;
$blue-0e2: #4990e2;

//---------Powerschool Updated Variables-----------//
$blue-bg: #1c6aef;
$blue-hover-bg: #0050a2;
$blue-active-bg: #00427c;
$blue-focus-bg: #0050a2;
$blue-btn-txt: #1162ee;
$font-family-ps-roboto: "Roboto";
$antique-white: #fee9d7;
$white-backgraound: rgb(255, 255, 255);
$blue-pale: #bad7ff;
$dark-night: #333333;
$link-water: #c4c9ca;
$btn-border-grey: #dfe2e2;
$btn-background: #e8f0fd;
$grey-background: #f1f4f4;
$black-333333: #333333;
$gray-4d5557: #4d5557;

$gray-border: #c4c4c4;
//---------text font color variables starts-----------//

$font-green-35-dark-400: $green-35 !default;
$font-gray-oslo-dark-400: $gray-oslo !default;
$font-modal-content-fallback-border-color-dark-400: $modal-content-fallback-border-color !default;
$font-white-100-white-500: $white !default;
$font-gray-100-gray-500: $modal-content-fallback-border-color !default;
$font-gray-white-500: $font-white-100-white-500 !default;
$font-gray-200-gray-900: $gray-a9 !default;
$font-light-400-dark-500: $light-400 !default;
$font-dark-500-white-500: $font-white-100-white-500 !default;
$font-sky-400-dark-400: $sky-400 !default;
$font-sky-400-blue-dark: $sky-400 !default;
$font-gray-500-black-bold: $gray !default;
$font-gray-dark-500: $gray !default;
$font-green-400-green-700: $green-400 !default;
$font-dodgerblue-dark-400: #4b8fe2 !default;
$font-gray-a9-dark-500: $font-gray-200-gray-900 !default;
$font-picton-blue-gray: $picton-blue !default;
$font-sun-color-orange-100: $sun-color !default;
$font-blue-500-blue-fe7: $blue-fe7 !default;
$font-gray-light-dark-400: $gray-light !default;
$font-gray-a9-dark-400: $gray-a9 !default;
$font-blue-400-dark-400: $blue-400 !default;
$font-gray-dark-400: $gray !default;
$font-white-dark-400: $white !default;
$font-white-gray-base: $white !default;
$font-red-463-white: $red-463 !default;
$font-blue-45-black: $ans-text !default;
$font-blue-50-black-500: $blue-5d8 !default;
$font-black-bold-white: $white !default;
$font-math-text-gray-base: $math-text !default;
$font-blue-ee2-black: $blue-ee2 !default;
$font-dark-600-white: $dark-600 !default;
$font-account-circle-dark-400: $account-circle !default;
$font-non-visible-tag-white: $non-visible-tag !default;
$font-gray-white: $gray !default;
$font-ans-text-blue-dark: $ans-text !default;
$font-lite-gray-medium-gray: $lite-gray-medium !default;
$font-gray-lighter-gray: $gray-lighter !default;
$font-blue-400-blue-dark: $blue-400 !default;
$font-blue-8e7-blue-dark: $blue-8e7 !default;
$font-light-300-dark-400: $light-300 !default;
$font-grey-light-dark-400: $grey-light !default;
$font-red-400-dark-400: $red-400 !default;
$font-orange-400-dark: $orange-400 !default;
$font-grade-range-70-79-bg-dark-400: $grade-range-70-79-bg !default;
$font-grade-range-80-89-bg-dark-400: $grade-range-80-89-bg !default;
$font-green-35-green-dark: $green-35 !default;
$font-red-400-red-900: $red-400 !default;
$font-dark-400-white: $dark-400 !default;
$font-orange-49-dark-400: $orange-400 !default;
$font-light-500-gray: $light-500 !default;
$font-place-holder-bg-dark-400: $place-holder-bg !default;
$font-light-500-black-bold: $light-500 !default;
$font-input-label-dark-400: $input-label !default;
$font-bg-progress-pointer-dark-100: $dark-100 !default;
$font-green-400-gray-base: $green-400 !default;
$font-title-color-black-bold: $title-color !default;
$font-default-gray-dark-400: $default-gray !default;
$font-dark-200-gray-dark-400: $dark-200 !default;
$font-dark-400-black: $dark-400 !default;
$font-dark-400-gray-base: $dark-400 !default;
$font-inherit-black-bold: inherit !default;
$font-dark-100-gray-base: $dark-100 !default;
$font-light-400-gray-base: $light-400 !default;
//---------text font color variables ends-----------//

//---------background color variables starts-----------//

$bg-green-35-green-800: $green-35 !default;
$bg-blue-300-blue-500: $blue-light !default;
$bg-green-45-green-700: $green-700 !default;
$bg-gray-700-gray-800: $default-gray !default;
$bg-sky-600-sky-400: $blue-light !default;
$bg-red-400-red-800: $red-400 !default;
$bg-green-700-green-dark: $green-light !default;
$bg-orange-400-dark: $orange-400 !default;
$bg-dogger-blue-dark-blue-600: $dogger-blue !default;
$bg-dark-blue-sky-400: $sky-400 !default;
$bg-tan-hide-orange-100: $tan-hide !default;
$bg-gray-e9e-dark-400: $gray-e9e !default;
$bg-danube-black-bold: $danube !default;
$bg-table-bg-black: $table-bg !default;
$bg-white-black: $white !default;
$bg-blue-ce0-blue-dark: $blue-ce0 !default;
$bg-dark-300-dark-400: $dark-300 !default;
$bg-orange-500-gray-base: $orange-500 !default;
$bg-danube-blue-dark: $danube !default;
$bg-source-divider-white: $source-divider !default;
$bg-resource-bg-white: $resource-bg !default;
$bg-video-icon-bg-blue-dark: $video-icon-bg !default;
$bg-blue-mastery-blue-dark: $blue-mastery !default;
$bg-light-300-white: $light-300 !default;
//---------background color variables ends-----------//

//---------icon variables start-----------//
$pin-dark-white: url("gooru/pin-white.png") !default;
$assessment-blue-gray-dark: url("gooru/images/assessment-blue.svg") !default;
$assessment-white-gray-dark: url("../assets/gooru/images/assessment-white.svg") !default;
//---------iconvariables ends-----------//

//---------other variables start-----------//
$opacity-light: 0.5 !default;
$opacity-full: 0.5 !default;
$opacity-lighter: 0.3 !default;
$opacity-medium: 0.6 !default;
$blue-clr: #1d71d9;
$orange-clr: #ea8410;

//---------other variables ends-----------//

//---------powerschool learningNav variables start-----------//
$ps-login-header-clr: #f1f4f4;
$ps-login-bg-clr: #f5f5f5;
$ps-blue-db: #0075db;
$ps-blue-ef: #1c6aef;
$ps-white-fd: #e8f0fd;
$ps-blue-ff: #bad7ff;
$ps-blue-a2: #0050a2;
$ps-blue-dark-7c: #00427c;
$ps-white-e2: #dfe2e2;
$ps-grey-74: #677274;
$ps-font-family-roboto: "Roboto";

//---------powerschool learningNav variables ends-----------//
$default-text-color: #595959;
$white-to-block: $white !default;
$block-gray: #67727b !default;
$dark-gray-600: #37424b !default;
$font-gray-white-base: #000 !default;

$ps-blue-100: #80bdff;
$ps-blue-dark: #495057;

$ps-grey-100: #838e91;

$ps-red-100: #820726;
$ps-red-200: #da2f5a;
$ps-red-reset: #e21707;
