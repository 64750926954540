.gru-student-class-card {
  width: 300px;
  height: 300px;

  .panel {
    @include border-top-radius(4px);
    @include border-bottom-radius(4px);
    margin: 0;

    .panel-heading {
      width: 100%;
      padding: 0;
      height: 40px;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
      border-bottom: 1px solid $dark-600;

      > .title {
        width: 90%;
        padding: 6px;

        a {
          color: $dark-600;
          margin: 0;
          font-size: $font-size-h6;
          font-weight: bold;
        }
        $line-height: 1.3;
        $lines-to-show: 1;

        .truncate {
          @include ellipsis(
            $font-size: $font-size-base,
            $line-height: $line-height,
            $lines-to-show: $lines-to-show,
            $excerpt-bg: $white
          );
        }
      }
    }

    .panel-body {
      padding: 0;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
      height: 248px;

      .information {
        height: 167px;
        width: 100%;
        color: $white;
        background-size: contain;
        background-repeat: no-repeat;
        padding: 103px 0 0;
        font-weight: bold;
        position: relative;
        background-position: center;
        background-color: $gray-base;

        &.no-play {
          pointer-events: none;
        }

        .current-activity {
          position: absolute;
          z-index: 10;
          font-size: 12px;
          padding: 0 10px;
          font-weight: bold;

          span {
            margin-top: 10px;
            display: block;

            &.start-studying {
              margin-top: 38px;
            }
          }
        }

        .title-section {
          $line-height: 0.5;
          $lines-to-show: 2;
          margin: 33px 0 0;
          padding: 0 10px;
          position: absolute;
          z-index: 10;
          width: 100%;

          .title {
            line-height: $font-size-base;
            vertical-align: top;
            display: inline-block;
            width: 86%;
            font-weight: bold;
          }

          .truncate {
            @include ellipsis(
              $font-size: $font-size-base,
              $line-height: $line-height,
              $lines-to-show: $lines-to-show,
              $excerpt-bg: $white
            );
          }

          i {
            margin: 0 2px 0 0;
            width: 22px;
            height: 17px;
            vertical-align: top;
            background-repeat: no-repeat;
            background-position: center center;
            display: inline-block;

            &.collection {
              background-image: url("gooru/images/navigation_collection.svg");
            }

            &.assessment {
              background-image: url("gooru/images/navigation_assessment.svg");
            }
          }
        }

        &.has-course {
          cursor: pointer;

          &:after {
            display: block;
            position: absolute;
            z-index: 9;
            background-image: linear-gradient(
              to bottom,
              $bg-left-box-shadow 0,
              $kbd-bg 100%
            );
            margin-top: -3px;
            height: 40%;
            width: 100%;
            content: "";
          }
        }

        &.has-no-course {
          display: grid;
          align-items: center;
          justify-items: center;
          cursor: pointer;
          padding: 0;
          opacity: 0.5;
          font-weight: 300;
          font-style: italic;
          text-align: center;
          color: $dark-600;
          background-color: $default-gray;

          p {
            font-size: 23px;
            line-height: 1;
            font-weight: normal;
            padding: 0;
            margin: 0;
          }
        }
      }

      .card-action-container {
        display: grid;
        grid-template-columns: 1fr 1fr 50px;
        justify-items: center;
        height: 50px;
        box-shadow: 1px 0 4px 0 $navbar-default-toggle-icon-bar-bg;

        &.public-class {
          grid-template-columns: 1fr 50px;
          justify-items: baseline;

          .course-map {
            border-right: 1px solid $icon-bg-color;
          }
        }

        &.premium-class {
          grid-template-columns: 50px 50px 50px 1fr;

          &.public-class {
            grid-template-columns: 50px 50px 1fr;
          }

          .class-activities,
          .course-map {
            grid-template-columns: 50px;
          }

          .course-map {
            border-right: 1px solid $icon-bg-color;
          }

          .my-proficiency {
            border-right: 1px solid $icon-bg-color;
            border-left: unset;
          }
        }

        .completion-score {
          text-align: center;
          color: $dark-600;
          justify-self: end;
          border-left: 1px solid $icon-bg-color;

          &:not(.has-competency-stats) {
            pointer-events: none;
          }

          &.has-competency-stats {
            background-color: $bg-dark-blue-sky-400;
            color: $white;
            cursor: pointer;
          }
          width: 100px;
          padding-top: 10px;

          span {
            font-weight: bold;
            font-size: 16px;
            padding-top: 10px;
          }
        }

        .course-map {
          display: grid;
          grid-template-columns: 50px 75px;
          justify-items: center;

          .course-map-icon {
            cursor: pointer;
            padding-top: 13px;
            width: 50px;
            text-align: center;
          }

          .course-map-score {
            font-weight: bold;
            font-size: 18px;
            cursor: pointer;
            padding-top: 10px;
            width: 50px;
            text-align: center;
            color: $white;

            &.not-started {
              pointer-events: none;
            }
          }

          i {
            color: $dark-600;
          }

          &.grade-range-not-started {
            .course-map-score {
              color: $dark-600;
            }
          }
          &.grade-range-90-100 {
            .course-map-icon {
              i {
                color: $font-dark-600-white;
              }
            }
          }
          &.grade-range-0-59 {
            .course-map-score {
              color: $font-black-bold-white;
            }
          }
          &.grade-range-60-69 {
            .course-map-score {
              color: $font-black-bold-white;
            }
          }
        }

        .class-activities {
          display: grid;
          grid-template-columns: 50px 75px;
          justify-items: center;
          font-weight: bold;
          border-right: 1px solid $icon-bg-color;

          .class-activities-icon {
            cursor: pointer;
            padding-top: 18px;
            width: 50px;
            text-align: center;
          }

          .class-activities-score {
            font-weight: bold;
            font-size: 18px;
            cursor: pointer;
            padding-top: 10px;
            width: 50px;
            text-align: center;

            &.not-started {
              pointer-events: none;
            }
          }

          &.grade-range-not-started {
            .class-activities-score {
              color: $dark-600;
            }
          }

          i.icon {
            background: url("gooru/images/teacher-landing-class-activities.svg");
            width: 10.2px;
            height: 15.8px;
            display: block;
            object-fit: contain;
            margin: 0 auto;
          }
        }
      }

      .my-proficiency {
        cursor: pointer;
        padding-top: 13px;
        width: 50px;
        text-align: center;
        border-left: 1px solid $icon-bg-color;

        i {
          color: $dark-600;
        }
      }

      .card-class-code {
        display: grid;
        grid-template-columns: 1fr auto;
        height: 32px;
        align-items: center;
        justify-items: end;
        color: $dark-600;
        grid-gap: 10px;

        .class-code {
          font-weight: bold;
          justify-self: center;
          padding-right: 10px;
        }
      }

      .completion-chart {
        height: 27px;
        padding: 0 11px;

        .charts.gru-x-bar-chart {
          border-radius: 4px;
          height: 7px;
        }

        label {
          display: block;
          font-size: $font-size-small;
          margin: 0;
          padding: 0 10px;
        }
      }

      .alert-player-error {
        border-radius: 4px;
        margin-bottom: 15px;
        background-color: $white;
        position: relative;
        line-height: 1.3;

        .alert-status {
          padding-left: 10px;
          color: $brand-danger;
          padding-top: 20px;
        }

        .clear-alert {
          position: absolute;
          right: 0;
          cursor: pointer;

          i {
            font-size: 20px;
            color: $brand-danger;
          }
        }
      }
    }
  }
}
