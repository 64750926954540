.gru-external-assessment-footer {
  .nav-player-container {
    .footer-section {
      padding: 10px;
      background-color: white;
      display: grid;
      grid-template-columns: 350px 1fr;
      align-items: center;
      position: fixed;
      width: 100%;
      left: 0;
      bottom: 0;
      z-index: 20;
      @media screen and (max-width: $screen-sm - 1) {
        grid-template-columns: auto;
      }

      .footer-title {
        display: grid;
        grid-template-columns: 1fr auto;
        grid-column-gap: 10px;
        align-items: center;
        font-weight: bold;

        .title-name {
          display: grid;
          grid-template-columns: 40px 1fr;
          align-items: center;

          .article-icon {
            text-align: center;
          }
        }

        .close-icon {
          display: flex;
          align-items: center;
          padding: 0;
          i {
            font-size: 25px;
          }
        }
      }

      .action-btns {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        a {
          display: block;
          margin-left: 20px;
          border-radius: 20px;
          padding: 2px 5px;
          font-weight: bold;
          text-align: center;
          display: grid;
          align-items: center;
          width: inherit;
          @media screen and (max-width: $screen-sm - 1) {
            margin: 0 auto;
          }
          span {
            padding: 0 5px;
          }
          i {
            font-size: 20px;
          }

          &.timespent-btn {
            grid-template-columns: 1fr auto;
            grid-column-gap: 5px;
            border: 1px solid $light-200;
            padding: 2px 20px;
            font-weight: normal;
            margin-left: auto;

            &.no-feedback {
              margin: 0 auto;
            }

            i {
              font-size: 15px;
              color: $red-200;
            }
          }

          &.not-visible {
            visibility: hidden;
          }

          &.cancel-btn {
            background-color: $dark-300;
            grid-template-columns: auto 1fr;
            color: $white;
            @media screen and (max-width: $screen-sm - 1) {
              display: flex;
              justify-content: center;
              width: 50px;
              height: 30px;
              span {
                display: none;
              }
              i {
                font-size: 23px;
              }
            }
            &.disable-event {
              opacity: 0.4;
            }
          }
          &.start-btn {
            background-color: $gooru-green;
            color: $white;
            grid-template-columns: auto 1fr;
            @media screen and (max-width: $screen-sm - 1) {
              display: flex;
              justify-content: center;
              width: 50px;
              height: 30px;
              span {
                display: none;
              }
              i {
                font-size: 23px;
              }
            }
          }
          &.continue-btn {
            background-color: $gooru-green;
            color: $white;
            grid-template-columns: auto 1fr;
            @media screen and (max-width: $screen-sm - 1) {
              display: flex;
              justify-content: center;
              width: 50px;
              height: 30px;
              span {
                display: none;
              }
              i {
                font-size: 23px;
              }
            }
          }
          &.feedback-btn {
            border: 1px solid $light-200;
            padding: 2px 20px;
            font-weight: normal;
            margin-right: auto;
          }
        }
        .hide-div {
          @media screen and (max-width: $screen-sm - 1) {
            display: none;
          }
        }
        .show-div {
          @media screen and (min-width: $screen-sm) {
            display: none;
          }
        }
      }
    }
  }
}
