.domain-info-pull-up {
  $line-height: 1.2;
  $lines-to-show: 6;
  width: 100%;
  box-shadow: 0 1px 3px 0 $navbar-box-shadow;
  background-color: $white-bold;
  display: grid;
  position: absolute;
  grid-template-rows: auto 1fr;
  top: 100%;
  z-index: 1;
  left: 0;
  right: 0;
  height: 100%;

  .competency-container {
    display: grid;
    overflow-y: auto;
    grid-template-rows: max-content 1fr;

    .title {
      text-transform: uppercase;
      font-weight: bold;
      padding: 10px 10px 0 15px;
    }

    ol {
      cursor: pointer;
      padding: 10px 0 10px 16px;

      li {
        cursor: default;
        padding-bottom: 10px;
        margin-bottom: 5px;
        border-bottom: 1px solid $breadcrumb-color;
        list-style: none;

        .topic-title {
          display: block;
          cursor: pointer;
          .topic-name {
            color: $sky-400;
          }
          .competency-count {
            font-size: 13px;
            margin-left: 23px;
          }
        }

        .competencies-list {
          display: flex;
          flex-flow: wrap;
          margin: 5px 5px 5px 20px;
          .competency-card {
            width: 170px;
            box-shadow: 0 0 5px $light-200;
            padding: 5px;
            margin: 2px;
            height: 100%;
            min-height: 180px;
            max-height: 180px;
            .comp-code {
              font-weight: bold;
              font-size: 11px;
            }
            .comp-desc {
              font-size: 14px;
              margin-top: 10px;
              background: $white;
              display: block;
              display: -webkit-box;
              max-height: 93.6px;
              font-size: 13px;
              line-height: 1.2;
              -webkit-line-clamp: 6;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }

        .competency-sec {
          display: flex;
          padding: 10px 0;

          .competency-status {
            display: grid;
            grid-template-columns: repeat(3, max-content);
            grid-gap: 10px;
            margin-right: 10px;

            .mastered {
              display: grid;
              grid-template-columns: 20px auto;
              justify-content: center;

              .status-label {
                width: 24px;
                height: 24px;
                background-color: $blue-mastery;
              }
            }

            .in-progress {
              display: grid;
              grid-template-columns: 20px auto;
              justify-content: center;

              .status-label {
                width: 24px;
                height: 24px;
                background-color: $blue-in-progress;
              }
            }

            .not-started {
              display: grid;
              grid-template-columns: 20px auto;
              justify-content: center;

              .status-label {
                width: 24px;
                height: 24px;
                background-color: $not-started;
              }
            }
          }
        }
      }
    }

    .competency-list {
      display: grid;
      padding: 15px;
      background-color: $white;
      grid-gap: 10px;
      grid-template-columns: repeat(2, 1fr);
      height: max-content;
      grid-template-rows: repeat(auto-fill, minmax(max-content, 180px));

      .competency-info {
        box-shadow: 0 2px 4px 0 $navbar-default-toggle-icon-bar-bg;
        background-color: $white;
        padding: 10px;
        height: 100%;
        cursor: pointer;
        max-height: 180px;
        min-height: 180px;

        .competency-desc {
          margin-top: 10px;
          @include ellipsis(
            $font-size: $font-size-base,
            $line-height: $line-height,
            $lines-to-show: $lines-to-show,
            $excerpt-bg: $white
          );
        }

        .competency {
          display: grid;
          grid-template-columns: 1fr 24px;
          grid-gap: 20px;
          padding-bottom: 10px;
          border-bottom: solid 2px;
          border-bottom-style: outset;
          font-size: 10px;

          .title {
            font-size: 11px;
            color: $dark-400;
            padding: 0;
            display: flex;
            .lo-content {
              cursor: pointer;
              .tag-tooltip {
                display: none;
              }
            }
          }

          .competency-status {
            width: 24px;
            height: 24px;

            &.status-0 {
              opacity: 0.3;
              background-color: $gray-no-started;
            }

            &.status-1 {
              background-color: $blue-in-progress;
            }

            &.status-2,
            &.status-3,
            &.status-4,
            &.status-5 {
              background-color: $blue-mastery;
            }
          }
        }
      }
    }
  }

  .domain-panel-header {
    padding: 16px;
    border-bottom: solid 1px $nobel-gray;
    display: grid;
    grid-template-areas: "title title close" "sequence sequence sequence";

    .domain-name {
      font-size: 22px;
      color: $sky-400;
      line-height: 1;
      grid-area: title;
    }

    .close {
      grid-area: close;
      justify-items: center;

      i {
        font-size: 26px;
      }
    }

    .domain-seq {
      font-weight: bold;
      font-size: 14px;
      grid-area: sequence;
    }

    .data-model-selector {
      display: grid;
      grid-template-columns: auto auto auto;
      justify-content: center;
      column-gap: 5px;
      grid-column-start: 3;
      .proficiency-selector,
      .portfolio-selector {
        opacity: 0.7;
        cursor: pointer;
        &.active,
        &:hover {
          color: $gray-base;
          opacity: 1;
        }
      }
    }
  }
}
