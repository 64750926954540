.gru-scientific-free-response {
  .answer-choices {
    display: grid;
    grid-template-columns: 1fr 50px;
    align-items: center;
    .answer-details {
      margin-bottom: 0px;
      label {
        width: 90%;
        color: #131415;
        font-size: 11px;
        text-transform: capitalize;
        display: flex;
        align-items: center;
        span {
          opacity: 0.7;
        }
        .category-info {
          cursor: pointer;
          height: 27px;
          padding-left: 5px;
          i {
            font-size: 18px;
            line-height: 27px;
          }
        }
      }
      .answer-text {
        input {
          border-bottom: 1px solid #131415;
        }
        input::placeholder {
          font-size: 15px;
          font-style: italic;
        }
      }

      .hint-details {
        display: grid;
        span {
          font-style: italic;
        }
      }
    }
    .info-icon {
      width: 24px;
      height: 24px;
      background-size: 24px 24px;
      background-image: url("./gooru/images/question-icon.png");
      display: block;
      justify-self: center;
      object-fit: contain;
      margin-top: 40px;
      cursor: pointer;
    }
    .close-icon {
      width: 24px;
      height: 24px;
      background-size: 24px 24px;
      background-image: url("./gooru/images/close-btn.png");
      display: block;
      justify-self: center;
      object-fit: contain;
      cursor: pointer;
    }
  }
}
