.profile {
  min-height: 100%;

  .content-panel {
    height: 100%;
    margin: 25px auto auto;
    float: inherit;

    .panel.add-content {
      border-radius: 5px;
      width: 90%;
      margin-top: 30px;
      padding: 60px 50px 40px;

      .panel-body {
        text-align: center;

        p {
          margin-top: 20px;
          font-size: $font-size-large;
        }

        button {
          padding-left: 20px;
          padding-right: 20px;
          font-size: 14px;
        }

        .gru-icon.explore {
          font-size: 150px;
          opacity: 0.1;
        }
      }
    }

    .gru-navigation-tabs {
      display: inline-block;
      width: 96%;
    }

    .user-profile-box {
      background-color: $white;
      box-shadow: 0 0 5px 4px $profile-box-shadow,
        0 0 2px 0 $slider-left-box-shadow;
      height: 50px;
      border-radius: 4px;

      .user-profile-back-arrow {
        width: 40px;
        cursor: pointer;
        margin-left: 20px;
        height: 50px;

        i {
          margin-left: -74px;
          margin-top: 14px;
        }
      }
    }

    .user-profile-info {
      margin: 0 auto;
      display: table;
      margin-top: -55px;

      .profile {
        .img-container {
          width: 60px;
          height: 60px;
          overflow: hidden;
          border-radius: 30px;
          margin-left: 15px;
          margin-right: 15px;

          img {
            width: 60px;
            height: 60px;
          }
        }

        .username {
          max-width: 170px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
          margin-top: 18px;
          font-size: 15px;
          text-transform: uppercase;
          color: $font-gray-dark-500;
        }
      }
    }
  }
}
