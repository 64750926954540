.report.student-activity-report {
  position: fixed;
  top: 100%;
  z-index: 1031;
  width: 768px;
  left: 0;
  right: 0;
  margin: auto;

  .backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    cursor: pointer;
    opacity: 0.8;
    background-color: $gray-base;
  }

  .activity-report-container {
    background-color: $white;
    box-shadow: 0 1px 3px 0 $navbar-box-shadow;
    border-radius: 4px;
    .report-header-container {
      display: grid;
      grid-template-columns: 1fr 56px;
      align-items: center;
      box-shadow: 0 1px 3px 0 $navbar-box-shadow;
      height: 56px;
      padding-left: 15px;
      .title-container {
        font-weight: 700;
        display: grid;
        align-items: center;
      }
      .close-report {
        justify-self: center;
        cursor: pointer;
        display: block;
      }
    }
  }

  @media screen and (max-width: $screen-sm - 1) {
    width: 100%;
    top: 0 !important;

    .activity-report-container {
      max-height: 100vh;
    }
  }
}
