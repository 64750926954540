.taxonomy.gru-subject-picker {
  .dropdown-menu {
    li {
      &.has-children {
        position: relative;

        &.compatiable {
          a {
            .keyboard_arrow_left,
            .keyboard_arrow_right {
              display: none;
            }
          }

          .sub-menu {
            left: 0;
            top: 100%;
          }
        }

        &.active,
        &.focus,
        &:hover {
          background-color: $sky-100;

          .sub-menu {
            display: block;
          }
        }
      }

      a {
        .gru-icon {
          font-size: $font-size-large;
          vertical-align: text-top;
          height: auto;
          width: auto;
        }

        &:hover {
          background-color: $sky-100;
        }

        &:active + .sub-menu,
        &:focus + .sub-menu {
          display: block;
        }
      }
    }

    .sub-menu {
      max-height: 300px;
      overflow: auto;
      top: -5px;

      &.dropdown-menu-left {
        left: 100%;
      }

      &.dropdown-menu-right {
        right: 100%;
      }
    }

    .sub-menu::-webkit-scrollbar {
      width: 10px;
      border-radius: 3px;
    }

    .sub-menu::-webkit-scrollbar-thumb {
      background: $gray-light;
      border: 1px solid $gray-light;
      border-radius: 5px;
    }
  }
}
