.grading-scale-legend {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: $font-size-small;
  @include clearfix();

  li {
    float: $tab-float-dir;
    line-height: 1;
    margin-right: 1em;
    display: table;
    border-radius: 19px;
    height: 20px;
    width: 75px;
    margin: 0 5px 0 0;

    @media (max-width: $screen-xs) {
      width: 80px;
    }

    i {
      display: inline-block;
      border-radius: 4px;
      height: 15px;
      width: 15px;
      margin: 0 5px 0 0;
    }
    span {
      display: table-cell;
      vertical-align: middle;
    }
    .textspancss {
      width: 75px;
      height: 12px;
      object-fit: contain;
      font-size: 12px;
      text-align: center;
      color: $white-bold;
    }

    &.not-started {
      background-color: $light-200;
      text-align: center;
    }
    &:last-child {
      margin: 0;
    }
    &.not-scored {
      color: $white;
      text-align: center;
    }
  }
}
