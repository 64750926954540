.student-domain-performance {
  .chart {
    svg {
      transform: rotateX(180deg);
      -webkit-transition: -webkit-transform 0.6s ease-in;
      transition: transform 0.6s ease-in;
      -webkit-transform-style: preserve-3d;
      transform-style: preserve-3d;

      .fill-0 {
        fill: $gray-no-started;
      }
      .fill-1 {
        fill: $blue-in-progress;
      }
      .fill-2,
      .fill-3,
      .fill-4,
      .fill-5 {
        fill: $blue-mastery;
      }

      #competency-cell {
        stroke: $snow-gray;
        stroke-width: 1;
        shape-rendering: crispedges;
        stroke-opacity: 0.4;
      }
    }
  }
}
