.force-change-password {
  .gru-header {
    pointer-events: none;
  }
}

.controller.change-password {
  display: block;

  .modal {
    display: block;
    position: relative;
    z-index: 0;
    padding: 0;
    margin: 15px auto;
    max-width: 405px;
    width: 100%;
    @media (min-width: $screen-sm-min) {
      width: 425px;
      max-width: 425px;
      margin: 60px auto;
    }

    .modal-dialog {
      width: 100%;
      max-width: 405px;
      margin: 0;
      @media (min-width: $screen-sm-min) {
        width: 405px;
        margin: 10px;
      }

      .modal-content {
        border: 0;

        .change-password-form {
          margin: 37px 24px 0;

          .form-group {
            margin: 5px 0 16px;

            label {
              width: 100%;
            }

            input {
              &:focus {
                border-color: $blue-200;
              }
            }

            .validation.error {
              position: relative;
              top: -15px;
            }

            span.error {
              color: $red-400;
              display: inline-block;
              font-size: 12px;
              margin-top: 4px;
            }
          }
        }
      }

      .modal-header {
        text-align: center;
        padding: 0;

        h3 {
          font-size: 30px;
          margin: 46px 20px 15px;
        }

        .message-text {
          font-size: 16px;
          line-height: normal;
        }
      }

      .modal-body {
        padding: 0;
      }

      .modal-footer {
        margin: 50px 0 0;
        padding: 0;
        text-align: center;

        .submit-button {
          margin: 0;
          padding: 25px 0 0;

          &.disable-event {
            .btn-success {
              background-color: $green-200;
            }
          }

          button {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            width: 100%;
            font-size: $font-size-h4;
            font-weight: 300;
            position: relative;

            i {
              position: absolute;
              font-size: $font-size-h3;
              line-height: 48px;
              right: 5px;
            }
          }
        }
      }
    }
  }
}
