.anonymous .reports.assessment.questions.gru-scientific-fib {
  line-height: 42px;
}
.reports.assessment.questions.gru-scientific-fib {
  word-wrap: break-word;
  overflow: auto;
  max-width: 10em;

  @media (min-width: $screen-sm-min) {
    max-width: 16em;
  }

  @media (min-width: $screen-lg-min) {
    max-width: 25em;
  }
  span {
    line-height: 30px;
  }
  label {
    display: grid;
    text-transform: capitalize;
    margin-bottom: 0px;
  }
  .answer,
  .sentence {
    p {
      display: inline-block;
      margin: 0;
      padding: 0;
    }
  }

  .answer {
    border-bottom: 2px solid;
    padding: 5px;
  }
  .answer.correct {
    border-bottom-color: $brand-success;
  }
  .answer.incorrect {
    border-bottom-color: $brand-incorrect;
  }

  .answer.anonymous {
    border-bottom-color: $brand-anonymous;
  }
}
