.reports_study-student-collection {
  .non-top {
    z-index: -1;
  }

  .app-container {
    padding: 0;
  }

  .gru-header,
  .gru-footer {
    display: none;
  }

  .report-container {
    padding-top: 0;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding-bottom: 40px;
    min-height: 100%;

    .summary-reset {
      .resources-info {
        height: 100%;
        width: 100%;
      }

      .resource-content {
        width: 100%;
        &.resource-back-drop {
          display: grid;
          position: fixed;
          height: auto;
          padding-bottom: 50px;
          bottom: 0;
          align-items: flex-end;
          @media screen and (max-width: 767px) {
            padding-bottom: 83px;
          }
        }
      }
    }

    .suggestion-panel {
      padding: 20px 180px;

      @media (min-width: $screen-sm) {
        padding: 20px 180px;
      }

      @media (min-width: $screen-md) {
        padding: 20px 180px;
      }

      .player.gru-suggest-test {
        width: 100%;
        padding: 0;
      }

      &.no-suggestions {
        .panel {
          box-shadow: none;
          margin: 0;
          border-radius: 5px;

          .panel-body {
            padding: 25px 20px 25px 25px;
          }

          .actions {
            margin: 20px 0 0 auto;

            .btn-next {
              padding: 6px 40px;
            }
          }
        }
      }
    }

    .qz-assessment-report {
      .qz-assessment-summary {
        background: $white;
      }
    }

    .summary-report-next {
      padding: 0px 190px 0px;
      margin: 20px 0 0 auto;

      .btn-next {
        width: 71px;
        height: 36px;
        border-radius: 3px;
      }
    }
  }

  .mastery-confirmation-popup {
    position: fixed;
    bottom: 30px;
    right: 0;
    z-index: 30;
    width: auto;
    height: calc(100% - 50px);
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 10px;
    padding-bottom: 20px;
    @media screen and (max-width: 767px) {
      padding-bottom: 85px;
    }

    .mastery-greeting-container {
      width: 300px;
      height: 315px;
      background-color: $white-6f4;
      display: grid;
      border-radius: 10px;
      @media screen and (max-width: $screen-360) {
        width: 100%;
      }

      .mastery-header {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 10px;
        border-bottom: 1px solid $border-box-shadow;
        height: 40px;

        .mastery-title {
          font-size: 15px;
          font-weight: bold;
        }
      }

      .mastery-info-container {
        padding: 15px;
        border-bottom: 1px solid $border-box-shadow;
        display: grid;
        row-gap: 8px;
        .greeting-images {
          display: grid;
          grid-template-columns: auto;
          .congratulations-img {
            background-image: url("assets/gooru/congrats-emoji.png");
            display: block;
            height: 137px;
            background-repeat: no-repeat;
            width: 100%;
            background-size: contain;
            background-position: center;
            transform: scale(1.5);
          }
        }
        .greeting-message {
          font-size: 13px;
          text-align: center;
          color: $dark-700;
          line-height: 1.5;
          .highlight-greeting {
            font-weight: bold;
          }
        }
      }
      .mastery-action-container {
        display: grid;
        grid-template-columns: auto auto;
        justify-content: right;
        align-items: center;
        padding: 8px;
        column-gap: 30px;
        padding-right: 20px;
        .show-me,
        .no-thanks {
          font-size: 12px;
          font-weight: bold;
          text-transform: uppercase;
          cursor: pointer;
        }
        .show-me {
          color: #2f9e4b;
        }
        .no-thanks {
          color: $dark-400;
        }
      }
    }
  }

  .next-content-container {
    position: fixed;
    width: 320px;
    background-color: $white;
    border-radius: 10px;
    padding: 10px;
    bottom: 50px;
    right: 10px;
    box-shadow: 0 0 10px grey;

    @media screen and (max-width: 767px) {
      bottom: 85px;
      right: 5px;
    }

    .header-panel {
      margin-bottom: 8px;

      .milestone-head {
        border-bottom: 2px solid $light-200;
        margin-bottom: 10px;

        .milestone-title {
          font-style: italic;
          display: block;
          line-height: 1.4;
          padding: 5px 0;
          font-size: 14px;
        }
      }
      .title {
        font-size: 15px;
        display: flex;
        align-items: center;
        font-weight: bold;
        text-transform: capitalize;
        span {
          width: 20px;
          height: 20px;
          background-position: center;
          background-size: 100% 100%;
          margin-left: 10px;
          display: block;
          &.assessment,
          &.assessment-external {
            background-image: url("gooru/images/assessment-gray.svg");
          }
          &.collection,
          &.collection-external {
            background-image: url("gooru/images/collection_gray.svg");
          }
          &.offline-activity {
            background-image: url("gooru/images/offline.svg");
          }
          &.lesson {
            background-image: url("gooru/images/lesson.svg");
          }
        }
      }
      .message {
        font-size: 13px;
        line-height: 1.3;

        .gru-taxonomy-tag-list {
          display: inline-flex;
        }
      }
    }
    .learner-path {
      padding: 0;
      .learner-path-container {
        border-radius: 0;
        .learner-path-panel {
          padding: 0;
          .milestone-course-map-collection-panel {
            pointer-events: none;
            &.hidden-resource-count {
              grid-template-columns: 70px 1fr 50px 60px !important;
            }
            &.hidden-info-icon {
              grid-template-columns: 70px 1fr 60px !important;
            }
            .colllection-play-btn {
              display: none;
            }
            .collection-title-container {
              pointer-events: all;
            }
          }
          .gru-accordion-lesson {
            pointer-events: none;
            .play-btn {
              visibility: hidden;
            }
          }
        }
      }
    }

    .collection-panel {
      display: grid;
      grid-template-columns: 40px auto;
      grid-gap: 5px;

      .icon {
        width: 40px;
        height: 40px;
        background-position: center;
        background-size: 40px 40px;
        display: inline-block;
        background-repeat: no-repeat;
      }

      .collection-info {
        line-height: 1.3;
      }
    }
  }
}

body.reports_study-student-collection,
body.student-learner-proficiency {
  &.fullscreen {
    @mixin fullscreenStyle {
      background-color: #353e47 !important;
    }

    @include fullscreenStyle;

    .qz-assessment-report {
      @include fullscreenStyle;
    }

    .gru-study-navbar {
      display: none;
    }

    .bar-charts {
      top: 0 !important;

      @include fullscreenStyle;
    }

    .report-container {
      padding-top: 0;
    }

    .gru-player-summary-footer {
      @include fullscreenStyle;

      .resource-title {
        color: $white;
      }

      .toggle-screen {
        i {
          color: $white;
        }
      }
    }
  }
}
