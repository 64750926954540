.charts.gru-x-bar-chart {
  background: $white;
  border-radius: 4px;
  height: 28px;
  width: 100%;
  .segment {
    height: 100%;
    float: $tab-float-dir;
    &:first-child {
      border-bottom-left-radius: 4px;
      border-top-left-radius: 4px;
    }
    &.full {
      max-width: 100%;
      &:last-child {
        border-bottom-right-radius: 4px;
        border-top-right-radius: 4px;
      }
    }
  }
}
