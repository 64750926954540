.controller.guardian-invite {
  display: block;

  .modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 0;
    padding: 0;
    margin: 15px auto;
    max-width: 405px;
    width: 100%;
    height: calc(100vh - 200px);
    @media (min-width: $screen-sm-min) {
      width: 425px;
      max-width: 425px;
      margin: 60px auto;
    }

    .modal-dialog {
      width: 100%;
      max-width: 405px;
      margin: 0;
      height: auto;
      @media (min-width: $screen-sm-min) {
        width: 405px;
        margin: 10px;
      }

      .modal-content {
        border: 0;

        .guardian-invite-form {
          .form-group {
            margin: 0px 0 5px;

            label {
              width: 100%;
              text-align: center;
              font-size: 18px;
              color: $blue-400;
            }

            &.text-color {
              label {
                color: $gray-oslo;
                font-size: 16px;
              }
            }
          }
        }
        .invitees-approved {
          label {
            width: 100%;
            text-align: center;
            font-size: 18px;
            color: $gray-oslo;
          }
        }
      }

      .modal-header {
        text-align: center;
        padding: 0;

        h3 {
          margin: 25px 20px 15px;
        }
      }

      .modal-body {
        padding: 10;
      }

      .modal-footer {
        padding: 0;
        text-align: center;

        .submit-button {
          margin: 0;

          button {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            width: 100%;
            font-size: $font-size-h4;
            font-weight: 300;
            position: relative;

            i {
              position: absolute;
              font-size: $font-size-h3;
              line-height: 48px;
              right: 5px;
            }
          }
        }
      }
    }
  }
}
