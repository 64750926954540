.oca-month-report-pull-up {
  position: fixed;
  top: 100%;
  z-index: 1031;
  width: 768px;
  left: 0;
  right: 0;
  margin: auto;
  color: $suggestion-panel-color;
  @media screen and (max-width: $screen-sm - 1) {
    width: 100%;
  }

  .backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    cursor: pointer;
    opacity: 0.8;
    background-color: $gray-base;
  }

  .oca-month-report-container {
    background: $white;
    max-height: 90vh;
    height: 90vh;
    display: grid;
    grid-template-rows: 56px auto 1fr;

    .report-header {
      box-shadow: 0 1px 3px 0 $navbar-box-shadow;
      display: grid;
      grid-template-columns: 1fr 56px;

      .header-title {
        padding: 10px;
        font-weight: bold;

        .title {
          display: block;
          font-size: 11px;
          line-height: 1.2;
          font-weight: normal;
          color: $dark-400;
        }

        .month {
          font-size: 14px;
          font-weight: bold;
          line-height: 1.33;
          color: $dark-400;
        }
      }

      .close {
        align-items: center;
        display: flex;
        justify-content: center;

        i {
          font-size: 24px;
        }
      }
    }

    .performance-activity {
      display: grid;
      grid-template-rows: 20px 1fr;
      position: relative;
      height: 240px;

      &:before {
        content: "";
        position: absolute;
        opacity: 0.3;
        width: 100%;
        height: 100%;
        background-color: rgb(242, 235, 235);
      }

      %webkit-container {
        -webkit-mask-size: cover;
        -webkit-mask-repeat: no-repeat;
        background: currentColor;
        -webkit-mask-image: url("gooru/images/combined-shape.svg");
      }

      %container {
        mask-size: cover;
        mask-repeat: no-repeat;
        background: currentColor;
        mask-image: url("gooru/images/combined-shape.svg");
      }

      .performance {
        text-align: center;
        display: grid;
        font-weight: bold;
        color: $white;
        justify-content: center;
        z-index: 1;
        grid-template-columns: 30px 1fr 30px;
        height: 240px;

        .left,
        .right {
          align-self: center;
          cursor: pointer;

          &.disable {
            pointer-events: none;
            opacity: 0.5;
          }

          i {
            font-size: 35px;
          }
        }

        .score-container {
          display: grid;
          justify-content: center;

          .score {
            font-size: 90px;
            width: max-content;
            height: max-content;
          }

          .time-spent {
            font-size: 20px;
            width: max-content;
            height: max-content;
            justify-self: center;
          }
        }
      }

      .performance-img {
        grid-row-start: 2;
        @extend %webkit-container;
        @extend %container;
        display: grid;
        align-self: end;
      }
    }

    .report-activity-container {
      overflow-y: auto;
      padding-bottom: 10px;

      .activity-row-container {
        height: 56px;
        box-shadow: 0 1px 3px 0 $navbar-box-shadow;
        background-color: $white;
        display: grid;
        grid-template-columns: 1fr 56px;
        cursor: pointer;

        &.not-started {
          pointer-events: none;
        }

        .activity-info {
          display: grid;
          grid-template-columns: 56px 1fr 50px;
          align-items: center;

          .icon {
            width: 20px;
            height: 20px;
            background-size: 20px 20px;
            opacity: 0.8;
            justify-self: center;
            background-repeat: no-repeat;

            &.collection-external-icons,
            &.assessment-external-icons {
              background-image: url("gooru/share.png");
            }

            &.assessment {
              background-image: url("gooru/images/add_assessment.svg");
            }

            &.assessment-external {
              background-image: url("gooru/images/add_assessment.svg");
            }

            &.collection-external {
              background-image: url("gooru/images/add_collection.svg");
            }

            &.collection {
              background-image: url("gooru/images/add_collection.svg");
            }

            &.meeting {
              background-image: url("gooru/video-conference-ico.png");
              height: 42px;
              width: 63px;
              background-size: cover;
              position: relative;
              bottom: 11px;
            }
            &.offline-activity {
              background-image: url("gooru/images/offline.svg");
            }
          }

          .title {
            font-size: 15px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.33;
            letter-spacing: normal;
            color: $dark-400;
            align-items: center;
          }
        }

        .not-started {
          display: flex;
          align-items: center;
          background-color: $suggestion-panel-color;
          justify-content: center;
          font-size: 10px;
          height: 54px;
          font-weight: 700;
          color: $white;
        }

        .timespent {
          display: flex;
          align-items: center;
          background-color: $suggestion-panel-color;
          justify-content: center;
          font-size: 12px;
          height: 54px;
          font-weight: 700;
          color: $white;
        }

        .score {
          font-size: 20px;
          font-weight: bold;
          color: $white-bold;
          align-items: center;
          display: flex;
          justify-content: center;
          cursor: pointer;
          height: 54px;
        }
      }
    }
  }
}
