.content.collections.edit {
  .tags {
    margin: 10px 0;
  }

  .century-skills {
    display: grid;
    .skills-buttons {
      margin-top: 5px;
    }
    .btn {
      max-width: fit-content;
    }
  }
  .standards {
    display: grid;
    .btn {
      max-width: fit-content;
    }
  }
}

.exemplar-show {
  clear: both;
}
