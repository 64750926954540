.grade.free-response-question-grade {
  position: fixed;
  top: 100%;
  z-index: 1031;
  width: 768px;
  left: 0;
  right: 0;
  margin: auto;
  color: $suggestion-panel-color;

  .popover {
    border: 0;
    padding: 0;

    .popover-title {
      text-align: center;

      .close-popover {
        position: absolute;
        right: 5px;
        color: $suggestion-panel-color;
        cursor: pointer;
      }
    }
  }

  .backdrop {
    background-color: $backdrop-color;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    cursor: pointer;
    opacity: 0.8;
    background-color: $gray-base;
  }

  .backdrop-pull-ups {
    .backdrop {
      background: none !important;
    }
  }

  .loading-spinner {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
  }

  .rubric-file-preview-container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 5%;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3;
    visibility: hidden;

    .rubric-file-preview {
      width: 90%;
      height: 90vh;
      margin: 0 auto;
      background: $white;
      text-align: center;
      border-radius: 8px;

      iframe {
        width: 100%;
        height: 100%;
        padding: 20px 20px 70px;
      }

      .rubric-file-preview-close {
        height: 20px;

        .close {
          color: $breadcrumb-color;
          margin-right: 20px;
          font-size: 24px;
          margin-top: 20px;
        }
      }
    }
  }

  .caught-up-container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 30%;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3;
    display: none;

    .caught-up {
      width: 400px;
      height: 300px;
      margin: 0 auto;
      background: $white;
      text-align: center;
      border-radius: 8px;

      .caught-up-tick-container {
        position: relative;
        padding-top: 100px;
        padding-bottom: 28px;

        hr {
          margin: 0;
          border-width: 1px;
          border-color: $default-green;
          margin-left: 20px;
          margin-right: 20px;
          border-color: $green-50;
        }

        .caught-up-tick {
          position: absolute;
          width: 90px;
          height: 80px;
          background: $white;
          display: block;
          left: 40%;
          margin-top: -38px;
          padding-left: 8px;

          span {
            display: block;
            height: 75px;
            width: 75px;
            border-radius: 50%;
            color: $green-50;
            border: 2px solid $green-50;

            i {
              font-size: 40px;
              margin-top: 15px;
            }
          }
        }
      }
    }
  }

  .free-response-question-grade-container {
    background: $white-bold;
    width: 768px;
    max-height: 90vh;
    height: 90vh;

    .frq-grade-header {
      height: 50px;
      display: grid;
      grid-template-columns: 1fr 50px 50px;
      grid-column-gap: 10px;
      align-items: center;
      box-shadow: 0 2px 4px 0 $navbar-default-toggle-icon-bar-bg;
      width: 100%;
      position: relative;

      .frq-report-title {
        width: calc(100% - 50px);

        .collection-icon {
          width: 34px;
          height: 38px;
          background-repeat: no-repeat;
          background-size: 25px 25px;
          display: inline-block;
          float: left;

          &.assessment {
            background-image: url("../assets/gooru/images/assessment-gray.svg");
          }

          &.collection {
            background-image: url("../assets/gooru/images/collection_gray.svg");
          }
        }

        .title {
          width: 100%;
          font-size: 16px;
          line-height: 20px;
          padding-left: 15px;
          letter-spacing: normal;
          padding-top: 10px;
          color: $dark-400;

          p {
            margin: 0;
          }
        }
      }

      .frq-grade-breadcrumb {
        display: flex;

        &.dca-context {
          .lesson-title,
          .unit-title {
            display: none;
          }

          .collection-title {
            .gru-icon {
              display: none;
            }
            padding-left: 10px;
          }
        }

        .unit-title {
          display: grid;
          grid-template-columns: 50px auto;
          align-items: center;
          max-width: 195px;

          p {
            max-width: 150px;
            margin: 0;
            @include ellipsis(
              $font-size: 14px,
              $line-height: 1.2,
              $lines-to-show: 2
            );
          }

          .unit-icon {
            width: 100px;
            height: 50px;
            background-image: url("../assets/gooru/images/unit.svg");
            background-repeat: no-repeat;
            background-size: 30px 30px;
            background-position: 12px 12px;
          }
        }

        .lesson-title {
          display: grid;
          grid-template-columns: 25px 40px auto;
          align-items: center;
          max-width: 195px;

          .keyboard_arrow_right {
            margin-right: 5px;
          }

          p {
            margin: 0;
            max-width: 150px;
            @include ellipsis(
              $font-size: 14px,
              $line-height: 1.2,
              $lines-to-show: 2
            );
          }

          .lesson-icon {
            width: 75px;
            height: 50px;
            background-image: url("../assets/gooru/images/lesson.svg");
            background-repeat: no-repeat;
            background-size: 30px 30px;
            background-position: 0 11px;
          }
        }

        .collection-title {
          display: grid;
          grid-template-columns: 25px 40px auto;
          align-items: center;
          max-width: 300px;

          p {
            margin: 0;
            max-width: 260px;
            @include ellipsis(
              $font-size: 14px,
              $line-height: 1.2,
              $lines-to-show: 2
            );
          }

          .collection-icon {
            width: 40px;
            height: 50px;
            background-repeat: no-repeat;
            background-size: 30px 30px;
            background-position: 0 12px;

            &.assessment {
              background-image: url("../assets/gooru/images/assessment-gray.svg");
            }

            &.collection {
              background-image: url("../assets/gooru/images/collection_gray.svg");
            }
          }
        }
      }

      .collection-performance-score {
        visibility: hidden;
      }

      .frq-grade-close-pullup {
        width: 50px;
        height: 20px;

        .close {
          color: $breadcrumb-color;
          margin-right: 20px;
          font-size: 24px;
        }
      }
    }

    .frq-grade-body {
      display: grid;
      grid-template-columns: 1fr 1fr;
      max-height: calc(90vh - 50px);
      height: calc(90vh - 50px);
      overflow-y: auto;
      width: 100%;

      .frq-grade-left-panel {
        width: 765px;
        .frq-grade-left-panel-header {
          border-bottom: solid 1px $dark-600;
          grid-template-columns: 90px 1fr 80px;
          display: grid;
          height: 80px;
          align-items: center;
          justify-items: center;

          .frq-grade-count {
            font-size: 40px;
            font-weight: bold;
            color: $frq-text;
          }

          .frq-self-grade {
            height: 79px;
            background: $green-496;
            text-align: center;
            line-height: 1.5;
            visibility: hidden;

            span {
              font-size: 10px;
              color: $dark-600;
              height: 15px;
            }

            .performance-score {
              font-size: 25px;
              font-weight: bold;
              text-align: center;
              color: $dark-600;
              height: 49px;
              padding-top: 10px;
            }
          }

          .frq-grade-students {
            width: 175px;
            height: 60px;
            margin-right: 22px;

            .frq-grade-students-carousel {
              #frq-grade-students-carousel-wrapper {
                .user-profile-img {
                  display: grid;
                  align-items: center;
                  justify-content: center;
                  width: 100%;
                  cursor: default;

                  img {
                    width: 45px;
                    border-radius: 50%;
                    border-color: $well-border;
                    height: 45px;
                    justify-self: center;
                  }

                  span {
                    font-size: 12px;
                    font-weight: bold;
                    color: $default-title-color;
                  }
                }

                .carousel-control {
                  opacity: 1;
                  background: none;

                  &.in-active {
                    opacity: 0.3;
                    pointer-events: none;
                  }

                  &.left {
                    z-index: 2;
                    width: 20px;
                    left: 30px;

                    i {
                      line-height: 50px;
                      color: $gray-base;
                    }
                  }

                  &.right {
                    z-index: 2;
                    width: 20px;
                    right: 30px;

                    i {
                      line-height: 50px;
                      color: $gray-base;
                    }
                  }
                }
              }
            }
          }
        }

        .student-carousel-containers {
          .student-info {
            display: grid;
            width: 100%;
            height: 56px;
            border-bottom: 1px solid $dark-600;
            background-color: $white;

            &.self-grade {
              grid-template-columns: 50px 1fr 50px 50px 70px;
            }

            &.no-self-grade {
              grid-template-columns: 50px 1fr 50px 50px;
            }

            .self-grade-section {
              .scores {
                font-weight: bold;
                text-align: center;
                color: $dark-600;
                padding-top: 10px;
              }
            }

            .carousel-control {
              opacity: 1;
              background: none;
              display: contents;
              text-shadow: unset;

              &.in-active {
                opacity: 0.3;
                pointer-events: none;

                i {
                  opacity: 0.3;
                }
              }

              &.left,
              &.right {
                z-index: 2;
                width: 20px;

                i {
                  line-height: 50px;
                  color: $gray-base;
                }

                &.left {
                  left: 30px;
                }

                &.right {
                  right: 30px;
                }
              }
            }

            .icon {
              margin: auto;

              span {
                cursor: pointer;
              }
            }

            .frq-grade-students {
              width: 100%;
              height: 100%;

              .frq-grade-students-carousel {
                padding-top: 5px;

                #frq-grade-students-carousel-wrapper {
                  .user-profile-img {
                    display: flex;
                    align-items: center;
                    justify-content: start;
                    width: 100%;
                    cursor: default;

                    .count-and-name {
                      display: grid;
                      padding-left: 10px;
                    }

                    img {
                      width: 32px;
                      height: 32px;
                      border-radius: 50%;
                      border-color: $well-border;
                      justify-self: center;
                    }

                    span {
                      font-size: 12px;
                      font-weight: bold;
                      color: $dark-600;
                      text-transform: capitalize;
                    }
                  }
                }
              }
            }

            .self-grade-section {
              height: 96%;
              width: 100%;
              padding: 5px;

              .label {
                font-size: 10px;
                text-align: center;
                color: $dark-600;
                padding: 0;
                display: block;
              }

              .score {
                font-size: 25px;
                font-weight: bold;
                text-align: center;
                color: $dark-600;
              }
            }
          }
        }

        .students-list {
          padding: 10px 15px 15px;

          .search-navigation {
            position: relative;

            .search-keyword {
              .search-box {
                width: 100%;
                margin: 13px 9px 3px 0;
                outline: none;
                box-shadow: none;
                padding-bottom: 5px;
                border-bottom: solid 1px $sky-400;
              }

              .search-icon {
                position: absolute;
                right: 10px;
                top: 10px;

                .clear,
                .search {
                  cursor: pointer;
                }
              }
            }
          }

          .list-container {
            .user-profile-img {
              display: flex;
              align-items: center;
              height: 56px;
              cursor: pointer;

              .profile-img {
                display: flex;

                img {
                  width: 32px;
                  border-radius: 50%;
                  border-color: $well-border;
                  height: 32px;
                  justify-self: center;
                }

                .circle-gray,
                .circle-green {
                  width: 8px;
                  height: 8px;
                  border-radius: 50%;
                  display: block;
                }

                .circle-gray {
                  background-color: $gray-submarine;
                }

                .circle-green {
                  background-color: $green-700;
                }
              }

              .count-and-name {
                display: grid;
                padding-left: 10px;
                color: $dark-400;

                .count {
                  font-size: 11px;
                  height: 13px;
                }

                .name {
                  font-size: 14px;
                  font-weight: 300;
                }
              }

              span {
                font-size: 12px;
                font-weight: bold;
                color: $dark-600;
                text-transform: capitalize;
              }
            }
          }
        }

        .frq-grade-left-panel-body {
          padding-left: 20px;
          padding-right: 20px;

          .frq-question-answer-info {
            .audio-player {
              display: grid;
              grid-template-columns: auto auto;
              justify-content: center;
              align-items: center;
              column-gap: 25px;
              margin-bottom: 10px;

              .audio-play {
                width: 24px;
                height: 24px;
                display: block;

                i {
                  width: inherit;
                  height: inherit;
                  background-color: $sky-400;
                  color: $white;
                  border-radius: 50%;
                  display: block;
                  text-align: center;
                  line-height: 24px;
                  cursor: pointer;
                }
              }

              .audio-progress {
                width: 96px;
                height: 8px;
                background-color: rgba($color: $gray-harder, $alpha: 0.8);
                display: block;
                position: relative;
                border-radius: 5px;

                .progress-filling {
                  width: 0;
                  position: absolute;
                  left: 0;
                  height: inherit;
                  border-radius: 5px;
                  background-color: $bg-progress-filling;

                  .progress-pointer {
                    width: 16px;
                    height: 16px;
                    background-color: $blue-400;
                    right: -8px;
                    top: -4px;
                    display: block;
                    border-radius: 50%;
                    position: absolute;
                  }
                }
              }
            }
          }

          .frq-question-answer-info,
          .frq-question-info {
            padding-top: 15px;

            .sayout-question-type {
              padding: 5px 0;
              font-size: 15px;

              .correct {
                color: $default-green;
              }
            }

            .underline-question-type {
              padding: 5px 0;
              font-size: 15px;

              .correct {
                color: $default-green;
              }

              .error {
                color: $timer-active-icon;
              }
            }

            .serp-question-answers {
              font-size: 15px;
              padding: 5px 0;
            }

            .frq-header {
              font-size: 25px;
              color: $dark-400;
              word-break: break-all;
              line-height: 1;
              display: grid;
            }

            span {
              input {
                text-align: center;
                background: none;
                font-size: 14px;
                border-bottom: 1px solid $input-gray;
                font-weight: bold;
                overflow: hidden;
              }
            }

            p {
              font-size: 13px;
              line-height: 1.15;
              color: $dark-400;
              word-break: break-word;
            }
          }
        }
      }

      .frq-grade-right-panel {
        background-color: $dark-600;

        .frq-grade-right-panel-header {
          height: 79px;
          grid-template-columns: 1fr 80px;
          display: grid;
          align-items: center;
          justify-items: center;
          margin-left: 3%;

          .frq-grade-info {
            .frq-grade-info-title {
              font-size: 25px;
              line-height: 1.09;
              color: $white-bold;
              display: block;

              .rubric-attachment {
                color: $blue-text;
                cursor: pointer;
              }
            }

            .frq-grade-info-desc {
              font-size: 13px;
              line-height: 1.15;
              color: $white-bold;
              display: block;
            }
          }

          .frq-grade-score {
            width: 100%;
            height: 100%;
            background-color: $oa-score-bg-color;
            padding-top: 10px;
            text-align: center;

            span {
              font-size: 20px;
              font-weight: bold;
              color: $dark-600;
              line-height: 1.5;
            }

            hr {
              margin: 0 15px;
              border-width: 2px;
              border-color: $dark-600;
            }
          }
        }

        .frq-grade-right-panel-body {
          color: $white;
          padding-left: 20px;
          padding-right: 20px;
          padding-top: 40px;

          .frq-grade-rubric-grading {
            width: 200px;
            font-size: 30px;
            line-height: 0.2;
            text-align: center;
            margin: 0 auto;
            padding-bottom: 20px;

            .studentScore {
              input {
                text-align: center;
                font-size: 30px;
                color: $white;
              }
            }
            .score {
              margin-right: 8%;
            }
          }

          .frq-grade-rubric-categories {
            .frq-grade-rubric-category {
              padding-bottom: 15px;

              &.comment-active {
                .frq-grade-rubric-level-container {
                  .mode_comment {
                    opacity: 1 !important;
                  }
                }
              }

              .frq-grade-rubric-category-info {
                display: inline-flex;

                p {
                  font-size: 13px;
                  font-weight: bold;
                  color: $white-bold;
                  line-height: 1.2;
                }
              }

              .frq-grade-rubric-level-container {
                display: flex;
                align-items: center;
                height: 25px;

                .frq-grade-rubric-category-info {
                  width: 290px;

                  p {
                    margin: 0;
                  }
                }

                .frq-grade-rubric-levels {
                  width: 290px;
                  background-color: $frq-gray;
                  border-radius: 16px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  padding: 3px;
                  color: $dark-400;
                  position: relative;

                  &.active,
                  &.no-progress {
                    background-color: $well-border;
                    border-radius: 0;
                  }

                  &.no-progress {
                    .frq-grade-info-no-level,
                    .frq-grade-info-popover {
                      opacity: 0.3;

                      &.selected {
                        opacity: 1 !important;
                        background-color: $selected-green;
                      }
                    }
                  }

                  i {
                    height: 20px;
                    width: 20px;
                    border-radius: 50%;
                    background-color: $white-minimum;
                    cursor: pointer;
                    position: relative;
                    z-index: 1;
                  }

                  .progress-bar {
                    position: absolute;
                    height: 26px;
                    display: block;
                    border-radius: 16px;
                    margin-left: -3px;
                    width: 0;
                  }

                  .popover {
                    .popover-title {
                      text-align: center;
                    }

                    .popover-content {
                      text-align: center;
                      background-color: $white;
                    }
                  }
                }

                .frq-grade-clear {
                  height: 18px;
                  width: 25px;
                  padding-left: 4px;
                  pointer-events: none;

                  .clear {
                    font-size: 18px;
                    visibility: hidden;
                  }

                  &.active {
                    .clear {
                      visibility: visible;
                      cursor: pointer;
                    }
                    pointer-events: all;
                  }
                }

                .frq-grade-comment {
                  height: 20px;
                  width: 30px;

                  .mode_comment {
                    opacity: 0.2;
                    cursor: pointer;
                  }
                }
              }

              .frq-grade-comment-section {
                padding-top: 20px;
                display: none;

                textarea {
                  font-size: 16px;
                  font-style: italic;
                  color: $white-bold;
                  background-color: $dark-600;
                }
              }
            }
          }

          .frq-grade-general-comment-container {
            padding-top: 20px;

            textarea {
              font-size: 16px;
              font-style: italic;
              color: $white-bold;
              background-color: $dark-600;
            }
          }

          .frq-grade-action-btns {
            text-align: center;
            padding-top: 20px;
            padding-bottom: 40px;

            &.disable-event {
              pointer-events: none;
              cursor: default;
              opacity: 0.5;
            }

            .confirm {
              width: 100px;
              height: 40px;
              border-radius: 4px;
              background: $bg-sky-600-sky-400;
              font-size: 15px;
              font-weight: bold;
              text-align: center;
              color: $white-bold;
              text-transform: uppercase;

              &:active {
                background: $bg-sky-600-sky-400;
                box-shadow: none;
              }
            }
          }
        }
      }
      .row.questions {
        height: 15%;
        display: initial;
        .col-sm-12.col-md-6 {
          .gru-questions {
            .summary-report-questions {
              .question-container {
                border: 0;
                .answer-container {
                  display: initial;
                  grid-template-columns: none;
                }
              }
            }
          }
        }
      }
      .question-line {
        border-top: 1px solid #35424b;
        background-color: #f0f0f0;
      }
    }
    .btn.confirm.btn-primary.btn-lg {
      margin-left: 45%;
    }
  }
}
