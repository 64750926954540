.controller.content.resources.play {
  > article {
    $content-md-width: 90%;
    $content-lg-width: 1100px;
    min-width: 0;
    padding-bottom: 0;

    > header {
      padding: 0;
      width: 100%;
      margin-bottom: 20px;

      @media (min-width: $screen-md) {
        padding-bottom: 0 !important;
      }

      * {
        color: $white;
      }

      .header-content {
        padding: 15px 5%;

        @media (min-width: $screen-md) {
          padding: 15px 0;
          width: $content-md-width;
          margin: 0 auto;
        }

        @media (min-width: $screen-lg) {
          width: $content-lg-width;
        }

        .btn-back {
          border: 0;
          padding: 0;
          background-color: $table-bg;
          font-size: $font-size-large;

          .gru-icon {
            vertical-align: text-top;
          }
        }

        .heading {
          @media (min-width: $screen-sm) {
            @include flexbox();
            @include justify-content(space-between);
          }

          .information {
            width: 100%;
            @media (min-width: $screen-sm) {
              width: 85%;
            }
            h1 {
              $line-height: 1.2;
              $lines-to-show: 1;
              text-transform: capitalize;
              @include ellipsis(
                $font-size: $font-size-h4,
                $line-height: $line-height,
                $lines-to-show: $lines-to-show,
                $excerpt-bg: $dark-400
              );
              @media (min-width: $screen-xs) {
                @include ellipsis(
                  $font-size: $font-size-h3,
                  $line-height: $line-height,
                  $lines-to-show: $lines-to-show,
                  $excerpt-bg: $dark-400
                );
              }
            }

            .details {
              margin-top: 10px;
              @include flexbox();

              .format {
                @include flexbox();
                @include align-items(center);

                i {
                  display: block;
                  height: 36px;
                  width: 28px;
                  margin: 0 auto;
                  vertical-align: top;
                  &.video-icon {
                    background: url("gooru/images/video-resource-green.svg");
                    background-size: 31px 30px;
                    height: 30px;
                    width: 31px;
                  }
                  &.html-icon {
                    background: url("gooru/images/html-icon.svg");
                    background-size: 29px 30px;
                    height: 30px;
                    width: 29px;
                  }
                  &.webpage-icon {
                    background: url("gooru/images/website-resource-green.svg");
                    background-size: 29px 30px;
                    height: 30px;
                    width: 29px;
                  }
                  &.interactive-icon {
                    background: url("gooru/images/interactive-resource-green.svg");
                    background-size: 34px 30px;
                    height: 30px;
                    width: 34px;
                    vertical-align: top;
                    margin: 5px 0 0 0;
                  }
                  &.question-icon {
                    background: url("gooru/images/question-resource-green.svg");
                    background-size: 30px 30px;
                    height: 30px;
                    width: 30px;
                  }
                  &.image-icon {
                    background: url("gooru/images/image-resource-green.svg");
                    background-size: 30px 30px;
                    height: 30px;
                    width: 30px;
                  }
                  &.text-icon {
                    background: url("gooru/images/text-resource-green.svg");
                    background-size: 31px 30px;
                    height: 30px;
                    width: 31px;
                  }
                  &.audio-icon {
                    background: url("gooru/images/audio-resource-green.svg");
                    background-size: 34px 30px;
                    height: 30px;
                    width: 34px;
                  }
                }

                span {
                  padding-left: 10px;
                }
              }

              .tags {
                @include flexbox();
                @include align-items(stretch);
                margin-left: 10px;
                padding-left: 10px;
                border-left: 2px solid $dark-300;

                .taxonomy.gru-taxonomy-tag {
                  border-color: $dark-300;
                  background-color: $dark-300;
                  color: $white;
                }
              }
            }
          }

          .publisher {
            margin-top: 10px;

            img {
              @include border-top-radius(50%);
              @include border-bottom-radius(50%);
              height: 1.7rem;
              width: 1.7rem;
              vertical-align: middle;
              margin: 0 5px;
            }

            @media (min-width: $screen-sm) {
              @include flexbox();
              @include flex-direction(column);
              @include align-items(center);
              @include justify-content(center);
              border-left: 2px solid $dark-300;
              padding-left: 20px;
              margin: 0 0 0 10px;
              min-width: 125px;
              max-width: 115px;

              span.owner,
              span.publisher-name {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 120px;
              }

              img {
                height: 2.3rem;
                width: 2.3rem;
                margin: 3px 0;
              }
            }
          }
        }
      }

      @media (min-width: $screen-md) {
        top: 63px;
        padding-bottom: 30px;
      }
    }

    > section {
      @media (min-width: $screen-md) {
        padding: 0;
        width: $content-md-width;
        margin: 0 auto;
      }

      @media (min-width: $screen-lg) {
        width: $content-lg-width;
      }

      .gru-youtube-resource,
      .gru-vimeo-resource {
        @extend .embed-responsive;
        @extend .embed-responsive-16by9;
      }
      .gru-url-resource,
      .gru-image-resource,
      .gru-pdf-resource {
        iframe {
          height: 300px;
        }
      }

      .not-iframe {
        padding: 25px;
        .panel-header {
          text-align: center;
        }
        p.description {
          line-height: $font-size-base;
        }
        p.title,
        .view-resource-button {
          font-size: $font-size-large;
          font-weight: 300;
          margin: 0 0 7px 0;
        }
        .panel-footer {
          text-align: center;
          background-color: $white;
          margin: 15px 0 0 0;
        }
        .panel-body {
          display: flex;
          justify-content: center;
          $line-height: 1.2;
          text-decoration: none;
          color: $text-color;

          .cards.gru-resource-card {
            margin: 0 auto;

            .panel-heading {
              padding: 12px 15px 0 15px;

              .header {
                padding: 0;
                margin: 0;
                border: 0;
              }
            }
            .panel-body {
              padding: 12px 15px 0 15px;
              display: block;

              .publisher {
                a {
                  color: $blue-400;
                }
              }
            }
            .panel-footer {
              margin: 0;
              text-align: $tab-float-dir;
            }
          }
        }
      }
    }
  }
  .error-message {
    padding-left: 10%;
    padding-top: 5%;
  }
}
