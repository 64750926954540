.uploaded-evidence-report {
  .uploaded-evidence-report-container {
    display: grid;
    grid-template-columns: 200px 1fr;
    background: white;
    .report-left-panel-body {
      .student-panel-head {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        padding: 10px;
        padding-left: 10px;
        border: 1px solid $light-200;
        height: 45px;
        .first-name,
        .last-name {
          display: flex;
          cursor: pointer;
          &.active {
            opacity: 0.5;
          }
        }
      }
      .student-name-panel {
        height: 40px;
        .student-details {
          display: flex;
          padding: 10px;
          align-items: center;
          border: 1px solid $light-200;
          border-top: none;
          .student-img {
            img {
              height: 25px;
              width: 25px;
              border-radius: 50%;
            }
          }
          .student-name {
            display: flex;
            align-items: center;
            margin-left: 10px;
          }
        }
      }
    }
    .report-right-panel-body {
      overflow-x: auto;
      width: 100%;
      table {
        thead tr th,
        tbody tr td {
          height: 40px;
          width: 150px;
          line-height: 1.2;
          text-align: center;
          min-width: 150px;
          padding: 0;
        }
        thead tr th {
          vertical-align: middle;
          background-color: $suggestion-panel-color;
          color: $white;
          text-transform: capitalize;
          .content-score-value {
            margin: 5px;
            @include ellipsis(
              $font-size: 13px,
              $line-height: 1.3,
              $lines-to-show: 2
            );
          }
        }
        tbody tr td {
          height: auto;
          .content-score-value {
            height: 58px;
            margin: 5px;
            @include ellipsis(
              $font-size: 14px,
              $line-height: 1.4,
              $lines-to-show: 3
            );
            &.image-container {
              display: flex;
              justify-content: center;
            }
            img {
              width: 50px;
              height: 50px;
              object-fit: contain;
              padding: 5px;
            }
          }
        }
      }
    }
  }
}
