.gru-feedback-form {
  background-color: $white;
  color: $gray-73;
  font-size: 15px;
  text-transform: initial;
  .feedback-container {
    display: grid;
    grid-template-rows: auto auto;
    overflow: auto;
    &::-webkit-scrollbar {
      height: 6px;
      width: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background: $gray-light;
      border: 1px solid $gray-light;
      border-radius: 5px;
    }
    .feedback-header-panel {
      display: grid;
      grid-template-rows: auto auto auto;
      align-items: center;
      padding: 10px;
      padding-bottom: 0px;
      .note {
        line-height: 1.2;
        font-size: 13px;
        p {
          margin-bottom: 5px;
        }
      }
    }
    .feedback-body-panel {
      text-transform: capitalize;
      padding: 10px;
      font-size: 14px;
      .category-panel {
        // grid-template-columns: repeat(2, 1fr);
        // display: grid;

        .category-title {
          display: grid;
          grid-template-columns: 56px auto 56px;
          height: 56px;
          cursor: pointer;
          line-height: 1.4;

          .info-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            color: $dark-400;
          }

          .category-name {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: 12px;
            color: $dark-400;
            font-weight: bold;
          }

          .accordion-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            color: $dark-400;
          }
        }

        .category-info {
          margin-left: 56px;
          .grade-rubric-levels {
            width: 100%;
            background-color: $frq-gray;
            border-radius: 16px;
            display: inline-flex;
            align-items: center;
            justify-content: space-between;
            padding: 3px;
            color: $dark-400;
            position: relative;
            grid-auto-flow: column;
            height: 16px;

            i {
              height: 12px;
              width: 12px;
              border-radius: 50%;
              background-color: $white;
              cursor: pointer;
              position: relative;
              z-index: 1;
            }

            .progress-bar {
              position: absolute;
              height: 16px;
              display: block;
              border-radius: 16px;
              margin-left: -3px;
              float: left;
              width: 0;
              font-size: 12px;
              line-height: 23px;
              color: $white;
              text-align: center;
              transition: width 0.6s ease;
            }
          }
        }

        .category {
          display: grid;
          grid-template-columns: 56px auto 56px;
          height: 56px;
          cursor: pointer;
        }
        .category-rating {
          padding: 7px 5px;
          justify-content: flex-start;
          display: flex;
          align-items: center;
          .row {
            margin: 0px;
            ul {
              margin: 0px;
              padding: 0;
              li {
                border-bottom: 0px;
                line-height: 1;
                padding: 3px;
                margin-right: 15px;
              }
            }
          }
          .number-stars-label {
            display: none;
          }
        }
        .category-quality {
          padding: 7px 5px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          .gru-radio {
            padding: 5px;
            .form-group {
              margin: 0px;
              label {
                text-transform: uppercase;
              }
            }
          }
        }
      }
      .feedback-comments {
        padding: 7px 5px;
        label {
          width: 100%;
          font-weight: bold;
          .form-group {
            margin-bottom: 0px;
            font-weight: normal;
          }
        }
      }
    }
    .feedback-button-container {
      display: flex;
      justify-content: space-around;
      padding-bottom: 8px;
      .feedback-button-submit {
        width: 97px;
        /* height: 24px; */
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: $white-bold;
        background: $blue-bg;
        border: 1px solid $btn-border-grey;
        border-radius: 4px;
      }
      .feedback-button-submit:hover {
        background: $ps-blue-a2;
      }
      .feedback-button-submit:focus {
        background: $blue-active-bg;
        box-shadow: 0px 0px 0px 1px $white-bold, 0px 0px 0px 2px $blue-bg,
          0px 0px 0px 4px $blue-pale;
      }
      .feedback-button-cancel {
        width: 97px;
        /* height: 24px; */
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: $blue-bg;
        background: $white-bold;
        border: 1px solid $btn-border-grey;
        border-radius: 4px;
      }
      .feedback-button-cancel:hover {
        background: $btn-background;
      }
      .feedback-button-cancel:focus {
        background: $btn-background;
        box-shadow: 0px 0px 0px 1px $white-bold, 0px 0px 0px 2px $blue-bg,
          0px 0px 0px 4px $blue-pale;
      }
    }
  }
  @media screen and (max-width: $screen-380) {
    .feedback-container {
      .feedback-body-panel {
        .category-panel {
          .category-rating {
            .row {
              ul {
                padding: 0px;
              }
            }
          }
        }
      }
    }
  }
}
