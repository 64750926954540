.pull-out-content {
  .competencies-pull-out-body {
    .pull-out-accordion-collection {
      background: $white;
      margin: 0 auto;

      .competency-items {
        padding: 5px 12px 0;
        width: 100%;
        display: table;
        border-bottom: 1px solid $dark-50;

        .assessment {
          float: left;
          margin: 2px 2px 2px -25px;

          i {
            background: url("gooru/images/add_assessment.svg");
            width: 20px;
            height: 15px;
            object-fit: contain;
            display: block;
          }
        }

        .collection {
          float: left;
          margin: 2px 2px 2px -25px;

          i {
            background: url("gooru/images/add_collection.svg");
            width: 20px;
            height: 15px;
            object-fit: contain;
            display: block;
          }
        }

        .panel-title {
          display: inline-block;
          width: 65%;

          .title {
            font-weight: bold;
            width: 95%;
            height: 20px;
            font-size: 15px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            text-transform: capitalize;
            letter-spacing: normal;
            text-align: left;
            color: $dark-800;
            float: left;

            .caret-arrow {
              width: 10px;
            }
          }

          .sub-title {
            font-size: 13px;
            color: $gray-lighter;
          }
        }

        .panel-info {
          float: right;
          line-height: 20px;

          .status {
            display: inline-block;
            text-align: right;
            font-size: 14px;
          }

          .time {
            font-size: 14px;
            display: inline-block;
            width: 82px;
            text-align: right;
          }
        }
      }

      .panel-body {
        padding: 3px !important;
        max-height: 230px;
        overflow-y: auto;
      }

      .panel-body::-webkit-scrollbar {
        height: 6px;
        width: 8px;
      }

      .panel-body::-webkit-scrollbar-thumb {
        background: $gray-light;
        border: 1px solid $gray-light;
        border-radius: 5px;
      }

      .panel-collapse {
        .panel-body {
          padding: 3px !important;

          .panel-group {
            .assessment {
              float: left;
              margin: 13px -25px;

              i {
                background: url("gooru/images/add_assessment.svg");
                width: 26px;
                height: 20px;
                object-fit: contain;
                display: block;
              }
            }

            %content-icon-size {
              -webkit-background-size: 20px 20px;
              background-size: 20px 20px;
              height: 20px;
              width: 20px;
              display: block;
            }

            .resource-icon {
              float: left;
              margin: 9px -25px;

              &.video i {
                background-image: url("gooru/images/video-resource-green.svg");
                @extend %content-icon-size;
              }

              &.webpage i {
                background-image: url("gooru/images/website-resource-green.svg");
                @extend %content-icon-size;
              }

              &.interactive i {
                background-image: url("gooru/images/interactive-resource-green.svg");
                @extend %content-icon-size;
              }

              &.text i {
                background-image: url("gooru/images/text-resource-green.svg");
                @extend %content-icon-size;
              }

              &.audio i {
                background-image: url("gooru/images/audio-resource-green.svg");
                @extend %content-icon-size;
              }

              &.image i {
                background-image: url("gooru/images/image-resource-green.svg");
                @extend %content-icon-size;
              }

              &.question i {
                background-image: url("gooru/images/question-resource-green.svg");
                @extend %content-icon-size;
              }
            }

            .items {
              height: 40px;
              padding: 8px;
              border-bottom: 1px solid $dark-50;

              .title {
                width: 65%;
                display: inline-block;
                float: left;
                @include ellipsis(
                  $font-size: 15px,
                  $line-height: 1.2,
                  $lines-to-show: 1,
                  $excerpt-bg: $white
                );
              }

              .panel-info {
                float: right;

                .time {
                  padding: 10px;
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
    }
  }
}
