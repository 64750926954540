.legend-info-pull-up {
  .backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    cursor: pointer;
    opacity: 0.8;
    background-color: $gray-base;
    z-index: 1000;
  }

  .legend-info-pull-up-container {
    position: absolute;
    top: calc(100% - 50px);
    background: white;
    height: 60px;
    width: 100%;
    overflow: hidden;
    z-index: 1001;
    transition: 1s ease-in-out;
    &.open-legend-pullup {
      top: 40px;
      height: 100%;
    }
    .legend-info-panel {
      height: 100%;
    }
    .legend-info-panel-head {
      display: flex;
      align-items: center;
      border-bottom: 1px solid gray;
      padding-left: 10px;
      justify-content: space-between;
      font-size: 17px;
      font-weight: bold;
      background-color: $blue-400;
      color: $white;
      font-weight: bold;

      .close-icon {
        display: flex;
        i {
          font-size: 20px;
        }
      }
    }
    .legend-list {
      height: calc(100% - 75px);
      overflow: hidden;
      overflow-y: auto;
    }
    .proficiency-legends {
      padding: 15px 10px;
      width: 100%;
      display: grid;
      grid-template-rows: repeat(6, 1fr);
      grid-gap: 20px;
      .description {
        display: block;
        padding-left: 25px;
      }
      .legend {
        display: flex;

        .box {
          display: block;
          width: 15.6px;
          height: 15.6px;
          transform: rotate(90deg);
          margin-right: 8.4px;
        }

        .box-class-grade-line {
          width: 18px;
          height: 5px;
          margin-top: 4px;
          box-shadow: 0 2px 4px 0 $navbar-default-toggle-icon-bar-bg;
          margin-right: 8.4px;
        }

        .box-skyline {
          width: 18px;
          height: 10px;
          box-shadow: 0 2px 4px 0 $navbar-default-toggle-icon-bar-bg;
          margin-right: 8.4px;
        }

        .title {
          line-height: 13px;
          font-size: 15px;
          font-weight: bold;
          color: $elephant-dark;
        }

        &.mastered {
          .box {
            background-color: $blue-mastery;
          }
        }

        &.in-progress {
          @media screen and (max-width: $screen-568) {
            margin-left: 3px;
          }
          .box {
            background-color: $blue-in-progress;
          }
        }

        &.not-started {
          .box {
            background-color: $not-started;
          }
        }

        &.show-gut {
          .box {
            background-color: $not-in-framework;
          }
        }

        &.skyline {
          .box-skyline {
            background-color: $white;
          }
        }

        &.class-grade-line {
          .box-class-grade-line {
            background-color: #8fd383;
          }
        }
      }
    }
  }
}
