.gru-player-summary-footer {
  position: fixed;
  bottom: 0;
  height: 40px;
  background: $white;
  right: 0;
  z-index: 20;
  width: 100%;
  box-shadow: 0 -1px 4px 0 $navbar-default-toggle-icon-bar-bg;
  @media screen and (max-width: 767px) {
    height: 80px;
  }
  padding: 0 10px;

  // .nav-player-container {
  //   display: grid;
  //   grid-template-columns: auto 1fr auto auto;
  //
  //   .prev {
  //     float: $tab-float-dir;
  //     width: 138px;
  //     height: 50px;
  //     font-size: 16px;
  //
  //     i {
  //       font-size: 35px;
  //       float: $tab-float-dir;
  //       margin-left: -92px;
  //       margin-top: 10px;
  //     }
  //
  //     span {
  //       float: $tab-float-dir;
  //       line-height: 54px;
  //       font-size: 16px;
  //     }
  //
  //     &.disabled {
  //       cursor: default;
  //       color: $breadcrumb-color;
  //       border-right: 1px solid $breadcrumb-color;
  //     }
  //   }
  //
  //   .prev.disabled {
  //     cursor: default;
  //     color: $breadcrumb-color;
  //     border-right: 1px solid $pre-border-color;
  //   }
  //
  //   .player-container {
  //     width: 100%;
  //     float: $tab-float-dir;
  //     height: 50px;
  //
  //     .player-info {
  //       display: flex;
  //     }
  //
  //     .resource-title {
  //       margin: auto;
  //       width: calc(80vw - 150px);
  //       height: 50px;
  //       text-align: center;
  //       font-size: 16px;
  //       line-height: 50px;
  //       color: $blue-400;
  //     }
  //   }
  //
  //   .toggle-screen {
  //     width: 50px;
  //     height: 50px;
  //     display: block;
  //     cursor: pointer;
  //     border-left: 1px solid $light-200;
  //
  //     i {
  //       line-height: 50px;
  //       margin-left: 12px;
  //     }
  //   }
  //
  //   .next-action {
  //     float: $menu-float-dir;
  //     width: 138px;
  //     height: 50px;
  //     color: $green-400;
  //     cursor: pointer;
  //     border-left: 1px solid $pre-border-color;
  //
  //     &.next {
  //       width: 138px;
  //       height: 50px;
  //       font-size: 16px;
  //       color: $green-700;
  //       background-color: $white;
  //       float: $tab-float-dir;
  //       cursor: pointer;
  //       border-left: 1px solid $green-700;
  //     }
  //
  //     i {
  //       float: $tab-float-dir;
  //       font-size: 35px;
  //       margin-left: -102px;
  //       margin-top: 10px;
  //     }
  //
  //     span {
  //       float: $tab-float-dir;
  //       line-height: 54px;
  //       margin-left: 40px;
  //       font-size: 16px;
  //     }
  //   }
  // }

  .nav-player-container {
    display: grid;
    grid-template-columns: 368px auto;
    height: 100%;

    .title-container {
      display: grid;
      grid-template-columns: auto 56px;
      @media screen and (max-width: 320px) {
        grid-template-columns: 270px auto;
      }
      .title-info {
        display: grid;
        grid-template-columns: 56px auto;
        overflow: hidden;

        .icon {
          background-size: 25px 25px;
          background-position: center;
          background-repeat: no-repeat;

          &.collection {
            background-image: url("gooru/images/add_collection.svg");
          }
          &.assessment {
            background-image: url("gooru/images/add_assessment.svg");
          }
        }

        .title {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          color: #37424b;
          span {
            font-size: 12px;
            @include ellipsis(
              $font-size: 12px,
              $line-height: 1.3,
              $lines-to-show: 1
            );
          }
        }
      }
    }

    .information-container {
      display: grid;
      grid-template-columns: 1fr auto;
      @media screen and (max-width: 787px) {
        grid-template-columns: auto auto;
        width: fit-content;
        margin: 0px auto;
      }
      // @media screen and (min-width: 768px) and (max-width: 768px) {
      //   grid-template-columns: 1fr 140px;
      // }
      .hide-div {
        @media screen and (max-width: 992px) {
          display: none;
        }
      }
      .show-div {
        @media screen and (min-width: 993px) {
          display: none;
        }
      }
      ul {
        list-style: none;
        height: 40px;
        padding-left: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0px;
        overflow-x: scroll;

        li {
          padding: 1px 20px;
          border-radius: 12px;
          border: solid 1px #b8bfc4;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          white-space: nowrap;
          margin: 0px 8px;
          cursor: pointer;
          background-color: #37424b;
          font-size: 12px;
          font-weight: bold;

          @media screen and (max-width: 767px) {
            padding: 1px 10px;
          }
          &.active {
            opacity: 0.3;
          }

          .active-icon {
            width: 10px;
            height: 10px;
            background-color: $timer-active-icon;
            border-radius: 50%;
            margin-left: 5px;
            border: 1px solid #fff;
          }
        }
      }
      ul::-webkit-scrollbar {
        display: none;
      }
    }

    .button-container {
      display: flex;
      justify-content: center;
      align-items: center;

      .prev {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 96px;
        height: 25px;
        border-radius: 4px;
        background: $white-bold;
        border: 1px solid $btn-border-grey;
        color: $blue-bg;
        cursor: pointer;
        @media screen and (max-width: 768px) {
          width: 50px;
          height: 28px;
          margin: 0px 8px;
        }

        &.disabled {
          cursor: default;
          pointer-events: none;
          opacity: 0.3;
        }
      }

      .prev:hover {
        background-color: $btn-background;
      }

      .prev:focus {
        background: $btn-background;
        box-shadow: 0px 0px 0px 1px #ffffff, 0px 0px 0px 2px #1c6aef,
          0px 0px 0px 4px #bad7ff;
      }

      .next {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 88px;
        height: 25px;
        border-radius: 4px;
        margin-left: 8px;
        background-color: $blue-bg;
        color: $white;
        cursor: pointer;
        @media screen and (max-width: 768px) {
          width: 50px;
          height: 28px;
          margin: 0px 8px;
        }

        // &.disabled {
        //   cursor: default;
        //   pointer-events: none;
        //   opacity: 0.3;
        // }
      }

      .next:hover {
        background-color: $blue-hover-bg;
      }

      .next:focus {
        box-shadow: 0px 0px 0px 1px $white-backgraound, 0px 0px 0px 2px $blue-bg,
          0px 0px 0px 4px $blue-pale;
        background: $blue-focus-bg;
      }
    }
  }
}
