.preview.oa-preview {
  position: fixed;
  top: 100%;
  z-index: 1031;
  max-width: 768px;
  left: 0;
  right: 0;
  margin: auto;
  color: $suggestion-panel-color;
  height: 90%;

  .backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    cursor: pointer;
    opacity: 0.8;
    background-color: $gray-base;
  }

  .rubric-file-preview-container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 5%;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3;
    visibility: hidden;

    .rubric-file-preview {
      max-width: 768px;
      height: 80vh;
      margin: 0 auto;
      background: $white;
      text-align: center;
      margin-top: 80px;

      iframe {
        width: 100%;
        height: 100%;
        padding: 20px 20px 70px;
      }

      .rubric-file-preview-close {
        height: 20px;

        .close {
          margin-right: 20px;
          font-size: 24px;
          margin-top: 20px;
        }
      }
    }
  }

  .oa-preview-container {
    height: 100%;
    background: $light-100;
    position: relative;

    .oa-user-list-container {
      .user-selected-profile {
        box-shadow: 0 0 13px -5px $content-popup-box-shadow;
        position: relative;
        z-index: 3;
        display: grid;
        grid-template-columns: 1fr 60px;
        background: $white;
        align-items: center;
        cursor: pointer;

        .oa-user-list-drop-down {
          justify-self: center;
          height: 20px;
        }
      }

      .oa-list-of-users {
        position: absolute;
        width: 100%;
        background: $white;
        z-index: 2;
        box-shadow: 0 12px 20px -14px $user-list-box-shadow;
        display: none;
        max-height: 300px;
        overflow-y: auto;

        .user-list-container {
          display: grid;
          grid-template-columns: 1fr 50px;
          border-bottom: 1px solid $breadcrumb-color;
          cursor: pointer;

          &:last-child {
            border: none;
          }

          &.selected {
            background-color: $selected-bg-color;
          }

          .oa-performance-container {
            display: grid;
            align-items: center;
            justify-items: center;

            .oa-performance {
              width: 100%;
              height: 100%;
              color: $white;
              font-size: 18px;
              font-weight: bold;
              align-items: center;
              justify-content: center;
              display: flex;
              flex-direction: column;

              &.grade-range-not-started {
                color: $suggestion-panel-color;
                font-size: 13px;
              }

              .oa-score {
                text-align: center;
                line-height: 1;
              }

              .oa-timespent {
                font-size: 12px;
                color: $white;
                text-align: center;
                line-height: 1;
                margin-top: 5px;
              }
            }
          }
        }
      }

      .user-profile-img {
        height: 45px;
        padding-left: 30px;
        padding-top: 7px;
        padding-bottom: 10px;

        img {
          width: 30px;
          height: 30px;
          border-radius: 50%;
        }

        span {
          padding-left: 10px;
        }
      }
    }

    .oa-header-container {
      height: 50px;
      background-color: $header-bg-color;
      display: grid;
      grid-template-columns: 50px 1fr 50px 50px 50px;
      align-items: center;
      justify-items: center;

      &.report {
        grid-template-columns: 50px 1fr 50px 50px 50px 50px;
      }
      &.download-option {
        grid-template-columns: 50px 1fr 50px 50px 50px 100px 50px;
        .download {
          display: grid;
          grid-template-rows: 21px;
          line-height: 1.3;
          text-align: center;
          color: $white;
          .download-icon {
            color: $white;
          }
        }
      }

      .close-pullup {
        width: 32px;
        height: 32px;
        background-color: $close-pull-up-bg;
        border-radius: 50%;
        text-align: center;
        cursor: pointer;

        i {
          color: $white;
          line-height: 32px;
          width: 32px;
          height: 32px;
        }
      }

      .preview-title-container {
        justify-self: left;
        display: grid;
        grid-template-columns: 30px 1fr;
        align-items: center;
        justify-items: center;

        .oa-icon {
          background-image: url("../assets/gooru/images/offline-white.svg");
          width: 25px;
          height: 25px;
          background-repeat: no-repeat;
          background-size: 25px 25px;
          opacity: 0.8;
          display: block;
        }

        .preview-title {
          font-weight: bold;
          margin: 0;
          color: $white;
          @media screen and (max-width: $screen-sm) {
            font-size: 13px;
          }
          @include ellipsis(
            $font-size: 15px,
            $line-height: 1.2,
            $lines-to-show: 1
          );
        }
      }

      .oa-overall-performance-container {
        height: 50px;
        width: 50px;
        display: grid;
        align-items: center;
        justify-items: center;

        .oa-performance {
          width: 100%;
          height: 100%;
          color: $white;
          font-size: 18px;
          font-weight: bold;
          align-items: center;
          justify-content: center;
          display: flex;
          flex-direction: column;

          .oa-score {
            text-align: center;
            line-height: 1;
          }

          .oa-timespent {
            font-size: 12px;
            color: $white;
            text-align: center;
            line-height: 1;
            margin-top: 5px;
          }
        }
      }

      .content-player,
      .content-remix,
      .print-preview {
        width: 40px;
        cursor: pointer;
        margin: 0 8px;

        i {
          color: $white;
          line-height: 48px;
        }
      }
    }

    .attempts-container {
      background-color: $white;
      margin: 0 15px;
      padding: 5px 15px;
      position: relative;

      .latest-attempt {
        color: rgb(62, 94, 103);
        font-size: 13px;
        cursor: pointer;
      }

      .toggle-attempts {
        cursor: pointer;
      }

      .all-attempts {
        display: none;
        top: 57px;
        background-color: $white;
        width: 100%;
        left: 0;
        padding-left: 15px;
        z-index: 1;
        box-shadow: 0 2px 4px 0 $container-box-shadow;
        right: 15px;

        .attempt-info {
          color: rgb(62, 94, 103);
          font-size: 13px;
          cursor: pointer;

          &.active-attempt {
            font-weight: bold;
          }
        }
      }
    }

    .oa-body-container {
      height: calc(100% - 50px);
      overflow-y: auto;
      padding: 15px;
      @media screen and (max-width: $screen-sm) {
        padding: 0;
      }

      &.report-view {
        height: calc(100% - 95px);
      }

      .oa-info-image-container {
        margin-bottom: 15px;
        display: grid;
        grid-template-columns: 250px 1fr;
        grid-gap: 15px;
        @media screen and (max-width: $screen-sm) {
          display: unset;
        }

        .oa-image-container {
          height: 230px;
          background-size: cover;
          background-position: center;
          display: block;
          position: relative;
          background-color: $white;

          .oa-standards {
            position: absolute;
            bottom: 55px;
            left: 10px;

            .gru-taxonomy-tag {
              border: none;
              border-radius: 10px;
              padding-left: 10px;
              padding-right: 10px;

              .standard-tag {
                .tag-label {
                  color: $dark-400;
                  font-weight: bold;
                }

                .tag-caption {
                  color: $dark-400;
                }
              }
            }
          }

          .oa-date-range {
            position: absolute;
            bottom: 0;
            background: $popover-box-shadow;
            color: $white;
            width: 100%;
            height: 30px;
            vertical-align: middle;
            display: grid;
            align-items: center;
            justify-content: center;
          }

          .oa-etc {
            position: absolute;
            bottom: 60px;
            right: 0;
            background: $dark-400;
            color: $white;
            border-bottom-left-radius: 16px;
            border-top-left-radius: 16px;
            padding: 0 10px;
            height: 32px;
            display: flex;
            align-items: center;
            grid-template-columns: 20px 10px 20px;

            .access_time {
              font-size: 20px;
              padding-right: 5px;
            }

            .etc-value {
              width: auto;
            }
          }
        }

        .oa-info-container {
          background-color: $white;
          @media screen and (max-width: $screen-sm) {
            margin-bottom: 15px;
            padding-bottom: 15px;
          }

          .oa-project-performance {
            display: grid;
            align-items: center;
            justify-items: center;
            height: 50px;

            .oa-performance {
              width: 100%;
              height: 100%;
              color: $white;
              font-size: 18px;
              font-weight: bold;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;

              .oa-score {
                text-align: center;
                line-height: 1;
              }

              .oa-timespent {
                font-size: 12px;
                color: $white;
                text-align: center;
                line-height: 1;
                margin-top: 5px;
              }
            }
          }

          .oa-info-header-container {
            display: grid;
            grid-template-columns: 40px 1fr 50px;
            align-items: center;
            height: 50px;

            .oa-icon-container {
              width: 20px;
              height: 20px;
              background-size: 20px 20px;
              background-image: url("./gooru/images/text-resource-dark.svg");
              display: block;
              justify-self: center;
            }

            .oa-title-container {
              .oa-project-label {
                color: $placeholder-color;
                font-weight: bold;
                @include ellipsis(
                  $font-size: 14px,
                  $line-height: 1.2,
                  $lines-to-show: 1
                );
              }

              .oa-project-tag {
                font-size: 11px;
                opacity: 0.7;
              }
            }
          }

          .oa-description-container {
            padding-left: 30px;
            padding-right: 10px;
            font-size: 13px;
            color: $placeholder-color;
            line-height: 1.5;
          }
        }
      }

      .oa-references-container {
        background: $white;
        padding: 15px;
        margin-bottom: 15px;
      }

      .oa-teacher-student-rubric {
        .student-rubric-section,
        .teacher-rubric-section {
          margin-bottom: 20px;
          background-color: $white;
        }

        .student-grading-panel,
        .teacher-grading-panel {
          padding: 15px 15px 0 30px;

          .rubric-category-container {
            .grade-rubric-categories {
              .grade-rubric-category {
                .grade-rubric-level-container {
                  .grade-rubric-levels {
                    background-color: $frq-gray;
                    border-radius: 16px;
                  }
                }
              }
            }
          }
        }

        .over-all-comment {
          padding-left: 30px;
          padding-bottom: 15px;

          .over-all-comment-label {
            color: $placeholder-color;
            font-weight: bold;
            line-height: 1.2;
          }

          p {
            margin: 0;
            padding-top: 10px;
            padding-left: 10px;
          }
        }

        .student-grading-header,
        .teacher-grading-header {
          display: grid;
          grid-template-columns: 50px 1fr 50px;
          align-items: center;
          box-shadow: 0 2px 10px -4px $content-popup-box-shadow;
          height: 50px;

          .rubric-icon-container {
            width: 20px;
            height: 20px;
            background-size: 20px 20px;
            background-image: url("./gooru/images/navigation_rubric_selected_icon.svg");
            display: block;
            justify-self: center;
          }

          .rubric-container {
            .grader-label {
              color: $placeholder-color;
              font-weight: bold;
              display: flex;
              align-items: flex-end;

              .rubric-attachment {
                color: $blue-text;
                cursor: pointer;
                margin-left: 10px;
                display: flex;
                align-items: flex-end;
              }
            }

            .grade-tag {
              font-size: 11px;
              opacity: 0.7;
            }
          }

          .score-section {
            width: 50px;
            height: 50px;

            .oa-grade-score {
              width: 100%;
              height: 100%;
              text-align: center;
              padding-top: 2px;

              &.grade-range-not-started {
                span {
                  color: $oa-score-bg-color;
                }

                hr {
                  border-color: $oa-score-bg-color;
                }
              }

              span {
                font-size: 18px;
                font-weight: bold;
                color: $white;
                line-height: 1.2;
              }

              hr {
                margin: 0 15px;
                border-width: 2px;
                border-color: $white;
              }
            }
          }
        }

        .grade-rubric-category {
          .grade-rubric-category-info {
            p {
              color: $placeholder-color;
            }
          }

          .grade-rubric-level-container {
            .grade-rubric-levels.no-progress {
              background-color: $light-100;
              border-radius: 15px;

              i {
                background-color: $breadcrumb-color;
              }
            }
          }
        }
      }
    }
  }
  .disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}
