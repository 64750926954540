.gru-collection-summary {
  height: 0;
  width: 0;
  overflow: hidden;
  transition: all 1s;
  position: relative;

  .left-panel-container {
    width: 100%;
    height: 100%;
    background-color: $white;
    border-radius: 5px;
    position: relative;
    display: flex;
    flex-direction: column;

    .header-panel {
      width: 100%;

      .collection-info {
        height: 56px;
        display: grid;
        grid-template-columns: 56px auto 56px;
        .hide-div.close-button {
          @media screen and (min-width: 768px) {
            display: none;
          }
        }
        @media screen and (max-width: 767px) {
          grid-template-columns: 56px auto 56px 56px;
        }

        .icon-container {
          display: flex;
          justify-content: center;
          align-items: center;
          background-repeat: no-repeat;
          background-position: center;

          &.collection {
            background-image: url("gooru/images/add_collection.svg");
          }
          &.assessment {
            background-image: url("gooru/images/add_assessment.svg");
          }
        }

        .title-container {
          display: flex;
          align-items: flex-start;
          justify-content: center;
          color: $dark-400;
          flex-direction: column;
          .lesson-title {
            font-size: 11px;
            margin: 0;
            @include ellipsis(
              $font-size: 11px,
              $line-height: 1.4,
              $lines-to-show: 1
            );
          }
          .collection-title {
            font-size: 14px;
            font-weight: 300;
            margin: 0;
            @include ellipsis(
              $font-size: 14px,
              $line-height: 1.1,
              $lines-to-show: 1
            );
          }

          &.suggestion {
            color: $orage-f3f;
          }
        }

        .timespent-container,
        .close-button {
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: bold;
          text-align: center;
          line-height: 16px;
        }
      }

      .collection-desc {
        .resource-count {
          display: grid;
          grid-template-columns: auto 24px;
          cursor: pointer;
          padding: 0 8px 8px;

          .resource-icon-count {
            display: flex;
            .resource,
            .question {
              width: 48px;
              display: flex;
              align-items: center;
              justify-content: center;
              opacity: 0.5;
              font-size: 11px;

              .icon {
                display: inline-block;
                width: 24px;
                height: 24px;
                background-repeat: no-repeat;
                background-position: center;
                background-size: 24px 24px;
                margin-left: 5px;
              }
            }
            .question .icon {
              background-image: url("gooru/images/add_question.svg");
            }
            .resource .icon {
              background-image: url("gooru/images/add_resource_icon.svg");
            }
          }

          .accordion-icon {
            display: flex;
          }
        }
        .description {
          display: grid;
          grid-template-columns: 56px auto;
          padding: 12px 0;

          .desc-img {
            display: flex;
            justify-content: center;
            align-items: center;
            .desc-icon {
              width: 56px;
              height: 56px;
              display: inline-block;
              background-repeat: no-repeat;
              background-position: center;
              background-size: cover;
            }
          }

          .desc {
            font-size: 12px;
            color: $dark-400;
            padding: 0 10px;
            line-height: 1.33;

            .taxonomy {
              display: inline-block;
              .gru-taxonomy-tag-list {
                .gru-taxonomy-tag {
                  border: none;
                  padding: 0;
                  margin: 0;
                  vertical-align: middle;

                  .standard-tag {
                    padding: 0;
                    display: flex;

                    span {
                      margin-left: 5px;
                    }
                  }
                }
                .non-visible-tags-container {
                  .all-tags {
                    right: 0;
                  }
                }
              }
            }
          }
        }
      }
    }

    .body-panel {
      box-shadow: 0 -3px 4px $box-shadow-bg-color;
      overflow: hidden;
      position: relative;
      height: 100%;
      display: block;
      width: 100%;

      .under-construction {
        display: flex;
        padding: 10px;
        align-items: center;
        justify-content: center;
        font-size: 12px;
      }

      .properties {
        display: flex;
        .attempts {
          .no-more-attempts {
            display: flex;
            text-align: left;
            .no-more {
              margin-left: 10px;
            }
          }
          .attempt {
            font-size: 25px;
            color: $attempt-color;
          }
        }
        .answers,
        .attempts,
        .navigation {
          font-size: 10px;
          line-height: 1.5;
          text-align: center;
          width: 100%;
          padding: 5px;
          i {
            font-size: 35px;
            color: $attempt-color;
          }
          .info {
            color: $info-color;
          }
        }
      }

      .resource-list {
        overflow-y: auto;
        display: block;
        max-height: 100%;
        position: relative;
        width: 100%;
        @media screen and (max-width: 767px) {
          padding-bottom: 36px;
        }

        &::-webkit-scrollbar {
          height: 6px;
          width: 8px;
        }
        &::-webkit-scrollbar-thumb {
          background: $gray-light;
          border: 1px solid $gray-light;
          border-radius: 5px;
        }

        .resource-info {
          display: grid;
          grid-template-columns: 56px auto 56px;
          padding: 8px 0;
          background-color: $bg-light-300-white;
          cursor: pointer;

          &.started,
          &.selected {
            background-color: $white;
          }

          .score {
            display: flex;
            justify-content: center;
            align-items: center;

            &.correct {
              i {
                font-size: 24px;
                width: 24px;
                height: 24px;
                color: $green-600;
              }
            }

            &.incorrect {
              i {
                font-size: 24px;
                width: 24px;
                height: 24px;
                color: $brand-incorrect;
              }
            }
          }

          .resource-icon {
            display: flex;
            justify-content: center;
            align-items: center;

            i {
              width: 24px;
              height: 24px;
              background-repeat: no-repeat;
              background-position: center;
              background-size: cover;
              background-image: url("gooru/images/add_question.svg");

              &.resource.interactive_resource {
                background-image: url("gooru/images/interactive-resource-dark.svg");
              }
              &.resource.video_resource {
                width: 20px;
                height: 20px;
                background-image: url("gooru/images/video-resource-dark.svg");
              }
              &.resource.html_resource {
                background-image: url("gooru/images/html-icon.png");
              }
              &.resource.webpage_resource {
                height: 25px;
                background-image: url("gooru/images/website-resource-dark.svg");
              }
              &.resource.image_resource {
                background-image: url("gooru/images/image-resource-dark.svg");
              }
              &.resource.audio_resource {
                background-image: url("gooru/images/audio-resource-dark.svg");
              }
              &.resource.text_resource {
                height: 25px;
                background-image: url("gooru/images/text-resource-dark.svg");
              }
              &.resource.h5p_interactive_slide {
                width: 25px;
                height: 25px;
                background-image: url("gooru/images/interactive-slides.png");
                background-size: contain;
              }
              &.resource.h5p_interactive_video {
                width: 25px;
                height: 25px;
                background-image: url("gooru/images/interactive-video.png");
                background-size: contain;
              }
              &.resource.h5p_drag_and_drop_resource {
                width: 32px;
                height: 33px;
                background-image: url("gooru/images/drag-and-drop-icon.png");
                background-size: contain;
              }
              &.resource.h5p_interactive_personality_quiz {
                width: 25px;
                height: 25px;
                background-image: url("gooru/images/personality-quiz-icon.png");
                background-size: contain;
              }
              &.question {
                background-image: url("gooru/images/add_question.svg");
              }
            }
          }

          &.selected {
            .resource-icon {
              i {
                background-image: url("gooru/images/question-resource-blue.svg");
                &.resource.interactive_resource {
                  background-image: url("gooru/images/interactive-resource-blue.svg");
                }
                &.resource.video_resource {
                  width: 20px;
                  height: 20px;
                  background-image: url("gooru/images/video-resource-blue.svg");
                }
                &.resource.html_resource {
                  height: 25px;
                  background-image: url("gooru/images/html-icon-blue.svg");
                }
                &.resource.webpage_resource {
                  height: 25px;
                  background-image: url("gooru/images/website-resource-blue.svg");
                }
                &.resource.image_resource {
                  background-image: url("gooru/images/image-resource-blue.svg");
                }
                &.resource.audio_resource {
                  background-image: url("gooru/images/audio-resource-blue.svg");
                }
                &.resource.text_resource {
                  height: 25px;
                  background-image: url("gooru/images/text-resource-blue.svg");
                }
                &.resource.h5p_interactive_slide {
                  background-image: url("gooru/images/interactive-slides-blue.svg");
                }
                &.resource.h5p_interactive_video {
                  background-image: url("gooru/images/interactive-video-blue.svg");
                }
                &.resource.h5p_drag_and_drop_resource {
                  background-image: url("gooru/images/drag-and-drop-icon.png");
                }
                &.resource.h5p_interactive_personality_quiz {
                  background-image: url("gooru/images/personality-quiz-icon.png");
                }
                &.question {
                  background-image: url("gooru/images/question-resource-blue.svg");
                }
              }
            }
          }
          &.started {
            .resource-icon {
              i {
                background-image: url("gooru/images/question-resource-green.svg");
                &.resource.interactive_resource {
                  background-image: url("gooru/images/interactive-resource-green.svg");
                }
                &.resource.video_resource {
                  width: 20px;
                  height: 20px;
                  background-image: url("gooru/images/video-resource-green.svg");
                }
                &.resource.html_resource {
                  height: 25px;
                  background-image: url("gooru/images/html-icon-green.svg");
                }
                &.resource.webpage_resource {
                  height: 25px;
                  background-image: url("gooru/images/website-resource-green.svg");
                }
                &.resource.image_resource {
                  background-image: url("gooru/images/image-resource-green.svg");
                }
                &.resource.audio_resource {
                  background-image: url("gooru/images/audio-resource-green.svg");
                }
                &.resource.text_resource {
                  height: 25px;
                  background-image: url("gooru/images/text-resource-green.svg");
                }
                &.resource.h5p_interactive_slide {
                  background-image: url("gooru/images/interactive-slides-green.svg");
                }
                &.resource.h5p_interactive_video {
                  background-image: url("gooru/images/interactive-video-green.svg");
                }
                &.question {
                  background-image: url("gooru/images/question-resource-green.svg");
                }
              }
            }
            &.selected {
              .resource-icon {
                i {
                  background-image: url("gooru/images/question-resource-blue.svg");
                  &.resource.interactive_resource {
                    background-image: url("gooru/images/interactive-resource-blue.svg");
                  }
                  &.resource.video_resource {
                    width: 20px;
                    height: 20px;
                    background-image: url("gooru/images/video-resource-blue.svg");
                  }
                  &.resource.html_resource {
                    height: 25px;
                    background-image: url("gooru/images/html-icon-blue.svg");
                  }
                  &.resource.webpage_resource {
                    height: 25px;
                    background-image: url("gooru/images/website-resource-blue.svg");
                  }
                  &.resource.image_resource {
                    background-image: url("gooru/images/image-resource-blue.svg");
                  }
                  &.resource.audio_resource {
                    background-image: url("gooru/images/audio-resource-blue.svg");
                  }
                  &.resource.text_resource {
                    height: 25px;
                    background-image: url("gooru/images/text-resource-blue.svg");
                  }
                  &.resource.h5p_interactive_slide {
                    background-image: url("gooru/images/interactive-slides-blue.svg");
                  }
                  &.resource.h5p_interactive_video {
                    background-image: url("gooru/images/interactive-video-blue.svg");
                  }
                  &.question {
                    background-image: url("gooru/images/question-resource-blue.svg");
                  }
                }
              }
            }
          }

          .resource-title {
            display: flex;
            flex-direction: column;
            font-size: 11px;
            color: $dark-400;

            .desc {
              font-weight: bold;
              opacity: $opacity-light;
              line-height: 1.3;
              max-height: 100% !important;
              @include ellipsis(
                $font-size: 11px,
                $line-height: 1.3,
                $lines-to-show: 2
              );
            }
          }

          .resource-timespent {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
          }
        }
      }
    }
  }
}
