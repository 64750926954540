.teacher_class_atc {
  .teacher.class {
    padding: 5px 0;
    background-color: $light-ps;
    height: 100%;
  }
}

.controller.teacher.class.atc {
}

body.teacher_class_atc {
  @media only screen and (max-width: $screen-in-680) {
    .app-container {
      padding-top: 102px;
    }
  }
}
