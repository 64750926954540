.gru-likert-scale {
  .gru-likert-scale-container {
    pointer-events: none;
    @media screen and (max-width: 768px) {
      width: inherit;
    }
    &.read-only {
      pointer-events: all;
    }
    .gru-likert-scale-panel {
      h5 {
        font-size: 18px;
      }
      select {
        background-color: $white;
        width: fit-content;
        min-width: 100px;
        padding: 4px;
      }

      .likert-scale-instruction {
        padding: 10px;
        display: grid;
        grid-template-columns: 1fr;
        grid-column-gap: 10px;
        align-items: center;

        .likert-scale-instruction-text {
          textarea {
            width: 100%;
          }
        }
      }
      .likert-scale-definition {
        padding: 10px;

        .likert-scale-definition-panel {
          display: grid;
          grid-template-columns: 150px auto;
          grid-column-gap: 10px;
          font-size: 14px;
          align-items: center;
        }
      }
      .likert-scale-point-label {
        padding: 10px;
        width: 700px;
        .point-label-panel {
          padding: 10px;
          .point-label-panel-body {
            display: grid;
            grid-template-columns: 100px 1fr;
            align-items: center;
            grid-column-gap: 20px;
            padding: 10px 0;
            .select-point-dropdown {
              display: grid;
            }
            .point-label-title {
              input {
                width: 100%;
              }
            }
          }
        }
      }
      .likert-scale-items {
        padding: 10px;
        .scale-items-panel {
          .scale-item-body {
            display: grid;
            grid-template-columns: 170px 300px auto;
            grid-column-gap: 30px;
            align-items: baseline;

            input {
              outline: none;
            }

            .scale-item-label {
              font-size: 15px;
              font-weight: bold;
            }
            .action-btn {
              font-size: 25px;
              cursor: pointer;
            }
          }
        }
      }
      .likert-scale-presentation {
        display: grid;
        grid-template-columns: 200px auto;
        align-items: center;
        padding: 10px;
        .likert-presentation-modes {
          display: grid;
          grid-template-columns: 1fr 1fr;
          align-items: center;
          span {
            margin: 0 5px;
          }
        }
      }
      .likert-scale-ui-templates {
        padding: 10px;
        display: grid;
        grid-template-columns: 200px 200px;
        align-items: center;

        .dropdown {
          .dropdown-toggle {
            display: flex;
            align-items: center;
            span {
              font-size: 25px;
            }
          }
          .dropdown-menu {
            .dropdown-item {
              padding: 2px 10px;
              cursor: pointer;
            }
          }
        }
      }
      .likert-ui-heading {
        padding: 10px;
      }
    }
  }
}
