.reports.pull-up-student-course-report {
  position: fixed;
  top: 100%;
  z-index: 1031;
  width: 768px;
  left: 0;
  right: 0;
  margin: auto;
  color: $suggestion-panel-color;

  .backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    cursor: pointer;
    opacity: 0.8;
    background-color: $gray-base;
  }

  .backdrop-pull-ups {
    .backdrop {
      background: none !important;
    }
  }

  .loading-spinner {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
  }

  .student-course-report-container {
    background: $white-bold;
    width: 768px;
    max-height: 90vh;
    height: 90vh;

    .report-header {
      display: flex;
      height: 60px;
      box-shadow: 0 2px 5px 0 $disabled-bg-color;
      position: relative;
      z-index: 1;

      .report-breadcrumb {
        width: calc(100% - 50px);
        display: flex;

        .course-title {
          display: flex;
          align-items: center;
          max-width: 100%;

          p {
            max-width: calc(100% - 45px);
            margin: 0;
            @include ellipsis(
              $font-size: 14px,
              $line-height: 1.2,
              $lines-to-show: 2
            );
          }

          .course-icon {
            width: 50px;
            height: 50px;
            background-image: url("../assets/gooru/images/course.svg");
            background-repeat: no-repeat;
            background-size: 30px 30px;
            background-position: 12px 12px;
          }
        }
      }

      .report-close-pullup {
        width: 50px;

        .close {
          color: $breadcrumb-color;
          margin-top: 20px;
          margin-right: 20px;
          font-size: 24px;
        }
      }
    }

    .report-content {
      max-height: calc(90vh - 60px);
      height: calc(90vh - 60px);
      overflow-y: auto;
      width: 100%;
      padding-bottom: 20px;

      .report-carousel-tags {
        padding-bottom: 15px;

        .report-carousel {
          position: relative;

          #report-carousel-wrapper {
            background-image: linear-gradient($dark-600, $dark-600);
            background-blend-mode: multiply;

            .course-background-cover {
              height: 251px;
              background-image: linear-gradient($dark-600, $dark-600);
              background-blend-mode: multiply;

              .report-carousel-content {
                width: 640px;
                margin: auto;
                position: relative;
                display: flex;
                height: 100%;
                align-items: center;

                .performance-display-container {
                  margin-right: 15px;
                  margin-top: 15px;
                  width: 190px;

                  .course-performance-average {
                    width: 185px;
                    height: 185px;
                    border-radius: 50%;
                    line-height: 185px;
                    text-align: center;
                    font-weight: bold;
                    text-indent: 10px;
                    font-size: 60px;
                    color: $white;
                  }
                }

                .course-description {
                  color: $white;
                  height: 160px;
                  font-size: 15px;
                  width: calc(100% - 200px);

                  .header-desc {
                    font-weight: bold;
                  }

                  .text-desc {
                    @include ellipsis(
                      $font-size: 15px,
                      $line-height: 1.5,
                      $lines-to-show: 5
                    );
                  }
                }
              }
            }
          }
        }

        .report-standard-tags {
          padding: 15px 15px 0;
          background: $white;

          .gru-taxonomy-tag {
            border: none;
            max-width: 150px;

            .standard-tag {
              background: $dark-400;
              padding: 4px;
              border-radius: 4px;

              .tag-caption,
              .tag-label {
                color: $white;
              }
            }
          }
          .non-visible-tags {
            margin-left: 10px;
            background: $suggestion-panel-color;
            width: 35px;
            color: $white;
            border-radius: 4px;
          }
          .popover {
            background: $white;
            margin-left: 5px;

            .arrow::after {
              border-left-color: $white;
              border-right-color: $white;
            }

            .popover-content {
              .gru-taxonomy-tag {
                max-width: 300px;
                width: 270px;
                border-radius: 4px;

                .standard-tag {
                  width: 270px;
                }
              }
            }
          }
        }
      }

      .report-performance-tabular {
        width: 100%;

        .report-performance-header {
          display: inline-grid;
          grid-template-columns: 50% 10% 40%;
          width: 100%;
          align-items: center;
          text-transform: uppercase;
          color: $dark-300;
          font-size: 10px;
          font-weight: bold;
          height: 40px;
          border-bottom: 1px solid $breadcrumb-color;
          position: relative;
          background: $white;

          .content-name {
            padding-left: 54px;
          }
        }

        .report-performance-list {
          display: inline-grid;
          grid-template-columns: 50% 10% 40%;
          width: 100%;
          align-items: center;
          color: $gray;
          height: 49px;
          border-bottom: 1px solid $breadcrumb-color;

          .content-name {
            display: flex;
            align-items: center;
            cursor: pointer;
            &.not-started {
              pointer-events: none;
            }

            .unit-icon {
              background-image: url("../assets/gooru/images/units-gray.svg");
              width: 50px;
              height: 40px;
              background-repeat: no-repeat;
              display: block;
              background-position: 15px;
            }

            span {
              width: calc(100% - 40px);
              padding-right: 20px;
              @include ellipsis(
                $font-size: 14px,
                $line-height: 1.2,
                $lines-to-show: 1
              );
            }
          }

          .completion {
            .completion-bar {
              width: 80%;
              background: #d8d8d8;
              height: 6px;
              border-radius: 3px;

              .segment {
                height: 6px;
                border-radius: 3px 0 0 3px;

                &.completed {
                  border-radius: 3px;
                }
              }
            }
          }
        }
      }
    }
  }
}
