.modal.gru-domain-picker,
.modal.gru-standard-picker {
  .modal-dialog {
    width: 80%;
  }

  .taxonomy.modals.gru-domain-picker,
  .taxonomy.modals.gru-standard-picker {
    @extend .content.modals.gru-course-new;

    max-width: 100%;

    .modal-body {
      padding-top: 20px;

      .actions {
        margin-top: 10px;
      }
    }

    .shortcut-list,
    .selected-list {
      ul {
        display: inline-block;
        list-style: none;
        padding-left: 10px;

        > li {
          display: inline-block;
        }
      }
    }

    .shortcut-list {
      padding-bottom: 0;
      margin-bottom: 10px;
      border-bottom: 1px solid $gray-lighter;
    }

    .browse-selector {
      .gru-browse-selector {
        margin-top: 10px;
        margin-bottom: 20px;

        ol,
        ul {
          list-style: none;
          padding: 0;
          margin: 0;
        }

        label {
          margin-bottom: 0;
        }

        ol {
          border: 1px solid $gray-light;
          @include flexbox();

          > li {
            width: 22%;

            > strong {
              background-color: $gray-lighter;
              display: block;
              border-bottom: 2px solid $gray-light;
              padding: 10px 15px;
            }

            .browse-panel {
              height: 420px;
              background-color: $gray-lighter;
              border-left: 1px solid $white;
              overflow: scroll;

              li {
                padding: 3px 15px;

                a {
                  color: $gray;
                  line-height: 1.4;
                  display: inline-block;
                }

                &.selected {
                  background-color: $blue-100;
                }
                &.active {
                  background-color: $blue-400;

                  a {
                    color: $white;
                  }
                }
              }
            }

            &:first-child {
              .browse-panel {
                border-left: 0 none;
              }
            }

            &:last-child {
              width: auto;
              @include flex();

              .browse-panel {
                background-color: $white;

                li {
                  padding: 6px 10px 10px;
                  position: relative;

                  > label {
                    margin-right: 20px;
                    @include flexbox();

                    > input {
                      width: 28px;
                    }
                    > div {
                      @include flex();

                      > strong,
                      > span {
                        display: block;
                        line-height: 1.2;
                      }
                      > strong {
                        margin: 4px 0;
                        line-height: 1.2;
                      }
                    }
                  }

                  button[data-toggle] {
                    background-color: $table-bg;
                    border: 0 none;
                    position: absolute;
                    top: 5px;
                    right: 5px;

                    i.keyboard_arrow_up {
                      display: none;
                    }

                    &.collapsed {
                      i.keyboard_arrow_up {
                        display: inline-block;
                      }
                      i.keyboard_arrow_down {
                        display: none;
                      }
                    }

                    &:active,
                    &:focus {
                      outline: 0 none;
                    }
                  }
                  .micro-panel {
                    .micro-competencies {
                      margin-left: 10px;
                    }

                    > ul {
                      background-color: $white;
                      border-radius: 3px;
                      border: 1px solid $gray-light;
                      margin: 0 10px 0;

                      > li {
                        border-bottom: 1px solid $gray-light;
                      }
                    }
                  }
                  .micro-competencies {
                    margin: 20px 0 0 20px;
                    padding: 0;
                    background: $table-bg;
                    font-size: 12px;
                  }
                }

                &.level-3 {
                  > li {
                    padding: 0 0 5px;

                    > .category {
                      padding: 5px 10px;
                      margin: 0;
                      background-color: $dark-300;
                      color: $white;
                      line-height: 1.3;
                    }

                    > .standard {
                      position: relative;
                      padding: 10px 40px 10px 10px;
                      border-bottom: 1px solid $gray-lighter;

                      > label {
                        @include flexbox();

                        strong,
                        span {
                          display: block;
                          line-height: 1.2;
                        }
                        strong {
                          margin: 4px 0;
                          line-height: 1.2;
                        }
                      }

                      > ul > li {
                        border-radius: 3px;
                        background-color: $gray-lighter;
                        margin: 6px 6px 0;

                        &:first-child {
                          margin-top: 10px;
                        }

                        &.no-content {
                          border-radius: 0;
                          background-color: $table-bg;
                          margin: 0;
                          padding-top: 0;
                        }

                        > ul {
                          background-color: $white;
                          border-radius: 3px;
                          border: 1px solid $gray-light;
                          margin: 0 10px 0;

                          > li {
                            border-bottom: 1px solid $gray-light;

                            > label {
                              margin-right: 0;

                              > div > span {
                                margin-top: 4px;
                              }
                            }

                            &:last-child {
                              border-bottom: 0 none;
                            }
                          }
                        }
                      }

                      &:last-of-type {
                        border-bottom: 0 none;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .selected-list {
      @include flexbox();

      > span {
        display: block;
        min-width: 140px;
        text-transform: capitalize;
        margin-top: 5px;
      }
    }
  }
}

.modal.gru-standard-picker {
  .modal-dialog {
    width: 86%;
  }
}
