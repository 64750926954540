.reports.gru-assessment-report {
  width: 100%;
  background-color: $white;
  /* Small devices (tablets, 768px and up) */
  @media (min-width: $screen-sm-min) {
  }
  /* Medium devices (desktops, 992px and up) */
  @media (min-width: $screen-md-min) {
  }

  .standard-tags {
    padding: 15px 15px 0;
    background: $white;
    display: none;

    .gru-taxonomy-tag-list {
      .gru-taxonomy-tag {
        border: none;
        max-width: 150px;

        .standard-tag {
          background: $dark-400;
          padding: 4px;
          border-radius: 4px;

          .tag-caption,
          .tag-label {
            color: $white;
          }
        }
      }

      .non-visible-tags {
        margin-left: 10px;
        background: $suggestion-panel-color;
        width: 35px;
        color: $white;
        border-radius: 4px;
        margin-top: 1px;
      }

      .popover {
        background: $white;
        margin-left: 5px;

        .arrow::after {
          border-left-color: $white;
          border-right-color: $white;
        }

        .popover-content {
          .gru-taxonomy-tag {
            max-width: 300px;
            width: 270px;
            border-radius: 4px;

            .standard-tag {
              width: 270px;
            }
          }
        }
      }
    }
  }

  .gru-assessment-summary {
    width: 100%;
    background: $white;

    .reports-owner {
      border-bottom: 1px solid $light-200;

      span {
        margin-left: 16px;
      }
    }

    .summary-mastry-question {
      max-height: 400px;
      overflow: auto;
    }

    .summary-mastry-question::-webkit-scrollbar {
      width: 6px;
    }

    .summary-mastry-question::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: $gray-harder;
    }
  }

  .hidden-report {
    @include flexbox();
    @include justify-content(center);
    @include align-items(center);
    height: 200px;

    span {
      @include flex();
      text-align: center;
      font-size: $font-size-large;
    }
  }
}
