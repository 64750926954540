#taxonomy-tags {
  margin-top: 20px;

  .taxonomy-tags-container {
    .taxonomy-tags-panel {
      display: flex;
      align-items: end;
      .taxonomy-tags-visible-parts {
        .taxonomy-tags-blk {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          list-style: none;
          padding: 0;
          margin: 0;
          .taxonomy-tags {
            border: 1px #a8b1b2 solid;
            border-radius: 100px;
            padding: 3px 8px;
            background-color: $light-800;
            color: $dark-850;
            margin: 2px;
            line-height: 18px;
            display: flex;
            align-items: center;
            margin-right: 8px;

            span {
              display: block;
              font-weight: 400;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: normal;
              cursor: pointer;
              @include ellipsis(
                $font-size: 12px,
                $line-height: 1,
                $lines-to-show: 1
              );
            }
          }
        }
      }
    }
  }
}
