.student-data-standard-chart {
  .student-data-standard-chart-container {
    display: grid;
    grid-template-columns: 1fr 400px;
    grid-column-gap: 10px;
    max-width: 1300px;
    width: 98%;
    margin: 0 auto;
    @media print {
      grid-template-columns: 550px 400px;
      .topic-info-pull-up {
        display: none;
      }
    }
    @media screen and (max-width: 800px) {
      grid-template-columns: 1fr;
      grid-row-gap: 10px;

      .student-data-standard-chart-panel {
        width: 93%;
        margin: 0 auto;
        overflow-x: auto;
      }
    }

    .student-data-standard-chart-panel {
      background-color: $gray-97;
      padding: 10px;
      display: grid;
      align-items: center;

      .standard-chart {
        display: flex;

        .go-back {
          i {
            cursor: pointer;
          }
        }
      }

      .data-standard-chart-header-container {
        display: flex;
        align-items: center;

        .standard-header-title {
          color: $dark-700;
          font-size: 18px;
          margin-left: 15px;
          position: relative;
          top: -4px;
        }
      }

      .standard-title {
        font-size: 16px;
        font-weight: bold;
        height: max-content;
        margin-left: 21px;
        line-height: normal;
        letter-spacing: normal;
        color: $dark-700;
        display: flex;
        margin: 0 auto;

        &.legend {
          .box {
            display: block;
            width: 16.6px;
            height: 16.6px;
            transform: rotate(90deg);
            margin-right: 8.4px;
            background-color: $blue-in-progress;
            margin-top: 2px;
          }
        }
      }

      .learner-proficiency-domain-matrix {
        display: grid;
        align-items: center;
        background-color: $gray-97;

        .actions {
          display: none;
        }

        .chart-area
          .matrix-chart
          .chart-container
          .domain-chart-container
          .scrollable-chart {
          height: calc(100vh - 270px);

          svg {
            #topic-group {
              .competency-cell {
                fill: $gray-no-started;

                &.class-grade-competency {
                  fill: $blue-in-progress;
                }
              }
            }

            #competencies-group {
              .competency-cell {
                fill: $gray-no-started;

                &.class-grade-domain-competency {
                  fill: $blue-in-progress;
                }
              }
            }
          }
        }
      }
    }

    .domain-panel-list-component {
      .gru-standard-domain-panel {
        .domain-list {
          height: calc(90vh - 140px);
        }
      }
    }
  }
}
