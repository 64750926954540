.activities.add-performance-data {
  position: fixed;
  top: 100%;
  z-index: 1033;
  width: 100%;
  left: 0;
  right: 0;
  margin: auto;
  background-color: $white;
  height: calc(100% - 52px);

  .backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    cursor: pointer;
    opacity: 0.8;
    background-color: $gray-base;
  }

  .add-performance-data-container {
    background-color: $white;
    height: 100%;

    .add-data-header-container {
      display: grid;
      grid-template-columns: 32px 1fr 50px auto;
      height: 56px;
      align-items: center;
      padding: 0 15px;
      column-gap: 15px;

      .icon-container {
        width: 22px;
        height: 16px;
        display: block;
        background-size: cover;

        &.collection-external-icon,
        &.assessment-external-icon {
          background-image: url("../assets/gooru/share.png");
        }

        &.assessment,
        &.assessment-external {
          background-image: url("../assets/gooru/images/add_assessment.svg");
        }

        &.collection,
        &.collection-external {
          background-image: url("../assets/gooru/images/add_collection.svg");
        }
      }

      .activity-info-container {
        display: grid;
        align-items: center;
        grid-template-columns: auto auto;
        justify-content: left;
        column-gap: 15px;
        height: 56px;

        .date-container {
          font-size: 10px;
          font-style: italic;
          color: $dark-400;
        }

        .activity-title-container {
          font-size: 16px;
          font-weight: bold;
          color: $dark-400;
        }
      }

      .close-container {
        color: $dark-400;
        opacity: 0.4;
        cursor: pointer;
      }
    }

    .add-data-body-container {
      height: calc(100% - 56px);
      overflow: auto;
    }
  }
}
