.gru-create-subject-card {
  width: 220px;
  margin: 10px 10px 10px;
  background-color: $white;
  border-radius: 5px;
  padding: 10px;

  .card-container {
    .header-panel {
      font-size: 12px;
      color: $dark-600;
      font-weight: bold;
      .subject {
        font-size: 14px;
      }
    }

    .body-panel {
      padding: 10px 0;
      font-size: 13px;
      color: $dark-600;
      font-weight: bold;
      overflow-y: auto;

      &.grade-list {
        height: 195px;
      }

      &::-webkit-scrollbar {
        height: 6px;
        width: 8px;
      }
      &::-webkit-scrollbar-thumb {
        background: $gray-light;
        border: 1px solid $gray-light;
        border-radius: 5px;
      }

      .class-card-image {
        position: relative;

        .card-image-blk {
          background-image: url("gooru/class-default.png");
          background-position: center;
          background-size: cover;
          width: 100%;
          height: 130px;
          display: block;
          position: relative;
        }
      }
      .validate-input {
        margin-top: 5px;
        .form-group {
          margin: 0;
          width: 100%;

          .form-control {
            font-size: 12px;
            color: $dark-600;
            font-weight: bold;
          }
        }
      }

      p {
        margin-bottom: 5px;
      }
      .grade-name {
        border-bottom: 1px solid $gray-80;
        cursor: pointer;
        line-height: 1.3;

        &.active {
          color: $green-700;

          &::after {
            visibility: visible;
            content: "\f00c";
            font-size: 13px;
            color: $green-700;
            float: right;
            padding-right: 10px;
          }
        }
      }
    }

    .footer-panel {
      font-size: 13px;
      color: $dark-600;
      font-weight: 500;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;

      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }

      .create-function {
        cursor: pointer;
        line-height: 1.3;
      }

      .next {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 95px;
        height: 30px;
        border-radius: 20px;
        color: $white;
        cursor: pointer;
      }

      .cancel {
        background: $lite-gray;
        margin-right: 10px;
        color: $gray-base;
      }
      .create {
        background-color: $green-700;
        &.disabled {
          opacity: 0.5;
          pointer-events: none;
        }
      }
    }
  }
}
