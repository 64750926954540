.reports.class-assessment.qz-questions-summary {
  $max-question-width: 150px;
  $question-height: 2em;
  $questions-per-column: 5;

  font-size: $font-size-small;
  position: relative;
  margin: 0 0 15px;

  .cursor-default {
    cursor: default;
  }
  .assessment-ques {
    pointer-events: none;
  }
  > ol {
    list-style: none;
    margin: 0;
    padding: 0;

    @include flexbox;
    @include flex-direction(column);
    @include flex-wrap(wrap);
    @include align-content(flex-start) height: $question-height *
      $questions-per-column;

    li {
      height: $question-height;
      width: $max-question-width;
      padding-left: 2em;
      display: table;
      flex-shrink: 0;
      margin-right: 30px;

      > a {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        max-width: $max-question-width;

        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;

        height: inherit;
        color: $text-color;

        > span {
          vertical-align: 0.4em;

          &.index {
            width: 2em;
            text-align: right;
            padding-right: 1.4em;
          }

          &.ratio {
            width: 3.2em;
            padding-left: 0.3em;
            text-align: center;
            color: $dark-100;
            font-size: $font-size-small;
          }
        }
        .charts.gru-x-bar-chart {
          display: inline-block;
          height: 1em;
          width: 6.8em;
          margin: 0 5px 0 0;
          background-color: $light-200;
          border-radius: 0;
          .segment {
            border-radius: 0;
          }
        }
      }
    }
  }

  > a.show-more {
    position: absolute;
    top: ($question-height * $questions-per-column / 2);
    right: 10px;
    color: $dark-100;
    .material-icons {
      font-size: $font-size-h3;
    }
  }
}
