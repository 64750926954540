.show-confirmation {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  background-position: center;
  background-color: $grey-background;

  @media screen and (max-width: $screen-1024) {
    background-position: inherit;
  }

  .qz-player {
    &.confirmation {
      margin: 0;
    }
  }
}

.qz-player-confirmation {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  overflow: auto;
  padding: 8px 8px 48px 8px;

  .player-confirmation-panel {
    height: 100%;
    width: 100%;

    &.open-left-panal {
      display: grid;
      grid-template-columns: 368px auto;
      .right-penal-container {
        .resource-content {
          width: 100%;
          &.resource-back-drop {
            justify-content: flex-start;
            margin-left: 10px;
          }
        }
      }
    }

    .left-panel-container {
      overflow: hidden;
      align-items: flex-end;
      width: 368px;
      height: 100%;
      display: flex !important;
      transition: all 2s;

      .header-panel {
        width: 100%;
      }

      &.show {
        display: flex !important;

        .gru-collection-summary {
          height: 100%;
          width: 100%;
        }
      }
    }

    .right-penal-container {
      width: 100%;
      height: 100%;
      position: relative;

      .resource-content {
        width: 100%;
        &.resource-back-drop {
          position: fixed;
          overflow: auto;
          height: auto;
          display: grid;
          justify-content: center;
          align-items: flex-end;
          column-gap: 7px;
          bottom: 35px;
        }
      }
    }
  }

  &.show-suggestion {
    background-color: $dark-400;
    z-index: 888;
    opacity: 0.4;
    pointer-events: none;
  }
  .confirmation-panel {
    background-color: $white;
    width: 33%;
    border: 1px solid $gray-border;
    max-height: calc(100vh - 100px);
    overflow-y: auto;
    margin-left: 7%;
    &.suggested-content {
      box-shadow: 0 2px 13px 6px $brown;
    }
    &::-webkit-scrollbar {
      height: 6px;
      width: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background: $gray-light;
      border: 1px solid $gray-light;
      border-radius: 5px;
    }
    .confirmation-container {
      display: grid;
      grid-template-rows: 150px auto;
      color: $white;
      .header-panel {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        &:after {
          display: block;
          z-index: 9;
          margin-top: -100px;
          height: 100%;
          width: 100%;
          content: "";
          background-image: linear-gradient(
            to bottom,
            $bg-left-box-shadow 0,
            $white 100%
          );
        }
        .header-content {
          display: grid;
          grid-template-rows: auto 25px;
          height: 100%;
          padding: 10px;
          .content-details {
            display: grid;
            grid-template-columns: auto 70px;
            height: 100%;
            position: relative;
            .title {
              font-size: 18px;
              align-items: flex-end;
              display: flex;
              font-weight: 600;
              line-height: 1.2;
            }
            .suggestion-badge {
              text-align: right;
              .suggestion-icon {
                i {
                  font-size: 50px;
                  background-color: $icon-bg;
                  border-radius: 50%;
                  color: $orange-400;
                  border: 1px solid $icon-border-color;
                }
                .teacher_suggestions {
                  background-image: url("gooru/images/account-cirlce-orange.svg");
                  width: 50px;
                  height: 50px;
                  background-size: 50px;
                  color: $orange-400;
                  display: block;
                  border-radius: 50%;
                  background-color: $dark;
                  margin: auto;
                }
              }
            }
          }
          .author-name {
            font-size: 12px;
            font-style: italic;
          }
        }
      }
      .body-panel {
        height: 100%;
        background-color: $white;
        .body-content {
          display: grid;
          grid-template-rows: auto;
          .description-panel {
            display: grid;
            grid-template-rows: auto auto auto;
            .description {
              padding: 10px;
              border-bottom: 1px solid $breadcrumb-color;
              p {
                color: $violet-a2;
                margin-bottom: 0px;
                $font-size: 13px;
                $line-height: 1.2;
                $lines-to-show: 3;
                @include ellipsis(
                  $font-size: $font-size,
                  $line-height: $line-height,
                  $lines-to-show: $lines-to-show
                );
              }
            }
            .details {
              display: grid;
              grid-template-columns: auto 60px 50px 100px;
              padding: 5px 10px;
              &.no-collection {
                grid-template-columns: 100px auto 100px;
                .start-button {
                  grid-column-start: 3;
                }
              }
              .taxonomy {
                align-items: center;
                margin-top: 1px;
                display: flex;
                .gru-taxonomy-tag-list {
                  .gru-taxonomy-tag {
                    background-color: $charcoal-grey;
                    padding: 5px;
                    .standard-tag {
                      width: 85px;
                    }
                    .tag-label {
                      color: $white;
                      font-size: 12px;
                      font-weight: bold;
                    }
                    .tag-caption {
                      color: $pre-bg;
                    }
                  }
                }
                .no-competency {
                  background-color: $charcoal-grey;
                  border: 1px solid $gray-light;
                  padding: 0 3px 2px;
                  border-radius: 4px;
                  width: 75px;
                  height: 45px;
                  text-align: center;
                  font-size: 14px;
                  display: flex;
                  align-items: center;
                  color: $white;
                  justify-content: center;
                  cursor: pointer;
                }
              }
              .icon {
                height: 22px;
                width: 22px;
                display: block;
                background-size: cover;
                background-repeat: no-repeat;
                margin-right: 5px;
              }
              .question,
              .resource {
                display: flex;
                align-items: center;
                justify-content: center;
                span {
                  color: $bg-progress-pointer;
                  font-size: 14px;
                }
              }
              .question .icon {
                background-image: url("gooru/images/add_question.svg");
              }
              .resource .icon {
                background-image: url("gooru/images/add_resource_icon.svg");
              }
              .start-button {
                display: flex;
                justify-content: flex-end;
                align-items: center;

                .start {
                  background-color: $green-51;
                  padding: 7px 5px;
                  text-align: center;
                  font-size: 14px;
                  width: 100%;
                  float: right;
                  text-transform: uppercase;
                  font-weight: 500;
                  cursor: pointer;
                  line-height: 32px;
                  border-radius: 3px;
                }
              }
            }
            .empty-collection-assessment {
              display: grid;
              justify-content: center;
              text-align: center;
              padding: 10px;
              color: $dark-300;
            }
            .properties {
              display: flex;
              .attempts {
                .no-more-attempts {
                  display: flex;
                  text-align: left;
                  .no-more {
                    margin-left: 10px;
                  }
                }
                .attempt {
                  font-size: 25px;
                  color: $attempt-color;
                }
              }
              .answers,
              .attempts,
              .navigation {
                font-size: 10px;
                line-height: 1.5;
                text-align: center;
                width: 100%;
                padding: 5px;
                i {
                  font-size: 35px;
                  color: $attempt-color;
                }
                .info {
                  color: $info-color;
                }
              }
            }
            @media screen and (max-width: $screen-380) {
              grid-template-rows: auto auto auto;
              .details {
                grid-auto-rows: 50px;
                grid-template-columns: auto 60px 50px;
                .start-button {
                  grid-row-start: 2;
                  display: grid;
                  grid-column-start: 1;
                  grid-column-end: 4;
                }
              }
            }
          }

          .content-panel {
            background-color: $gray-f9;
            padding-top: 5px;
            li {
              list-style: none;
              border-bottom: 1px solid $gray-fa;
            }
            .content-title {
              display: grid;
              grid-template-columns: 50px auto 50px;
              span {
                padding: 5px;
                font-size: 20px;
                font-weight: bold;
                background-color: $gray-f6;
                color: $blue-c8;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              .title {
                font-size: 15px;
                padding: 5px 10px;
                align-items: center;
                font-weight: 500;
                display: flex;
                color: $blue-af;
                background-color: $white;
                line-height: 1.2;
              }
              .image {
                background-color: $white;
                display: flex;
                i {
                  width: 25px;
                  height: 23px;
                  background-size: cover;
                  background-repeat: no-repeat;
                  display: block;
                  margin: auto;
                  opacity: 0.7;
                  &.resource.interactive_resource {
                    background-image: url("gooru/images/interactive-resource-dark.svg");
                  }
                  &.resource.video_resource {
                    background-image: url("gooru/images/video-resource-dark.svg");
                  }
                  &.resource.webpage_resource {
                    height: 25px;
                    background-image: url("gooru/images/website-resource-dark.svg");
                  }
                  &.resource.image_resource {
                    background-image: url("gooru/images/image-resource-dark.svg");
                  }
                  &.resource.audio_resource {
                    background-image: url("gooru/images/audio-resource-dark.svg");
                  }
                  &.resource.text_resource {
                    height: 25px;
                    background-image: url("gooru/images/text-resource-dark.svg");
                  }
                  &.question {
                    height: 25px;
                    background-image: url("gooru/images/add_question.svg");
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: $screen-1024) and (min-width: $screen-sm) {
    .confirmation-panel {
      width: 75%;
      margin-left: 0px;
    }
  }
  @media screen and (max-width: $screen-sm - 1) {
    .confirmation-panel {
      width: 100%;
      margin-left: 0px;
    }
  }
}
