.gru-sorting {
  .gru-sorting-container {
    .sorting-panel {
      .panel-section {
        border: 1px solid $dark-300;
        border-radius: 3px;
        .panel-content-header {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          align-items: center;
          border-bottom: 1px solid $dark-300;

          span {
            font-size: 18px;
            font-style: italic;
            text-align: center;
            padding: 5px 10px;
            color: $dark-200;

            input {
              text-align: center;
            }

            &:first-child {
              border-right: 1px dashed $light-200;
            }
            &:last-child {
              border-left: 1px dashed $light-200;
            }
          }
        }
        .panel-body-section {
          display: grid;
          grid-template-columns: repeat(2, 1fr);

          .list-item-panel {
            .input-field-section {
              display: grid;
              justify-content: center;
              input {
                padding: 5px;
                text-align: center;
                box-shadow: none;
                outline: none;
                border-bottom: 1px solid $light-200;

                &::-webkit-input-placeholder,
                &::-moz-placeholder,
                &:-ms-input-placeholder,
                &:-moz-placeholder {
                  opacity: 0.5;
                  line-height: 1.3;
                  font-size: 13px;
                }
              }
              .add-item {
                cursor: pointer;
                padding: 4px;
                font-weight: bold;
                border: 1px dashed $dark-300;
                font-size: 20px;
                width: 100px;
                margin: 10px auto;
                text-align: center;
              }
            }
          }

          ul {
            padding: 0;
            margin: 0;

            &.list-item-right {
              border-left: 1px dashed $light-200;
            }
            li {
              list-style: none;
              margin: 5px 8px;
              font-size: 15px;
              text-align: center;
              background-color: $light-100;
              display: grid;
              grid-template-columns: 1fr auto;
              align-items: center;

              span {
                font-size: 17px;
                padding: 5px 10px;
                cursor: pointer;
              }

              input {
                text-align: center;
                box-shadow: none;
                outline: none;
                border-bottom: 1px solid $light-200;
              }
            }
          }
        }
      }
    }
  }
}
