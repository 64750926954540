.gru-learner-accordion-unit {
  padding: 10px 0 0;
  border-bottom: 1px solid $light-100;
  list-style-type: none;

  .unit {
    display: flex;
    justify-content: space-between;
    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }

    > .panel-title {
      font-size: $font-size-large;
      color: $gray;
      height: 43px;
      width: 90%;
      float: $tab-float-dir;

      > a {
        width: 90%;
        vertical-align: middle;
        height: 43px;

        .title {
          height: inherit;

          .prefix {
            border-right: $border-right-side $light-300;
            border-left: $border-left-side $light-300;
            margin: 0 10px;
            padding: 5px 10px 0;
            color: $gray-base;
            font-weight: bold;
            height: inherit;
            display: inline-block;
          }
        }
      }
      .unitPlan {
        padding-left: 89px;
        font-size: 13px;
        margin-bottom: 5px;
        .guiding-lable {
          margin-top: 10px;
        }
      }
    }

    + .collapse {
      &.in {
        background-color: $light-300;
        padding: 13px 15px;
      }

      > .panel-body {
        padding: 0;
        border-radius: 4px;

        .panel-group {
          padding: 0;
        }
      }
    }
    .class-info {
      margin: 9px 26px 0px 0px;

      &.disable-tag {
        pointer-events: none;
        a {
          color: $light-400;
          opacity: 0.5;
        }
      }
      @media screen and (max-width: $screen-sm) {
        display: none;
      }
    }

    .performance-container {
      width: 81px;
      display: inline-block;
      margin-top: -11px;
      margin-left: 25px;
      vertical-align: middle;
      color: $white;
      text-align: center;
      height: 100%;
      min-width: 76px;
      cursor: pointer;
      &.not-started {
        pointer-events: none;
      }

      .container-box {
        height: 63px;
        border-bottom: 1px solid white;

        .percentage {
          font-size: $font-size-lead;
          font-weight: 900;
          line-height: 24px;
          margin: 0px 0 0;
          cursor: pointer;

          .not-started {
            font-size: 10px;
            color: $black-bold;
            line-height: 15px;
            margin-top: 8px;
          }
        }

        .not-started {
          color: $light-400;
          font-size: $font-size-xxsmall;
          font-weight: bold;
          margin: 3px 0 5px;
        }

        .completion-bar {
          border-radius: 5px;
          height: 5px;
          background-color: $white;
          margin: 0 auto;
          min-width: 35px;
          width: 32%;

          .segment {
            height: 5px;

            &:first-child {
              border-bottom-left-radius: 4px;
              border-top-left-radius: 4px;
            }

            &.full {
              max-width: 100%;

              &:last-child {
                border-bottom-right-radius: 5px;
                border-top-right-radius: 5px;
              }
            }

            &.disable-performance {
              background-color: $white !important;
            }
          }
        }
        .timespend {
          border-left: 1px solid $light-100;
          display: inline-block;
          height: 67px;
          width: 88px;
          margin: 0;
          vertical-align: middle;
          padding: 10px 5px 0;
          text-align: center;
          font-weight: 900;
          float: $menu-float-dir;
          line-height: normal;
          font-size: larger;
          color: $dark-300;

          .total-time-spent {
            font-size: 10px;
          }
        }
      }
    }
  }

  &:last-child {
    border: 0;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;

    .unit {
      + .collapse {
        &.in {
          border-bottom-right-radius: 4px;
          border-bottom-left-radius: 4px;
        }
      }
    }
  }

  .item-disabled {
    .content-count,
    .icon-container,
    .panel-title,
    .score,
    .state {
      opacity: 0.5;
    }

    .panel-title {
      .title {
        cursor: default;
      }
    }

    .icon-container {
      .score {
        button {
          cursor: default;
        }
      }
    }
  }
}
