.gru-assignments-list {
  @include flexbox;
  @include flex-direction(column);
  height: 100%;
  .out-of-scope {
    display: none;
  }
  .header {
    margin-right: 0;
    .search {
      .search-navigation {
        margin: 10px 32px;
        input {
          outline: none;
          width: 315px;
          padding-left: 5px;
        }
        .search-icon {
          position: absolute;
          left: 17px;
        }
      }
    }
    .views {
      button {
        margin-top: 10px;

        &.standards-btn {
          padding: 7px 16px;
        }
      }
      .options {
        @media (min-width: $screen-sm) {
          float: $menu-float-dir;
        }
        .btn-group {
          i {
            font-size: 24px;
          }
        }
      }
    }
  }
  .assignments-list-container {
    @include flexbox;
    height: 100%;
    overflow: hidden;
    .assignments-list {
      padding-right: 0px;
      width: 100%;
      height: 100%;
    }
    .assignments-info {
      margin-left: 10px;
      width: 70%;
      height: 100%;
      @media (min-width: $screen-md-min) {
        width: 40%;
      }
    }
  }
}
