.teacher-ca-unscheduled-items-pullup {
  position: fixed;
  top: calc(100% - 35px);
  z-index: 1030;
  width: 960px;
  left: 0;
  right: 0;
  margin: auto;
  @media screen and (max-width: $screen-md) {
    width: 100%;
  }

  .backdrop {
    background-color: $backdrop-color;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    cursor: pointer;
    opacity: 0.8;
    background-color: $gray-base;
    display: none;
  }

  &.active {
    .backdrop {
      display: block;
    }

    .ca-unscheduled-items-container {
      .ca-unscheduled-items-header {
        box-shadow: 0 2px 4px 0 $date-picker-box-shadow;

        .unscheduled-items {
          .unscheduled-items-month {
            visibility: visible;
          }
        }

        .arrow_drop_down {
          display: inline-block;
        }

        .arrow_drop_up {
          display: none;
        }
      }
    }
  }

  .backdrop-pull-ups {
    .backdrop {
      background: none !important;
    }
  }

  .loading-spinner {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
  }

  .ca-unscheduled-items-container {
    background: $white-bold;
    width: 960px;
    max-height: 90vh;
    height: 90vh;
    @media screen and (max-width: $screen-md) {
      width: 100%;
    }

    .ca-unscheduled-items-header {
      height: 35px;
      display: grid;
      grid-template-columns: 1fr 50px;
      box-shadow: 0 2px 60px 0 $date-picker-box-shadow;
      cursor: pointer;
      align-items: center;

      .unscheduled-items {
        padding-left: 20px;
        font-weight: bold;

        .unscheduled-items-month {
          visibility: hidden;
        }
      }

      .arrow_drop_up {
        display: inline-block;
      }

      .arrow_drop_down {
        display: none;
      }
    }

    .ca-unscheduled-content-items {
      background-color: #f1f3f7;
      max-height: calc(90vh - 35px);
      height: calc(90vh - 35px);
      overflow-y: auto;
      padding: 20px;
    }
  }

  .schedule-ca-datepicker-container {
    background: $white;
    position: absolute;
    z-index: 10;
    width: inherit;
    box-shadow: 0 13px 16px 0 $date-picker-box-shadow;
    top: 50px;
    width: 250px;
    display: none;

    #schedule-ca-datepicker {
      .datepicker {
        width: 100%;

        .table-condensed {
          width: 100%;

          thead tr:first-child {
            display: none;
          }
        }
      }
    }
  }
}
