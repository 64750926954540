.player-resource {
  .gru-header {
    display: none;
  }
  .app-container {
    margin: 0;
    padding: 0;
    height: 100vh;
  }
}

.resource-player-container {
  position: relative;
  width: 100%;
  height: 100%;

  .header-container {
    position: fixed;
    top: 0;
    color: $white;
    height: 45px;
    background-color: $white;
    width: 100%;
    padding: 1px 4px 12px 12px;
    border-bottom: 1px solid $link-water;
    border-radius: 0px;
    z-index: 1000;

    .close-player {
      // width: 50px;
      cursor: pointer;
      height: 100%;
      .nav-icon-container {
        height: 100%;

        i {
          font-size: 22px;
          margin-left: 2px;
          margin-top: 10px;
          color: $gray-4d5557;
        }
      }
    }

    .isDeepLink-content-title {
      width: 100%;
    }

    .default-content-title {
      width: calc(100% - 25px);
    }

    .content-title {
      height: 100%;
      padding: 0px 10px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      color: $black-333333;

      .title-info {
        display: flex;
        justify-content: space-between;

        .title-left {
          color: $dark-950;
          font-family: "Roboto";
          font-weight: 500;
          line-height: 26px;
          padding-block: 8px;
          size: 18px;
        }

        .title-right {
          display: flex;
          height: 32px;
          margin-top: 5px;
        }

        .icon {
          background-size: 20px 25px !important;
          display: none;
        }
        .title {
          text-align: center;
          font-family: "Roboto";
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 26px;
          color: $black-333333;
          height: 100%;
          padding: 8px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }

      &.image_resource {
        .icon {
          background: url("gooru/images/image-resource.svg") no-repeat;
        }
      }
      &.video_resource {
        .icon {
          background: url("gooru/images/video-resource.svg") no-repeat;
        }
      }
      &.text_resource {
        .icon {
          background: url("gooru/images/text-resource.svg") no-repeat;
        }
      }
      &.audio_resource {
        .icon {
          background: url("gooru/images/audio-resource.svg") no-repeat;
        }
      }
      &.html_resource {
        .icon {
          background: url("gooru/images/html-icon.png") no-repeat;
        }
      }
      &.webpage_resource {
        .icon {
          background: url("gooru/images/website-resource.svg") no-repeat;
        }
      }
      &.interactive_resource {
        .icon {
          background: url("gooru/images/interactive-resource.svg") no-repeat;
        }
      }
      &.h5p_interactive_video {
        .icon {
          background: url("gooru/images/interactive-video.png") no-repeat;
        }
      }
      &.h5p_interactive_slide {
        .icon {
          background: url("gooru/images/interactive-slides.png") no-repeat;
        }
      }
      &.h5p_interactive_personality_quiz {
        .icon {
          background: url("gooru/images/personality-quiz-icon.png") no-repeat;
        }
      }
      &.h5p_drag_and_drop_resource {
        .icon {
          background: url("gooru/images/drag-and-drop-icon.png") no-repeat;
        }
      }
    }
  }

  .mycontentclass {
    padding: 50px 50px 10px;
  }

  .body-container {
    width: 100%;
    height: 100%;
    background-image: url("/assets/gooru/images/Pattern.svg");

    .more-btn {
      margin-top: 20px;
      a {
        font-family: Roboto;
        font-size: 14px;
        font-weight: 500;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: left;
        color: $blue-btn-txt;
      }
      display: flex;
      padding: 4px 8px 4px 8px;
      border-radius: 4px;
      gap: 4px;
      border: 1px solid $btn-border-grey;
      background: linear-gradient(0deg, #ffffff, #ffffff),
        linear-gradient(0deg, #dfe2e2, #dfe2e2);
    }

    .more-btn-icon {
      width: 20px;
      height: 20px;
      padding: 2.5px;
    }
  }

  .resource-tab-head {
    background-color: $white;

    top: 45px;
    position: fixed;
    width: 100%;

    .resource-blk {
      padding: 0;
      display: flex;
      align-items: center;
      list-style: none;
      border-bottom: 3px solid $ps-white-e2;

      margin-left: 15%;
      margin-right: 15%;
      margin-bottom: 0;

      #about-tab {
        width: 45px;
      }

      #preview-tab {
        width: 57px;
      }

      li {
        padding: 10px;
        cursor: pointer;
        border-bottom: 2px solid transparent;
        color: $dark-night;
        text-align: left;
        padding-left: 0px;
        margin-right: 32px;

        a {
          text-decoration: none;
          border: none;
          outline: none;
          background-color: transparent;
          display: block;
        }

        span {
          display: block;
          text-shadow: 0 0 1px $light-300;
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
        }

        &.active {
          color: $blue-active-bg;
          border-bottom: 3px solid $blue-active-bg;
          border-radius: 3px;
          margin-bottom: -4px;
        }
      }
    }
  }

  .mycontentbody {
    height: 100%;
    display: block;
    padding: 20px;
    border-radius: 8px;
    background: var(--text-color-white, $white);
  }

  .aboutpage {
    width: 80%;
    margin-left: 10%;
  }

  .previewpage {
    width: 100%;
  }

  .body {
    #information {
      padding: 0 10px 0 10px;
      overflow-y: scroll;
    }

    .tab-pane {
      width: 100%;
      height: 100%;

      .resource-description {
        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: $dark-night;
      }

      .abouturl-btn {
        font-weight: 400;
        font-size: 25px;
      }
    }
  }

  .footer-container {
    position: fixed;
    bottom: 0;
    height: 40px;
    background-color: $white;
    box-shadow: 0 -1px 4px 0 $navbar-default-toggle-icon-bar-bg;
    width: 100%;
    padding: 8px;

    .close-btn {
      float: right;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 88px;
      height: 25px;
      border-radius: 20px;
      background-color: $green-700;
      color: $white;
      cursor: pointer;
    }
  }
}
