#signmodal {
  .modal-dialog {
    width: fit-content;
  }
}

.controller.sign-up {
  display: block;

  .modal {
    box-shadow: 0 5px 15px 0 $navbar-default-toggle-icon-bar-bg;
    border-radius: 5px;
    background-color: $white;
    width: 100%;
    margin: 25px auto;
    padding: 0;
    float: none;
    max-width: 400px;
    position: relative;
    display: block;
    z-index: 0;

    .modal-dialog {
      width: 100%;
      margin: 0;
      height: auto;

      .modal-content {
        border: 0;
      }

      .modal-header {
        text-align: center;
        padding: 5px 0 0;

        .back {
          display: grid;
          grid-template-columns: auto;
          padding-top: 24px;
          width: 100%;

          .title {
            display: flex;
            flex-direction: column;
            text-align: center;
            font-size: 20px;
            color: $dark-500;
            line-height: 1.4;

            span {
              font-size: 12px;
              color: $gray-dark;

              span {
                font-weight: bold;
                color: $font-green-35-dark-400;
                text-transform: capitalize;
              }
            }
          }
        }

        .img-container {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          .gooru-logo {
            background: url("gooru/images/gooru-logo.svg");
            height: 32px;
            padding: 0;
            width: 111px;
            transform: scale(0.6, 0.6);
            justify-self: center;
          }

          .tenant-logo {
            height: 112px;
            padding: 0;
            justify-self: center;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            margin: 12px 0;
            max-width: 220px;
            width: 100%;

            &-img {
              width: 70px;
              height: 70px;
            }

            span {
              width: 112px;
              height: 112px;
              border-radius: 50%;
              background-color: $orange-aob;
              display: flex;
              align-items: center;
              justify-content: center;
              color: $white;
              font-size: 30px;
              font-weight: bold;
              text-transform: uppercase;
              margin: auto;
            }
          }

          .gooru {
            width: 112px;
            background: url("gooru/images/NavMath_Logo.png");
            background-size: contain;
            background-repeat: no-repeat;
          }
        }

        .progress-dots {
          margin: 0 auto;

          .dot {
            width: 10px;
            height: 10px;
            background: $light-400;
            border-radius: 50px;
            margin: 0 5px 0 0;
            display: inline-block;

            &.color {
              background: $green-400;
            }
          }
        }

        .step-title {
          font-size: 38px;
          font-weight: 700;
        }

        h1 {
          font-size: 25px;
          font-weight: 700;
          margin: 15px 0;
        }

        .description {
          font-size: 16px;
          font-weight: 300;
          color: $font-gray-oslo-dark-400;
        }

        .sign-in-description {
          font-size: 16px;
          color: $font-gray-oslo-dark-400;
        }

        .google-button {
          height: 50px;
          width: 230px;
          box-shadow: 0 2px 4px 0 $left-arrow-box-shadow;
          border-radius: 1px;
          text-align: center;
          position: relative;
          cursor: pointer;
          background-color: $bg-blue-300-blue-500;
          margin: 20px auto;

          &:active {
            background-color: $bg-blue-300-blue-500;
          }

          &:hover {
            box-shadow: 0 0 3px 3px $hover-box-shadow;
          }

          .google-button-content-wrapper {
            border: 1px solid $table-bg;
            width: 100%;
            height: 100%;

            .google-button-icon {
              float: $tab-float-dir;
              background-color: $white;
              border-radius: 1px;
              padding: 15px;

              .google-button-icon-image {
                width: 18px;
                height: 18px;
                display: block;
                background: url("gooru/images/google-icon.svg");
              }
            }

            .google-button-content {
              line-height: 48px;
              font-size: $font-size-h6;
              font-weight: 500;
              letter-spacing: 0.21px;
              margin-left: 6px;
              margin-right: 6px;
              vertical-align: top;
              color: $white;
            }
          }
        }
      }

      .divider {
        border-bottom: 2px solid $light-200;
        margin: 5px 0 0;
        width: 100%;
      }

      .modal-body {
        padding: 0;

        .sign-up-finish-form,
        .sign-up-form {
          .form-right {
            padding: 0 55px;
          }

          .signup-consent {
            padding: 0;
          }

          .form-left:after {
            content: "";
            background-color: $gray-ultra-light;
            position: absolute;
            height: 90%;
            left: 100%;
            display: block;
          }
          background-color: $white;
          padding-left: 20px;
          padding-right: 20px;

          form {
            padding-top: 1px;
          }

          .firstName {
            .error {
              margin-left: 0;
            }
          }

          .form-group {
            margin: 2px 0 0;

            label {
              width: 100%;
              color: $font-gray-oslo-dark-400;
              font-size: 16px;
            }

            &.lastName {
              @media (max-width: $screen-sm - 1) {
                label {
                  padding-left: 20px;
                }
              }
            }

            .validation.error {
              position: relative;
              top: -15px;
            }

            span.required {
              display: block;

              &:after {
                content: " *";
              }
            }

            &.birth-day-date {
              margin-bottom: 30px;
              display: block;
            }

            a.btn-link {
              padding: 0;
              text-transform: none;
              color: $blue-200;
              font-size: $font-size-base;
            }

            input {
              &:focus {
                border-color: $blue-200;
              }
            }

            .birth-months {
              float: $tab-float-dir;
              margin-right: 11%;
              width: 25%;
              margin-top: 8px;

              span {
                font-size: 14px;
                line-height: 31px;
              }

              .bootstrap-select {
                width: 105px;
              }
            }

            .birth-days {
              float: $tab-float-dir;
              margin-right: 11%;
              width: 25%;
              margin-top: 8px;

              span {
                font-size: 14px;
                line-height: 31px;
              }

              .bootstrap-select {
                width: 105px;
              }
            }

            .birth-years {
              float: $tab-float-dir;
              width: 25%;
              margin-top: 8px;

              span {
                font-size: 14px;
                line-height: 31px;
              }

              .bootstrap-select {
                width: 105px;
              }
            }

            &.sign-up-button {
              margin: 0 -20px;

              button {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                width: 100%;
                font-size: 16px;
                font-weight: 600;
                position: relative;
                text-transform: uppercase;

                i {
                  position: absolute;
                  font-size: 28px;
                  line-height: 33px;
                }
              }
            }

            &.disable-event {
              opacity: 0.5;
            }

            &.sign-up-finish-btn {
              margin-top: 10%;
            }

            span.error {
              color: $red-400;
              display: inline-block;
              font-size: 12px;
              margin-top: 4px;
            }

            &.role {
              margin: 10px 0 20px;

              &.tenant-signup {
                .error {
                  color: $red-400;
                  display: inline-block;
                  font-size: 12px;
                  margin-top: 4px;
                }
              }

              span {
                font-size: 16px;
                color: $font-gray-oslo-dark-400;
              }

              .roles {
                @include flexbox;

                .gru-radio {
                  input[type="radio"] {
                    margin-top: 10px;
                    transform: scale(0.8);
                  }
                  margin-right: 20px;
                  @media (min-width: $screen-sm-min) {
                    margin-right: 30px;
                  }
                }

                & > :last-child {
                  margin-right: 0;
                }
              }
            }

            &.personal-info {
              padding: 0;

              label {
                display: flex;
                line-height: 22px;

                span {
                  margin-right: 10px;
                  color: $gray;
                  display: flex;
                  align-items: center;

                  &.user-checked {
                    background: url("/assets/gooru/images/check-circle-0.svg");
                    border: none;
                    background-position: center;
                    background-repeat: no-repeat;
                    cursor: pointer;
                    filter: brightness(0.4);

                    i {
                      visibility: hidden;
                    }
                  }

                  .user-content {
                    height: max-content;
                    cursor: pointer;
                    margin-bottom: 4px;
                  }
                }
              }
            }

            .dropdown-menu {
              max-height: 400px;
              overflow-y: auto;
            }
          }
        }

        .sign-up-finish-form {
          .bootstrap-select {
            width: 100%;

            &.btn-group {
              .dropdown-menu.inner {
                overflow-y: hidden;
                max-height: 150px !important;
              }
            }
          }
        }

        .gru-radio {
          input[type="radio"]:checked {
            &:before {
              background-color: $green-400;
            }

            &:after {
              border-color: $green-400;
            }
          }
        }
      }
    }

    .child-layout {
      .modal-header {
        h3 {
          margin: 50px 0 5px;
        }
      }

      .modal-body {
        margin: 20px 0 0;

        .form-group {
          &.username {
            margin: 5px 0 15px;

            input {
              border-bottom: 2px solid $green-400;

              &:focus {
                border-color: $green-400;
              }
            }
          }

          &.birth-day-date {
            input {
              border-bottom: 2px solid $red-400;
              margin: 0 5% 0 0;

              &:focus {
                border-color: $red-400;
              }

              &.months {
                width: 40%;
              }

              &.days {
                width: 20%;
              }

              &.years {
                width: 27%;
                margin: 0;
              }
            }
          }
        }

        .footer-description {
          line-height: $font-size-base;
        }
      }
    }
  }

  &.finish {
    .modal {
      box-shadow: 0 11px 15px $navbar-default-toggle-icon-bar-bg;

      .modal-content {
        box-shadow: none;
      }
      overflow: visible;
      margin: 15px auto auto;
      max-width: 552px;

      .sign-up-finish-form {
        box-shadow: 0 11px 15px $navbar-default-toggle-icon-bar-bg;
      }

      .signUpForm {
        height: 100%;
        width: 90%;
        margin: auto;
        @media (min-width: $screen-md) {
          width: 69%;
          margin: auto;
        }
      }
    }
  }
}

.back-drop {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0.8;
  background-color: $gray-base;
}

.guardian-request {
  width: 360px;
  height: 256px;
  border-radius: 8px;
  box-shadow: 0 -2px 4px 2px $box-shadow-bg-color;
  background-color: $white-bold;
  z-index: 1031;
  left: 0;
  right: 0;
  color: $suggestion-panel-color;
  bottom: 0;
  top: 10px;
  overflow: auto;

  .title {
    margin: 0 40px 15px;
    font-size: 20px;
    font-weight: 200;
    text-align: center;
    color: $dark-400;
    padding-top: 10px;
  }

  .para-one {
    margin: 22px 40px;
  }

  .para-two {
    margin-top: 15px;

    a {
      color: $sky-400 !important;
    }
  }

  .para-one,
  .para-two {
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    color: $dark-400;
    line-height: 1.14;
  }

  .next-btn {
    display: grid;
    grid-template-columns: 40% 60%;
    margin: 35px 20px 0;

    .maybe-later {
      width: 119px;
      height: 40px;
      border: solid 1px $nobel-gray;
      background-color: $white-bold;
      outline: none;
    }

    .link-accounts {
      width: 190px;
      height: 40px;
      border: solid 1px $green-700;
      color: $white-bold;
      background: $green-700;
      outline: none;
    }
  }
}

.acknowledgements {
  width: 360px;
  height: 215px;
  padding: 16px 31px 16px 34px;
  border-radius: 8px;
  box-shadow: 0 -2px 4px 2px $box-shadow-bg-color;
  background-color: $white-bold;
  margin: 100px auto auto !important;
  z-index: 1031;
  left: 0;
  right: 0;
  margin: auto;
  color: $suggestion-panel-color;
  position: fixed;
  bottom: 0;
  top: 10px;
  overflow: auto;

  .title {
    margin: 0 40px 15px;
    font-size: 20px;
    font-weight: 200;
    text-align: center;
    color: $dark-400;
  }

  .para-two {
    a {
      color: $sky-400 !important;
    }
  }

  .para-one,
  .para-two {
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    color: $dark-400;
    line-height: 1.14;
  }

  .next-btn {
    button {
      width: 119px;
      height: 40px;
      margin: 28px 89px 0 87px;
      border: solid 1px $nobel-gray;
      background-color: $white-bold;
      outline: none;
    }
  }
}

.identify-your-account {
  width: 360px;
  height: 256px;
  border-radius: 8px;
  box-shadow: 0 -2px 4px 2px $box-shadow-bg-color;
  background-color: $white-bold;
  margin: 16px auto auto;
  z-index: 1031;
  left: 0;
  right: 0;
  margin: auto;
  color: $suggestion-panel-color;
  position: fixed;
  bottom: 0;
  top: 10px;

  .identify-my-account {
    display: grid;
    grid-template-columns: 48px 1fr;
    height: 56px;

    .clear {
      margin: auto;
      cursor: pointer;
    }

    .details {
      color: $dark-400;
      margin: auto auto auto 10px;

      .email {
        height: 15px;
        font-size: 11px;
        padding-top: 2px;
        font-weight: bold;
      }

      .name {
        font-size: 14px;
      }
    }
  }

  .msg {
    margin: 8px 75px 8px 77px;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    line-height: 1.14;
  }

  .search-for {
    color: $blue-400;
    font-size: 11px;
    margin: 0;
    padding: 0 18px;

    label {
      margin: 0;
    }

    .search-content {
      grid-template-columns: 20fr 1fr;
      align-items: center;
      display: grid;
      border: 0;
      border-bottom: solid 1px $gray-9c;
      background: $white;

      input {
        width: 100%;
        border: none;
        font-size: 14px;
        padding: 0 8px 8px;
        outline: none;
        box-shadow: none;
        color: $dark-400;
      }

      i {
        color: $black-bold;
        cursor: pointer;
      }
    }
  }

  .next-btn {
    button {
      width: 198px;
      height: 40px;
      margin: 40px 81px 0;
      padding: 10px 8px;
      border: solid 1px $nobel-gray;
      background-color: $white-bold;
      font-size: 14px;
      line-height: 1.43;
      text-align: center;
      color: $dark-400;
      outline: none;

      &.disable-button {
        pointer-events: none;
        opacity: 0.5;
      }
    }
  }

  .loading {
    width: 198px;
    height: 40px;
    margin: 10px 81px 0;
    padding: 10px 8px;
    line-height: 1.43;
    text-align: center;
  }
}

.search-results {
  width: 360px;
  height: 70%;
  border-radius: 4px;
  box-shadow: 0 2px 4px 2px $box-shadow-bg-color;
  background-color: $white-bold;
  margin: 16px auto auto;
  z-index: 1031;
  left: 0;
  right: 0;
  margin: auto;
  color: $suggestion-panel-color;
  position: fixed;
  bottom: 0;
  top: 10px;
  overflow: unset;

  .back {
    display: grid;
    grid-template-columns: 48px 1fr;
    height: 56px;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: $white;

    .clear {
      margin: auto;
      cursor: pointer;
    }

    .details {
      color: $dark-400;
      margin: auto auto auto 10px;

      .email {
        height: 15px;
        font-size: 11px;
        padding-top: 2px;
        font-weight: bold;
      }

      .name {
        font-size: 14px;
      }
    }
  }

  .next-btn {
    display: grid;
    grid-template-columns: 40% 60%;
    padding: 16px 15px 24px;
    position: sticky;
    bottom: 0;
    z-index: 1;
    background-color: $white;

    .try-again {
      width: 88px;
      height: 40px;
      border: solid 1px $nobel-gray;
      background-color: $white-bold;
      outline: none;
    }

    .link-accounts {
      width: 164px;
      height: 40px;
      border: solid 1px $green-700;
      color: $white-bold;
      background: $green-700;
      margin-left: auto;
      outline: none;

      &.disable-events {
        pointer-events: none;
        opacity: $opacity-light;
      }
    }
  }

  .no-result,
  .title {
    opacity: $opacity-light;
    color: $dark-400;
    font-weight: 200;
    padding: 12px 16px 24px;
    font-size: 20px;
    text-align: center;
    line-height: 1.2;
  }

  .accounts-list {
    min-height: 70%;
    overflow: auto;
    height: 70%;

    .list-accounts {
      display: grid;
      grid-template-columns: 48px 1fr 1fr 48px;
      min-height: 50px;
      margin-bottom: 10px;

      &.visible {
        opacity: 1;
      }

      &.not-visible {
        opacity: 0.5;
      }

      .tenant-name {
        margin: auto 0;
        border-left: 1px solid $gray;
        padding-left: 10px;
      }

      .profile {
        img {
          width: 35px;
          height: 35px;
          overflow: hidden;
          border-radius: 50px;
          margin: 13px auto auto;
        }
      }

      .details {
        color: $dark-400;
        margin-left: 10px;

        .email {
          height: 15px;
          font-size: 11px;
          font-weight: bold;
        }

        .name {
          font-size: 11px;
          padding-top: 13px;
        }
      }

      .icons {
        margin: auto;

        .gru-icon-blue {
          background: url("/assets/gooru/images/tick-inside-circle-blue.svg")
            no-repeat center center;
          display: inline-block;
          height: 24px;
          width: 24px;
          vertical-align: text-bottom;
          background-size: 17px;
          cursor: pointer;
        }

        .gru-icon-circle {
          display: inline-block;
          height: 17px;
          width: 17px;
          border-radius: 50%;
          border: 2px solid $gray-3b3;
          cursor: pointer;
        }
      }
    }
  }
}

.personalize-settings {
  width: 360px;
  height: 408px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 2px $box-shadow-bg-color;
  background-color: $white-bold;
  margin: 16px auto auto;
  z-index: 1031;
  left: 0;
  right: 0;
  margin: auto;
  color: $suggestion-panel-color;
  position: fixed;
  bottom: 0;
  top: 10px;
  overflow: auto;

  .back {
    display: grid;
    grid-template-columns: 48px 1fr;
    height: 56px;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: $white;

    .clear {
      margin: auto;
      cursor: pointer;
    }

    .details {
      color: $dark-400;
      margin: auto auto auto 10px;

      .email {
        height: 15px;
        font-size: 11px;
        padding-top: 2px;
        font-weight: bold;
      }

      .name {
        font-size: 14px;
      }
    }
  }

  .next-btn {
    display: grid;
    grid-template-columns: 40% 60%;
    padding: 16px 15px 24px;
    position: sticky;
    bottom: 0;
    z-index: 1;
    background-color: $white;

    .later {
      width: 88px;
      height: 40px;
      border: solid 1px $nobel-gray;
      background-color: $white-bold;
      outline: none;
    }

    .link-accounts {
      width: 164px;
      height: 40px;
      border: solid 1px $green-700;
      color: $white-bold;
      background: $green-700;
      margin-left: auto;
      outline: none;

      &.disable-events {
        pointer-events: none;
        opacity: $opacity-light;
      }
    }
  }

  .title {
    color: $dark-400;
    padding: 12px 16px 24px;
    font-size: 14px;
    text-align: center;
    line-height: 1.2;
    margin: 8px 47px 8px 48px;
  }
}
