.cards.no-suggest-result-found {
  margin: auto;
  width: 230px;
  display: flex;

  .panel-body {
    background: $white;
    border-radius: 4px;
    box-shadow: 0 1px 4px $navbar-box-shadow;

    .no-suggest-result-content {
      .title {
        font-size: 15px;
        text-align: center;
      }

      .icon {
        text-align: center;
        padding: 10px;
        cursor: default;

        i {
          color: $gray-base;
          font-size: 86px;
          text-align: center;
        }
      }

      .suggest-message {
        text-align: center;
        width: 200px;
      }

      .message {
        text-align: justify;
        width: 200px;
      }
    }
  }
}
