.teacher_class_sel-dashboard {
  .teacher {
    &.class {
      height: 100%;
    }
  }

  .content {
    height: 100%;
  }
}

.unit-progress-container {
  display: grid;
  grid-template-columns: 30% 70%;
  background-color: $white;
  height: 100%;
  @media screen and (max-width: $screen-in-680) {
    padding: 47px 0;
    grid-template-columns: 100%;
  }

  .left-container {
    padding-bottom: 30px;
    background-color: $white;
    border-right: 1px solid $black-bold;
  }

  .right-container {
    height: 100%;
    overflow: auto;
    @media screen and (max-width: $screen-in-680) {
      overflow: unset;
    }

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px $navbar-box-shadow;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $gray-a9;
      outline: 1px solid slategrey;
    }
  }

  .header-container {
    display: grid;
    background-color: $white;

    .back-container {
      display: grid;
      grid-template-columns: auto 1fr auto;
      text-align: center;
      align-items: center;
      padding-bottom: 15px;

      .back {
        width: 50px;
        height: 50px;
        cursor: pointer;
        padding-top: 12px;
      }

      .title {
        text-align: center;
        color: $black-bold;
        font-size: 17px;
      }

      .backdrop {
        z-index: 10;
        cursor: pointer;
      }

      .active-period {
        justify-content: center;
        cursor: pointer;
        grid-gap: 3px;
        display: flex;

        .report-period-text {
          font-size: 14px;
          font-weight: bold;
          color: $bg-report-period;
        }

        .next-cal-arrow,
        .prev-cal-arrow {
          display: flex;
          align-items: center;

          &.disable {
            pointer-events: none;
            opacity: $opacity-lighter;
          }
        }

        .datepicker {
          display: grid;
          align-items: center;
          grid-template-columns: repeat(4, auto);
          column-gap: 5px;

          i {
            display: flex;
            font-size: 20px;
          }
        }
      }

      .date-range-picker-container {
        display: none;
        position: absolute;
        z-index: 15;
        background-color: $white;
        top: 48px;
        font-size: 13px;
        right: 0;
        @media screen and (max-width: $screen-in-680) {
          top: 100px;
        }

        .header-section {
          display: none;
        }

        .date-range-selector {
          display: flex;
          justify-content: space-around;
          padding: 5px;
          background-color: $white;

          span {
            &.active,
            &:hover {
              color: $gray-base;
              cursor: pointer;
            }

            &.disabled {
              pointer-events: none;
            }
          }
        }

        .ca-date-picker-container,
        .ca-month-picker,
        .ca-week-picker-container,
        .ca.ca-daterange-picker {
          .backdrop {
            display: none;
          }
        }

        &.open {
          display: block;
        }

        .ca-month-picker {
          background-color: $white;

          .ca-monthpicker-container {
            #ca-monthpicker {
              .datepicker {
                .table-condensed {
                  tbody {
                    tr {
                      @media screen and (max-width: $screen-sm) {
                        td {
                          grid-template-columns: repeat(3, 1fr);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .ca-date-picker-container,
        .ca-month-picker,
        .ca-week-picker-container {
          box-shadow: 2px 2px 3px $date-picker-box-shadow,
            -2px 2px 3px $date-picker-box-shadow;

          .backdrop {
            opacity: 0.5;
            background-color: $gray-base;
          }

          .ca-date-nav-container,
          .ca-week-nav-container {
            height: 40px;
            z-index: 2;
            background-color: $white;

            .ca-date-picker-toggle {
              pointer-events: none;
              font-size: 20px;

              i {
                display: none;
              }
            }
          }

          .ca-datepicker-container,
          .ca-weekpicker-container {
            display: grid;
            padding-bottom: 0;

            .ca-datepicker-today {
              height: 40px;
              box-shadow: 0 2px 4px 0 $box-shadow-bg-color;
              background-color: $has-active-color;
            }

            #ca-datepicker,
            #ca-weekpicker {
              grid-row-start: 1;
              padding: 10px 20px 20px;
              //NOTE overwritting style for enabling all days to be clickable
              .datepicker .table-condensed tr td.no-activities {
                pointer-events: none;
              }

              &.weekly {
                .datepicker {
                  .table-condensed {
                    tbody {
                      .tooltip {
                        z-index: 9999;
                        visibility: visible;
                      }
                    }

                    tbody tr {
                      &.no-activities {
                        td {
                          pointer-events: none;
                        }
                      }

                      &.no-activities:hover {
                        pointer-events: all;
                      }

                      &.week-active,
                      &:hover {
                        border-radius: 50px;
                        background-color: $has-active-bg-color;

                        td {
                          color: $has-active-color;

                          &.active {
                            background: none;
                            width: auto;
                            height: auto;

                            &:before {
                              content: "";
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }

              .datepicker-inline {
                .datepicker-days {
                  .table-condensed {
                    thead {
                      tr:nth-child(2) {
                        display: grid;
                        width: 100%;
                        justify-content: center;
                        grid-template-columns: repeat(7, 1fr);

                        th.dow {
                          height: 40px;
                          width: 40px;
                          justify-self: center;
                        }
                      }
                    }

                    tbody {
                      tr {
                        display: grid;
                        width: 100%;
                        justify-content: center;
                        grid-template-columns: repeat(7, 1fr);

                        td {
                          height: 36px;
                          width: auto;
                          justify-self: center;
                          margin: auto;

                          &.has-activities:before {
                            position: relative;
                            left: 50%;
                            transform: translateX(-50%);
                          }

                          &.active {
                            background-color: $has-active-bg-color;
                            background-image: -webkit-linear-gradient(
                              top,
                              $has-active-bg-color,
                              $has-active-bg-color
                            );
                            border-radius: 50%;
                            display: flex;
                            margin: auto;
                            align-items: center;
                            justify-content: center;
                            color: $has-active-color;
                            width: 40px;
                            height: 40px;

                            &:before {
                              content: none;
                            }
                          }

                          &.today {
                            background-color: $has-active-color;
                            background-image: -webkit-linear-gradient(
                              top,
                              $has-active-color,
                              $has-active-color
                            );
                            border-radius: 50%;
                            display: flex;
                            margin: auto;
                            align-items: center;
                            justify-content: center;
                            color: $white;
                            width: 40px;
                            height: 40px;
                            pointer-events: auto;

                            &:before {
                              content: none;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .ca-daterange-picker {
          position: relative;

          .backdrop {
            opacity: 0.5;
            background-color: $gray-base;
          }

          .content-thumbnail {
            display: none;
          }

          .ca-datepicker-body {
            position: relative;
            transform: none;
            left: 0;
            top: 0;

            .date-info {
              display: none;
            }
          }
        }

        .all-time {
          .backdrop {
            position: fixed;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: -9;
            cursor: pointer;
            opacity: 0.5;
            background-color: $gray-base;
          }

          .date-range {
            height: 40px;
            z-index: 2;
            background-color: $white;
            width: 327px;
            display: grid;
            grid-template-columns: 1fr 20px 1fr;
            justify-self: center;
            position: relative;
            display: grid;
            align-items: center;
            justify-items: center;
            box-shadow: 0 2px 4px 0 $date-picker-box-shadow;
          }
        }
      }
    }

    .header-section {
      display: flex;
      justify-content: start;
      padding: 0 10px 20px;
      column-gap: 20px;
      width: 100%;
      overflow: auto;

      &::-webkit-scrollbar {
        height: 5px;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px $navbar-box-shadow;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $gray-a9;
        outline: 1px solid slategrey;
      }

      .box {
        width: 130px;
        height: 120px;
        text-align: center;
        color: $black-bold;
        font-size: 15px;
        display: grid;
        justify-content: center;
        line-height: 1;
        border-radius: 5px;
        cursor: pointer;

        .community-icon,
        .energizer-icon,
        .family-icon,
        .illuminate-icon,
        .peer-icon,
        .recharge-icon,
        .sparker-icon {
          width: 120px;
          height: 120px;
          background-size: 150px;
          background-position: center;
          background-repeat: no-repeat;
          display: block;
          align-self: center;
          margin: auto;
          cursor: pointer;
          border-radius: 5px;
        }

        .energizer-icon {
          background-image: url("../assets/gooru/images/energizer.png");
        }

        .recharge-icon {
          background-image: url("../assets/gooru/images/recharge.png");
        }

        .sparker-icon {
          background-image: url("../assets/gooru/images/sparker.png");
        }

        .illuminate-icon {
          background-image: url("../assets/gooru/images/illuminate.png");
        }

        .peer-icon {
          background-image: url("../assets/gooru/images/peer-connection.png");
        }

        .family-icon {
          background-image: url("../assets/gooru/images/family-connection.png");
        }

        .community-icon {
          background-image: url("../assets/gooru/images/community-connection.png");
        }

        span {
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          width: 130px;
          font-size: 14px;
        }
      }
    }
  }

  .class-progress-section {
    margin: auto;
    background-color: $white;
    @media screen and (max-width: 720px) {
      width: 100%;
    }

    .back-container {
      display: grid;
      text-align: center;
      align-items: center;
      padding-bottom: 15px;

      .title {
        text-align: center;
        color: $black-bold;
        font-size: 17px;
      }
    }

    .textlabel {
      position: absolute;
      background-color: $gray-light;
      padding: 5px;
      color: $black-bold;
      border-radius: 5px;
      width: auto;
      opacity: 0;
    }

    #sel_data_report {
      overflow: auto;
      height: 530px;
      width: 95%;
      margin: auto;

      &::-webkit-scrollbar {
        height: 5px;
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px $navbar-box-shadow;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $gray-a9;
        outline: 1px solid slategrey;
      }
    }

    .list-container {
      text-align: center;
      min-height: 400px;
      height: 780px;
      overflow: auto;
      @media screen and (max-width: $screen-in-680) {
        min-height: 400px;
        height: 400px;
      }

      .data-report {
        display: grid;
        grid-template-columns: 40px auto;
        align-items: center;
        justify-content: center;

        .lesson-title {
          font-size: 15px;
          transform: rotate(-90deg);
        }
      }

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px $navbar-box-shadow;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $gray-a9;
        outline: 1px solid slategrey;
      }
    }

    #sel_data_report_chart text {
      fill: $black-bold;
      font: 16px sans-serif;
      text-anchor: middle;
    }

    #sel_data_report_chart .text-label {
      fill: $black-bold;
      font: 14px sans-serif;
      text-anchor: start;
    }

    .bar:hover {
      cursor: pointer;
    }

    .axis line,
    .axis path {
      fill: none;
      stroke: #000;
      shape-rendering: crispEdges;
    }

    .axis line,
    .axis path {
      fill: none;
      stroke: $black-bold;
      shape-rendering: crispEdges;
    }

    g.g {
      cursor: pointer;
    }

    .instructions {
      display: flex;
      color: $black-bold;
      justify-content: center;
      padding-bottom: 15px;
      padding-top: 15px;

      .list {
        display: flex;
        align-items: center;
        height: 30px;
        margin-left: 10px;
        font-size: 15px;

        .title {
          line-height: normal;
        }

        .box-started {
          background-color: $blue-200;
          display: block;
          width: 15.6px;
          height: 15.6px;
          transform: rotate(90deg);
          margin-right: 8.4px;
        }

        .box-completed {
          background-color: $dark-400;
          display: block;
          width: 15.6px;
          height: 15.6px;
          transform: rotate(90deg);
          margin-right: 8.4px;
        }
      }
    }

    .no-data-section {
      text-align: center;
      font-size: 17px;
      padding: 50px;
      color: $black-bold;
    }
  }

  .detail-view-section {
    width: 100%;
    margin: auto;
    background-color: $white;
    border-top: 1px solid $black-bold;

    .common-container {
      display: grid;
      grid-template-columns: unset;
    }

    .back-container {
      display: grid;
      text-align: center;
      align-items: center;
      padding: 12px 0 15px;

      .title {
        text-align: center;
        color: $black-bold;
        font-size: 17px;
      }
    }

    .textlbl {
      position: absolute;
      background-color: $gray-light;
      padding: 5px;
      color: $black-bold;
      border-radius: 5px;
      width: auto;
      opacity: 0;
    }

    .list-container {
      text-align: center;
      min-height: 500px;
    }

    .activity {
      text-align: center;
    }

    .axis line,
    .axis path {
      fill: none;
      stroke: $black-bold;
      shape-rendering: crispEdges;
    }

    .instructions {
      display: flex;
      color: $black-bold;
      justify-content: center;
      padding-bottom: 15px;

      .list {
        display: flex;
        align-items: center;
        height: 30px;
        margin-left: 10px;
        font-size: 15px;

        .title {
          line-height: normal;
        }

        .box-started {
          background-color: $blue-200;
          display: block;
          width: 15.6px;
          height: 15.6px;
          transform: rotate(90deg);
          margin-right: 8.4px;
        }

        .box-completed {
          background-color: $dark-400;
          display: block;
          width: 15.6px;
          height: 15.6px;
          transform: rotate(90deg);
          margin-right: 8.4px;
        }
      }
    }

    .line-bar-chart {
      overflow: auto;

      &::-webkit-scrollbar {
        height: 5px;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px $navbar-box-shadow;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $gray-a9;
        outline: 1px solid slategrey;
      }

      .line-graft {
        min-height: 450px;

        .no-data-section {
          text-align: center;
          font-size: 17px;
          padding-top: 100px;
          color: $black-bold;
        }

        #line_chart {
          text-align: center;
        }
      }

      .title-section {
        text-align: center;
        align-items: center;
        padding: 10px 0 15px;
        height: 65px;

        .title {
          font-size: 17px;
          color: $black-bold;
        }
      }

      .started-activity {
        background-color: $blue-200;
        display: block;
        width: 15.6px;
        height: 15.6px;
        margin-right: 8.4px;
        border-radius: 50%;
      }

      .completed-activity {
        background-color: $dark-400;
        display: block;
        width: 15.6px;
        height: 15.6px;
        margin-right: 8.4px;
        border-radius: 50%;
      }
    }
  }

  .report-container {
    display: grid;
    width: 100%;
    margin: auto;
    background-color: $white;
    border-top: 1px solid $black-bold;

    .instruction,
    .title-section {
      text-align: center;
      align-items: center;
      padding: 10px 0 15px;
      height: 65px;

      .title {
        font-size: 17px;
        color: $black-bold;
      }
    }

    .no-data-section {
      text-align: center;
      font-size: 15px;
      padding-top: 100px;
      color: $black-bold;
    }

    .axis line,
    .axis path {
      fill: none;
      stroke: $black-bold;
      shape-rendering: crispEdges;
    }

    .chart-container {
      overflow: auto;
      min-height: 250px;

      &::-webkit-scrollbar {
        height: 5px;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px $navbar-box-shadow;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $gray-a9;
        outline: 1px solid slategrey;
      }
    }

    .reflection-container {
      display: grid;
      border-bottom: 1px solid $black-bold;
      overflow: auto;

      #reflection_chart {
        display: grid;
        align-items: center;
        justify-content: center;
        grid-template-columns: 30px auto;
        @media screen and (max-width: $screen-in-680) {
          justify-content: start;
        }

        .left-title {
          transform: rotate(-90deg);
        }
      }
    }

    .reflection-quiz-container {
      display: grid;
      border-bottom: 1px solid $black-bold;
      overflow: auto;

      #reflection_quiz_chart {
        display: grid;
        align-items: center;
        justify-content: start;
        grid-template-columns: 30px auto;

        .left-title {
          transform: rotate(-90deg);
        }
      }
    }

    .feelings-summary-container {
      display: grid;
      border-bottom: 1px solid $black-bold;
      overflow: auto;

      #feelings_chart {
        display: grid;
        align-items: center;
        justify-content: center;
        grid-template-columns: 100px auto;
        @media screen and (max-width: $screen-in-680) {
          justify-content: start;
        }

        .left-title {
          transform: rotate(-90deg);
        }
      }
    }

    .recharge-container {
      display: flex;
      font-weight: bold;
      justify-content: center;

      &.show {
        display: flex !important;
      }

      .three-bounce-spinner {
        margin: 100px;
      }

      .common-section {
        display: grid;
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
        @media screen and (max-width: $screen-md) {
          width: 100%;
          padding-left: 20px;
          padding-right: 20px;
        }

        .back-container {
          padding-top: 10px;
          display: flex;
          width: max-content;
          cursor: pointer;

          .title-back {
            font-size: 14px;
          }
        }

        .no-data-section {
          text-align: center;
          font-size: 17px;
          padding: 50px;
          color: $black-bold;
        }
      }

      .recharge-title {
        font-size: 15px;
        color: #2f3894;
        align-items: center;
        padding: 10px 0 15px;
      }

      .title {
        text-align: center;
        align-items: center;
        padding: 0 0 15px;
        color: #f0a70f;
        font-size: 22px;
      }

      .question {
        color: #5ca451;
        font-size: 15px;
      }

      table {
        margin-bottom: 10px;
      }

      td,
      th {
        border: 1px solid $table-border-color;
        text-align: left;
        padding: 0 8px;
      }

      tr:nth-child(even) {
        background-color: #d8bfd8;
      }
    }

    .not-completed-student {
      margin: 30px 100px;
      @media screen and (max-width: $screen-in-680) {
        margin: 30px 20px;
      }

      .title {
        font-size: 20px;
        font-weight: bold;
      }

      table {
        width: 50%;
        font-size: 17px;
        font-weight: bold;
        margin-top: 20px;

        td,
        th {
          border: 1px solid $black-bold;
          text-align: left;
          padding: 0 8px;
        }
      }
    }
  }
}
