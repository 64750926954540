.backdrop-close {
  width: 100vw;
  height: 100vh;
  position: fixed;
  right: 0;
  left: 0;
  background-color: $gray-base;
  top: 0;
  opacity: 0.05;
}

.gru-activity-feedback {
  background-color: $white;
  border-radius: 5px;
  position: relative;
  width: 360px;
  display: inline-block;
  max-height: 320px;
  overflow: hidden;
  z-index: 999;
  border: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0);
  @media screen and (max-width: 320px) {
    max-width: 300px;
  }
  .feedback-header {
    display: grid;
    grid-template-columns: auto 40px;
    height: 32px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    padding: 0px 0px 0px 16px;

    .text {
      color: #37424b;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      opacity: 0.5;
      font-weight: bold;
    }

    .close-icon {
      padding: 0;
      font-size: 12px;
      width: 40px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        font-size: 24px;
      }
    }
  }

  .feedback-content-body {
    height: 280px;
    margin-top: 5px;
    overflow: auto;

    &::-webkit-scrollbar {
      height: 6px;
      width: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background: $gray-light;
      border: 1px solid $gray-light;
      border-radius: 5px;
    }
  }
}
