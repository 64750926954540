.qz-image-modal {
  .panel-body:hover {
    display: flex;
  }
  .panel-primary {
    background: transparent;

    .zoomMenu {
      display: flex;
      position: absolute;
      top: 1px;
      left: 15px;
      color: $white;
      width: 100px;
      justify-content: space-between;
      padding: 10px;
      > span > i.material-icons {
        background-repeat: no-repeat;
        width: 34px;
        height: 34px;
        font-size: 32px;
        background-size: 34px 34px;
        display: block;
        justify-self: center;
        cursor: pointer;
      }
      .panel-body:hover {
        display: flex;
      }
      .zoomPlus {
      }
      .zoomMinus {
      }
    }
    @media (min-width: 1025px) {
      .zoomMenu {
        display: none !important;
      }
    }
    .panel-body {
      display: block;
      overflow: auto;
      height: 90vh;
      img {
        padding: 0 2px;
        touch-action: pan-down pan-x pan-y pinch-zoom;
        transform-origin: top left;
        width: 100%;
        height: 100%;
      }
    }
  }
  @media (min-width: 1025px) {
    .panel-primary.zoomMenu {
      display: none !important;
    }
  }

  .panel-height {
    height: calc(90vh - 45px) !important;
  }
}
