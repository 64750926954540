.gru-partner-product-card {
  margin: 10px auto;
  width: max-content;
  height: 325px;

  .panel-body,
  .panel-footer {
    @include flexbox;
  }

  .panel {
    width: 300px;
    max-height: 360px;
    border-radius: 4px;
    min-height: 200px;
    box-shadow: 0 0 6px 1px $table-box-shadow-07;

    .panel-heading {
      .title-section {
        height: 45px;
        text-align: center;
        margin: auto;
        cursor: pointer;

        .title {
          color: $picton-blue;
          font-weight: 300;
          width: 270px;
          padding-bottom: 3px;
          @include ellipsis(
            $font-size: 16px,
            $line-height: 0.94,
            $lines-to-show: 2,
            $excerpt-bg: $white
          );
        }

        .product-name {
          font-weight: bold;
        }
      }
      @include flexbox;
      @include align-items(center);
      background-color: $white;
      padding: 10px 8px 0 15px;
    }

    .panel-body {
      @include flex-direction(column);
      @include justify-content(space-between);
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      padding: 5px 15px 1px;
      height: 220px;

      .description {
        height: 60px;
        text-align: $tab-float-dir;
        width: 270px;
        object-fit: contain;
        color: $gray-dove;

        p {
          @include ellipsis(
            $font-size: 13px,
            $line-height: 1.16,
            $lines-to-show: 4,
            $excerpt-bg: $white
          );
        }
      }

      .image {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        height: 130px;
        img {
          width: 130px;
        }
      }
    }

    .panel-footer {
      background-color: $white;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      border-top: 1px solid $gray-lighter;
      height: 50px;
      padding: 8px 12px;

      .product-info {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        grid-gap: 10px;
        width: 100%;
        line-height: 1.16;

        .subject {
          @include ellipsis(
            $font-size: 13px,
            $line-height: 1.16,
            $lines-to-show: 2,
            $excerpt-bg: $white
          );
        }

        .grade-list {
          text-align: center;
          cursor: pointer;
          .grades-title {
            @include ellipsis(
              $font-size: 13px,
              $line-height: 1.16,
              $lines-to-show: 1,
              $excerpt-bg: $white
            );
          }
          .more-grade {
            color: $blue-400;
          }
        }
      }
    }
  }
}
