.fractional {
  display: inline-block;
  text-align: center;
  .top,
  .bottom {
    padding: 0 5px;
  }
  .bottom {
    border-top: 1px solid $gray-base;
    display: block;
  }
}
