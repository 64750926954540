.portfolio.gru-portfolio-content-card {
  .portfolio-card-container {
    display: grid;
    grid-template-columns: 1fr;
    background-color: $white;
    border-bottom: 1px solid $navbar-box-shadow;
    margin-bottom: 5px;
    .left-panel {
      display: grid;
      grid-template-rows: 55px auto 35px;
      .activity-info,
      .activity-details {
        display: grid;
        grid-template-columns: 80px 1fr;
        column-gap: 5px;
      }

      .activity-info {
        .activity-thumbnail {
          width: 80px;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
        }

        .activity-title {
          display: grid;
          grid-template-rows: auto auto;
          align-items: center;
          .title {
            font-weight: bold;
            display: grid;
            grid-template-columns: 1fr 50px;
            background: $table-bg;
            max-height: 28px;
            font-size: 14px;
            line-height: 1;
            overflow: hidden;
            text-overflow: ellipsis;

            .assessment-external-icons,
            .collection-external-icons {
              width: 20px;
              height: 20px;
              background-size: 20px 20px;
              opacity: 0.8;
              justify-self: center;
              background-image: url("gooru/share.png");
              background-repeat: no-repeat;
              margin: auto 10px auto auto;
            }
          }
          .owner {
            font-size: 11px;
          }
        }
      }
      .activity-details {
        display: grid;
        grid-template-columns: 80px 1fr;
        .description {
          @include ellipsis(
            $font-size: 12px,
            $line-height: 1.2,
            $lines-to-show: 3
          );
        }
        .taxonomies {
          display: grid;
          align-items: center;
          .gru-taxonomy-tag-list {
            display: grid;
            justify-content: center;
            .gru-taxonomy-tag {
              min-width: 70px;
              max-width: 80px;
              height: 20px;
              border-radius: 12px;
              background-color: $dark-gray;

              .standard-tag {
                text-align: center;
                padding: 0;

                .tag-label {
                  font-size: 10px;
                  font-weight: bold;
                  color: $white;
                  line-height: 18px;
                }

                .tag-caption {
                  font-size: 11px;
                  display: none;
                }
              }
            }

            .non-visible-tags {
              width: 40px;
              height: 20px;
              border-radius: 12px;
              background-color: $white;
              color: $dark-gray;
              padding: 0;
              justify-self: center;

              span {
                font-weight: bold;
                margin-left: 0;
                height: 20px;
                line-height: 20px;
                font-size: 11px;
                display: grid;
                align-items: center;
                justify-content: center;
                width: inherit;
              }
            }
          }
        }
      }
      .activity-metadata {
        display: grid;
        grid-template-columns: auto auto 80px;
        @media screen and (max-width: 768px) {
          grid-template-columns: auto auto 55px;
        }
        justify-content: space-between;
        height: 35px;
        line-height: 35px;
        border-top: 1px solid $navbar-box-shadow;
        padding-left: 5px;

        .last-accessed {
          align-self: center;
          font-size: 13px;
          height: 35px;
        }
        .content-count {
          display: grid;
          grid-template-columns: auto auto;
          column-gap: 6px;
          .count {
            display: grid;
            grid-template-columns: auto auto;
            align-items: center;
            justify-content: center;
            column-gap: 5px;
          }

          %icon-style {
            width: 20px;
            background-size: 20px;
            background-position: center;
            height: 35px;
            background-repeat: no-repeat;
          }
          .resource-count {
            .icon {
              background-image: url("gooru/images/resource-icon.svg");
              @extend %icon-style;
            }
          }

          .question-count {
            .icon {
              background-image: url("gooru/images/question-icon.svg");
              @extend %icon-style;
              width: 18px;
              background-size: 18px;
            }
          }

          .task-count {
            .icon {
              background-image: url("gooru/images/text-resource-dark.svg");
              @extend %icon-style;
            }
          }
        }

        .grading-icon {
          background-image: url("../assets/gooru/images/grade-pending.png");
          width: 22px;
          height: 22px;
          background-size: 22px;
          background-position: center;
          background-repeat: no-repeat;
          display: block;
          align-self: center;
          margin: auto;
          cursor: pointer;

          &.ca-diagnostic {
            pointer-events: none;
          }
        }

        .activity-performance {
          height: 35px;
          line-height: 35px;
          text-align: center;
          color: $font-gray-white-500;
          font-weight: bold;
          font-size: 14px;
          cursor: pointer;
          &.timespent,
          &.grade-range-not-started {
            background-color: $timespent-label;
            color: $dark-800;
          }
          &.ca-diagnostic {
            pointer-events: none;
          }
        }
      }
    }
    .right-panel {
      display: grid;
      display: none;
      .activity-reef-values {
        display: grid;
        > div {
          display: grid;
          justify-content: center;
          align-content: center;
          background-color: $activity-reef-value;
          color: $white;
          border-bottom: 1px solid $navbar-box-shadow;
          border-left: 1px solid $navbar-box-shadow;
          height: 35px;
          &.activity-reef-value {
            span {
              text-align: center;
              font-size: 11px;
              &.reef-label {
                align-self: self-end;
                line-height: 1;
              }
              &.reef-value {
                align-self: self-start;
                font-weight: bold;
              }
            }
          }
        }
      }
      .activity-performance {
        height: 35px;
        line-height: 35px;
        text-align: center;
        color: $white;
        font-weight: bold;
        font-size: 14px;
        cursor: pointer;
        &.timespent {
          background-color: $timespent-label;
          color: $dark-800;
        }
        &.ca-diagnostic {
          pointer-events: none;
        }
      }
    }

    @media screen and (max-width: $screen-320) {
      .left-panel {
        .activity-metadata {
          .last-accessed {
            font-size: 11px;
          }
        }
      }
    }
  }
}
