.content.modals.gru-delete-content {
  .modal-header {
    background-color: $yellow-400;
  }
  .modal-body form div {
    ul {
      list-style: none;
      padding-left: 0;
    }
  }
  input[type=text] {
    outline: none;
  }
  p span,
  span {
    display: inline-block;
    text-transform: lowercase;
  }
  p span:first-letter,
  span:first-letter {
    text-transform: capitalize;
  }
}
