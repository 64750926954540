.activity-course-card {
  .card-panel-container {
    width: 220px;
    height: 384px;
    object-fit: contain;
    border-radius: 4px;
    background-color: $white;
    box-shadow: 0 0 6px 1px $table-box-shadow-07;

    .card-panel-header {
      width: 220px;
      height: 130px;
      object-fit: contain;
      background-color: $table-bg;
      background-size: cover;
      background-repeat: no-repeat;
      padding: 0;
      position: relative;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;

      .info {
        color: $white;
        position: absolute;
        z-index: 10;

        .title {
          padding-left: 10px;
          padding-right: 10px;
          width: 100%;
          height: 42px;
          margin-top: 88px;
          font-weight: bold;
          $line-height: 1.5;
          $lines-to-show: 2;
          @include ellipsis(
            $font-size: 13px,
            $line-height: $line-height,
            $lines-to-show: $lines-to-show
          );
        }
      }

      &::after {
        display: block;
        position: absolute;
        z-index: 9;
        background-image: linear-gradient(
          to bottom,
          $bg-left-box-shadow 0,
          $gray-base 100%
        );
        margin-top: 52px;
        height: 60%;
        width: 100%;
        content: "";
      }
    }

    .unit-count-container {
      height: 25px;
      width: 100%;
      border-bottom: 1px solid $light-600;

      .icon {
        background-size: 15px 15px;
        width: 15px;
        height: 15px;
        display: block;
        float: left;
        margin-right: 6px;
        margin-top: 5px;
        background-image: url("../assets/gooru/images/unit.svg");
        margin-left: 10px;
      }

      span {
        font-size: 14px;
      }
    }

    .course-info-container {
      height: 150px;
      border-bottom: 1px solid $gray-ultra-light;
      padding: 15px;

      .course-user-info {
        height: 30px;
        cursor: default;
        @include flexbox;

        .created-by {
          font-style: italic;
          width: 60px;
        }

        .img-container {
          width: 20px;
          height: 20px;
          overflow: hidden;
          border-radius: 50px;
          margin-left: 10px;

          img {
            width: 100%;
          }
        }

        .username {
          margin-left: 10px;
          max-width: 90px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
          color: $blue-400;
        }
      }

      .course-description {
        $line-height: 1.5;
        $lines-to-show: 3;
        @include ellipsis(
          $font-size: 13px,
          $line-height: $line-height,
          $lines-to-show: $lines-to-show
        );
      }

      .course-standards {
        margin-top: 10px;

        .taxonomy-tag {
          .content {
            font-size: 12px;
            width: 130px;
            height: 32px;
          }
        }

        .non-visible-tags {
          padding: 2px;
          font-size: 12px;
        }
      }
    }

    .card-panel-footer {
      .buttons-container {
        height: 36px;
        border-bottom: 1px solid $gray-ultra-light;

        .play-btn {
          width: 45px;
          border-right: 1px solid $gray-ultra-light;

          i {
            margin-left: -62px;
            margin-top: 7px;
            color: $blue-400;
            cursor: pointer;
          }
        }
      }

      .course-prefs-container {
        .left-arc {
          display: block;
          height: 15px;
          width: 9px;
          position: relative;
          background: $gray-f7;
          border-top-right-radius: 12.5px;
          border-bottom-right-radius: 12.5px;
          z-index: 1;
          top: -8px;
        }

        .right-arc {
          display: block;
          height: 15px;
          width: 9px;
          position: relative;
          background: $gray-f7;
          border-top-left-radius: 12.5px;
          border-bottom-left-radius: 12.5px;
          margin-left: 211px;
          z-index: 1;
          top: -22px;
        }

        .course-prefs {
          width: 73.3px;
          height: 43px;
          float: left;
          background-color: $blue-400;
          position: relative;
          top: -30px;

          .count {
            font-size: 15px;
            font-weight: bold;
            text-align: center;
            color: $white;
            line-height: 1;
            margin-top: 9px;
          }

          .title {
            font-size: 10px;
            text-align: center;
            color: $white;
            line-height: 1;
          }
        }
      }
      &.disable-footer {
        pointer-events: none;
        opacity: 0.5;
      }
    }

    .panel-footer-container {
      .actions-container {
        height: 36px;
        border-bottom: 1px solid $gray-ultra-light;
        background-color: $white;
        display: grid;
        grid-template-columns: auto auto;
        justify-content: space-between;

        .left-aligned {
        }
        .right-aligned {
          display: grid;
          grid-template-columns: auto auto;
        }

        .action-btn {
          display: grid;
          justify-content: center;
          align-items: center;
          width: 45px;
          height: 36px;
          i {
            cursor: pointer;
          }
        }
        .play-btn {
          border-right: 1px solid $gray-ultra-light;
          i {
            color: $blue-400;
          }
        }
        .edit-btn {
          border-left: 1px solid $gray-ultra-light;
        }
      }
    }
  }
}
