.gru-resource-edit {
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 1050;

  .resource-container {
    .resource-header {
      background-color: $white;
      padding: 12px 12px 0 12px;

      .resource-header-title {
        height: 32px;
        position: relative;
        display: flex;
        align-items: center;
        gap: 24px;

        i.material-icons {
          height: 24px;
          width: 24px;
          cursor: pointer;
          color: $gray-4d5557;
        }

        span {
          text-transform: capitalize;
          font-family: "Roboto";
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 26px;
          color: $dark-night;
        }

        &::after {
          content: "";
          position: absolute;
          left: -12px;
          bottom: -12px;
          height: 1px;
          width: calc(100% + 24px) !important;
          background-color: $link-water;
        }
      }

      .resource-header-content {
        height: 32px;
        margin: 28px 48px 0 48px;
        display: flex;
        justify-content: space-between;

        .header {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 30px;
          color: $black-333333;
        }

        .preview-button {
          padding: 4px 8px;
          display: flex;
          align-items: center;
          gap: 6px;
          color: $blue-btn-txt;
          background-color: $white;
          border: none;
          border-radius: 4px;

          &:hover {
            color: $blue-active-bg;
            background-color: $btn-background;
          }

          i.material-icons {
            height: 20px;
            width: 20px;
          }

          span {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            font-weight: 500;
            line-height: 24px;
          }
        }
      }
    }

    .resource-tab-head {
      background-color: $white;

      .resource-blk {
        width: 90%;
        min-width: calc(100% - 120px);
        margin: auto;
        padding: 0;
        display: flex;
        align-items: center;
        gap: 32px;
        list-style: none;
        border-bottom: 3px solid $ps-white-e2;

        li {
          margin-top: 10px;
          padding: 6px 0px;
          cursor: pointer;
          border-bottom: 2px solid transparent;
          color: $dark-night;

          a {
            text-decoration: none;
            border: none;
            outline: none;
            background-color: transparent;
            display: block;
          }

          span {
            display: block;
            text-shadow: 0 0 1px $light-300;
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
          }

          &.active {
            position: relative;
            color: $blue-active-bg;

            &::after {
              content: "";
              height: 3px;
              position: absolute;
              left: 0;
              bottom: -4px;
              width: 100%;
              border-radius: 3px;
              background-color: $blue-active-bg;
            }
          }
        }
      }
    }
  }

  .footer {
    height: 64px;
    width: 100%;
    background: $white-bold;
    box-sizing: border-box;
    border-top: 1px solid $link-water;
    border-radius: 0px;
    position: fixed;
    left: 0;
    bottom: 0;

    .footer-container {
      height: 32px;
      width: 90%;
      min-width: calc(100% - 120px);
      margin: 16px auto;
      display: flex;
      justify-content: space-between;
    }

    .cancel-button {
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      color: $blue-bg;
      background-color: $white;
      border: 0px solid $btn-border-grey;
      border-radius: 4px;
      padding: 4px 8px 4px 8px;

      &:hover {
        color: $blue-active-bg;
        background-color: $btn-background;
      }
    }

    .save-button {
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      background: $blue-bg;
      color: $white-bold;
      border: 0px solid $btn-border-grey;
      border-radius: 4px;
      padding: 4px 8px 4px 8px;

      &:hover {
        background-color: $blue-active-bg;
        color: $btn-background;
      }
    }

    .delete-button {
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      background: $ps-red-200;
      color: $white-bold;
      border: 0px solid $btn-border-grey;
      border-radius: 4px;
      padding: 4px 8px 4px 8px;

      &:hover {
        background-color: $ps-red-100;
        color: $btn-background;
      }
    }
  }

  .body {
    margin: 0;
    background-color: $white;

    .tab-pane {
      padding: 24px 0 12px 0;
      width: 90%;
      min-width: calc(100% - 120px);
      margin: 0 auto;
      height: calc(100vh - 216px);
      overflow-y: auto;

      .iframe-container {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 56.25%; /* Assuming a 16:9 aspect ratio for the video */
      }

      .iframe-container iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 1px solid $link-water;
        border-radius: 4px;
      }

      .ps-input-container {
        width: 80%;
        max-width: 424px;
        margin: auto;

        .subject {
          .custom-subject-selecter {
            .ps-dropdown-subject-picker {
              width: 100%;
            }
          }
        }
      }
    }

    .required-badge {
      margin-left: 8px;
      padding: 2.7px;
      color: $ps-grey-100;
      border: 1px solid $btn-border-grey;
      border-radius: 4px;
      background: $grey-background;
      font-weight: 400;
      font-size: 10px;
      line-height: 14px;
    }

    .required-badge-red {
      margin-left: 8px;
      padding: 2.7px;
      color: $ps-red-reset;
      border: 1px solid $btn-border-grey;
      border-radius: 4px;
      background: $grey-background;
      font-weight: 400;
      font-size: 10px;
      line-height: 14px;
    }

    .required-badge-error {
      color: white;
      margin-top: 4px;
      background: $ps-red-reset;
      padding: 0px 4px 2px;
      border-color: $ps-red-reset;
    }

    .error,
    .warning {
      color: $ps-red-reset !important;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px !important;
      line-height: 20px;
    }

    .ps-edit-resource {
      padding-bottom: 20px;
      .form-control,
      input[type="text"].form-control,
      input[type="password"].form-control,
      input[type="email"].form-control,
      input[type="number"].form-control,
      [type="text"].form-control,
      [type="password"].form-control,
      [type="email"].form-control,
      [type="tel"].form-control,
      [contenteditable].form-control,
      .dropdown {
        margin: 5px 22px 0px 0px;
        color: $dark-night;
        height: 27px;
        font-family: "Roboto";
        font-style: normal;
        font-size: 14px;

        width: 100%;
        box-sizing: border-box;
        height: 32px;
        border: 1px solid $link-water;
        border-radius: 4px;
        padding-right: 1%;
        padding-left: 0.5% !important;
        min-width: 160px;
      }

      /* Override styles for textarea */
      textarea.form-control {
        height: auto;
        font-weight: 400;
      }
      .form-control:not(textarea),
      .dropdown {
        line-height: 20px;
        font-weight: 500;
      }
      /* Active state for form control elements */
      textarea.form-control:focus,
      input.form-control:focus,
      input[type="text"]:focus,
      input[type="password"]:focus,
      input[type="email"]:focus,
      input[type="number"]:focus,
      [type="text"].form-control:focus,
      [type="password"].form-control:focus,
      [type="email"].form-control:focus,
      [type="tel"].form-control:focus,
      [contenteditable].form-control:focus .form-control:focus,
      .dropdown:focus {
        color: $ps-blue-dark;
        background-color: $white;
        border-color: $ps-blue-100;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      }

      .input-title {
        margin: 11px 10px 0px 0px;
        color: $dark-night;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;

        &.ps-input-container {
          margin: auto;
          margin-top: 11px;
        }
      }
      .ps-resource-thumbnail {
        .thumbnail-container {
          display: flex;
          align-items: flex-start;
        }

        .delete.btn {
          margin-left: -10px;
          margin-top: -8px;
          padding: 8px;
          background-color: transparent;
          font-size: 20px;
          cursor: pointer;
        }
        .default-thumbnail-label {
          margin-left: -10px;
          padding: 8px;
          background-color: transparent;
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
        }
        .thumbnail-image {
          // background-image: url("../assets/gooru/images/resource-gray.svg");
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          border: 1px solid $link-water;
          border-radius: 4px;
          height: 100px;
          width: 100px;
        }
        .gru-file-picker {
          input {
            display: none;
          }
          .file-picker {
            .upload.btn {
              margin-top: 0px;
              border: none;
            }
          }
        }
      }

      .ps-icon-help {
        margin-left: 8px;
        color: $gray-4d5557;
        cursor: pointer;
      }
      .ps-margin {
        margin: 11px 10px 0px 38px;
      }

      .ps-etl-color {
        color: $ps-grey-74;
      }

      .collapsible {
        display: flex;
        align-items: center;
      }

      .info {
        margin: 11px 10px 0px 0px;
        color: $ps-grey-74;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        width: calc(100% - 44px);
        max-width: 424px;
      }

      .thumbnail-info {
        margin: 4px 10px 0px 0px;
        color: $ps-grey-74;
        font-family: "Roboto";
        font-style: italic;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
      }

      .tags {
        margin: 10px 0px;
        max-width: 100%;
        min-width: 320px;
      }

      .word-suggestion {
        list-style: none;
        position: relative;
        margin-top: 0px;
        background-color: $white;
        margin-top: 0px;
        width: 100%;
        padding: 0;
        border: 1px solid $gray-light;
        z-index: 0;
        border-radius: 5px;
        max-height: 250px;
        overflow: auto;

        li {
          padding: 10px;
          cursor: pointer;

          &.label {
            text-transform: uppercase;
            color: $dark-100;
            cursor: inherit;
          }
        }

        li:hover {
          background-color: $gray-ultra-light;

          &.label {
            background-color: unset;
          }
        }
      }

      .tag-list {
        display: flex;
        column-gap: 10px;

        .list {
          color: $blue-bg;
          font-weight: 400;
          font-size: 12px;
          display: inline-block;
          border: 1px solid $btn-border-grey;
          padding: 0 3px 2px;
          border-radius: 12px;
          background-color: $white;
          margin-bottom: 3px;
          vertical-align: top;
          position: relative;
          display: grid;
          grid-template-columns: 1fr 15px;
          align-items: center;

          span {
            padding: 3px 10px 0 10px;
          }

          i {
            cursor: pointer;
            font-size: 13px;
            text-align: end;
            height: 9px;
          }
        }
      }

      .collapse-label {
        margin: 11px 22px 0px 0px;
        color: $dark-night;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        display: flex;
        align-items: center;
      }

      .ps-select {
        margin-right: 5px;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        color: $blue-bg;
        background-color: $white;
        border: 1px solid $btn-border-grey;
        border-radius: 4px;
        padding: 4px 8px 4px 8px;
        cursor: pointer; /* Added cursor style */

        &:hover {
          color: $blue-active-bg;
          background-color: $btn-background;
        }

        &:disabled {
          /* Added disabled state styles */
          opacity: 0.6;
          cursor: not-allowed;
          background: $btn-border-grey;
        }
      }

      .skills-buttons {
        @extend .tags;
        display: flex;
        column-gap: 10px;

        .btn-skill {
          color: $blue-bg;
          font-weight: 400;
          font-size: 12px;
          display: inline-block;
          border: 1px solid $btn-border-grey;
          padding: 0 3px 2px;
          border-radius: 12px;
          background-color: $white;
          margin-bottom: 3px;
          vertical-align: top;
          position: relative;
          display: grid;
          grid-template-columns: 1fr 15px;
          align-items: center;

          span {
            padding: 3px 2px 0 10px;
          }

          i {
            cursor: pointer;
            font-size: 13px;
            text-align: end;
            height: 9px;
          }
        }
      }

      .delete.btn {
        margin-left: -10px;
        margin-top: -8px;
        padding: 8px;
        background-color: transparent;
        font-size: 20px;
        cursor: pointer;
      }
      .add-new-video-segmen-button {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        color: $blue-bg;
        background-color: $white;
        border: 0px solid $btn-border-grey;
        border-radius: 4px;
        padding: 4px 8px 4px 8px;

        &:hover {
          color: $blue-active-bg;
          background-color: $btn-background;
        }
      }
    }
    .error,
    .warning {
      margin: 4px 22px 0px 0px;
    }
    .ps-icon {
      display: inline-block;
      vertical-align: -6px;
    }
    .ps-icon-label {
      margin-top: 30px;
      display: inline-block;
    }
  }

  .ps-checkbox {
    .switch {
      position: relative;
      display: inline-block;
      width: 30px;
      height: 18px;
      margin-right: 10px;
    }

    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $white;
      border-radius: 18px;
      border: 1px solid $ps-grey-74;
    }

    .slider:before {
      position: absolute;
      content: "";
      height: 12px;
      width: 12px;
      left: 2px;
      top: 2px;
      background-color: $ps-grey-74;
      border-radius: 50%;
    }

    input:checked + .slider {
      background-color: $blue-active-bg;
    }

    input:checked + .slider:before {
      transform: translateX(100%);
    }

    .switch input:checked + .slider:before {
      content: "\2713";
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 10px;
      color: $blue-active-bg;
      text-align: center;
      background-color: $white;
    }

    .slider:before {
      content: "";
    }

    input:checked + .slider:before {
      content: "";
    }
  }

  .gru-resource-edit-custom-dropdown {
    .dropdown-toggle {
      margin: 5px 22px 0px 0px;
      color: $dark-night;
      font-family: "Roboto";
      font-style: normal;
      font-size: 14px;
      line-height: 20px;
      width: 100%;
      box-sizing: border-box;
      height: 32px;
      font-weight: 500;
      min-width: 160px;

      &:focus,
      &.active,
      &:active {
        color: $ps-blue-dark;
        background-color: $white;
        border-color: $ps-blue-100;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      }
    }
    .dropdown-menu {
      width: 100%;
      margin: 5px 22px 0px 0px;
    }

    .pills-container {
      margin: 5px 22px 0px 0px;
      width: 100%;
      min-width: 160px;
    }
  }

  .ps-resource-edit-custom-taxonomy-selector {
    .dropdown-toggle {
      margin: 5px 22px 0px 0px;
      color: $dark-night;
      font-family: "Roboto";
      font-style: normal;
      font-size: 14px;
      line-height: 20px;
      width: 100%;
      box-sizing: border-box;
      height: 32px;
      font-weight: 500;
      min-width: 160px;

      &:focus,
      &.active,
      &:active {
        color: $ps-blue-dark;
        background-color: $white;
        border-color: $ps-blue-100;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      }
    }
    .dropdown-menu {
      width: 100%;
      margin: 5px 22px 0px 0px;
    }
  }
  .gru-file-picker {
    > div {
      @include flexbox();

      input {
        margin: 5px 12px 0px 22px;
        font-size: 14px !important;
        font-weight: 500;
        width: 26.7%;
        box-sizing: border-box;
        height: 32px;
        border: 1px solid $link-water !important;
        border-radius: 4px !important;
        padding-right: 1% !important;
        padding-left: 0.5% !important;
        min-width: 160px;
      }

      .file-picker {
        @include flex();

        .upload.btn {
          @extend .ps-select;
          margin: 1px 22px 0px 0px;
          border: none !important;

          .fa-upload {
            font-size: $font-size-large;
            margin-right: 5px;
            padding: 2px;
          }
        }
      }
    }
    & + .validation {
      .error {
        margin-top: 0;
      }
    }
  }

  .ps-collapsible {
    /* Change icon on expand */
    .collapsed:not(.show) .icon:before {
      content: "arrow_right"; /* arrow-right */
    }
    .icon:before {
      content: "arrow_drop_down"; /* arrow-drop-down */
    }
    .icon-container {
      display: flex;
      align-items: center;
    }

    #collapseRecommended,
    #collapseOtherAttributes,
    #collapseAdditionalInfo {
      margin-left: 24px;
    }
  }

  .pills-container {
    margin: 5px 22px 0px 0px;
    width: 100%;
    min-width: 160px;
    display: flex;
    flex-wrap: wrap;

    .pill {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 3px 8px;
      gap: 6px;
      box-sizing: border-box;
      border: 1px solid $btn-border-grey;
      border-radius: 12px;
      background: $white-bold;
      margin: 4px;
      color: $blue-bg;
      font-weight: 400;
      font-size: 12px;
    }

    .pill-text {
      flex: 1;
    }

    .pill-close {
      cursor: pointer;
      font-size: 14px;
      text-align: end;
      height: 9px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
    }
  }

  #table-body td {
    margin: 0 !important;
  }

  #table-body td input {
    margin: 0 !important;
  }

  /* Styles for the custom modal */
  .ps-edit-resource-modal {
    .modal-content {
      border-radius: 8px;
    }

    .modal-header {
      border-bottom: 1px solid $gray-80;

      .title-wrapper {
        display: flex;
        align-items: center;
      }

      .modal-title {
        margin-right: 10px;
      }

      .close {
        margin-left: auto;
      }
    }

    .ps-body-content {
      color: $dark-night;
      font-family: "Roboto";
      font-style: normal;
      font-size: 14px;
      font-weight: 400;

      .input-title {
        margin: 11px 10px 0px 0px;
        color: $dark-night;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }

      .search-container {
        display: flex;
        align-items: center;
        padding: 5px;
        color: $dark-night;
        font-family: "Roboto";
        font-style: normal;
        font-size: 14px;
        font-weight: 400;
        box-sizing: border-box;
        height: 32px;
        border: 1px solid $link-water;
        border-radius: 4px;
        padding-right: 1%;
        padding-left: 0.5% !important;

        &:focus-within {
          color: $ps-blue-dark;
          background-color: $white;
          border-color: $ps-blue-100;
          outline: 0;
          box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        }

        .search-icon {
          margin-right: 5px;
          color: #888;
        }

        .search-input {
          border: none;
          outline: none;
          width: 100%;
          padding-left: 0%;
        }
      }

      .attreibutesContainer {
        max-height: 500px;
        overflow-y: scroll;
        min-height: 220px;

        .info {
          color: $ps-grey-74;
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
        }
      }

      .error-Info {
        color: $dark-night !important;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 14px !important;
        line-height: 20px;
      }
    }

    .modal-footer {
      border-top: 1px solid $gray-80;

      .cancel-button {
        margin-right: 5px;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        color: $ps-blue-ef;
        background-color: $white;
        border: 1px solid $btn-border-grey;
        border-radius: 4px;
        padding: 4px 8px 4px 8px;

        &:hover {
          color: $ps-blue-dark-7c;
          background-color: $ps-white-fd;
        }
      }

      .save-button {
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        background: $ps-blue-ef;
        color: $white-bold;
        border: 0px solid $btn-border-grey;
        border-radius: 4px;
        padding: 4px 8px 4px 8px;

        &:hover {
          background-color: $ps-blue-dark-7c;
          color: $ps-white-fd;
        }
      }
    }
  }
}
