.class-activities.gru-class-activity-card {
  .class-activity-card {
    display: grid;
    background-color: $white;
    position: relative;

    // box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.3);
    .card-top-header {
      display: flex;
      justify-content: space-between;
      padding: 5px 10px;

      .content-icon {
        border-radius: 8px;
        padding: 5px;
        display: block;
        width: 28px;
        height: 28px;
        background-color: $dark-500;
        i {
          width: 18px;
          height: 18px;
          background-repeat: no-repeat;
          background-size: 100%;
          background-position: center;
          display: block;
          &.offline-activity-icon {
            background-image: url("../assets/gooru/images/offline-white.svg");
          }

          &.collection-external-icon,
          &.collection-icon {
            background-image: url("../assets/gooru/images/collection-white.svg");
          }

          &.assessment-external-icon,
          &.assessment-icon {
            background-image: url("../assets/gooru/images/assessment-white.svg");
          }
          &.meeting-icon {
            background-image: url("../assets/gooru/video-conference-white-icon.png");
          }
        }
      }

      .taxonomy-container {
        height: 35px;
        display: grid;
        align-items: center;
        text-align: end;
        cursor: pointer;
        margin: auto 0 auto auto;

        .gru-taxonomy-tag-list {
          .gru-taxonomy-tag {
            min-width: fit-content;
            max-width: 100px;
            height: 23px;
            border-radius: 12px;
            background-color: $dark-500;

            .standard-tag {
              text-align: center;
              padding: 0;

              .tag-label {
                font-size: 12px;
                font-weight: bold;
                color: $white;
                line-height: 21px;
              }

              .tag-caption {
                display: none;
              }
            }
          }

          .non-visible-tags {
            color: $font-non-visible-tag-white;
            padding: 0;

            span {
              font-weight: 700;
              font-size: 14px;
              padding-left: 5px;
              padding-right: 5px;
              height: 26px;
            }
          }
        }
      }
      &.taxonomy-container {
        position: absolute;
        z-index: 15;
        width: inherit;
        justify-content: flex-end;
        cursor: pointer;
      }
    }
    .activity-info {
      display: grid;
      align-items: center;

      .content-counts {
        display: grid;
        grid-template-columns: auto auto;
        column-gap: 5px;

        .count-container {
          display: grid;
          grid-template-columns: auto auto;
          column-gap: 3px;
          align-items: center;
          .icon-style {
            width: 20px;
            height: 20px;
            background-size: 20px;
          }

          &.task-count {
            .task-icon {
              background-image: url("../assets/gooru/images/text-resource-dark.svg");
            }
          }

          &.question-count {
            .question-icon {
              background-image: url("../assets/gooru/images/add_question.svg");
            }
          }

          &.resource-count {
            .resource-icon {
              background-image: url("../assets/gooru/images/add_resource_icon.svg");
            }
          }
        }
      }
      .taxonomy-container {
        height: 30px;
        display: grid;
        align-items: center;
        text-align: end;
        display: none;
        cursor: pointer;
        margin: auto 15px auto auto;

        .gru-taxonomy-tag-list {
          .gru-taxonomy-tag {
            min-width: fit-content;
            max-width: 100px;
            height: 23px;
            border-radius: 12px;
            background-color: $dark-500;

            .standard-tag {
              text-align: center;
              padding: 0;

              .tag-label {
                font-size: 12px;
                font-weight: bold;
                color: $white;
                line-height: 21px;
              }

              .tag-caption {
                display: none;
              }
            }
          }

          .non-visible-tags {
            color: $non-visible-tag;
            padding: 0;

            span {
              font-weight: 700;
              font-size: 14px;
              padding-left: 5px;
              padding-right: 5px;
              height: 26px;
            }
          }
        }
      }
    }

    .class-info {
      .assigned-class {
        display: grid;
        border: 1px solid $border-box-shadow;
        align-items: center;

        .class-title {
          color: $dark-400;
          padding-left: 10px;
          font-size: 13px;
          text-transform: capitalize;
          line-height: 1.2;
          white-space: normal;
        }

        .activity-action {
          text-align: center;
          padding: 3px;

          &.disable-delete {
            pointer-events: none;
            opacity: 0.5;
          }

          .icon-style {
            height: inherit;
            display: grid;
            font-size: 23px;
            align-items: center;
            cursor: pointer;
          }
        }

        .suggestion-count {
          cursor: pointer;

          i.check_circle {
            color: $orange-400;
          }
        }

        .delete {
          display: flex;
          cursor: pointer;
        }

        .oa-grading {
          height: inherit;
          width: 50px;
          border-left: 1px solid $border-box-shadow;
          display: grid;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          .rubric-icon {
            width: 25px;
            height: 25px;
            background-size: 25px;
            display: block;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url("../assets/gooru/images/rubric-green.svg");
          }

          &.not-completed {
            cursor: default;

            .rubric-icon {
              background-image: url("../assets/gooru/images/add_rubric.svg");
            }
          }
        }

        .add-data {
          width: 50px;
          height: 45px;
          border: 1px solid $border-box-shadow;
          font-size: 11px;
          line-height: 1;
          text-align: center;
          color: $white;
          text-align: center;
          display: grid;
          align-items: center;
          font-weight: bold;
          opacity: 1;
          cursor: pointer;
          padding: 11px 0;

          &.performed-activity {
            grid-template-rows: 1fr 1fr;

            .activity-score {
              font-size: 13px;
            }

            &.collection-performance {
              grid-template-rows: 1fr;

              .activity-score {
                display: none;
              }
              .activity-timespent {
                display: block;
              }
            }

            &.only-score {
              grid-template-rows: 1fr;
            }
          }

          &.grade-range-not-started {
            background-color: $gray-67;
          }

          &.not-started {
            background-color: $gray-67;
            font-size: 10px;
            text-transform: uppercase;
          }

          &.disable-performance {
            opacity: $opacity-full;
            font-weight: normal;
            pointer-events: none;
          }
          &.no-student {
            pointer-events: none;
            opacity: $opacity-full;
          }

          &.disable-acticity-performance {
            grid-template-rows: 1fr;
            pointer-events: none;
            font-size: 18px;
            color: $gray-base;
            background-color: $white;
            .activity-timespent {
              display: none;
            }
          }
        }
      }
    }

    .activity-actions {
      align-items: center;
      display: grid;
      grid-template-columns: repeat(6, auto);
      padding: 0 5px;
      border-bottom: 1px solid $light-100;

      > div {
        display: grid;
        align-items: center;
        text-align: center;
        grid-row-gap: 3px;
        line-height: 1;
      }

      .disable-delete {
        pointer-events: none;
        opacity: $opacity-light;
      }

      .video-call {
        cursor: pointer;

        &.disabled-meeting {
          opacity: $opacity-full;
        }
        .videocam {
          color: $white;
          background-color: $bg-video-icon-bg-blue-dark;
          width: fit-content;
          margin: 0 auto;
          padding: 0 3px;
          font-size: 19px;
          border-radius: 3px;
        }
      }

      .go-live {
        cursor: pointer;
        height: 30px;

        &.disable {
          .golive-icon {
            opacity: 0.5;
          }

          label {
            opacity: $opacity-light;
          }
        }

        .golive-icon {
          width: 18px;
          height: 18px;
          background-repeat: no-repeat;
          background-size: contain;
          background-image: url("../assets/gooru/on-air-icon.png");
          cursor: pointer;
          background-position: center;
          display: block;
          margin: auto;
        }
        &.disabled {
          pointer-events: none;
        }
      }

      .more-container {
        grid-column-start: 6;
        position: relative;
        .more-info-icon {
          display: grid;
          align-items: center;
          cursor: pointer;
          i {
            font-size: 28px;
          }
        }
        .more-container-list {
          padding: 5px;
          text-align: center;
          .mark-completed {
            cursor: pointer;
            display: grid;
            grid-template-columns: 30px 1fr;
            align-items: center;
            font-size: 14px;

            label {
              white-space: nowrap;
              margin: 0;
              cursor: pointer;
            }

            &.completed {
              cursor: default;
              pointer-events: none;
            }

            &.in-active {
              cursor: not-allowed;
              pointer-events: none;
              opacity: 0.5;
            }
          }
          .reschedule-activity {
            display: grid;
            grid-template-columns: 30px 1fr;
            align-items: center;
            font-size: 14px;
            padding: 5px 0;
            cursor: pointer;

            &.in-active {
              cursor: not-allowed;
              pointer-events: none;
              opacity: 0.5;
            }

            label {
              margin: 0;
              cursor: pointer;
            }
          }

          .classroom-content {
            display: grid;
            grid-template-columns: 30px 1fr;
            align-items: center;
            font-size: 14px;
            padding: 5px 0;
            cursor: pointer;

            i.classroom-icon {
              width: 24px;
              height: 24px;
              background-repeat: no-repeat;
              background-size: 100%;
              background-position: center;
              display: block;
              background-image: url("/assets/gooru/classroom-icon.svg");
            }

            &.in-active {
              cursor: not-allowed;
              pointer-events: none;
              opacity: 0.5;
            }

            label {
              margin: 0;
              cursor: pointer;
            }
          }
        }
      }

      .play-activity {
        color: $account-circle;
        cursor: pointer;
        text-align: center;
      }

      .reschedule-activity {
        cursor: pointer;
        i {
          font-size: 25px;
        }
      }

      .mastery-accrual {
        height: 20px;
        cursor: pointer;

        i {
          font-size: 25px;
        }

        &.checked {
          background: url("/assets/gooru/images/check-circle-1.svg") no-repeat
            center;
          background-size: contain;
          border: none;
          display: block;
          height: 18px;

          i {
            visibility: hidden;
          }
        }
      }

      .isMobileViewTooltip {
        position: relative;
        display: inline-block;
      }
      .isMobileViewTooltip .tooltiptext {
        visibility: hidden;
        background-color: $dark-100;
        color: white;
        border-radius: 7px;
        padding: 5px 10px;
        position: absolute;
        z-index: 1;
        top: 85%;
        left: 7px;
        line-height: normal;
      }
      .isMobileViewTooltip .tooltiptext::after {
        content: "";
        position: absolute;
        bottom: 100%;
        left: 41%;
        border: 6px solid;
        border-bottom: 8px solid $dark-100;
      }
      .isMobileViewTooltip:hover .tooltiptext {
        visibility: visible;
        text-align: center;
      }
    }
  }
  .video-card {
    .more-container,
    .delete-activity {
      cursor: pointer;
      margin-top: 2px;
    }
    .more-container {
      .dropdown {
        .more-container-list {
          padding: 5px;
          .reschedule-activity {
            i {
              position: relative;
              top: 7px;
            }
            label {
              cursor: pointer;
            }
          }
        }
      }
    }
    &.archived-video-card {
      pointer-events: none;
    }
  }
}
.activate-activity {
  .gru-switch {
    padding: 0;
    height: min-content;

    &.assigned {
      pointer-events: none;
    }

    .toggle.btn {
      width: 36px !important;
      height: 18px !important;
      border-radius: 20px;

      .toggle-group {
        .toggle-handle,
        .toggle-off,
        .toggle-on {
          border-radius: 20px;
        }

        .toggle-off,
        .toggle-on {
          font-style: italic;
          font-size: $font-size-xxsmall;
          line-height: 4px !important;
        }

        .toggle-on {
          left: -16px;
          top: -2px;
        }

        .toggle-off {
          left: 26px;
          top: -1px;
        }

        .toggle-handle {
          top: -6px;
          left: -2px;
        }
      }

      &.off {
        .toggle-group {
          .toggle-handle {
            left: 0px;
          }
        }
      }
    }
  }
}
.class-activities.gru-class-activity-card {
  &.card-view {
    .class-activity-card {
      width: 240px;
      border-radius: 4px;
      margin: 16px;
      grid-template-rows: 160px 40px 80px 45px 46px;
      .content-thumbnail {
        width: 240px;
        height: 160px;
        object-fit: contain;
        background-color: $bg-table-bg-black;
        background-size: cover;
        background-repeat: no-repeat;
        padding: 0;
        position: relative;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        background-position: center center;
        cursor: pointer;

        .icon {
          display: none;
        }

        &:after {
          display: block;
          position: absolute;
          z-index: 9;
          background-image: linear-gradient(
            to bottom,
            $bg-left-box-shadow 0,
            $gray-base 100%
          );
          margin-top: 6px;
          height: 73%;
          width: 100%;
          content: "";
        }

        .content-title {
          position: absolute;
          bottom: 5px;
          padding: 0px 8px;
          color: $white;
          z-index: 10;
          font-weight: 700;
          line-height: 1.2;
          display: flex;
          justify-content: space-between;
          text-align: center;
          width: 100%;
          $font-size: 13px;
          $line-height: 1.2;
          $lines-to-show: 2;

          .assessment-external-icons,
          .collection-external-icons {
            width: 18px;
            height: 18px;
            background-repeat: no-repeat;
            background-size: 100%;
            background-position: center;
            display: block;
            background-image: url("/assets/gooru/share.png");
          }

          span {
            @-moz-document url-prefix() {
              &::before,
              &::after {
                content: "" !important;
              }
            }
            @include ellipsis(
              $font-size: $font-size,
              $line-height: $line-height,
              $lines-to-show: $lines-to-show
            );
          }
        }
      }

      .activity-info {
        grid-template-columns: auto 1fr auto;
        height: 30px;
        border-bottom: 1px solid $border-box-shadow;
        padding: 0 5px;

        .card-thumbnail,
        .more {
          display: none;
        }

        .activity-date {
          justify-self: center;
          color: $dark-400;
          font-size: 11px;
        }
      }

      .content-info {
        padding: 5px;
        min-height: 30px;
        @include ellipsis(
          $font-size: 12px,
          $line-height: 1.3,
          $lines-to-show: 3
        );
        background: 0 0;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        max-height: 53px;
        @-moz-document url-prefix() {
          &::before,
          &::after {
            content: "" !important;
          }
        }
      }

      .class-info {
        max-height: 46px;
        overflow: hidden;

        .assigned-class {
          grid-template-columns: 1fr 40px auto auto auto;
          height: 46px;
          line-height: 35px;

          .class-list {
            display: none;
          }

          .student-count {
            cursor: pointer;

            i.account_circle {
              color: $font-account-circle-dark-400;
            }

            span {
              display: none;
            }
          }
        }
      }

      .activity-actions {
        font-size: 9px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px;
        border-top: 1px solid $light-100;
        label {
          margin: 0;
        }
        .mastery {
          i {
            font-size: 22px;
          }
        }
        .more-container {
          grid-column-start: auto;
        }
        &.archived-actions {
          pointer-events: none;
        }
      }
    }
    .secondary-class-list {
      display: grid;
      position: relative;
      background-color: $white;
      .secondary-class-list-container {
        display: grid;
        grid-template-rows: 46px 20px;
        .view-more-botton {
          width: 100%;
          text-align: center;
          background-color: $white;
          cursor: pointer;
        }
      }
      &.enabled-view {
        .secondary-class-list-container {
          grid-template-rows: 93px 20px;
          position: absolute;
          width: 100%;
          background-color: $white;

          .class-info {
            max-height: 93px;
            overflow-y: auto;
          }
        }
      }
      &.archived-secondary-class {
        pointer-events: none;
      }
    }
  }

  &.list-view {
    border-bottom: 2px solid $border-box-shadow;

    .class-activity-card {
      height: auto;
      width: 100%;
      grid-template-columns: auto 300px;
      box-shadow: 0 0 6px 1px $ruberic-box-shadow;
      border: 1px solid $border-bottom-shadow;
      .activity-actions,
      .content-info {
        display: none;
      }
      .card-top-header {
        display: none;
      }

      .content-thumbnail {
        background-image: unset !important;
        display: grid;
        grid-template-columns: 40px auto;
        cursor: pointer;

        .content-title {
          display: flex;
          justify-content: left;
          align-items: center;
          width: max-content;
          span {
            $font-size: 13px;
            $line-height: 1.2;
            $lines-to-show: 1;
            @-moz-document url-prefix() {
              &::before,
              &::after {
                content: "" !important;
              }
            }
            @include ellipsis(
              $font-size: $font-size,
              $line-height: $line-height,
              $lines-to-show: $lines-to-show
            );
          }
        }

        .icon {
          display: flex;

          %icon-content {
            width: 20px;
            height: 20px;
            background-repeat: no-repeat;
            background-size: 20px 20px;
            opacity: 0.8;
            justify-self: center;
            display: block;
            margin: auto;
            cursor: pointer;
          }

          .offline-activity-icon {
            background-image: url("../assets/gooru/images/offline.svg");
            @extend %icon-content;
          }

          .collection-external-icon,
          .collection-icon {
            @extend %icon-content;
            background-image: url("../assets/gooru/images/add_collection.svg");
          }

          .assessment-external-icon,
          .assessment-icon {
            @extend %icon-content;
            background-image: url("../assets/gooru/images/add_assessment.svg");
          }
        }
      }

      .activity-info {
        grid-template-columns: auto 160px 35px 17px;

        .content-counts {
          grid-template-columns: auto auto;

          .class-list {
            justify-content: center;
            display: flex;
            align-items: center;
            cursor: pointer;

            span {
              height: 23px;
            }
          }
        }

        .activity-date {
          display: none;
        }

        .more {
          display: flex;
          margin: auto;
          cursor: pointer;

          span {
            width: 24px;
            height: 24px;
            display: block;
          }
        }

        .card-thumbnail {
          width: 50px;
          height: 50px;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center center;
        }
        .taxonomy-container {
          display: block;
        }
      }
      .secondary-class-list {
        grid-column-start: 1;
        grid-column-end: 3;
        background-color: $class-list-bg;

        .view-more-botton {
          display: none;
        }
        &.archived-secondary-class {
          pointer-events: none;
        }
      }
      .class-info {
        grid-column-start: 1;
        grid-column-end: 3;
        background-color: $class-list-bg;

        .assigned-class {
          background-color: $white;
          grid-template-columns: 32px 1fr 70px auto auto auto;
          height: 46px;
          padding-left: 10px;

          .student-count {
            padding-left: 10px;
            display: flex;
            align-items: center;
            color: $font-sky-400-dark-400;

            span {
              cursor: pointer;
              display: block;
              padding-left: 5px;
            }
          }

          .delete {
            padding-right: 10px;
          }
        }
      }

      .activity-actions {
        display: grid;
        grid-column-start: 1;
        grid-column-end: 3;
        padding: 4px 10px;
        border-top: 1px solid $breadcrumb-color;

        > div {
          grid-template-columns: auto auto;
          grid-column-gap: 5px;
          text-align: right;

          label {
            display: inline-block;
            margin-bottom: 0;
            text-align: left;
          }
          .disable-delete {
            pointer-events: none;
            opacity: 0.5;
          }
        }

        .mastery {
          label {
            margin-bottom: 0;
            margin-top: 2px;
          }
        }
        &.archived-actions {
          pointer-events: none;
        }
      }
    }
  }
  .present {
    display: contents;
    .present-btn {
      background-image: url("../assets/gooru/images/cf_btn.png");
      height: 23px;
      max-width: 23px;
      display: block;
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center;
      cursor: pointer;
    }
  }
  .present-toggle {
    text-align: left;
    .dropdown-item {
      padding: 6px;
      cursor: pointer;
    }
  }

  @media screen and (max-width: $screen-sm) {
    &.list-view {
      .class-activity-card {
        margin-bottom: 4px;

        .activity-actions {
          > div {
            grid-template-columns: none;
            text-align: center;

            label {
              text-align: center;
            }
          }
        }
      }
    }
  }
}
