.gru-video-authorize-card {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 9999;
  overflow-y: auto;

  .backdrop1 {
    position: fixed;
    left: 0;
    top: 0;
    background-color: $navbar-default-toggle-icon-bar-bg;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .gru-video-authorize-card-container {
    width: 500px;
    margin: 0 auto;
    margin-top: 100px;
    background-color: $white;
    border-radius: 5px;
    padding: 20px;
    position: relative;
    display: grid;
    grid-template-columns: 50px 1fr;
    grid-column-gap: 10px;
    .zoom-meet-icon {
      background: url("gooru/images/zoom.svg");
      width: 50px;
      background-repeat: no-repeat;
    }

    @media screen and (max-width: 500px) {
      width: 100%;
      margin-top: 0;
    }

    i.calendar_today {
      font-size: 40px;
      color: $blue-400;
    }
    .gru-video-authorize-content {
      display: grid;
      grid-template-columns: 1fr;
      justify-items: center;
      grid-row-gap: 10px;

      .auth-header {
        font-weight: bold;
        font-size: 15px;
        color: $dark-500;
        line-height: 1.4;
      }

      .auth-btn {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;

        span {
          margin-left: 20px;
          padding: 5px;
          font-size: 14px;
          text-transform: uppercase;
          cursor: pointer;
          font-weight: bold;

          &.auth-allow {
            color: $sky-400;
          }
        }
      }
      .no-auth-btn {
        text-align: center;
        font-size: 11px;
      }
    }
  }
}
