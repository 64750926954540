.controller.forgot-password {
  display: block;
  display: flex;
  flex-direction: column;
  .copyright-information {
    position: absolute;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    bottom: 0;
    @media screen and (max-height: 650px) {
      margin-top: 10px;
      position: relative;
    }
  }
  .modal {
    display: block;
    position: relative;
    z-index: 0;
    padding: 0;
    margin: 15px auto;
    max-width: 405px;
    width: 100%;
    float: none;
    @media (min-width: $screen-sm-min) {
      width: 425px;
      max-width: 425px;
      margin: 60px auto;
    }
    .modal-dialog {
      width: 100%;
      max-width: 405px;
      margin: 0;
      @media (min-width: $screen-sm-min) {
        width: 405px;
        margin: 10px;
      }
      .modal-content {
        border: 0;
        padding: 54px 0 47px;
      }
      .modal-header {
        text-align: center;
        padding: 0;
        .img-container {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          .gooru-logo {
            background: url("gooru/images/gooru-logo.svg");
            height: 32px;
            padding: 0;
            width: 111px;
            transform: scale(0.6, 0.6);
            justify-self: center;
          }
          .tenant-logo {
            height: 50px;
            padding: 0;
            justify-self: center;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            max-width: 220px;
            width: 100%;
            &-img {
              width: 70px;
              height: 70px;
            }
            span {
              width: 112px;
              height: 112px;
              border-radius: 50%;
              background-color: $orange-aob;
              display: flex;
              align-items: center;
              justify-content: center;
              color: $white;
              font-size: 30px;
              font-weight: bold;
              text-transform: uppercase;
              margin: auto;
            }
          }
          .gooru {
            background: url("gooru/images/learningNav-logo-with-text.svg");
            background-size: contain;
            background-repeat: no-repeat;
          }
        }
      }
      .modal-body {
        padding: 0;
        .forgot-password-form {
          margin: 37px 24px 0;
          text-align: center;
          .form-group {
            margin: 5px 0 16px 0;
            label {
              width: 100%;
              text-align: initial;
              width: 317px;
            }
            input {
              text-align: left;
              padding-left: 15px;
              height: 51px;
              box-shadow: none;
              border: 1px solid #ddd;
              box-shadow: none;
              &:focus {
                border-color: $blue-400;
              }
            }
            .validation.error {
              position: relative;
              top: -15px;
            }

            &.resend-button {
              font-weight: 500;
              font-size: 18px;
              line-height: 28px;
              color: #1162ee;
              background: #ffffff;
              border: 1px solid #dfe2e2;
              border-radius: 6px;
            }

            &.submit-button {
              .cancel {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                text-align: center;
                color: #1162ee;
                cursor: pointer;
              }
              .resend-button {
                font-weight: 500;
                font-size: 18px;
                line-height: 28px;
                color: #1162ee;
                background: #ffffff;
                border: 1px solid #dfe2e2;
                border-radius: 6px;
              }

              button {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                width: 314px;
                height: 44px;
                border-radius: 4px;
                border: 1px solid #677274;
                position: relative;
                font-weight: 500;
                font-size: 18px;
                line-height: 28px;
                color: #ffffff;
                i {
                  font-size: $font-size-h3;
                  line-height: 48px;
                  float: $menu-float-dir;
                }
                &.focus {
                  background: #0050a2;
                  color: #fff;
                  box-shadow: 0 0 0 1px #fff, 0 0 0 2px #1c6aef,
                    0 0 0 4px #bad7ff;
                }
              }
            }
            span.error {
              color: $red-400;
              display: inline-block;
              font-size: 12px;
              margin-top: 4px;
            }
          }
          .footer-description {
            line-height: $font-size-h6;
            .forgot-img {
              background-image: url("../assets/gooru/images/forgot-image.svg");
            }
            .description-one {
              font-weight: 400;
              font-size: 18px;
              line-height: 26px;
              text-align: center;
              color: #333333;
            }
            .forgot-1 {
              font-weight: 400;
              font-size: 18px;
              line-height: 26px;
              text-align: center;
              color: #333333;
            }
            .forgot-2 {
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              text-align: center;
              color: #333333;
            }
            .description-two {
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              text-align: center;
              color: #333333;
            }
          }
        }
      }
    }
  }
}
