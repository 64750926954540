.reports.assessment.questions.qz-true-false {
  ul {
    padding-left: 0;

    li {
      list-style: none;
      position: relative;

      span.correct {
        color: $brand-success;
      }

      span.incorrect {
        color: $brand-incorrect;
      }

      span.anonymous {
        color: $brand-anonymous;
      }
    }

    li span.answer-text {
      display: inline-block;
      margin-left: 2.5em;
      margin-top: 0.4em;

      p {
        display: inline-block;
        margin: 0;
        padding: 0;
      }
    }
  }

  li {
    &.incorrect_answer {
      border: 1px solid $incorrect-color !important;
    }

    &.correct_answer {
      border: 1px solid $green-700 !important;
    }

    .incorrect {
      i {
        left: 20px;
        top: 10px;
      }
    }
  }

  li span i {
    position: absolute;
    top: 0.2em;
    left: 0;
  }
}
