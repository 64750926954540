.player-external {
  .gru-header {
    display: none;
  }

  .external-player-container {
    .header-container {
      top: 0;
      width: 100%;
      position: fixed;
      color: $white;
      z-index: $zindex-navbar-fixed + 1;
      height: 64px;
      background-color: $blue-400;

      .action {
        width: 68px;
        height: 64px;
        border-right: 2px solid $light-200;
        cursor: pointer;

        .close-icon {
          padding: 21px;

          i {
            font-size: 25px;
            color: $white;
          }
        }
      }

      .content-title {
        width: calc(100% - 200px);
        height: 64px;

        &.capture-feedback {
          .gru-activity-feedback {
            .backdrop-close {
              z-index: 1;
            }

            .feedback-content-tab {
              position: absolute;
              top: 6px;
              bottom: 10px;

              .tab-info {
                padding: 19px;
              }
            }

            .feedback-content-body {
              top: 60px;
              position: absolute;

              .feedback-content-tab {
                .tab-info {
                  display: none;
                }

                .feedback-content-tab-box {
                  &.pull-up {
                    display: none;
                  }
                }
              }
            }
          }
        }

        .title-info {
          height: 64px;

          i.icon {
            background-color: $white;
            width: 25px;
            height: 25px;
            background-size: 23px;
            display: block;
            background-repeat: no-repeat;
            background-color: $table-bg;
            margin: 23px 15px 0;
          }

          i.assessment-external {
            background-image: url("gooru/images/navigation_assessment.svg");
          }

          i.collection-external {
            background-image: url("gooru/images/navigation_collection.svg");
          }
        }

        .title {
          width: calc(100% - 60px);
          position: relative;
          @include translateY(-50%);
          top: 50%;
          $line-height: 1.3;
          $lines-to-show: 2;
          @include ellipsis(
            $font-size: 16px,
            $line-height: $line-height,
            $lines-to-show: 2,
            $excerpt-bg: $blue-400
          );
        }
      }
      .remix {
        width: 125px;
        height: 64px;
        border-left: 2px solid $light-200;
        cursor: pointer;
        .nav-icon-container {
          i {
            margin-left: -53px;
            margin-top: 20px;
          }
          span {
            padding-left: 4px;
            padding-top: 20px;
          }
        }
      }
    }

    .player-container {
      .iframe-contaier {
        margin: 20px 15px;
        height: calc(100vh - 95px);
        iframe {
          width: 100%;
          height: 100%;
        }
      }

      .gru-external-assessment-page {
        height: calc(100vh - 60px);
      }

      .scorm-player,
      .gru-external-collection-page {
        height: calc(100vh - 50px);
      }
    }

    .toggle-screen {
      position: absolute;
      right: 0;
      top: 64px;
      background-color: $white;
      width: 35px;
      height: 35px;
      cursor: pointer;
      border: 2px solid $thumbnail-border;
      border-radius: 5px 0 0 5px;
      z-index: 9999;

      i {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
      }
    }
  }
  &.fullscreen {
    //Fullscreen mode global styles

    .app-container {
      padding: 0;

      .header-container {
        display: none;
      }
      .toggle-screen {
        top: 0;
      }
      .external-player-container {
        height: 100%;
        overflow: hidden;
        position: relative;
        .player-container {
          .scorm-player {
            height: 100%;
            .scorm-file {
              height: 100vh;
              width: 100%;
              margin: 0;
            }
          }
        }
      }
    }
  }
}
