.ca-student-suggestion-panel {
  position: fixed;
  top: 100%;
  z-index: 1031;
  width: 768px;
  left: 0;
  right: 0;
  margin: auto;
  color: $suggestion-panel-color;
  height: 100%;

  .backdrop {
    background-color: $backdrop-color;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    cursor: pointer;
    opacity: 0.8;
    background-color: $gray-base;
  }

  .panel {
    display: grid;
    grid-template-rows: 50px 1fr;
    background-color: $white;
    height: 100%;

    .header {
      border-bottom: 1px solid $breadcrumb-color;
      display: grid;
      grid-template-columns: 1fr 50px;
      color: $suggestion-panel-color;

      .title {
        font-size: 20px;
        padding: 5px;
      }

      .report-close {
        display: grid;
        align-items: center;
        height: 100%;
        justify-content: flex-end;
        padding-right: 10px;

        i {
          font-size: 25px;
        }
      }
    }

    .three-bounce-spinner {
      visibility: visible;
    }

    > .suggestion-panel:first-child {
      border-top: 1px solid $breadcrumb-color;
    }

    .suggestion-panel {
      display: inline-grid;
      width: 100%;
      grid-template-columns: 65px auto 60px 150px 60px 60px;
      height: 60px;
      color: $orange-400;
      border-bottom: 1px solid $breadcrumb-color;

      .icon {
        display: inline-flex;

        %icon-container {
          width: 20px;
          height: 20px;
          min-height: 20px;
          background-repeat: no-repeat;
          background-size: 20px 20px;
          opacity: 0.8;
          justify-self: center;
          margin: auto;
          display: block;
        }

        i {
          &.offlineactivity {
            @extend %icon-container;
            background-image: url("../assets/gooru/images/offline-orange.svg");
          }

          &.assessment {
            @extend %icon-container;
            background-image: url("../assets/gooru/images/assessment-orange.svg");
          }

          &.collection {
            @extend %icon-container;
            background-image: url("../assets/gooru/images/collection-orange.svg");
          }
        }
      }

      .title-info {
        display: flex;
        align-items: center;

        p {
          margin: 0;
          color: $gray-29;
          padding: 0 20px 0 0;
          @include ellipsis(
            $font-size: 15px,
            $line-height: 1.5,
            $lines-to-show: 1
          );
        }
      }

      .user-count {
        display: grid;
        grid-template-columns: repeat(2, auto);
        cursor: pointer;
      }

      .content-counts {
        display: inline-flex;
        align-items: center;
        justify-content: space-evenly;
        justify-self: center;

        .resource-count {
          width: 40px;
          display: inline-grid;
          align-items: center;
          grid-template-columns: 50% 50%;
          justify-items: center;
          height: 20px;

          .resource-icon {
            width: 20px;
            height: 20px;
            background-repeat: no-repeat;
            background-size: 20px 20px;
            background-image: url("../assets/gooru/images/add_resource_icon.svg");
            opacity: 0.8;
          }

          span {
            height: 20px;
          }
        }

        .question-count {
          width: 40px;
          display: inline-grid;
          align-items: center;
          grid-template-columns: 50% 50%;
          justify-items: center;
          height: 20px;

          .question-icon {
            width: 20px;
            height: 20px;
            background-repeat: no-repeat;
            background-size: 20px 20px;
            background-image: url("../assets/gooru/images/add_question.svg");
            opacity: 0.8;
          }

          span {
            height: 20px;
          }
        }
      }

      .play-container {
        pointer-events: all;
        cursor: pointer;
        display: flex;

        .ca-play-btn {
          width: max-content;
          height: max-content;
          margin: auto;

          a {
            color: $orange-49;
          }
        }
      }

      .suggestion-panel-actions-performance {
        width: 100%;
        height: 100%;

        .thumbnail-img {
          width: 100%;
          height: 100%;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .suggestion-panel-performance {
          width: 100%;
          height: 100%;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          color: $white;
          font-size: 18px;
          font-weight: bold;
          cursor: pointer;

          &.collection {
            background-color: $gray-e68;
            font-size: 12px;
            text-overflow: ellipsis;
          }

          &.not-started {
            pointer-events: none;
          }

          .no-performance {
            color: $dark-400;
          }
        }
      }
    }
  }
}
