.competency-report-pull-up {
  position: fixed;
  top: 100%;
  z-index: 1031;
  width: 768px;
  left: 0;
  right: 0;
  margin: auto;
  color: $suggestion-panel-color;
  display: block;
  background-color: $white;

  .competency-report {
    background-color: $white;

    .competency-report-header {
      display: flex;
      box-shadow: 0 2px 4px 0 $box-shadow-bg-color;
      display: grid;
      grid-template-columns: 60px 1fr 50px;

      &.disable-student-performance {
        grid-template-columns: 1fr 50px;
        padding-left: 15px;
        .competency-status {
          display: none;
        }
        .report-info {
          .competency-status {
            display: none;
          }
        }
      }

      .competency-status {
        width: 24px;
        height: 24px;
        justify-self: center;
        margin: auto;

        &.status-0 {
          opacity: 0.3;
          background-color: $gray-no-started;
        }

        &.status-1 {
          background-color: $blue-in-progress;
        }

        &.status-2,
        &.status-3,
        &.status-4,
        &.status-5 {
          background-color: $blue-mastery;
        }
        @media screen and (max-width: 767px) {
          &.status-0,
          &.status-1,
          &.status-2,
          &.status-3,
          &.status-4,
          &.status-5 {
            margin: 20px 10px 10px 10px;
          }
        }
      }

      .report-info {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;

        .title-section {
          width: 100%;
          font-size: 16px;
          color: $dark-400;

          .title-content {
            display: grid;
          }

          .thumbnail-student-img {
            width: 29px;
            border-radius: 50%;
            border-color: transparent;
            height: 29px;
          }

          .domain-name {
            font-size: 10px;
            font-style: italic;
            color: $dark-400;
          }

          .title-content {
            .competency-code {
              font-size: 12px;
              font-weight: bold;
              color: $dark-400;
            }

            .type {
              text-transform: uppercase;
            }
          }

          .title {
            text-transform: capitalize;
          }

          .title,
          .type {
            height: 21px;
            line-height: 21px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          &.content {
            margin: auto;

            .type {
              font-size: 20px;
              font-weight: bold;
              color: #36424a;
            }
          }
        }

        .competency-status {
          width: 100%;
          color: $dark-400;
          font-size: 16px;
          height: 100%;
          display: flex;
          align-items: center;

          .badge {
            background-image: url("gooru/gooru-edu-rights-badge.png");
            width: 69px;
            height: 60px;
            background-size: cover;
            background-color: $white;
            margin-left: 15px;
            margin-top: -8px;
          }
        }
      }

      .close-pullup {
        opacity: 0.5;
        align-items: center;
        display: flex;
        justify-content: center;

        i {
          font-size: 28px;
        }
      }
      @media screen and (max-width: 767px) {
        display: flex;
        .report-info {
          grid-template-columns: auto;
          padding-left: 10px;
        }
      }
    }

    .body {
      height: 80vh;
      min-height: 135px;
      width: 760px;
      overflow-x: hidden;
      overflow-y: auto;
      width: 100%;
    }
  }
  @media screen and (max-width: $screen-md) {
    width: 100vw;

    .competency-report {
      .body {
        width: 100vw;

        .collection-accordion {
          .collection-content {
            .collection-header {
              .collection-info .title {
                width: 73%;
              }
            }
          }
        }
      }
    }
  }
}
