.gru-user-teaser {
  span {
    display: inline-block;
    vertical-align: middle;
    img.avatar {
      width: 25px;
      height: 25px;
      border-radius: 50px;
      margin: 5px;
      display: inline-block;
      vertical-align: middle;
    }
    p {
      margin: 0px;
    }
  }
}
