.qz-youtube-resource {
  height: 100%;
  width: 100%;
  iframe {
    margin: 0 auto;
    display: block;
    width: 100%;
    height: 100%;
  }
}
