.qz-submit-confirmation {
  width: 100%;
  .panel-heading {
    display: block;
    text-align: left;
    background-color: $white;
    color: #37424b;
    border-radius: 10px;
    padding: 8px;
  }

  .title {
    // color: $white;
    font-size: $font-size-h6;
  }

  .panel-body {
    padding: 15px 30px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);

    .description {
      font-size: 15px;
      line-height: 1.5px;
    }

    div {
      display: block;
      width: 100%;
    }

    .info {
      @include flexbox;
      @include align-items(center);
      margin-top: 35px;

      > * {
        float: $tab-float-dir;
      }

      p {
        line-height: 22px;
        margin: 0;
      }

      i {
        font-size: $font-size-h2;
        margin-right: 10px;
      }
    }

    .actions {
      margin-top: 30px;
      text-align: right;
      .cancel,
      .submit {
        padding: 3px 17px;
        border-radius: 20px;
        color: #fff;
      }
      .cancel {
        background-color: #37424b;
      }
    }
  }
}
