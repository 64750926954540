.gru-standard-domain-panel {
  .gru-standard-domain-panel-container {
    width: 100%;
    height: 100%;
    box-shadow: 0 1px 3px 0 $navbar-box-shadow;
    background-color: $white-bold;
    display: grid;
    grid-template-rows: auto 1fr;
    position: relative;
    @media screen and (max-width: 768px) {
      min-width: 380px;
    }

    @media screen and (max-width: 800px) {
      min-width: auto;
      position: static;
    }

    .close-icon {
      padding-top: 10px;
      padding-right: 10px;
      display: none;
      i {
        font-size: 20px;
      }
    }

    a {
      text-decoration: none;
      color: $dark-200;
    }

    .panel-header {
      padding: 16px;
      border-bottom: solid 1px $nobel-gray;
      display: grid;
      grid-template-columns: auto auto;
      .show-div {
        @media screen and (min-width: 801px) {
          display: none;
        }
      }
      @media print {
        display: none;
      }
    }

    .hide-div {
      @media screen and (max-width: 800px) {
        display: none !important;
      }
    }
    .search-competencies {
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: center;
      border-bottom: 2px solid $light-200;
      margin: 10px 20px;

      input {
        width: 100%;
        border: none;
        box-shadow: none;

        &::placeholder {
          font-style: italic;
        }
      }
      i {
        font-size: 20px;
        color: $light-200;
      }
      @media print {
        display: none;
      }
    }

    @media screen and (max-width: 800px) {
      &.pullUp {
        position: absolute;
        top: 65%;
        width: 91%;
        grid-template-rows: 10px auto 1fr;
        left: 4.5%;
        height: calc(100% - 56%);
        .close-icon {
          display: block;
        }
        .domain-list {
          display: block;
          height: calc(90vh - 415px) !important;
        }
      }
    }
    .domain-list {
      padding-left: 16px;
      overflow: hidden;
      height: calc(90vh - 150px);
      overflow-y: auto;
      @media screen and (max-width: 800px) {
        display: none;
      }

      .domain-list-panel {
        .list-panel-head {
          display: block;
          border-bottom: 1px solid $light-300;
          padding: 10px 5px;

          .domain-details-section {
            display: grid;
            grid-template-columns: auto 1fr;
            align-items: center;
            grid-column-gap: 10px;
            font-size: 14px;

            .domain-name-title {
              span {
                display: block;
                line-height: 1.4;
                &.title {
                  color: $font-blue-8e7-blue-dark;
                  font-weight: 400;
                  line-height: 1.4;
                }
                &.counts {
                  font-weight: bold;
                  white-space: nowrap;
                  font-size: 13px;
                }
              }
            }
          }
          .competency-info-section {
            display: grid;
            grid-template-columns: 1fr 1fr;
            align-items: center;
            display: none;
            .competency-info-card {
              text-align: center;
              padding: 5px;
              &:first-child {
                border-right: 1px solid $light-200;
              }
              span {
                display: block;

                &.competency-code {
                  font-weight: bold;
                  font-size: 15px;
                }

                &.title-comp {
                  color: $font-grey-light-dark-400;
                  @include ellipsis(
                    $font-size: 13px,
                    $line-height: 1.2,
                    $lines-to-show: 1
                  );
                }
              }
            }
          }
        }

        .domain-list-panel-body {
          padding-left: 10px;
          .list-panel-head {
            .domain-details-section {
              .title {
                color: $dark-200;
              }
            }
          }
        }
      }
      @media print {
        display: none;
      }
    }
  }

  .three-bounce-spinner {
    position: absolute;
    left: 39.5%;
    z-index: 9;
    bottom: 50%;
  }
}
