.evidence {
  margin: 10px 0 15px;

  .title {
    margin-bottom: 8px;
    text-transform: uppercase;
  }

  .evidence-view {
    display: grid;
    grid-template-columns: 50px 1fr 50px;
    min-height: 48px;
    background-color: $white;
    border-bottom: 1px solid $gray-e4;

    i {
      margin: auto;
      cursor: pointer;
    }

    .file-details {
      padding-top: 4px;
      cursor: pointer;

      .title-name {
        height: 18px;
        text-transform: uppercase;
      }

      .name {
        color: $dark-400;
        opacity: 0.7;
        font-size: 11px;
      }
    }

    .delete-icon {
      margin: auto;
      cursor: pointer;
      margin-top: 13px;
    }
  }

  .evidence-view-default {
    display: grid;
    grid-template-columns: 50px 1fr;
    min-height: 48px;
    background-color: $white;
    cursor: pointer;
    border-bottom: 1px solid$gray-e4;

    i {
      margin: auto;
    }

    .file-details {
      padding-top: 4px;

      .title-name {
        height: 18px;
        text-transform: uppercase;
      }

      .name {
        color: $dark-400;
        opacity: 0.7;
        font-size: 11px;
      }
    }
  }
}

.evidence-iframe-container {
  z-index: 1031;
  width: 80%;
  left: 0;
  right: 0;
  margin: auto;
  color: $suggestion-panel-color;
  position: fixed;
  bottom: 0;
  top: 10px;

  .back-drop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1 !important;
    cursor: pointer;
  }

  .iframe-panel {
    background: $white;
    width: 100%;
    max-height: 90%;
    height: 100%;
    overflow: visible;

    .close-panel {
      display: flex;
      justify-content: space-between;
      padding: 10px;

      i {
        cursor: pointer;
      }
    }
  }
}
