.student_class_diagnosis-of-knowledge {
  background-color: $grey-background;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .student.class {
    width: 100%;
    height: calc(100% - 16px);
    margin: 8px;
  }

  .app-container {
    .gru-study-navbar {
      .course-map {
        pointer-events: none;
        opacity: 0.5;
      }
    }

    .diagnosis-of-knowledge {
      height: 100%;
      @media screen and (max-width: 799px) {
        height: 90%;
        margin-top: 50px;
      }

      .diagnosis-of-knowledge-panel {
        width: 360px;
        border-radius: 10px;
        background-color: $white-bold;
        height: 100%;
        @media screen and (max-width: $screen-600) {
          width: 95%;
          margin-left: 0;
          margin-top: 0;
        }
        .header-panel {
          width: 100%;

          .assessment-info {
            height: 56px;
            display: grid;
            grid-template-columns: 56px auto;

            .icon-container {
              display: flex;
              justify-content: center;
              align-items: center;
              background-repeat: no-repeat;
              background-position: center;
              background-image: url("gooru/images/add_assessment.svg");
            }

            .title-container {
              display: flex;
              align-items: flex-start;
              justify-content: center;
              color: $dark-400;
              flex-direction: column;

              .assessment-title {
                font-size: 14px;
                font-weight: 300;
                margin: 0;
              }
            }
          }

          .assessment-desc {
            padding: 0 15px;
            .question {
              display: flex;
              font-size: 13px;
              opacity: 0.5;

              .icon {
                height: 24px;
                width: 24px;
                display: block;
                background-size: cover;
                background-repeat: no-repeat;
                margin-left: 9px;
                background-image: url("gooru/images/add_question.svg");
              }
            }
          }
        }
        .establish-skyline {
          margin: 15px;
        }
        .diagnostic-message {
          padding: 0 15px;
          line-height: 1.3;
          font-size: 13px;
        }
      }
    }

    .footer {
      position: fixed;
      bottom: 0;
      height: 40px;
      background: $white;
      right: 0;
      z-index: 20;
      width: 100%;
      box-shadow: 0 -1px 4px 0 $navbar-default-toggle-icon-bar-bg;
      padding: 0 10px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .cancel,
      .start {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 95px;
        height: 25px;
        border-radius: 4px;
        color: $white;
        margin: 0 5px;
        cursor: pointer;
        &.disabled {
          pointer-events: none;
          opacity: 0.5;
        }
      }

      .cancel {
        color: $blue-bg;
        border: 1px solid $btn-border-grey;
        background-color: $btn-background;
      }
      .start {
        background-color: $blue-bg;
      }

      .start:hover {
        background-color: $blue-hover-bg;
      }

      .start:focus {
        box-shadow: 0px 0px 0px 1px $white-backgraound, 0px 0px 0px 2px $blue-bg,
          0px 0px 0px 4px $blue-pale;
        background: $blue-focus-bg;
      }

      .cancel:hover {
        background-color: $btn-background;
        border: 1px solid $btn-border-grey;
      }

      .cancel:focus {
        background: $btn-background;
        border: 1px solid $btn-border-grey;
        box-shadow: 0px 0px 0px 1px $white-backgraound, 0px 0px 0px 2px $blue-bg,
          0px 0px 0px 4px $blue-pale;
      }
    }
  }
}
