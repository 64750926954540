.gru-jump-start-courses {
  .jump-start-container {
    .jump-start-header-panel {
      background-color: $white;
      .jump-start-head {
        display: grid;
        grid-template-columns: 50px 1fr;
        grid-column-gap: 10px;
        align-items: center;
        padding: 0 10px;
        .back-button {
          display: flex;
          align-items: center;
          cursor: pointer;
          i {
            font-size: 35px;
          }
        }
        .header-title {
          font-size: 35px;
        }
      }
    }
    .jump-start-panel-body {
      background-image: url("gooru/images/program-banner.jpg");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: $dark-400;
        opacity: 0.5;
      }
      .jump-start-panel-section {
        width: 80%;
        margin: 0 auto;
        position: relative;
        .jump-start-panel-head-message {
          width: 500px;
          background-color: $white;
          padding: 30px;
          box-shadow: 0 5px 10px $dark-200;
          height: 400px;
          display: grid;
          align-items: center;
          .jump-start-message {
            span {
              font-size: 30px;
              display: block;
              line-height: 1.3;
              &.jump-start-title {
                font-size: 35px;
                font-weight: bold;
              }
            }
          }
          .jump-start-panel-message {
            padding: 10px 0;
            font-size: 20px;
          }
          @media screen and (max-width: 767px) {
            width: auto;
            height: 100%;
          }
        }
      }
    }
    .jump-start-panel-cards {
      &:last-child {
        .divider-line {
          display: none;
        }
      }
      .jump-start-course-container-panel {
        .program-course-list-panel {
          display: flex;
          flex-wrap: wrap;
          grid-row-gap: 20px;
          margin: 0 auto;
          max-width: 1400px;
        }
      }

      .divider-line {
        border-bottom: 1px dashed $black-bold;
        width: 300px;
        margin: 10px auto;
        position: relative;

        &::before {
          content: "";
          width: 10px;
          height: 10px;
          border: 1px solid $black-bold;
          border-radius: 50%;
          position: absolute;
          top: -4px;
          left: -10px;
        }
        &::after {
          content: "";
          width: 10px;
          height: 10px;
          border: 1px solid $black-bold;
          border-radius: 50%;
          position: absolute;
          top: -4px;
          right: -10px;
        }
      }
    }
  }
}
