.player .gru-hs-text {
  .answer-choices {
    margin-top: 20px;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li.answer {
        position: relative;
        border: 2px solid $gray-lighter;
        margin-top: 10px;
        padding: 15px;
        border-radius: 7px;
        word-wrap: break-word;
        overflow: auto;

        &.selected {
          border-color: $blue-400;
          div {
            display: inline-block;
          }
        }

        &.disabled {
          cursor: $cursor-disabled;
        }

        &:not(.disabled):hover {
          border-color: $dark-300;
        }
        > * {
          display: inline-block;
        }
        div {
          float: $menu-float-dir;
          display: none;
          i {
            color: $blue-400;
          }
        }
      }
    }
  }
}
