.model-playlist {
  neon-2_14_0-icon[data-icon="close"] {
    margin-left: auto;
    padding-right: 0px;
    width: 70px;
  }
  .modal-title {
    font-size: $font-size-lead;
    padding-left: 25px;
    font-weight: 500;
  }
  .cross-icon:hover {
    cursor: pointer;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $black-bold;
  height: 80px;
}

.search-container {
  .search-input {
    flex: 1;
    border: none;
    font-size: 16px;
    margin-left: 8px;
  }
  .search-bar {
    .search-icon {
      margin-left: 8px;
    }
    display: flex;
    align-items: center;
    width: 100%;
  }
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 2px;
  margin-bottom: 16px;
}

.new-line {
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
  width: 100%;
  border-color: darkgray;
  border-width: 2px;
}

.title-heading {
  font-size: 17px;
  font-weight: 400;
}

.playlist-heading {
  margin-top: 20px;
  margin-bottom: 15px;
  font-size: 17px;
}

.add-to {
  .modals {
    background-color: $white;
  }
  &.fade {
    .modal-dialog {
      -webkit-transform: translate3d(125%, 0, 0);
      height: 96%;
      margin: 0 auto;
      transform: translate3d(125%, 0, 0);
      float: $menu-float-dir;
      width: 280px;
      @media (min-width: $screen-sm-min) {
        width: 520px;
      }
    }

    &.in {
      .modal-dialog {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
      }
    }
  }

  .gru-add-to {
    @include flexbox;
    @include flex-direction(column);
    border: 0;
    .has-feedback label ~ .form-control-feedback {
      top: 0;
    }
  }
}

.gru-add-to {
  height: 100vh;
  border-radius: 0;

  .modal-header {
    @include flex-basis(40px);
    @include flex-shrink(0);
    background-color: $blue-400;
    border-radius: 3px;
    color: $white;
    padding: 7px 10px;
    text-align: initial;
  }

  .modal-body {
    @include flex-grow(1);
    @include flexbox;
    @include flex-wrap(wrap);
    @include align-content(flex-start);
    @include flex-direction(column);
    border: 0;
    height: 100%;
    margin-top: 10px;
    overflow: hidden;
    padding: 0 25px;

    .accordion {
      width: 100%;
      .neon-accordion-content {
        padding: 10px 0px 15px 44px;
      }
      #accordion-create-playlist {
        .neon-text-field-input-container {
          width: 250%;
          > input:focus {
            border: 1px solid #c4c9ca;
          }
        }

        #buttons-playlist {
          float: none;
          margin-left: 68%;
          margin-top: 12px;
        }
      }
    }

    .description {
      @include flexbox;
      @include flex-wrap(wrap);
      @include justify-content(space-between);
      @media (max-width: $screen-xs-max) {
        @include justify-content(center);
      }
      border: 1px $table-bg;
      box-shadow: 0 2px $light-200;
      font-size: $font-size-base;
      margin: 0 0 5px;
      width: 100%;

      > span {
        @media (max-width: $screen-xs-max) {
          @include order(2);
        }
      }

      .search {
        @media (max-width: $screen-xs-max) {
          @include order(1);
        }

        > i {
          margin-left: 7px;
          margin-top: 7px;
          position: absolute;
        }
      }

      .form-group {
        //margin: 0;
      }

      input {
        border: solid 2px $light-200;
        border-radius: 3px;
        box-shadow: none;
        height: 35px;
        padding-left: 30px;
        width: 210px;
      }
    }

    > .info {
      @include flexbox;
      @include align-items(center);
      line-height: 1.2;
      margin-bottom: 10px;

      .gru-icon {
        color: $yellow-300;
        margin-right: 5px;
      }
    }

    .category-options {
      margin: 20px auto;
      height: 35px;
      button {
        margin-right: 2px;
      }

      .btn-secondary {
        background-color: $gray;
      }

      i {
        display: inline-block;
        line-height: 16px;
        font-size: $font-size-h5;
        vertical-align: middle;
        margin-right: 5px;
      }

      .assessments {
        i {
          background: url("gooru/images/navigation_assessment.svg");
          width: 20px;
          min-height: 15px;
        }
        &.active {
          i {
            background: url("gooru/images/navigation_assessment_selected.svg");
          }
        }
      }

      .collections {
        i {
          background: url("gooru/images/navigation_collection.svg");
          width: 20px;
          min-height: 15px;
        }
        &.active {
          i {
            background: url("gooru/images/navigation_collection_selected.svg");
          }
        }
      }
    }

    .list {
      @include flex(1);
      @include flexbox;
      @include flex-wrap(wrap);
      @include align-items(flex-start);
      @include align-content(flex-start);
      border: 0;
      margin: 0 -40px 0 0;
      overflow-y: scroll;
      padding: 0;

      .show-more-results {
        width: 50%;
        text-align: center;
        padding: 20px;
        border: 2px dashed $breadcrumb-color;
        margin: 0px 108px 16px 108px;
        border-radius: 5px 5px;
        cursor: pointer;
      }
      > * {
        @include flex-shrink(0);
        border: 1px $table-bg;
        width: 244px;
        margin-bottom: 16px;
      }

      .no-more-results {
        width: 50%;
        text-align: center;
        padding: 20px;
        margin: 0px 108px 16px 108px;
        cursor: pointer;
      }

      .panel {
        margin: 0;
      }

      .gru-collection-card {
        margin: 0;
        width: 229px;
        .panel {
          width: 100%;
          &:hover {
            cursor: pointer;
          }
          .panel-heading {
            padding: 0;
            height: 64px;
            border-radius: 6px;
            img {
              height: 64px;
              width: 64px;
              border-bottom-right-radius: 0;
              border-top-right-radius: 0;
            }
            .collection-info {
              margin-left: 5px;
              height: 64px;
              width: 150px;
              .question-resources {
                font-size: $font-size-xsmall;
              }
            }
          }
        }
      }

      .selected {
        .gru-collection-card {
          border: 3px solid $blue-400;
          border-radius: 6px;
        }
      }
    }
  }

  .modal-footer {
    @include flex-basis(44px);
    @include flex-shrink(0);
    height: 44px;
    margin-top: 10px;
    padding: 0;
    width: 100%;

    div {
      &:last-child {
        margin-right: 25px;
      }
    }

    .btn-group {
      height: 100%;

      > * {
        height: 100%;
        width: 50%;
      }
    }
    .btn-empty.cancel {
      border: 1px solid $gray-light;
      background-color: $white;
    }
    .hidden-xs,
    .visible-xs {
      .clear-materials {
        background: none;
        border: none;
        color: $ps-red-200;
        margin-right: 170px;
        font-weight: 500;
      }
      .cancel-button {
        margin-right: 16px;
      }

      .ps-custom-button {
        width: 70px;
        font-family: "Roboto";
        font-style: normal;
        font-weight: normal;
        line-height: 31px;
        color: $white-bold;
        background: $blue-bg;
        border: 1px solid $btn-border-grey;
        border-radius: 4px;
      }
      .ps-custom-button:hover {
        background: $ps-blue-a2;
      }
      .ps-custom-button:focus {
        background: $blue-active-bg;
        box-shadow: 0px 0px 0px 1px $white-bold, 0px 0px 0px 2px $blue-bg,
          0px 0px 0px 4px $blue-pale;
      }
      .ps-custom-button:disabled {
        background: $grey-background;
        color: $black-bold;
      }
    }
  }
}
