.teacher-external-assessment-report-grid-view {
  .grid-view-container {
    .external-assessment-link {
      width: 310px;
      text-align: center;
      margin: 15px auto;
      font-size: 15px;
      color: $dark-400;
      .note {
        margin-bottom: 8px;
      }
      .link {
        color: $link-blue-color;
      }
    }

    .cards-container {
      margin: 10px 0 0 10px;
      .card {
        width: 225px;
        height: 72px;
        border-radius: 4px;
        background-color: $white;
        box-shadow: 0 0 4px 1px $navbar-box-shadow;
        display: flex;
        float: left;
        margin: 10px;
        .student-thumbnail {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          background-size: contain;
          margin: 12px 5px;
        }
        .student-name {
          margin-top: 13px;
          .firstname,
          .lastname {
            font-size: 13px;
            color: $black-bold;
            width: 95px;
          }
        }
        .student-score {
          opacity: 0.9;
          font-size: 25px;
          font-weight: bold;
          text-align: center;
          width: 70px;
          margin-top: 14px;

          &.student-timeSpent {
            font-size: 15px;
          }
        }
      }
    }
  }
}
