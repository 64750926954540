.network {
  @media (max-width: $screen-sm-max) {
    .content-navigation .category-menu ul {
      li {
        top: 2px;
      }
    }
  }

  .category-menu {
    max-width: 960px;
    border-bottom: 2px solid $gray-light;
    margin-left: 29px;
    margin-right: 47px;

    .category-options {
      li {
        top: 9px;
        margin-left: 0;
        margin-right: 20px;

        a {
          span {
            color: $gray-shaft;
            font-size: 16px;
          }
          box-shadow: none;

          &:hover {
            box-shadow: none;
          }
        }

        &.active {
          border-bottom: 4px solid $gray-darker;
        }
      }

      :hover {
        a {
          span {
            opacity: 1;
          }
        }
      }
    }
  }

  .network-followers,
  .network-followings {
    text-align: center;
    @media (min-width: $screen-sm) {
      padding: 0 0 0 30px;
      text-align: $tab-float-dir;
    }

    > .followings,
    > .followers {
      margin: 30px auto;
      float: none;
      padding: 0;
    }
  }

  .card {
    float: $tab-float-dir;
    margin: 0 20px 10px;
    @media (min-width: $screen-sm) {
      margin: 0 20px 10px 0;
    }

    .gru-user-network-card {
      button {
        &:hover {
          color: $white;
        }
      }
    }
  }

  .panel.add-content {
    margin-top: 60px !important;
    margin-left: auto;
    margin-right: auto;
  }

  .content_outlet {
    margin-top: 20px;
    display: inline-block;
  }
}
