.backdrop-close {
  width: 100vw;
  height: 100vh;
  position: fixed;
  right: 0;
  left: 0;
  background-color: $gray-base;
  top: 0;
  opacity: 0.05;
}

.qz-activity-feedback {
  background-color: $white;
  border-radius: 10px;
  position: relative;
  width: 360px;
  display: inline-block;
  max-height: 320px;
  overflow: hidden;
  z-index: 999;
  border: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0);

  @media screen and (max-width: 320px) {
    max-width: 300px;
    margin: auto;
  }

  .feedback-header {
    display: grid;
    grid-template-columns: auto 40px;
    height: 32px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    padding: 0px 0px 0px 16px;

    .text {
      color: $dark-400;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      opacity: 0.5;
      font-family: "Montserrat";
      font-weight: bold;
      font-size: 12px;
    }

    .close-icon {
      padding: 0;
      font-size: 12px;
      width: 40px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        font-size: 24px;
      }
    }
  }

  .feedback-content-body {
    margin-top: 5px;
    .reaction-bar {
      padding: 0 10px 10px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      background-color: $white;
      border-radius: 5px;

      .reaction-header {
        padding: 7px 5px;
        justify-content: center;
        line-height: 1.5;
      }

      .reaction-content {
        padding: 7px 5px;

        .qz-emotion-picker {
          text-align: right;

          .legend {
            display: none;
          }

          .emotion {
            width: 27px;
            height: 27px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $screen-sm - 1px) {
  .qz-activity-feedback {
    .feedback-content-body {
      width: 100%;
      right: 0;

      .reaction-bar {
        .reaction-content {
          .qz-emotion-picker {
            .emotion-picker {
              .reaction-sm {
                display: none;
              }

              .emotions-list {
                display: block;
                padding: 0;
                margin: 0;
                position: relative;
                width: 100%;
                bottom: 0;

                li {
                  display: inline-block;
                  padding: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
