.scorm-file {
  height: 95%;
  width: 97%;
  margin: 20px 20px 0;
  @media only screen and (max-width: $screen-sm-min) {
    width: 95%;
    margin: 25px 10px 0;
  }
}
.scorm-footer {
  position: fixed;
  bottom: 0;
  height: 40px;
  background: $white;
  right: 0;
  z-index: 999;
  width: 100%;
  background-color: $white;
  box-shadow: 0 -1px 4px 0 $navbar-default-toggle-icon-bar-bg;
  padding: 0 10px;
  display: grid;
  grid-template-columns: 1fr 100px;
  @media screen and (max-width: $screen-sm - 1) {
    height: 50px;
  }

  .collection-info {
    height: 40px;
    display: grid;
    margin: auto auto auto 0;
    grid-template-columns: 56px auto;

    .icon-container {
      display: flex;
      justify-content: center;
      align-items: center;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 25px 25px;

      &.collection {
        background-image: url("gooru/images/add_collection.svg");
      }

      &.assessment {
        background-image: url("gooru/images/add_assessment.svg");
      }
    }

    .title-container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-family: "Montserrat";
      font-size: 14px;
      color: $dark-400;
    }
  }

  .close {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 88px;
    height: 25px;
    border-radius: 20px;
    margin: auto;
    background-color: $green-700;
    color: $white;
    cursor: pointer;
    opacity: 1;
    font-size: 14px;
    font-weight: bold;
  }
}
