.questions.answers.gru-encoding-assessment {
  padding: 10px 0;
  margin-top: -15px;
  position: relative;

  .answer-legend {
    position: absolute;
    bottom: 100%;
    right: 0;
    font-size: $font-size-large;
    text-align: center;

    span {
      display: inline-block;
      width: 2.7rem;
      @media (min-width: $screen-xs) {
        width: 3.5rem;
      }
      @media (min-width: $screen-sm) {
        width: 4.4rem;
      }
    }
  }

  .panel {
    margin-bottom: 15px !important;

    .answer-content {
      @include flexbox();
      @include justify-content(space-between);
      @include align-items(center);
      padding-left: 20px;
      display: grid;
      grid-template-columns: auto 1fr auto;
      column-gap: 25px;

      .letter-container {
        margin: 0 20px 0 0;
        min-width: 1em;
        text-align: center;
        @include align-self(center);
        border-right: 1px solid $gray-light;
        grid-column: 1;
        grid-row-start: 1;
        grid-row-end: 3;
        justify-self: left;
      }

      .text-area-container {
        @include flex();
        width: auto;
        margin-top: 5px;

        .btn-toolbar {
          max-width: 40em;
          @media (min-width: $screen-sm-min) {
            max-width: 43em;
          }
          @media (min-width: $screen-lg-min) {
            max-width: 59em;
          }
        }

        .gru-rich-text-editor {
          .rich-editor {
            min-height: 3.2em;
            max-height: 6.9em;
            margin: 10px 20px 10px 0;
            border-left: 0;
            border-right: 0;
            border-top: 0;
            max-width: 24em;
            @media (min-width: $screen-sm-min) {
              max-width: 26em;
            }
            @media (min-width: $screen-lg-min) {
              max-width: 43em;
            }

            &.editable {
              max-width: 40em;
              @media (min-width: $screen-sm-min) {
                max-width: 43em;
              }
              @media (min-width: $screen-lg-min) {
                max-width: 59em;
              }
            }
          }
        }

        p {
          margin: 10px 0 10px 10px;
        }
      }

      .delete {
        outline: none;
        padding: 10px;
        cursor: pointer;
        border: 0;
        background: $white;
        justify-self: right;
      }

      &.view-mode {
        @include justify-content(flex-start);

        .answer-text {
          overflow: auto;
          word-wrap: break-word;

          .gru-math-text {
            padding: 10px 0;
            display: block;
          }
        }
      }

      .audio-recorder {
        display: grid;
        grid-template-columns: auto auto;
        align-items: center;
        column-gap: 20px;
        height: 45px;
        justify-self: left;

        .record-actions {
          .record-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 33px;
            height: 33px;
            cursor: pointer;
            display: block;

            i {
              font-size: 30px;
              width: inherit;
              height: inherit;
              color: $nobel-gray;
              display: block;
              text-align: center;
              line-height: 30px;
              border: 1px solid $nobel-gray;
              border-radius: 50%;
            }
          }
        }

        .audio-player {
          display: grid;
          grid-template-columns: auto auto;
          justify-content: center;
          align-items: center;
          column-gap: 25px;

          .audio-play {
            width: 24px;
            height: 24px;
            display: block;

            i {
              width: inherit;
              height: inherit;
              background-color: $gray-harder;
              color: $white;
              border-radius: 50%;
              display: block;
              text-align: center;
              line-height: 24px;
            }
          }

          .audio-progress {
            width: 96px;
            height: 8px;
            background-color: rgba($color: $gray-harder, $alpha: 0.3);
            display: block;
            position: relative;
            border-radius: 5px;

            .progress-filling {
              width: 0;
              position: absolute;
              left: 0;
              height: inherit;
              border-radius: 5px;

              .progress-pointer {
                width: 16px;
                height: 16px;
                background-color: $bg-progress-pointer;
                right: -8px;
                top: -4px;
                display: block;
                border-radius: 50%;
                position: absolute;
              }
            }

            .audio-wave {
              position: absolute;
              left: 30px;
              top: 6px;
              display: none;

              span {
                display: block;
                bottom: 0;
                width: 3px;
                height: 5px;
                background: $violet-b6;
                position: absolute;
                animation: audio-wave 1.5s infinite ease-in-out;
              }

              span:nth-child(2) {
                left: 8px;
                animation-delay: 0.2s;
              }

              span:nth-child(3) {
                left: 16px;
                animation-delay: 0.4s;
              }

              span:nth-child(4) {
                left: 24px;
                animation-delay: 0.6s;
              }

              span:nth-child(5) {
                left: 32px;
                animation-delay: 0.8s;
              }
              @keyframes audio-wave {
                0% {
                  height: 5px;
                  transform: translateY(0px);
                  background: $blue-db;
                }

                25% {
                  height: 40px;
                  transform: translateY(20px);
                  background: $blue-db;
                }
                /*effect is to animate the height of each span from 5px to 30px*/
                /*translateY makes Y axis move down to give the effect that it is growing from the center*/
                50% {
                  height: 5px;
                  transform: translateY(0px);
                  background: $blue-db;
                }

                100% {
                  height: 5px;
                  transform: translateY(0px);
                  background: $blue-db;
                }
              }
            }
          }
        }

        &.recording {
          .record-actions {
            .record-icon {
              i {
                color: $white;
                border-color: $bg-red-bar;
                background-color: $bg-red-bar;
              }
            }
          }

          .audio-player {
            .audio-play {
              i {
                background-color: $gray-harder;
                color: $white;
              }
            }

            .audio-progress {
              background-color: rgba($color: $gray-harder, $alpha: 0.3);
            }

            .progress-filling {
              width: 0;

              .progress-pointer {
                background-color: $bg-progress-pointer;
              }

              .audio-wave {
                display: block;
              }
            }
          }
        }

        &.enable {
          .audio-player {
            opacity: 1;

            .audio-play {
              i {
                cursor: pointer;
                background-color: $sky-400;
              }
            }

            .audio-progress {
              background-color: $gray-harder;

              .progress-filling {
                background-color: $bg-progress-filling;

                .progress-pointer {
                  background-color: $blue-400;
                }
              }
            }
          }
        }

        .audio-error-text {
          color: $red-400;
          font-size: 12px;
          margin-top: 50px;
          position: absolute;
        }
      }

      .confirm-btn {
        text-align: center;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        cursor: pointer;
        grid-template-columns: auto auto;
        align-items: center;
        grid-column: 3;
        grid-row: 2;
        justify-self: left;

        &.disable-event {
          opacity: 0.5;
        }
      }

      .text-area-container {
        width: 80%;
        line-height: 1;
      }

      .audio-text-content {
        p {
          margin: 10px 0;
        }
      }
    }
  }

  .max-length-error {
    * {
      color: $orange-400;
    }
  }

  .add-answer {
    margin-bottom: 10px;

    a {
      cursor: pointer;
      border: 2px dashed $gray-light;
      border-radius: 6px;
      margin-top: 10px;
      color: $gray;
      display: block;
      width: 100%;
      padding: 20px;
      text-align: center;
    }
  }

  .questions.answers.gru-encoding-assessment
    .panel
    .answer-content
    .text-area-container
    .gru-rich-text-editor
    .rich-editor {
    min-height: 0.1em;
    max-height: 2.9em;
    margin: 0 20px 5px 0;
  }

  .questions.answers.gru-encoding-assessment
    .panel
    .answer-content
    .text-area-container
    .gru-rich-text-editor
    .rich-editor {
    max-width: 24em;
  }

  .gru-rich-text-editor .rich-editor {
    min-height: 0;
  }
}
