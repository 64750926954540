.add-course.featured-course-card {
  .card-panel-container {
    width: 300px;
    height: 398px;
    object-fit: contain;
    border-radius: 4px;
    background-color: $bg-white-black;
    box-shadow: 0 0 6px 1px $table-box-shadow-07;

    .card-panel-header {
      width: 300px;
      height: 187px;
      object-fit: contain;
      background-color: $table-bg;
      background-size: cover;
      background-repeat: no-repeat;
      padding: 0;
      position: relative;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;

      .info {
        color: $white;
        position: absolute;
        z-index: 10;
        bottom: 0;

        .title {
          padding-left: 10px;
          padding-right: 10px;
          width: 100%;
          height: 42px;
          margin-top: 88px;
          font-weight: bold;
          $line-height: 1.5;
          $lines-to-show: 2;
          @include ellipsis(
            $font-size: 13px,
            $line-height: $line-height,
            $lines-to-show: $lines-to-show
          );
        }
      }

      &::after {
        display: block;
        position: absolute;
        z-index: 9;
        background-image: linear-gradient(
          to bottom,
          $bg-left-box-shadow 0,
          $gray-base 100%
        );
        margin-top: 75px;
        height: 60%;
        width: 100%;
        content: "";
      }
      .badged-group {
        position: absolute;
        right: 10px;
        top: 10px;
        background-image: url("gooru/badge-group.svg");
        width: 30px;
        height: 30px;
        background-size: 30px 30px;
        display: block;
      }
    }

    .count-container {
      border-bottom: 1px solid $border-box-shadow;
      .unit-count-container {
        height: 25px;
        width: max-content;
        background-color: $white;

        .icon {
          background-size: 15px 15px;
          width: 15px;
          height: 15px;
          display: block;
          float: left;
          margin-right: 6px;
          margin-top: 5px;
          background-image: url("../assets/gooru/images/units-gray.svg");
          margin-left: 10px;
        }

        span {
          font-size: 14px;
        }
      }
    }

    .course-info-container {
      height: 150px;
      border-bottom: 1px solid $gray-ultra-light;
      padding: 15px;
      background-color: $white;

      .course-user-info {
        height: 30px;
        cursor: default;
        @include flexbox;

        .created-by {
          font-style: italic;
          width: 60px;
        }

        .img-container {
          width: 20px;
          height: 20px;
          overflow: hidden;
          border-radius: 50px;
          margin-left: 10px;

          img {
            width: 100%;
          }
        }

        .username {
          margin-left: 10px;
          max-width: 92px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
          color: $blue-400;
        }
      }

      .course-description {
        $line-height: 1.5;
        $lines-to-show: 3;
        @include ellipsis(
          $font-size: 13px,
          $line-height: $line-height,
          $lines-to-show: $lines-to-show
        );
      }

      .course-standards {
        margin-top: 10px;

        .taxonomy-tag {
          .content {
            font-size: 12px;
            width: 130px;
            height: 32px;
          }
        }

        .non-visible-tags {
          padding: 2px;
          font-size: 12px;
        }
      }
    }

    .card-panel-footer {
      .buttons-container {
        height: 36px;
        border-bottom: 1px solid $gray-ultra-light;
        background-color: $white;
        display: grid;
        grid-template-columns: auto auto;
        justify-content: space-between;

        .left-aligned {
        }
        .right-aligned {
          display: grid;
          grid-template-columns: auto auto;
        }

        .action-btn {
          display: grid;
          justify-content: center;
          align-items: center;
          width: 45px;
          height: 36px;
          i {
            cursor: pointer;
          }
        }
        .play-btn {
          border-right: 1px solid $gray-ultra-light;
          i {
            color: $blue-400;
          }
        }
        .edit-btn {
          border-left: 1px solid $gray-ultra-light;
        }
      }
    }
  }
}
