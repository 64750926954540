.diagnostic-competency-report-pull-up {
  top: 100%;
  position: fixed;
  z-index: 1031;
  width: 768px;
  left: 0;
  right: 0;
  margin: auto;
  color: $suggestion-panel-color;

  .backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    cursor: pointer;
    opacity: 0.8;
    background-color: $gray-base;
  }
  .diagnostic-competency-report-container {
    background: $white;
    max-height: 90vh;
    height: 90vh;
    display: grid;
    grid-template-rows: 56px auto 56px 1fr;

    .report-header {
      display: grid;
      grid-template-columns: auto 56px;
      border-bottom: 1px solid $table-border-color;

      .close {
        display: flex;
        align-items: center;
        justify-content: center;

        i {
          font-size: 24px;
        }
      }

      .header-title {
        display: grid;
        grid-template-columns: 56px 1fr 50px;
        height: 100%;
        padding: 10px 10px 10px 10px;
        align-items: center;
        grid-gap: 10px;

        .title {
          align-items: center;
        }
        .comp-code {
          background-color: $dark-400;
          color: $white;
          width: auto;
          text-align: center;
          max-width: 100px;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 4px;
        }
      }
    }
    .sub-header {
      display: grid;
      grid-template-columns: auto 50px;
      padding: 0px 0px 0px 10px;
      align-items: center;
      span {
        font-size: 14px;
        font-weight: bold;
      }
      .suggestion-explore {
        margin-top: 5px;
        i {
          transform: rotate(90deg);
          margin-top: -2px;
          margin-left: -2px;
          color: $tan-hide;
          font-size: 34px;
        }
      }
      .overall-score {
        text-align: center;
        padding: 10px;
      }
    }
    .assessment {
      display: grid;
      grid-template-columns: 40px auto;
      padding: 15px 15px;
      .assessment-icons {
        background-image: url("../assets/gooru/images/assessment-gray.svg");
        width: 20px;
        height: 20px;
        background-size: 20px 20px;
      }
    }
    .performance-view {
      .student-list {
        .performance-detail {
          align-items: center;
          display: grid;
          grid-template-columns: 50px auto 98px 110px;
          border-bottom: 1px solid $table-border-color;
          .sort-score {
            display: flex;
            font-weight: bold;
            align-items: center;
            font-size: 13px;
            color: $suggestion-panel-color;
            i {
              cursor: pointer;
            }
          }
          .conduct {
            line-height: 1.6;
            .conduct-date {
              font-weight: bold;
            }
          }
          .overall-select {
            background: gray;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            margin: auto;
            cursor: pointer;
          }
          .student-check {
            label {
              input {
                padding: 5px 0px 0px 17px;
              }
            }
          }
          .selected-type {
            .dropdown {
              .dropdown-toggle {
                width: 100px;
                height: 35px;
              }
            }
          }
        }
        .suggest-bar {
          width: 100%;
          height: 48px;
          background: $orange-49;
          visibility: hidden;
          opacity: 0;
          transition: opacity 0.3s, visibility 0.3s;
          &.active {
            visibility: visible;
            opacity: 1;
            transition: opacity 0.3s, visibility 0.3s;
          }
          .suggest-bar-container {
            display: grid;
            grid-template-columns: 56px 1fr 150px;
            height: 48px;
            .close-all-suggestion {
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              i {
                font-size: 24px;
                color: $white;
                opacity: 1;
                font-weight: 600;
              }
            }
            .no-of-student-selected {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              font-size: 16px;
              color: $white;
              font-weight: 600;
            }
            .suggestion-count-container {
              width: 145px;
              display: flex;
              align-items: center;
              cursor: pointer;
              span {
                text-transform: uppercase;
                color: $white;
                font-size: 12px;
                font-weight: 600;
                margin-right: 10px;
              }
              .suggestion-explore {
                width: 20px;
                border-radius: 50%;
                background: 0 0;
                height: 20px;
                margin-top: 2px;
                color: $white;
                position: relative;
                i {
                  transform: rotate(90deg);
                  margin-top: -2px;
                  margin-left: -2px;
                }
                .suggestion-count {
                  width: 16px;
                  border-radius: 50%;
                  background: $white;
                  position: absolute;
                  line-height: 14px;
                  top: -5px;
                  height: 16px;
                  left: 14px;
                  color: $gray-4a;
                  border: 1px solid $gray-4a;
                  font-size: 9px;
                  text-indent: 4px;
                }
              }
            }
          }
        }
        .student {
          overflow-y: auto;
          max-height: 50vh;
          .student-info {
            display: grid;
            grid-template-columns: auto 56px;
            border-bottom: 1px solid $list-group-border;
            .student-info-container {
              display: grid;
              grid-template-columns: 25px 50px 1fr;
              align-items: center;
              padding: 10px 10px 10px 17px;
              label {
                margin: 0;
              }
              .student-icon-col {
                .selected-user-for-suggestion {
                  width: 29px;
                  background: $tan-hide;
                  border-radius: 50%;
                  line-height: 19px;
                  margin: auto;
                  position: relative;
                  top: 9px;
                  color: $white;
                  height: 29px;
                  i {
                    position: relative;
                    top: 3px;
                    left: 3px;
                  }
                  .de-select-user {
                    position: relative;
                    top: -29px;
                    width: 29px;
                    height: 29px;
                    background: rgba(0, 0, 0, 0.2);
                    border-radius: 50%;
                    line-height: 19px;
                    visibility: hidden;
                    opacity: 0;
                    transition: opacity 0.3s, visibility 0.3s;
                    i {
                      font-size: 16px;
                      margin-right: 8px;
                      margin-top: 3px;
                    }
                  }
                  &:hover {
                    .de-select-user {
                      visibility: visible;
                      opacity: 1;
                    }
                  }
                }
                .student-icon {
                  background-image: url(/assets/gooru/profile.png);
                  background-size: contain;
                  border-radius: 50%;
                  width: 30px;
                  height: 30px;
                  margin: auto;
                  cursor: pointer;
                }
              }
              &.not-started {
                pointer-events: none;
              }
            }
            .student-score {
              text-align: center;
              padding: 15px 15px;
              &.grade-range-not-started {
                background-color: $white-ea;
                pointer-events: none;
              }
            }
            &:last-child {
              border: none;
            }
          }
        }
      }
    }
  }
}
