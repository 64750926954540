.gru-status-switch {
  display: inline-block;
  padding: 5px;

  div {
    display: inline-block;
  }
  @include btn(default, $red-400, $green-400);
  @include btn(primary, $green-400);

  .btn {
    border: 1px solid $table-bg;
    font-weight: bold;
    background-color: $green-400;
    border-radius: 19px;
  }

  .btn-primary {
    background-color: $green-400;
    .toggle-handle {
      background-image: url("/assets/gooru/images/tick-green.svg");
      background-size: 20px;
      background-repeat: no-repeat;
    }
  }

  .btn-primary:hover {
    background-color: $green-400;
  }

  .btn-default.off {
    color: $white;
    background-color: $red-400;
    border: 1px solid $table-bg;

    .toggle-handle {
      background-image: url("/assets/gooru/images/wrong-answer.svg");
      background-size: 12px;
      background-repeat: no-repeat;
      margin-left: 22px;
    }
  }

  .btn-default:hover {
    background-color: $red-400;
  }

  .toggle-handle.btn-default {
    background-color: $white;
    margin-left: -22px;
    border-radius: 30px;
    padding: 10px;
  }

  .switch {
    .toggle-group {
      label {
        display: none;
      }
    }
  }
}
