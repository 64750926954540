.gru-image-picker {
  .instruction {
    display: block;
  }

  .restriction {
    display: block;
    font-style: italic;
    font-size: $font-size-small;
    margin-bottom: 5px;
  }

  .file-picker {
    display: inline-block;

    button.upload {
      margin-bottom: 10px;

      i {
        font-size: 1rem;
        margin-right: 5px;
      }
    }

    .file-picker__preview > img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      padding: 4px;
      background-color: $white-bold;
      border: 1px solid $gray-lighter;
      border-radius: 4px;
      margin-bottom: 5px;

      @media (min-width: $screen-sm) {
        max-width: 800px;
        max-height: 800px;
      }
    }
  }

  .validation {
    margin-left: 5px;
  }

  .actions {
    margin: 10px 0;
  }
}
