.pill {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3px 8px;
  gap: 6px;
  box-sizing: border-box;
  border: 1px solid $ps-white-e2;
  border-radius: 12px;
  background: $white-bold;
  margin: 4px;
  color: $ps-blue-ef;
  font-weight: 400;
  font-size: 12px;
}

.pill-text {
  flex: 1;
}

.pill-close {
  cursor: pointer;
  font-size: 14px;
  text-align: end;
  height: 9px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
}
