.qz-switch {
  display: inline-block;
  padding: 5px;
  div {
    display: inline-block;
  }
  .btn {
    border: 1px solid transparent;
    font-size: $font-size-h6;
    font-weight: bold;
  }
  .btn-default {
    background-color: $dark-200;
  }
  .btn-default.active {
    color: $white;
    background-color: $dark-200;
    border: 1px solid transparent;
  }
  .toggle-handle.btn-default {
    background-color: $white;
  }
}
