#controller-not-found {
  position: relative;
  height: 100vh;

  .panel-body {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    max-width: 767px;
    width: 100%;
    line-height: 1.4;
    padding: 110px 40px;
    text-align: center;
    background: $white;
    -webkit-box-shadow: 0 15px 15px -10px $disabled-bg-color;
    box-shadow: 0 15px 15px -10px $disabled-bg-color;

    .gooru-logo {
      width: 83px;
      height: 42px;
      background-image: url("gooru/images/gooru-logo.svg");
      background-repeat: no-repeat;
      margin: 0 auto;
      background-size: 45px 48px;
      background-color: $gray-252;
      border-radius: 4px;
      color: $white;
      font-size: 12px;
      display: block;
      padding-top: 5px;
      background-position: 18px 6px;
      box-shadow: 0 2px 10px 0 $navbar-default-toggle-icon-bar-bg;
      cursor: pointer;
    }

    h2 {
      font-size: 18px;
      font-weight: 400;
      color: $gray;
      margin-top: 0;
      margin-bottom: 25px;

      #counter {
        color: $blue-7bf;
        font-weight: bold;
      }

      .gooru-text {
        width: 46px;
        height: 18px;
        background-image: url("gooru/images/gooru-logo.svg");
        background-repeat: no-repeat;
        background-size: 46px 18px;
        display: inline-block;
        background-position: 0 2px;
        position: relative;
        top: 4px;
      }
    }
    @media only screen and (max-width: 767px) {
      h2 {
        font-size: 18px;
      }
    }
  }

  .panel-container {
    position: relative;
    height: 180px;

    h1 {
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      font-size: 165px;
      font-weight: 700;
      margin: 0;
      color: $blue-7bf;

      > span {
        color: $green-43c;
      }
    }
    @media only screen and (max-width: 480px) {
      h1 {
        font-size: 141px;
      }
    }
  }
}
