.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $black-bold;
  z-index: -1;
  cursor: pointer;
  opacity: 40% !important;
}
.gru-pull-up {
  background: $white-bold;
  position: fixed;
  top: 0;
  height: 90vh;
  top: 100%;
  z-index: 1000;
  background: $white;
  width: 768px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  .gru-learner-pathway {
    background: $white-bold;
    width: 768px;
    margin-left: 0px;
    margin-top: 0px;
    max-height: calc(90vh - 130px);
    height: calc(90vh - 130px);
  }
  .close {
    display: none !important;
  }
  .pullup-close {
    position: absolute;
    right: 2%;
    top: 5%;
    background: $white;
    width: 63px;
    text-align: center;
    cursor: pointer;
    height: 56px;
    i {
      font-size: 35px;
      margin-left: -105px;
    }
    div {
      line-height: 1px;
    }
  }

  .gru-learner-pathway .unit-body::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }
  .pathwy-panel-container {
    background: $white-bold !important;
    max-height: calc(90vh - 130px);
    height: calc(90vh - 130px);
  }
}
