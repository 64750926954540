.controller.reset-password {
  display: flex;
  flex-direction: column;
  .copyright-information {
    position: absolute;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    bottom: 0;
    @media screen and (max-height: 650px) {
      margin-top: 10px;
      position: relative;
    }
  }
  .modal {
    display: block;
    position: relative;
    z-index: 0;
    padding: 0;
    margin: 15px auto;
    max-width: 405px;
    width: 100%;
    @media (min-width: $screen-sm-min) {
      width: 425px;
      max-width: 425px;
      margin: 60px auto;
    }
    .modal-dialog {
      width: 100%;
      max-width: 405px;
      margin: 0;
      @media (min-width: $screen-sm-min) {
        width: 405px;
        margin: 10px;
      }
      .modal-content {
        border: 0;
        padding: 54px 0 47px;
        .reset-password-form {
          margin: 37px 24px 0;
          .form-group {
            margin: 5px 0 20px 0;
            label {
              width: 100%;
              position: relative;
              span.required:after {
                content: "";
              }
            }
            .input-container {
              position: relative;
            }

            .error-messages {
              position: absolute;
              top: 100%;
              left: 0;
            }
            input {
              text-align: left;
              padding-left: 15px;
              height: 51px;
              box-shadow: none;
              border: 1px solid $table-border-color;
              box-shadow: none;
              width: 100%;
              &:focus {
                border-color: $blue-400;
              }
            }
            .validation.error {
              //position: relative;
              top: -15px;
            }

            .submit-btn {
              width: 314px;
              height: 44px;
              font-size: 18px;
              font-weight: 500;
            }

            .reset-btn-invalid {
              border-radius: 6px;
              background: $ps-white-e2;
              color: $ps-grey-74;
              font-size: 18px;
              width: 100%;
              cursor: not-allowed;
            }

            .reset-btn-valid {
              border-radius: 6px;
              background: $blue-bg;
              color: $white;
              font-size: 18px;
              width: 100%;
            }

            span.error {
              color: $red-400;
              display: inline-block;
              font-size: 12px;
              margin-top: 0;
            }
            .password {
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              color: $dark-night;
            }
            .password-toggle-icon {
              position: absolute;
              top: 50%;
              right: 15px;
              transform: translateY(-50%);
              width: 20px;
              height: 20px;
              background-size: 20px 20px;
              cursor: pointer;
              z-index: 1;
            }

            .password-toggle-icon-on {
              background-image: url(/assets/gooru/visibility-on.svg);
            }

            .password-toggle-icon-off {
              background-image: url(/assets/gooru/visibility-off.svg);
            }
            .required {
              font-weight: 350;
              font-size: 10px;
              line-height: 14px;
              color: $gray-4d5557;
              border: 1px solid $ps-white-e2;
              border-radius: 4px;
              background: $light-ps;
              margin-left: 8px;
              padding: 2.7px;
            }
            .requiredColoured {
              font-weight: 350;
              font-size: 10px;
              line-height: 14px;
              color: $white;
              border: 1px solid $ps-white-e2;
              border-radius: 4px;
              background: $ps-red-reset;
              margin-left: 8px;
              padding: 2.7px;
            }
          }
        }
      }
      .modal-header {
        text-align: center;
        padding: 0;
        .img-container {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          .gooru-logo {
            background: url("gooru/images/gooru-logo.svg");
            height: 32px;
            padding: 0;
            width: 111px;
            transform: scale(0.6, 0.6);
            justify-self: center;
          }
          .tenant-logo {
            height: 50px;
            padding: 0;
            justify-self: center;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            max-width: 220px;
            width: 100%;
            &-img {
              width: 70px;
              height: 70px;
            }
            span {
              width: 112px;
              height: 112px;
              border-radius: 50%;
              background-color: $orange-aob;
              display: flex;
              align-items: center;
              justify-content: center;
              color: $white;
              font-size: 30px;
              font-weight: bold;
              text-transform: uppercase;
              margin: auto;
            }
          }
          .gooru {
            background: url("gooru/images/learningNav-logo-with-text.svg");
            background-size: contain;
            background-repeat: no-repeat;
          }
        }
      }
      .modal-body {
        padding: 0;
      }

      .footer-description {
        line-height: $font-size-h6;
        .description-one {
          font-weight: 400;
          font-size: 18px;
          line-height: 26px;
          text-align: center;
          color: $dark-night;
        }
        .description-two {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          color: $dark-night;
          text-align: start;
        }
        .valid {
          display: block;
          margin-left: -16px;
          color: green;
        }

        .invalid {
          display: block;
          margin-left: -16px;
          color: red;
        }
        .iconRightWrong {
          margin-right: 2px;
        }
        .ulDescription {
          padding-left: 22px;
        }
        .forgot-1 {
          font-weight: 400;
          font-size: 18px;
          line-height: 26px;
          text-align: center;
          color: $dark-night;
        }
        .forgot-2 {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          color: $dark-night;
        }
      }

      .modal-footer {
        padding: 0;
        text-align: center;
        .submit-button {
          margin: 0;
          padding: 25px 0 0;
          .sign-in {
            width: 100%;
            font-weight: 500;
            font-size: 18px;
            background: $blue-bg;
            border: 1px solid $ps-grey-74;
            border-radius: 4px;
            padding: 4px 12px;
            color: $white-bold;
          }
          button {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            width: 314px;
            height: 44px;
            font-size: $font-size-h4;
            font-weight: 300;
            position: relative;
            font-weight: 500;
            font-size: 18px;
            line-height: 28px;
            color: $white-bold;
            i {
              position: absolute;
              font-size: $font-size-h3;
              line-height: 48px;
              right: 5px;
            }
            &.focus {
              background: $blue-hover-bg;
              color: $white-bold;
              box-shadow: 0 0 0 1px $white-bold, 0 0 0 2px $blue-bg,
                0 0 0 4px $blue-pale;
            }
          }
        }
      }
    }
  }
}
