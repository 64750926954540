.cards.gru-user-network-card {
  .truncate {
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .panel {
    width: 225px;
    height: 281px;
    border-radius: 4px;
    background-color: $white;
    box-shadow: 0 0 4px 1px $navbar-box-shadow;
    border-radius: 6px;

    .panel-heading {
      background-color: $white;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
      padding: 0;
      height: 177px;
      width: 100%;
      display: grid;
      text-align: center;
      padding-left: 15px;
      padding-right: 15px;

      .image {
        display: inline-block;
        margin: 20px auto 0;

        img {
          width: 110px;
          height: 110px;
          border-radius: 50%;
        }
      }

      .user-info {
        display: inline-block;
        color: $text-color;
        vertical-align: top;
        width: 199px;

        h6 {
          margin-left: 5px;
          margin-right: 5px;
          opacity: 0.9;
          font-size: 18px;
          text-align: center;
          text-transform: capitalize;
          color: $black-bold;
          width: 199px;
          margin-bottom: 0;
          margin-top: 0;
        }
      }

      .panel-border {
        border-bottom: 1px solid $gray-lighter;
      }
    }

    .panel-body {
      padding: 0 15px;

      .following-followers {
        > * {
          width: 49%;
          display: inline-block;
        }
      }

      .followers {
        border-right: 1px solid $gray-lighter;

        .description {
          opacity: 0.9;
          font-size: 15px;
          font-weight: 300;
          text-align: center;
          color: $gray-dove;
        }

        .count {
          span {
            font-size: 15px;
            font-weight: 500;
            text-align: center;
            color: $gray-dove;
          }
        }
      }

      .following {
        .description {
          opacity: 0.9;
          font-size: 15px;
          font-weight: 300;
          text-align: center;
          color: $gray-dove;
        }
      }

      .count {
        font-weight: bold;
        margin-top: 12px;

        span {
          font-size: 15px;
          font-weight: 500;
          text-align: center;
          color: $gray;
        }
      }
    }

    .panel-footer {
      padding: 5px 0 0;
      background-color: $white;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;

      .follow-btn {
        padding-top: 6px;

        button {
          width: 100%;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          border-bottom-right-radius: 4px;
          border-bottom-left-radius: 4px;
        }

        .btn-unfollow {
          background-color: $light-300;

          .unfollow {
            display: none;
          }

          .following {
            display: inline;
          }

          &:focus,
          &:hover {
            @extend .btn-info;

            .unfollow {
              display: inline;
            }

            .following {
              display: none;
            }
          }
        }
      }
    }
  }
}
