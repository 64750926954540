.student_class_class-activities {
  overflow-y: hidden;

  .app-container {
    overflow-y: hidden;

    .student.class {
      padding: 0;
      width: 100%;
    }
  }
}

.controller.student.class.class-activities {
  display: grid;
  grid-template-columns: 30% 70%;
  width: 100%;
  overflow: auto;
  background-color: $light-ps;

  .loading-spinner {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    visibility: hidden;

    &.active {
      visibility: visible;
    }
  }

  .activity-date-range-selector {
    height: 100%;
    display: flex;
    margin: auto;
    flex-direction: column;
    padding-top: 10px;

    .date-range-picker-container {
      z-index: 2;
      background-color: $white;
      margin-top: 10px;

      &.open {
        display: block;
      }

      .date-range-selector {
        display: grid;
        grid-template-columns: repeat(3, auto);
        height: 35px;
        align-items: center;
        text-align: center;

        span {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          margin: 0 5px;

          &.active {
            border-bottom: 3px solid $blue-f3;
          }
        }
      }

      .ca-month-picker {
        width: 320px;

        .ca-monthpicker-container {
          #ca-monthpicker {
            .datepicker {
              .table-condensed {
                tbody {
                  tr {
                    td {
                      span.month {
                        width: 50px;
                        min-width: auto;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .ca-date-picker-container,
      .ca-month-picker,
      .ca-week-picker-container {
        box-shadow: unset;

        .backdrop {
          display: none;
        }

        .ca-date-nav-container,
        .ca-week-nav-container {
          height: 40px;
          z-index: 2;
          background-color: $white;

          .ca-date-picker-toggle {
            pointer-events: none;
            font-size: 20px;

            i {
              display: none;
            }
          }
        }

        .ca-datepicker-container,
        .ca-weekpicker-container {
          display: grid;
          padding-bottom: 0;

          .ca-datepicker-today {
            height: 40px;
            box-shadow: 0 2px 4px 0 $box-shadow-bg-color;
            background-color: $has-active-color;
          }

          #ca-datepicker,
          #ca-weekpicker {
            grid-row-start: 1;
            padding: 10px 20px 20px;
            //NOTE overwritting style for enabling all days to be clickable
            .datepicker .table-condensed tr td.no-activities {
              pointer-events: none;
            }

            &.weekly {
              .datepicker {
                .table-condensed {
                  tbody tr {
                    &.no-activities {
                      pointer-events: none;
                    }

                    &.week-active,
                    &:hover {
                      border-radius: 50px;
                      background-color: $has-active-bg-color;

                      td {
                        color: $has-active-color;

                        &.active {
                          background: none;
                          width: auto;
                          height: auto;

                          &:before {
                            content: "";
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            .datepicker-inline {
              .datepicker-days {
                .table-condensed {
                  thead {
                    tr:nth-child(2) {
                      display: grid;
                      width: 100%;
                      justify-content: center;
                      grid-template-columns: repeat(7, 1fr);

                      th.dow {
                        height: 40px;
                        width: 40px;
                        justify-self: center;
                      }
                    }
                  }

                  tbody {
                    tr {
                      display: grid;
                      width: 100%;
                      justify-content: center;
                      grid-template-columns: repeat(7, 1fr);

                      td {
                        height: 36px;
                        width: auto;
                        justify-self: center;
                        margin: auto;

                        &.has-activities:before {
                          position: relative;
                          left: 50%;
                          transform: translateX(-50%);
                        }

                        &.active {
                          background-color: $has-active-bg-color;
                          background-image: -webkit-linear-gradient(
                            top,
                            $has-active-bg-color,
                            $has-active-bg-color
                          );
                          border-radius: 50%;
                          display: flex;
                          margin: auto;
                          align-items: center;
                          justify-content: center;
                          color: $has-active-color;
                          width: 40px;
                          height: 40px;

                          &:before {
                            content: none;
                          }
                        }

                        &.today {
                          background-color: $has-active-color;
                          background-image: -webkit-linear-gradient(
                            top,
                            $has-active-color,
                            $has-active-color
                          );
                          border-radius: 50%;
                          display: flex;
                          margin: auto;
                          align-items: center;
                          justify-content: center;
                          color: $white;
                          width: 40px;
                          height: 40px;
                          pointer-events: auto;

                          &:before {
                            content: none;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .date-section {
      background-color: $white;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .ca-container {
    width: 85%;
    @media screen and (max-width: $screen-sm) {
      width: auto;
    }
    height: 100%;
    display: flex;
    margin: auto;
    flex-direction: column;
    padding-top: 10px;

    .header-container {
      border-radius: 4px;
      background-color: $white;
      box-shadow: 0 0 6px 1px $table-box-shadow-07;
      position: relative;

      %tab-container {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding: 5px;
        opacity: 0.6;
        span {
          color: #000000;
        }
      }

      .activity-tab {
        display: grid;
        grid-template-columns: repeat(2, auto);
        column-gap: 8px;
        align-items: center;
        text-align: center;

        .items-to-grade-selector,
        .schedule-icon {
          @extend %tab-container;

          &.active {
            background-color: $blue-f3;
            color: $font-white-100-white-500;
            opacity: 1;
            pointer-events: none;

            .grading-icon {
              background-image: url("../assets/gooru/images/add_rubric_white.svg");
            }
          }

          i {
            height: 35px;
            line-height: 35px;
            padding-left: 10px;
          }

          .grading-icon {
            width: 22px;
            height: 22px;
            background-size: 22px;
            display: block;
            background-position: center;
            background-repeat: no-repeat;
            margin-right: 3px;
            background-image: url("../assets/gooru/images/add_rubric.svg");
            margin-left: 10px;
          }
        }
        .items-to-grade-selector {
          border-radius: 0 4px 4px 0;
        }

        .schedule-icon {
          border-radius: 4px 0 0 4px;
        }
      }

      .filters {
        display: grid;
        grid-template-columns: repeat(2, 50%);
        column-gap: 8px;
        align-items: center;
        text-align: center;
        padding: 10px 40px 0;

        .activities {
          display: flex;
          width: 100%;
          align-items: center;
          color: $dark-600;
          height: 100%;

          .contents-selector {
            display: grid;
            grid-template-columns: auto auto auto;
            justify-content: center;
            column-gap: 1px;
            height: 100%;
            margin: auto;

            .content-selector {
              opacity: 0.6;
              padding: 10px;

              .icon {
                width: 20px;
                height: 20px;
                background-size: 20px;
                display: block;
                background-position: center;
                background-repeat: no-repeat;
                cursor: pointer;

                &.assessment-icon {
                  background-image: url("../assets/gooru/images/add_assessment.svg");
                }

                &.collection-icon {
                  background-image: url("../assets/gooru/images/add_collection.svg");
                }

                &.offline-activity-icon {
                  background-position: 2px 2px;
                  background-image: url("../assets/gooru/images/offline.svg");
                }
              }

              &.active {
                background-color: $blue-f3;
                opacity: 1;

                i {
                  &.assessment-icon {
                    background-image: url("../assets/gooru/images/assessment-white.svg");
                  }

                  &.collection-icon {
                    background-image: url("../assets/gooru/images/collection-white.svg");
                  }

                  &.offline-activity-icon {
                    background-size: 26px;
                    background-position: center;
                    background-image: url("../assets/gooru/images/offline-white.svg");
                  }
                }
              }
            }
          }
        }

        .activity-date-range-selector {
          display: flex;
          justify-content: center;
          align-items: center;
          column-gap: 5px;
          cursor: pointer;
          padding: 0 10px 0 20px;
          position: relative;

          .backdrop {
            position: fixed;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: -9;
            cursor: pointer;
            opacity: 0.8;
            background-color: $gray-base;
          }

          .date-range-picker-container {
            z-index: 2;
            background-color: $white;
            display: none;
            position: absolute;
            top: 32px;
            border: 1px solid $breadcrumb-color;

            &.open {
              display: block;
            }

            .date-range-selector {
              display: grid;
              grid-template-columns: repeat(3, auto);
              height: 35px;
              align-items: center;
            }

            .ca-date-picker-container,
            .ca-month-picker,
            .ca-week-picker-container {
              box-shadow: 2px 2px 3px $box-shadow-bg-color,
                -2px 2px 3px $box-shadow-bg-color;

              .ca-date-nav-container,
              .ca-week-nav-container {
                height: 40px;
                z-index: 2;
                background-color: $white;

                .ca-date-picker-toggle {
                  pointer-events: none;
                  font-size: 20px;

                  i {
                    display: none;
                  }
                }
              }

              .ca-datepicker-container,
              .ca-weekpicker-container {
                display: grid;
                padding-bottom: 0;

                .ca-datepicker-today {
                  height: 40px;
                  box-shadow: 0 2px 4px 0 $box-shadow-bg-color;
                  background-color: $has-active-color;
                }

                #ca-datepicker,
                #ca-weekpicker {
                  grid-row-start: 1;
                  padding: 10px 20px 20px;
                  //NOTE overwritting style for enabling all days to be clickable
                  .datepicker .table-condensed tr td.no-activities {
                    pointer-events: none;
                  }

                  &.weekly {
                    .datepicker {
                      .table-condensed {
                        tbody tr {
                          &.no-activities {
                            pointer-events: none;
                          }

                          &.week-active,
                          &:hover {
                            border-radius: 50px;
                            background-color: $has-active-bg-color;

                            td {
                              color: $has-active-color;

                              &.active {
                                background: none;
                                width: auto;
                                height: auto;

                                &:before {
                                  content: "";
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }

                  .datepicker-inline {
                    .datepicker-days {
                      .table-condensed {
                        thead {
                          tr:nth-child(2) {
                            display: grid;
                            width: 100%;
                            justify-content: center;
                            grid-template-columns: repeat(7, 1fr);

                            th.dow {
                              height: 40px;
                              width: 40px;
                              justify-self: center;
                            }
                          }
                        }

                        tbody {
                          tr {
                            display: grid;
                            width: 100%;
                            justify-content: center;
                            grid-template-columns: repeat(7, 1fr);

                            td {
                              height: 36px;
                              width: auto;
                              justify-self: center;
                              margin: auto;

                              &.has-activities:before {
                                position: relative;
                                left: 50%;
                                transform: translateX(-50%);
                              }

                              &.active {
                                background-color: $has-active-bg-color;
                                background-image: -webkit-linear-gradient(
                                  top,
                                  $has-active-bg-color,
                                  $has-active-bg-color
                                );
                                border-radius: 50%;
                                display: flex;
                                margin: auto;
                                align-items: center;
                                justify-content: center;
                                color: $has-active-color;
                                width: 40px;
                                height: 40px;

                                &:before {
                                  content: none;
                                }
                              }

                              &.today {
                                background-color: $has-active-color;
                                background-image: -webkit-linear-gradient(
                                  top,
                                  $has-active-color,
                                  $has-active-color
                                );
                                border-radius: 50%;
                                display: flex;
                                margin: auto;
                                align-items: center;
                                justify-content: center;
                                color: $white;
                                width: 40px;
                                height: 40px;
                                pointer-events: auto;

                                &:before {
                                  content: none;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .date-section {
            display: flex;
            align-items: center;

            .dropdown-icon {
              display: flex;
            }
          }
        }
      }
    }

    .no-rubric-content {
      margin-top: 10px;
      width: 100%;
      box-shadow: 0 2px 4px 0 $box-shadow-bg-color;
      background-color: $white;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      font-size: 18px;
      font-style: italic;
    }

    .items-to-grade-list {
      .gru-grade-items {
        width: 100%;
        margin-top: 10px;
      }
    }

    .dca-content-list-container {
      margin-top: 10px;
      width: 100%;
      box-shadow: 0 2px 4px 0 $box-shadow-bg-color;
      background-color: $white;

      .added-date {
        font-size: 15px;
        font-weight: bold;
        padding: 0 10px;
        display: grid;
        grid-template-columns: max-content auto;
        align-items: center;
        grid-gap: 10px;
        background-color: $lite-gray;

        hr {
          border-top: 1px solid $gray-67;
          width: 100%;
        }
      }

      &.dca-content-list-container-today {
        box-shadow: 0 0 11px 3px $blue-400;
        border-radius: 4px;
      }

      .no-content {
        padding: 10px;
        font-size: 18px;
        text-align: center;
        margin: 0;
      }
    }
  }

  @media screen and (max-width: 930px) {
    grid-template-columns: 45% 55%;
  }
}
