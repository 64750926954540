.reports_context {
  overflow: hidden;
  height: 100%;

  > .ember-view,
  .quizzes,
  .app-container {
    height: 100%;
    background-color: $body-bg;
    padding: 0;
  }
}

.reports_collection .container-fluid {
  min-width: $screen-md-min;
}

.app-container .controller.reports {
  overflow: auto;
  height: 100%;
  $gutter-width: 30px;
  $on-air-icon: 40px;

  display: block;
  padding: 0 30px 30px;

  .header {
    .title {
      > i {
        display: inline-block;
        margin: 0 5px 0 15px;
        width: $on-air-icon;
        height: $on-air-icon;
        vertical-align: middle;
        background-image: url('quizzes-addon/quizzes/images/on-air-icon.svg');
        background-size: $on-air-icon $on-air-icon;
        vertical-align: 28px;
        @include text-hide();
      }
      > span {
        display: inline-block;
        font-size: $font-size-h4;
        margin: 6px 0;
      }
    }

    .actions {
      .btn-info {
        i {
          font-size: $font-size-base;
          vertical-align: -2px;
          margin-right: 6px;
        }
      }
    }

    &.anonymous {
      background-color: $dark-400;
      color: $white;
      width: 100vw;
      margin-left: -$gutter-width;
      @include clearfix();

      .title {
        float: $tab-float-dir;
        margin-left: $gutter-width;

        > i {
          background-image: url('quizzes-addon/quizzes/images/on-air-icon-anonymous.svg');
        }
      }
      .mode {
        font-size: $font-size-base;
        float: $menu-float-dir;
        margin-right: $gutter-width + 20px;
        margin-top: 18px;

        > i {
          font-size: $font-size-large;
          vertical-align: text-bottom;
          margin-right: 10px;
        }
      }
    }
  }

  .qz-class-assessment-report {
    padding-top: 10px;
  }
}
