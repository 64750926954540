.gru-url-resource {
  height: 100vh;
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
  @media (min-width: $screen-lg) {
    overflow-y: hidden;
  }
  .web-container {
    height: calc(100% + 1px);
  }
  iframe {
    width: 100%;
    border: 0 none;
  }
  .url-player-frame {
    height: calc(100% - 18px) !important;
  }
}
