.reroute-line-chart {
  position: relative;
  height: inherit;
  width: inherit;
  &::before {
    content: "";
    position: absolute;
    left: 34px;
    top: -20px;
    width: 2px;
    height: 20px;
    background-color: $orange-300;
  }
  .reroute-line-chart-container {
    background-color: $white;
    padding: 10px;

    .reroute-line-chart-panel {
      .action-buttons {
        position: relative;

        span {
          position: absolute;
          right: 0;
          z-index: 100;
          cursor: pointer;
        }
      }
      .reroute-line-chart-body {
        padding: 20px;
        width: 90%;
        margin-left: auto;
        margin-bottom: 50px;

        .reroute-chart {
          position: absolute;
          left: 0;
          top: 0;
          z-index: 1;

          svg {
            path {
              fill: none;
              stroke: $orange-300;
              stroke-width: 2;
              stroke-dasharray: 150%;
              stroke-dashoffset: 150%;
              animation: draw 1s linear forwards;
            }
          }
        }
        @keyframes draw {
          to {
            stroke-dashoffset: 0;
          }
        }
        .row-content-panel {
          display: flex;
          transition: flex-grow 1000ms linear;

          &.content-right-align {
            // justify-content: flex-end;
            flex-direction: row-reverse;
          }

          .collection-panel-sections {
            z-index: 2;
            margin: 20px 0;
            width: calc(100% / 5);
            display: grid;
            justify-content: center;

            &.row-grid-item-3 {
              width: calc(100% / 3);
            }

            @media screen and (max-width: 600px) {
              width: calc(100% / 3);
            }

            .collection-popup-container {
              position: relative;
              text-align: center;

              .arrow-icon-item {
                position: absolute;
                font-size: 34px;
                bottom: -13px;
                left: 36%;
                color: #e3e5ea;
                transform: rotate(90deg);
                z-index: -1;

                &.arrow_drop_up {
                  transform: rotate(270deg);
                  bottom: 83%;
                }
              }

              .competency-code {
                display: block;
                text-align: center;
                padding: 1px 20px;
                width: fit-content;
                border-radius: 20px;
                font-weight: bold;
                font-size: 12px;
                color: $white;
                line-height: 1.3;
                margin: 5px auto;
                &.not-started,
                &.grade-range-not-started {
                  background-color: $light-300;
                }
                &.empty-comp-code {
                  background-color: $white;
                }
              }

              .collection-popup-panel {
                border: 1px solid $light-200;
                margin: 10px;
                width: 100px;
                line-height: 1.2;
                text-align: center;
                background-color: $white;
                border-radius: 5px 5px 0 0;
                // color: $white;

                .play_circle {
                  font-size: 20px;
                  margin-top: 2px;
                  cursor: pointer;
                  color: $blue-300;
                }

                .content-detail-panel {
                  padding: 5px;
                  height: 40px;
                  span {
                    display: block;

                    &.domain-title {
                      font-size: 9px;
                    }

                    &.competency-desc {
                      font-size: 10px;
                      font-weight: bold;
                    }
                  }
                }

                .performance-item {
                  display: block;
                  padding: 3px;
                  width: 100%;
                  font-size: 15px;
                  font-weight: bold;
                  cursor: pointer;

                  &.timespent-item {
                    background-color: $light-300;
                  }
                }
              }
            }
            .collection-icon {
              display: block;
              width: 25px;
              height: 25px;
              background-color: $white;
              margin: 0 50px;
              background-size: contain;
              background-position: center;
              background-repeat: no-repeat;
              cursor: pointer;

              &.format-collection,
              &.format-collection-external {
                background-image: url("../assets/gooru/images/collection-orange.svg");
              }

              &.format-assessment,
              &.format-assessment-external {
                background-image: url("../assets/gooru/images/assessment-orange.svg");
              }
              &.format-lesson {
                background-image: url("../assets/gooru/images/lesson-orange.svg");
              }
            }
          }
        }
      }
    }
  }
}
