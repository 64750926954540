.gru-input {
  .form-group {
    position: relative;

    .visibility-hidden {
      display: none;
    }
  }
  .has-feedback {
    input {
      padding-right: 30px;
    }
  }
  .clear {
    color: $light-400;
    cursor: pointer;
    padding: 5px;
    pointer-events: auto;
    z-index: 10;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
}
