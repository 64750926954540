.cards.qz-resource-card {
  $line-height: 1.2;
  width: 220px;
  height: 270px;

  .panel {
    border-radius: 6px;
    .panel-heading {
      background-color: $white;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      height: 40%;

      .header {
        width: 100%;
        > * {
          display: inline-block;
        }
        .image {
          display: inline-block;
          vertical-align: top;
        }
        i {
          display: block;
          height: 36px;
          width: 28px;
          vertical-align: top;
          margin: 5px 0 0 0;
          background-repeat: no-repeat;

          &.video_resource-icon {
            background: url("quizzes-addon/quizzes/images/video-resource-green.svg");
            -webkit-background-size: 31px 30px;
            background-size: 31px 30px;
            height: 30px;
            width: 31px;
          }
          &.webpage_resource-icon {
            background: url("quizzes-addon/quizzes/images/website-resource-green.svg");
            -webkit-background-size: 29px 30px;
            background-size: 29px 30px;
            height: 30px;
            width: 29px;
          }
          &.interactive_resource-icon {
            background: url("quizzes-addon/quizzes/images/interactive-resource-green.svg");
            -webkit-background-size: 34px 30px;
            background-size: 34px 30px;
            height: 30px;
            width: 34px;
            vertical-align: top;
            margin: 5px 0 0 0;
          }
          &.question-icon {
            background: url("quizzes-addon/quizzes/images/question-resource-green.svg");
            -webkit-background-size: 30px 30px;
            background-size: 30px 30px;
            height: 30px;
            width: 30px;
          }
          &.image_resource-icon {
            background: url("quizzes-addon/quizzes/images/image-resource-green.svg");
            -webkit-background-size: 30px 30px;
            background-size: 30px 30px;
            height: 30px;
            width: 30px;
          }
          &.text_resource-icon {
            background: url("quizzes-addon/quizzes/images/text-resource-green.svg");
            -webkit-background-size: 31px 30px;
            background-size: 31px 30px;
            height: 30px;
            width: 31px;
          }
          &.audio_resource-icon {
            background: url("quizzes-addon/quizzes/images/audio-resource-green.svg");
            -webkit-background-size: 34px 30px;
            background-size: 34px 30px;
            height: 30px;
            width: 34px;
          }
        }
      }
      .resource-info {
        width: 100%;
        line-height: $line-height;
        $lines-to-show: 2;
        margin-top: 20vh;
        .title-section {
          display: flex;
          justify-content: center;
          text-align: center;
        }
        .resource-type {
          display: flex;
          justify-content: center;
          padding-top: 5px;
          font-family: "Roboto";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: $black-333333;
        }

        h6 {
          margin: 5px 0 5px 0;
          cursor: pointer;
          @include ellipsis(
            $font-size: $font-size-base,
            $line-height: $line-height,
            $lines-to-show: $lines-to-show,
            $excerpt-bg: $white
          );
        }
        .resource-type,
        .question-type {
          span {
            color: $text-color;
          }
        }
        .title-section {
          h6.title {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            text-align: center;
            color: $black-333333;
          }
          .publish-icon {
            display: inline-block;
            float: $menu-float-dir;
            vertical-align: top;
            margin-top: 5px;
            background: url("quizzes-addon/quizzes/badge-01.png");
            -webkit-background-size: 17px 17px;
            background-size: 17px 17px;
            height: 17px;
            width: 17px;
          }
        }
      }
    }
    .panel-body {
      padding: 12px 15px 0 15px;
      height: 128px;
      display: flex !important;
      justify-content: flex-start;
      .image {
        .img {
          width: 7vw;
          height: 11vh;
        }
      }
      .publisher {
        margin: 3px 0 6px 2px;
        width: 100%;
        .img {
          width: 20px;
          height: 20px;
          border-radius: 50px;
        }
        > * {
          display: inline-block;
        }
        .publisher-name {
          max-width: 165px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          vertical-align: middle;
          > a {
            margin-left: 5px;
            color: $blue-400;
          }
        }
      }
      .description {
        margin-left: 20px;
        $lines-to-show: 3;
        width: 100%;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $black-333333;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
      }
    }
    .panel-footer {
      background-color: $white;
      padding: 10px 15px 10px 15px;
      display: flex;
      justify-content: center;
      .buttons {
        display: inline-block;
      }
      .visibility {
        display: inline-block;
        float: $menu-float-dir;
        height: 24px;
      }
      .a {
        margin-left: 40%;
      }
    }
  }
}
