.milestone-report.student-milestone-summary-report {
  .student-milestone-summary-report-container {
    .student-report-header-container {
      box-shadow: 0 2px 5px 0 $disabled-bg-color;
      height: 240px;
      position: relative;

      .left-arrow,
      .right-arrow {
        position: absolute;
        top: 37%;
        cursor: pointer;
        i {
          font-size: 50px;
        }
        &.disable-event {
          opacity: 0.5;
        }
      }

      .left-arrow {
        left: 0;
      }

      .right-arrow {
        right: 0;
      }
    }

    .student-report-body-container {
      height: calc(80vh - 230px);
      overflow-y: auto;
      &::-webkit-scrollbar {
        height: 6px;
        width: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background: $gray-light;
        border: 1px solid $gray-light;
        border-radius: 5px;
      }
      .milestone-lessons-container {
        height: inherit;
        &.milestone-not-started {
          opacity: 0.7;
        }
      }

      @media screen and (max-width: $screen-xs) {
        height: calc(80vh - 165px);
      }
    }
  }
}
