.recently-launched-cards {
  .recently-launched-jump-start {
    color: $white;
    padding-bottom: 20px;
    line-height: 1.4;
    text-align: center;
    align-items: center;
    .recently-launched-title {
      display: flex;
      align-items: center;
      padding: 30px 0;
      font-size: 20px;
      font-weight: 200;
      line-height: 1.2;
    }
    .launch-message {
      display: flex;
      font-size: 14px;
      font-weight: 300;
      line-height: 1.14;
    }
    .recently-launched-jump-start-cards {
      display: flex;
      flex-wrap: wrap;
      grid-row-gap: 20px;
    }
  }
  .recent-cards-section {
    display: flex;
    flex-wrap: wrap;
    color: $white;
    .card-layout {
      width: 350px;
      height: 200px;
      border-radius: 10px;
      background-image: url("gooru/images/program-card.jpg");
      background-position: center;
      background-size: 100%;
      background-repeat: no-repeat;
      position: relative;
      margin: 10px;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: $dark-400;
        opacity: 0.5;
      }
    }
    .card-content {
      position: relative;
      display: grid;
      grid-template-rows: repeat(3, 1fr);
      text-align: center;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      padding: 10px;
      row-gap: 10px;
      .card-header {
        .header-title {
          font-size: 35px;
          line-height: 1;
        }
      }
      .card-description {
        .description {
          font-size: 12px;
          font-weight: bold;
          line-height: 1.67;
        }
      }
      .explore-button {
        padding: 5px;
        .preview-btn {
          padding: 5px 20px;
          background-color: $black-a0a;
          color: $white;
          border-radius: 20px;
          font-weight: bold;
          border: none;
        }
      }
    }
  }
  @media screen and (max-width: 600px) {
    .recent-cards-section {
      display: flex;
      flex-wrap: wrap;
      row-gap: 10px;
      .card-layout {
        // width: inherit;
        height: auto;
        margin: 5px;
        .card-content {
          display: flex;
          row-gap: 0px;
        }
      }
    }
  }
}
