.search-student.gru-search-student {
  .student-search-container {
    background-color: $white;
    border-radius: 5px;
    .header-container {
      height: 48px;
      display: grid;
      grid-template-columns: 110px 1fr 100px;
      @media screen and (max-width: $screen-375) {
        grid-template-columns: 100px 1fr 100px;
      }
      @media screen and (max-width: $screen-320) {
        grid-template-columns: 75px 1fr 75px;
      }
      padding-left: 8px;
      column-gap: 15px;

      .search-type-selector-container {
        position: relative;
        width: 100%;
        height: 48px;
        border-right: 1px solid $sep-border-color;
        .active-search-criteria {
          height: 48px;
          line-height: 10px;
          padding: 8px 0;
          cursor: pointer;
          .search-by {
            font-size: 11px;
            display: flex;
            line-height: 20px;
            i {
              font-size: 20px;
              position: absolute;
              right: 0;
            }
          }
          .search-type {
            font-size: 15px;
            color: #3c4452;
            font-weight: 700;
          }
        }
        .search-type-list-container {
          display: none;
          position: absolute;
          top: 27px;
          width: inherit;
          background-color: $white;
          z-index: 9;
          box-shadow: 0 2px 7px 2px $disabled-bg-color;
          align-items: center;
          .search-type {
            padding: 0;
            margin: 0;
            font-size: 15px;
            padding: 5px 20px;
            @media screen and (max-width: 375px) {
              padding: 5px;
            }
            border-bottom: 1px solid $white;

            &.active-criteria,
            &:hover {
              background-color: $dark-50;
              font-weight: bold;
            }

            &:hover {
              cursor: pointer;
            }
          }
        }
      }

      .search-student-input-container {
        position: relative;
        input.search-student {
          height: 48px;
          width: 180px;
          &:focus,
          &:active {
            outline: none;
          }
          &::placeholder {
            font-size: 12px;
          }
        }
        .search-icon {
          width: 24px;
          display: block;
          position: absolute;
          right: 0;
          top: 14px;
          cursor: pointer;
        }
      }
      .apply-settings-container {
        background-color: $bg-blue-mastery-blue-dark;
        color: $white;
        height: 48px;
        line-height: 48px;
        text-align: center;
        text-transform: uppercase;
        border-top-right-radius: 5px;
        cursor: pointer;
        &.disable-event {
          opacity: $opacity-full;
        }
      }
    }
    .body-container {
      overflow: auto;
      .filtered-students-list-container {
        display: grid;
        min-width: 500px;
        width: 100%;

        .students-list-header-container {
          display: grid;
          grid-template-columns: 30px repeat(4, 1fr);
          padding: 0 15px;
          height: 40px;
          align-items: center;
          border-top: 2px solid $sep-border-color;
          border-bottom: 2px solid $sep-border-color;
          .student-info-header {
            height: 40px;
            line-height: 40px;
            padding-left: 8px;
            border-right: 2px dashed $sep-border-color;
            font-size: 10px;
            text-transform: uppercase;
            font-weight: bold;
            &:last-child {
              border: none;
            }
          }

          .student-name {
            grid-column-start: 1;
            grid-column-end: 3;
            padding-left: 0;
          }

          &.dummy-scroll {
            margin-right: 8px;
          }
        }
        .filtered-students-container {
          max-height: 279px;
          overflow-y: auto;

          &::-webkit-scrollbar {
            height: 6px;
            width: 8px;
          }

          &::-webkit-scrollbar-thumb {
            background: $gray-light;
            border: 1px solid $gray-light;
            border-radius: 5px;
          }

          .student-info-row {
            display: grid;
            grid-template-columns: 30px repeat(4, 1fr);
            padding: 0 15px;
            border-bottom: 1px solid $sep-border-color;
            height: 40px;
            align-items: center;

            .student-info-column {
              height: 40px;
              line-height: 40px;
              border-right: 2px dashed $sep-border-color;
              padding-left: 8px;
              &:last-child {
                border: none;
              }

              &.student-thumbnail {
                padding-left: 0;
              }
              .tooltip {
                visibility: visible;
              }
            }

            .student-thumbnail {
              width: 30px;
              height: 30px;
              border-radius: 50%;
              display: block;
              background-size: 30px;
              padding-right: 8px;
              cursor: pointer;

              &.selected-student {
                position: relative;
                background-image: unset !important;
                background-color: #b0b1b0;

                .tick-icon {
                  position: absolute;
                  width: 30px;
                  height: 30px;
                  line-height: 30px;
                  left: 2px;
                }
              }
            }

            .student-name {
              cursor: pointer;
              text-transform: capitalize;
            }

            .student-username {
              display: grid;
              line-height: normal;
              align-items: center;
              .stu-username,
              .stu-email {
                font-size: 13px;
              }
            }

            &:last-child {
              border: none;
            }
          }
        }
      }
    }

    .loading-spinner {
      width: max-content;
      margin: auto;
    }

    .no-students-found {
      margin: auto;
      width: max-content;
      padding: 10px 0;
    }
  }
}
