.featured-courses {
  .panel-body {
    .courses {
      height: 80px;
      overflow-y: auto;
      @media (min-width: $screen-lg) {
        @include flexbox;
      }
      .gru-course-card.small {
        padding: 0;
        margin: 12px 20px 0 0;
        width: 230px;
        float: $tab-float-dir;
        @media (max-width: $screen-lg) {
          width: 100%;
          float: none;
        }
        .panel {
          padding: 0;
          overflow: hidden;
          box-shadow: none;
          border: 1px solid $light-400;
          min-height: 65px;
          .panel-body {
            padding: 0;
            .card-header {
              .header-content {
                @media (max-width: $screen-lg) {
                  width: 100%;
                }
                .course-title {
                  width: 100%;
                }
                .subject {
                  margin: 15px 0 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
