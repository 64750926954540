.datepicker {
  padding: 0;

  .btn-default {
    border: 0 solid $table-bg;
  }

  .input-group .form-control:first-child {
    border-radius: 4px;
  }

  .form-control[readonly] {
    border-radius: 4px;
    background-color: $white;
  }

  .input-group-btn {
    position: absolute;
    padding: 7px 17px;
    right: 0;
  }

  .datepicker-field {
  }

  .datepicker-icon {
    background: url("gooru/icon-sprite.png") no-repeat scroll -7px -472px
      $table-bg;
    cursor: pointer;
    display: block;
    float: $menu-float-dir;
    height: 22px;
    margin-left: -20px;
    width: 32px;
  }
}
