.student_class_milestone {
  background-color: $light-ps;
  .app-container {
    padding-top: 100px;
  }

  .student.class {
    width: 100%;
    height: auto;
    min-height: 100%;

    .content {
      display: grid;
      justify-content: center;
      margin: 0 auto;
      max-width: 960px;
      @media screen and (max-width: $screen-sm) {
        display: block;
      }
    }
  }

  .student-milestone-not-ready {
    font-size: 24px;
    line-height: 1.2;
    border-radius: 4px;
    background: $white;
    padding: 15px;
    box-shadow: $disabled-color 0 0 11px;
  }

  .title-container {
    display: grid;
    margin: 10px 15px;
    @media screen and (max-width: $screen-767) {
      margin: auto;
    }
    h5 {
      // padding-left: 15px;
      margin: 0px;
      &.learning-path-label {
        display: grid;
        grid-template-columns: auto auto;
        justify-content: left;
        column-gap: 10px;
        .toggle-course-info {
          cursor: pointer;
          height: 27px;
          i {
            font-size: 18px;
            line-height: 27px;
          }
        }
      }
    }
    .active-period {
      display: grid;
      grid-template-columns: auto auto;
      justify-content: right;
      align-items: center;
      cursor: pointer;
      column-gap: 8px;
      .report-period-text {
        font-size: 14px;
        font-weight: bold;
        color: $black-bold;
      }
    }
  }

  .course-info-toggle-container {
    display: none;
    &.show-course-info {
      display: block;
    }
    .rescope-content-preview {
      display: grid;
      grid-template-columns: 1fr 185px 70px;
      align-items: center;
      font-size: 14px;
      line-height: 1.3;

      @media (max-width: 480px) {
        display: flex;
      }

      .rescope-content-preview-msg {
        padding: 10px;
        text-align: justify;
      }

      .show-completed-course {
        padding-left: 15px;
        padding-bottom: 15px;
      }
    }
  }

  .milestone-course-map-view {
    padding-bottom: 50px;

    @media screen and (min-width: $screen-sm + 1) {
      width: 90vw;
      max-width: 960px;
    }
    .course-map-milestone-container {
      .milestone-accordion-container {
        .header-container {
          color: $white;
          font-size: 12px;
          text-transform: uppercase;
          padding-left: 15px;
        }
      }
      &.self-grading-enabled {
        display: grid;
        grid-template-columns: auto 331px;
        column-gap: 15px;

        .student-class-milestone-course-map {
          margin-top: 22px;
        }

        @media screen and (max-width: $screen-md) {
          grid-template-columns: auto;
        }
        .self-grading-container {
          .header-container {
            color: $white;
            font-size: 12px;
            text-transform: uppercase;
            padding-left: 15px;
          }
        }
      }
    }
  }
}
