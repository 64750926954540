.century-skills.gru-century-skill-tag {
  display: inline-block !important;
  position: relative;
  .content {
    border: 1px solid $gray-light;
    padding: 3px 4px;
    border-radius: 4px;
    background-color: $white;
    margin-bottom: 3px;
    vertical-align: top;
  }
  span {
    display: inline-block !important;
    margin: 0 !important;
    line-height: $font-size-base;
  }
  .btn-skill {
    border: 1px solid $dark-300;
    margin: 0 5px 5px 0;
    padding: 2px 20px 2px 5px;
    border-radius: 4px;
    background-color: $dark-300;
    vertical-align: top;
    color: $white;
    .remove-skill {
      padding: 0;
      position: absolute;
      right: 10px;
      top: 5px;
      background-color: $dark-300;
      border: 0;
      i {
        font-size: inherit;
      }
    }
  }
}
