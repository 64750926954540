.tenant-login-container {
  box-shadow: 0 5px 15px 0 $navbar-default-toggle-icon-bar-bg;
  border-radius: 5px;
  background-color: $white;
  width: 100%;
  margin: 15px auto;
  padding: 0;
  float: none;
  max-width: 400px;
  height: calc(100% - 80px);
  position: relative;

  @media screen and (max-height: 600px) {
    height: auto;
  }
  @media (min-width: $screen-sm-min) {
    width: 400px;
    margin: 25px auto 15px;
  }

  .back {
    display: grid;
    grid-template-columns: 48px auto;
    padding-top: 24px;
    width: 100%;

    @media screen and (max-width: $screen-568 - 1) {
      padding-top: 40px;
    }

    .icon {
      width: 100%;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        width: 24px;
        height: 24px;
        object-fit: contain;
        cursor: pointer;
      }
    }
    .title {
      display: flex;
      flex-direction: column;
      text-align: center;
      font-size: 20px;
      color: $dark-500;
      line-height: 1.4;
    }
  }

  .form-container {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    height: calc(100% - 80px);

    label {
      width: 250px;
      .tenant-domain {
        display: grid;
        grid-template-columns: auto auto;
        .domain {
          display: flex;
          align-items: center;
          font-size: 16px;
          margin-bottom: 15px;
          box-shadow: inset 0 -2px 0 $table-border-color;
          color: $black-517;
        }
        input {
          text-align: left;
          box-shadow: inset 0 -2px 0 $table-border-color;
        }
        .error-messages {
          position: absolute;
          width: 250px;
          right: 0;
        }
      }
    }

    .submit-btn {
      width: 250px;
      height: 48px;
      box-shadow: 0 2px 2px 0 $slider-box-shadow,
        0 0 2px 0 $slider-left-box-shadow;
      border: solid 1px $gray-94;
      background-color: $bg-green-35-green-800;
      font-size: 14px;
      font-weight: bold;
    }

    .img-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .gooru-logo {
        background: url("gooru/images/gooru-logo.svg");
        height: 32px;
        padding: 0;
        width: 111px;
        transform: scale(0.6, 0.6);
        justify-self: center;
      }
      .tenant-logo {
        background: url("gooru/images/NavMath_Logo.png");
        height: 112px;
        padding: 0;
        width: 112px;
        justify-self: center;
        background-size: contain;
        background-repeat: no-repeat;
        margin: 12px 0;
      }
    }
  }
}
