.gru-class-activities-default-scope-sequence {
  height: calc(100vh - 210px);
  overflow: hidden;
  overflow-y: auto;

  @media screen and (max-width: 768px) {
    height: calc(100vh - 250px);
  }

  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px $navbar-box-shadow;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $light-500;
  }
  .scope-sequence-panel-section {
    %panel-header-section {
      background-color: $scope-sequence;
      padding: 5px 10px;
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: center;
      box-shadow: 0 0 5px $disabled-bg-color;
      cursor: pointer;

      .header-title {
        display: grid;
        line-height: 1.4;

        .title-code {
          font-size: 12px;
        }
        .title-name {
          font-weight: bold;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
      .time-section {
        font-weight: bold;
        font-size: 12px;
      }
    }
    .module-panel {
      margin: 0 5px;
      margin-top: 5px;
      .module-panel-header {
        @extend %panel-header-section;
        padding: 10px;
      }
      .module-panel-body {
        display: none;
        &.level-open {
          display: block;
          transition: 0.2s ease-in-out;
        }
        .topic-panel {
          margin-left: 10px;
          margin-top: 5px;
          .topic-panel-header {
            @extend %panel-header-section;
            background-color: $bg-left-box-shadow;
            padding: 10px;
          }
          .topic-panel-body {
            display: none;
            &.level-open {
              display: block;
              transition: 0.2s ease-in-out;
            }
            .competency-panel {
              margin-left: 10px;
              margin-top: 5px;
              transition: 0.2s;

              .competency-panel-header {
                @extend %panel-header-section;
                background-color: $white;
                grid-template-columns: 1fr auto auto auto;
                .compentency-panel {
                  display: flex;
                }
                .title-name {
                  font-weight: normal;
                }
                .competency-metadata {
                  font-size: 12px;
                  margin-left: 5px;
                }
                .zoom-section {
                  display: flex;
                  align-items: center;
                  padding: 5px 10px;
                  .switch_right,
                  .switch_left {
                    transform: rotate(125deg);
                  }
                }
                .header-title {
                  .title-code {
                    .lo-name {
                      text-overflow: ellipsis;
                      overflow: hidden;
                      white-space: nowrap;
                      max-width: 225px;
                    }
                  }
                }
              }
              .competency-panel-body {
                display: none;
                &.level-open {
                  display: block;
                  transition: 0.2s ease-in-out;
                }
                .competency-panel-description {
                  background-color: $light-100;
                  padding: 10px;
                }
                .student-resource-content,
                .teacher-resource-content {
                  .resource-header {
                    display: block;
                    margin: 5px;
                    font-size: 13px;
                    font-weight: bold;
                    text-transform: uppercase;
                  }
                  .load-more-content {
                    padding: 10px;
                    button {
                      background-color: $table-bg;
                      border: none;
                      font-size: 12px;
                      color: $white;
                      border-radius: 2px;
                      font-weight: bold;
                      outline: none;
                    }
                  }
                }
                .resource-container {
                  padding: 10px;
                  background-color: $bg-resource-bg-white;
                  height: 200px;
                  overflow: hidden;
                  overflow-y: auto;

                  .source-divider {
                    display: block;
                    margin: 2px auto;
                    width: 100%;
                    background-color: $bg-source-divider-white;
                    font-size: 12px;
                    padding: 3px 10px;
                    font-weight: bold;
                  }
                  .no-result {
                    display: block;
                    width: 100%;
                    text-align: center;
                  }
                }
              }
              &.zoom-in {
                position: absolute;
                right: 0;
                top: 0;
                width: 100%;
                height: calc(100vh - 120px);
                overflow: hidden;
                background-color: $light-100;
                z-index: 1000;

                .zoom-in-title {
                  padding: 5px;
                  background-color: $white;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
                .competency-panel-body {
                  height: calc(100vh - 220px);
                  overflow: hidden;
                  overflow-y: auto;
                  .load-more-content button {
                    color: $dark-500;
                  }
                  .resource-container {
                    height: auto;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .compentency-status {
    width: 32px;
    height: 32px;
  }
}
