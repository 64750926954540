.ca-date-picker-container {
  box-shadow: 0 4px 40px 0 $date-picker-box-shadow;
  background-color: $white;

  .backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -9;
    cursor: pointer;
    opacity: 0.8;
    background-color: $gray-base;
  }

  .ca-date-nav-container {
    width: 100%;
    position: relative;
    display: grid;
    grid-template-columns: 50px 1fr 50px;
    align-items: center;
    justify-items: center;
    box-shadow: 0 2px 4px 0 $date-picker-box-shadow;
    height: auto;

    .next-cal-arrow,
    .prev-cal-arrow {
      cursor: pointer;
      user-select: none;

      i {
        display: flex;
        font-size: 30px;
        pointer-events: none;
      }

      &.disable {
        pointer-events: none;
      }
    }

    .cal-mm-yyyy {
      display: inline-flex;
      font-weight: bold;
      pointer-events: none;
      width: max-content;

      .arrow_drop_down,
      .arrow_drop_up {
        display: none;
        pointer-events: none;
        height: 20px;
        align-self: center;
      }

      &.ca-date-picker-toggle {
        cursor: pointer;
        pointer-events: all;
        font-size: 24px;

        .arrow_drop_up {
          display: none;
        }

        .arrow_drop_down {
          display: inline-block;
        }

        &.active {
          .arrow_drop_up {
            display: inline-block;
          }

          .arrow_drop_down {
            display: none;
          }
        }
      }
    }
  }

  .ca-datepicker-container {
    background: $white;
    z-index: 1;
    width: inherit;

    hr {
      margin: 0;
    }

    .ca-datepicker-today {
      text-align: center;
      text-transform: uppercase;
      font-weight: bold;
      padding-top: 4px;
      padding-bottom: 4px;
      background-color: $green-700;
      color: $font-gray-white-500;
      cursor: pointer;
    }

    &.ca-date-picker-toggle {
      display: none;
    }

    #ca-datepicker,
    #ca-weekpicker {
      &.disable {
        pointer-events: none;
        opacity: 0.5;
      }

      .datepicker {
        width: 100%;

        .table-condensed {
          width: 100%;

          thead tr:first-child {
            display: none;
          }

          thead tr:nth-child(2) {
            display: grid;
            width: 100%;
            justify-content: center;
            grid-template-columns: repeat(7, 1fr);
            min-height: 25px;
          }

          tbody {
            display: grid;

            tr {
              display: grid;
              width: 100%;
              justify-content: center;
              grid-template-columns: repeat(7, 1fr);
              min-height: 25px;

              td {
                width: 100%;
                height: 100%;
              }
            }
          }

          tr td.disabled:hover,
          tr td.new:hover,
          tr td.no-activities:hover,
          tr td.old:hover {
            background: none;
            font-weight: normal;
            color: $modal-content-fallback-border-color;
          }

          tr td.disabled,
          tr td.new,
          tr td.no-activities,
          tr td.old {
            pointer-events: none;
            font-weight: normal;
            color: $font-gray-100-gray-500;
          }

          tr td.day:hover {
            background: none;
            font-weight: bold;
            color: $gray-base;
          }

          tr td.has-activities {
            font-weight: bold;
            color: $gray-base;
          }

          tr td.has-activities:before {
            content: "";
            display: inline-block;
            background-color: $gray-base;
            width: 3px;
            height: 3px;
            position: relative;
            bottom: -10px;
            left: 10px;
          }

          tr td.today:hover {
            background-color: $today-hover;
            background-image: -webkit-linear-gradient(
              top,
              $orage-fdd,
              $lite-yellow
            );
          }

          tr td.active {
            background-color: $bg-today-hover;
            background-image: unset;
            color: $white;
            text-shadow: none;

            &:hover {
              background-color: $bg-today-hover;
              background-image: unset;
            }
          }
        }
      }
    }
  }

  &.ca-datepicker-orientation-top {
    .ca-datepicker-top {
      display: inline-block;
    }
  }

  &.ca-datepicker-orientation-bottom {
    .ca-datepicker-bottom {
      display: inline-block;
    }
  }

  .ca-datepicker-top {
    position: absolute;
    right: -7px;
    bottom: -14px;
    color: $white;
    display: none;
  }

  .ca-datepicker-bottom {
    position: absolute;
    right: -7px;
    top: -14px;
    color: $white;
    display: none;
  }

  &.ca-datepicker-orientation-center {
    .ca-datepicker-bottom,
    .ca-datepicker-top {
      right: 0;
      left: calc(100% / 2);
    }
  }

  &.ca-datepicker-orientation-left {
    .ca-datepicker-bottom,
    .ca-datepicker-top {
      right: 0;
      left: 2px;
    }
  }
}
