.teacher_class_student-learner-proficiency {
  .gru-class-navbar.teacher {
    .studyplayer-title {
      .nav-title {
        pointer-events: none;
        i {
          display: none;
        }
      }
    }
  }
}
.student_class_student-learner-proficiency {
  height: 100%;
  margin: unset;
  width: 100vw;

  .student.class {
    width: 100%;
  }

  .content
    > .student-proficiency-container
    > .panel-container
    > .student-proficiency-panel
    > .panel-header
    > .back {
    visibility: hidden;
  }
}

.no-course-content {
  font-size: 20px;
  align-items: center;
  height: 85vh;
  display: flex;
  justify-content: center;
}

.student_class_student-learner-proficiency,
.teacher_class_student-learner-proficiency {
  .teacher.class {
    padding: 0;
    @media screen and (max-width: 768px) {
      overflow: auto;
    }
  }

  .student-proficiency-container {
    width: 100%;
    height: 100%;
    background: $light-ps;
    grid-gap: 20px;
    padding: 10px 20px;
    display: grid;
    min-height: 92vh;
    grid-template-columns: 1fr 35%;
    @media screen and (min-width: 1024px) and (max-width: 1299px) {
      overflow: hidden;
      overflow-x: auto;
    }
    .panel-container {
      .inspect-competency {
        @media screen and (min-width: 993px) {
          display: none;
        }
      }
    }
    .pull-up-container {
      padding-right: 15px;
    }

    .taxonomy-grades {
      .taxonomy-grades-container {
        .taxonomy-grades {
          .grade-list {
            @extend %scroll-style;

            .grade-container {
              cursor: pointer;

              &.active-grade {
                background-color: $blue-eo;
                border: none;
                box-shadow: 0 2px 4px 0 $disabled-color;
                opacity: 1;
                font-weight: 700;
                display: grid;
                align-items: center;
                color: $white;

                .grade-title {
                  background-color: $blue-eo;
                  opacity: 1;

                  &:before {
                    border-color: $table-bg $table-bg $table-bg $blue-eo;
                  }
                }
              }

              .grade-title {
                color: $white;
                opacity: 0.7;
              }
            }
          }
        }

        .baseline-toggle {
          position: relative;
          padding: 0;
          margin: 0;
          max-width: 100px;
          width: max-content;
          min-width: 100px;
          text-align: right;
          cursor: pointer;

          .baseline-label {
            border: none;
            display: block;
            padding-left: 10px;
            padding-right: 10px;
            height: 24px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: $white;
            opacity: 0.7;
          }

          &.active-baseline {
            font-weight: bold;

            .baseline-label {
              background-color: $blue-eo;
              border: none;
              box-shadow: 0 2px 4px 0 $disabled-color;
              opacity: 1;
              font-weight: bold;
              display: grid;
              align-items: center;
              justify-content: center;
              color: $white;
            }

            .baseline-label:after,
            .baseline-label:before {
              content: "";
              display: inline-block;
              border-width: 16px;
              border-style: solid;
              width: 0;
              height: 0;
              left: 100%;
              top: -1px;
              position: absolute;
              z-index: 1;
            }

            .baseline-label:after {
              border-color: $table-bg $table-bg $table-bg $table-bg;
            }

            .baseline-label:before {
              border-width: 12px;
              display: block;
              border-color: $table-bg $table-bg $table-bg$blue-eo;
              z-index: 0;
              top: 0;
            }
          }
        }
      }
    }
  }
}
