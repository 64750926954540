.reports.pull-up-collection-report-gridview {
  .grid-view-container {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: 220px 220px 220px;
    padding-top: 40px;
    margin: 0 auto;
    width: 690px;

    .grid-view-card {
      width: 220px;
      min-height: 130px;
      box-shadow: 0 0 4px 1px $navbar-box-shadow;
      background: $white;
      border-radius: 4px;
      padding-bottom: 10px;

      .user-profile-summary {
        height: 70px;
        border-bottom: 1px solid #969696;
        margin-left: 10px;
        margin-right: 10px;

        .user-profile-details {
          width: 190px;
          display: flex;
          align-items: center;
          cursor: pointer;
          padding-top: 10px;

          &.not-started {
            pointer-events: none;
          }

          .user-profile-img {
            width: 70px;

            img {
              width: 50px;
              border-radius: 50%;
              margin-left: 7px;
              border-color: $table-bg;
              height: 50px;
            }
          }

          .username {
            line-height: 1.2;

            p {
              margin: 0;
              padding: 0;
            }
          }
        }
      }

      .contents-container {
        display: grid;
        grid-template-columns: 47px 47px 47px 47px;
        justify-items: center;
        grid-column-gap: 3px;
        grid-row-gap: 8px;
        margin-top: 15px;
        margin-left: 10px;
        margin-right: 10px;

        .time-spent {
          width: 100%;
          text-align: center;
        }

        .not-graded {
          height: 25px;
          width: 25px;

          i {
            margin-top: 2px;
            color: #474849;
            opacity: 0.5;
          }
        }

        .content-score-value {
          display: flex;

          div {
            width: 25px;
            height: 25px;
            border-radius: 50%;
            font-size: 12px;
            font-weight: bold;
            line-height: 23px;
            text-align: center;

            &.correct {
              color: $font-green-35-green-dark;
              border: 1px solid $font-green-35-green-dark;
            }

            &.wrong {
              color: $font-red-400-red-900;
              border: 1px solid $font-red-400-red-900;
            }

            &.not-started {
              color: $suggestion-panel-color;
              border: 1px solid $suggestion-panel-color;
            }

            &.skipped {
              span {
                color: $breadcrumb-color;
                font-size: 12px;
              }

              i {
                font-size: 30px;
                color: $breadcrumb-color;
                display: block;
                margin-top: -25px;
                margin-left: -2px;
              }
            }
          }
        }
      }
    }
  }
}
