.pdf-container {
  .table-container {
    table {
      thead {
        tr {
          .student-name {
            display: none;
            &.hideElement {
              display: block;
            }
          }
          th {
            text-align: center;
          }
        }
      }
      tbody {
        tr {
          border: 1px solid #ddd;
          th {
            text-align: center;
            &.com-code {
              padding: 20px;
              .com-cell {
                display: flex;
                justify-content: center;
                .ans-icon {
                  margin-left: 15px;
                  margin-top: 3px;
                }
              }
            }
          }
          td {
            text-align: center;
            .com-cell {
              display: flex;
              justify-content: center;
              padding: 15px;
              .ans-option {
                .option {
                  &.correct {
                    color: $green-400 !important;
                  }
                  &.incorrect {
                    color: $red-800 !important;
                  }
                  &.partially-correct {
                    color: $green-400 !important;
                  }
                }
              }
              .ans-icon {
                margin-left: 15px;
                margin-top: 4px;
              }
            }
            .question-count {
              display: flex;
              justify-content: center;
              padding: 15px;
            }
          }
        }
      }
    }
    .pdf-page-break {
      page-break-after: always;
    }
  }
}
