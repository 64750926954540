.gru-i2d-template {
  .i2d-template-container {
    button {
      padding: 10px;
    }

    .i2d-table-template {
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      top: 0;
      display: none;

      tr {
        td,
        th {
          border: 1px solid $black-bold;
          border-collapse: collapse;
          padding: 5px;
          text-align: left;

          &.score {
            width: 50px;
          }

          &.student-name {
            text-transform: capitalize;
          }
        }
      }
    }
  }
}
