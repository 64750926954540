.gru-study-navbar {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 100;

  .gru-icon {
    vertical-align: middle;
  }

  %app-info-container {
    display: inline-block;
    word-wrap: break-word;
    vertical-align: middle;
    font-weight: 700;
    font-size: 14px;
    line-height: 1.3;
  }

  %app-container {
    color: $white;
    height: 100%;
    align-items: center;
    grid-gap: 5px;
    display: grid;
  }

  .header-panel {
    height: 48px;
    width: 100%;
    background-color: $blue-400;
    @media only screen and (max-width: $screen-xs) {
      height: 102px;
    }

    .nav-body {
      width: 100%;
      height: 100%;
      display: grid;
      box-shadow: 0 1px 4px $navbar-box-shadow;
      grid-template-columns: 48px 145px 145px 170px 145px 1fr 59px 59px 59px;

      &.public-class {
        grid-template-columns: 48px 145px 170px 170px 1fr 59px 59px 59px;
      }

      &.no-class-info {
        grid-template-columns: 48px 145px 145px 170px 145px 1fr 59px 59px;
      }

      &.premium-class {
        grid-template-columns: 48px 145px 145px 170px 145px 1fr 59px 59px 59px 59px;

        &.no-class-info {
          grid-template-columns: 48px 145px 170px 145px 1fr 59px 59px 59px;
        }

        &.public-class {
          grid-template-columns: 48px 145px 170px 145px 1fr 59px 59px 59px 59px;
        }
      }

      &.nav-menu-class-activities {
        grid-template-columns: 48px 145px 180px 170px 145px 1fr 59px 59px 59px;

        &.no-class-info {
          grid-template-columns: 48px 180px 170px 145px 1fr 59px;
        }

        &.premium-class {
          grid-template-columns: 48px 145px 180px 170px 145px 1fr 59px 59px 59px 59px;

          &.no-class-info {
            grid-template-columns: 48px 180px 170px 145px 1fr 59px 59px;
          }

          &.public-class {
            grid-template-columns: 48px 170px 145px 1fr 59px 59px 59px 59px;
          }
        }
      }

      &.nav-menu-dashboard {
        grid-template-columns: 48px 180px 145px 170px 145px 1fr 59px 59px 59px;

        &.no-class-info {
          grid-template-columns: 48px 180px 170px 145px 1fr 59px;
        }

        &.premium-class {
          grid-template-columns: 48px 180px 145px 170px 145px 1fr 59px 59px 59px 59px;

          &.no-class-info {
            grid-template-columns: 48px 180px 145px 170px 145px 1fr 59px 59px;
          }

          &.public-class {
            grid-template-columns: 48px 170px 145px 145px 1fr 59px 59px 59px 59px;
          }
        }
      }

      &.nav-menu-course-map,
      &.nav-menu-study-player {
        grid-template-columns: 48px 145px 145px 210px 145px 1fr 59px 59px 59px;

        &.no-class-info {
          grid-template-columns: 48px 145px 210px 145px 1fr 59px 59px;
        }

        &.public-class {
          grid-template-columns: 48px 180px 170px 1fr 59px 59px 59px;
        }

        &.premium-class {
          grid-template-columns: 48px 145px 145px 210px 145px 1fr 59px 59px 59px 59px;

          &.no-class-info {
            grid-template-columns: 48px 145px 210px 145px 1fr 59px 59px;
          }

          &.public-class {
            grid-template-columns: 48px 145px 210px 145px 1fr 59px 59px 59px 59px;
          }
        }
      }

      &.independent-learning {
        grid-template-columns: 48px 180px 1fr 59px 59px 59px;

        &.no-class-info {
          grid-template-columns: 48px 180px 1fr 59px;
        }

        &.nav-menu-course-map,
        &.nav-menu-study-player,
        &.nav-menu-dashboard {
          grid-template-columns: 48px 180px 1fr 59px 59px 59px;

          &.no-class-info {
            grid-template-columns: 48px 180px 1fr 59px;
          }
        }
      }
      /* Extra small devices (phones, 600px and down) */
      @media only screen and (max-width: $screen-xs) {
        grid-template-columns: repeat(8, 1fr);

        &.public-class {
          grid-template-columns: repeat(8, 1fr);
          .profile-prof.vactive {
            grid-column: 2 / 4;
          }
          .suggestion-tab {
            grid-column-start: 5;
          }
        }

        &.no-class-info {
          grid-template-columns: repeat(6, 1fr);
        }

        &.nav-menu-class-activities,
        &.nav-menu-course-map,
        &.nav-menu-study-player,
        &.nav-menu-dashboard {
          grid-template-columns: repeat(8, 1fr);

          &.no-class-info {
            grid-template-columns: repeat(6, 1fr);
          }

          &.public-class {
            grid-template-columns: repeat(8, 1fr);
            .course-map {
              order: 4;
              &.vactive {
                grid-column: 1 / 3;
              }
            }
            .profile-prof {
              order: 5;
              &.vactive {
                grid-column: 2 / 4;
              }
            }
            .dashboard {
              order: 6;
              &.vactive {
                grid-column: 3 / 5;
              }
            }
            .suggestion-tab {
              grid-column-start: 5;
            }
          }
        }

        &.independent-learning {
          grid-template-columns: repeat(7, 1fr);

          &.no-class-info {
            grid-template-columns: repeat(6, 1fr);
          }

          &.nav-menu-course-map,
          &.nav-menu-study-player,
          &.nav-menu-dashboard {
            grid-template-columns: repeat(7, 1fr);

            &.no-class-info {
              grid-template-columns: repeat(5, 1fr);
            }
          }
        }

        &.premium-class {
          grid-template-columns: repeat(8, 1fr);

          &.no-class-info {
            grid-template-columns: repeat(7, 1fr);
          }

          &.public-class {
            grid-template-columns: repeat(8, 1fr);
          }

          &.nav-menu-class-activities,
          &.nav-menu-course-map,
          &.nav-menu-study-player,
          &.nav-menu-dashboard {
            &.no-class-info {
              grid-template-columns: repeat(7, 1fr);
            }
            &.public-class {
              grid-template-columns: repeat(8, 1fr);
            }
            grid-template-columns: repeat(8, 1fr);
          }

          .studyplayer-title {
            grid-column: 2 / 8;
          }
        }

        .studyplayer-title {
          grid-column: 2 / 8;
          order: 2;
          border-right: 1px solid $breadcrumb-color;
          border-bottom: 1px solid $breadcrumb-color;
          width: 100%;
          text-align: center;
        }

        .performance-summary {
          order: 3;
          border-right: unset !important;
        }

        .class-activities {
          order: 4;

          .class-activities-tab {
            grid-template-columns: auto auto;
          }

          &.vactive {
            a.tab-menu {
              grid-template-columns: auto auto;
            }
            grid-column: 1 / 3;
          }
        }
        .dashboard {
          order: 7;
          &.vactive {
            grid-column: 3 / 5;
          }
        }

        .course-map {
          order: 5;

          .course-map-tab {
            grid-template-columns: auto auto;
          }

          &.vactive {
            a.tab-menu {
              grid-template-columns: auto auto;
            }
            grid-column: 2 / 4;
          }
        }

        .profile-prof {
          order: 6;
          border-right: unset;

          &.vactive {
            grid-column: 3 / 5;
          }
        }
        @media only screen and (max-width: $screen-xs) {
          .suggestion-tab {
            position: inherit !important;
          }
        }

        .suggestion-tab {
          order: 7;
          border-right: 1px solid $gray-80;
        }

        .notification-tab {
          order: 9;
          grid-column-start: 7;
          border-right: 1px solid $gray-80;

          &.vactive {
            grid-column: 6 / 6;
          }
        }

        &.nav-menu-default {
          .notification-tab {
            grid-column: 6 / 6;
          }
        }

        .class-info {
          order: 10;
          width: 100%;

          &.vactive {
            grid-column: 7 / 8;
          }

          a {
            margin: 0 auto;
          }
        }

        .class-activities,
        .course-map,
        .profile-prof,
        .dashboard {
          a.tab-menu {
            grid-gap: 0;
            grid-template-columns: auto;

            .add-info {
              display: none;
            }
          }
        }
      }
      @media only screen and (min-width: $screen-xs) and (max-width: $screen-sm) {
        grid-template-columns: 48px 59px 59px 59px 59px 1fr 59px 59px 59px;

        &.public-class {
          grid-template-columns: 48px 59px 59px 59px 1fr 59px 75px 59px;
        }

        &.independent-learning {
          grid-template-columns: 48px 59px 89px 1fr 59px 59px 59px;

          &.no-class-info {
            grid-template-columns: 48px 59px 89px 1fr 59px;
          }

          &.nav-menu-course-map,
          &.nav-menu-study-player,
          &.nav-menu-dashboard {
            grid-template-columns: 48px 59px 89px 1fr 59px 59px 59px;

            &.no-class-info {
              grid-template-columns: 48px 59px 89px 1fr 59px;
            }
          }
        }

        &.premium-class {
          border: 1px solid red;
          grid-template-columns: 48px 59px 59px 59px 59px 1fr 59px 75px 59px 59px;

          &.no-class-info {
            grid-template-columns: 48px 59px 59px 59px 59px 1fr 59px 75px;
          }

          &.public-class {
            grid-template-columns: 48px 59px 59px 59px 1fr 59px 75px 59px 59px;
          }

          &.nav-menu-class-activities {
            grid-template-columns: 48px 59px 89px 59px 59px 1fr 59px 89px 59px 59px;

            &.no-class-info {
              grid-template-columns: 48px 59px 89px 59px 59px 1fr 59px 89px;
            }
          }

          &.nav-menu-dashboard {
            grid-template-columns: 48px 89px 59px 59px 59px 1fr 59px 89px 59px 59px;

            &.no-class-info {
              grid-template-columns: 48px 89px 59px 59px 59px 1fr 59px 89px;
            }
          }

          &.nav-menu-course-map,
          &.nav-menu-study-player {
            grid-template-columns: 48px 59px 59px 89px 59px 1fr 59px 89px 59px 59px;

            &.no-class-info {
              grid-template-columns: 48px 59px 59px 89px 59px 1fr 59px 89px;
            }
          }
        }

        &.nav-menu-class-activities {
          grid-template-columns: 48px 59px 89px 59px 59px 1fr 59px 59px 59px;

          &.no-class-info {
            grid-template-columns: 48px 59px 89px 59px 59px 1fr 59px;
          }
        }

        &.nav-menu-dashboard {
          grid-template-columns: 48px 59px 89px 59px 59px 1fr 59px 59px 59px;

          &.no-class-info {
            grid-template-columns: 48px 59px 89px 59px 59px 1fr 59px;
          }
        }

        &.nav-menu-course-map,
        &.nav-menu-study-player {
          grid-template-columns: 48px 59px 59px 89px 59px 1fr 59px 59px 59px;

          &.no-class-info {
            grid-template-columns: 48px 59px 59px 89px 59px 1fr 59px;
          }
          &.public-class {
            grid-template-columns: 48px 59px 89px 59px 1fr 59px 75px 59px;
          }
          &.premium-class {
            &.public-class {
              grid-template-columns: 48px 59px 89px 59px 1fr 59px 75px 59px 59px;
            }
          }
        }

        .class-activities,
        .course-map,
        .profile-prof,
        .dashboard {
          a.tab-menu {
            grid-gap: 0;
            grid-template-columns: auto;

            .class-activity-tab,
            .course-map-tab {
              grid-template-columns: auto;
            }

            .add-info {
              display: none;
            }
          }
        }

        .class-activities,
        .course-map {
          &.vactive {
            a.tab-menu {
              grid-template-columns: auto auto;
            }
          }
        }
      }

      .tab {
        .performance-score {
          font-size: 18px;
          font-weight: bold;
          display: none;
        }

        &.vactive {
          .performance-score {
            display: block;

            &.not-started {
              pointer-events: none;
              cursor: default;
            }
          }
        }

        &:not(.vactive) {
          background-color: $blue-400;
        }
      }

      .class-activities,
      .class-management,
      .course-map,
      .profile-prof,
      .study-player {
        .no-course {
          pointer-events: none;
        }

        &.enable {
          pointer-events: all;
        }
      }

      .app-close {
        height: 48px;
        background-color: $white;

        a {
          @extend %app-container;
          grid-template-columns: auto;

          i.arrow_back,
          i.close {
            padding: 12px;
            opacity: 1;
            color: $dark-600;
            font-size: 24px;
            justify-content: center;
            align-items: center;
            display: grid;

            &:hover {
              color: $gray-base;
            }
          }

          i.arrow_back {
            font-size: 40px;
          }
        }
      }

      .class-activities {
        border-right: 1px solid $white;
        height: 48px;

        .class-activity-tab {
          display: grid;
          grid-template-columns: auto auto;
          align-items: center;
          justify-content: center;
          justify-items: center;
        }

        &.grade-range-not-started {
          background: $table-bg;

          &.vactive {
            background-color: $sky-400;
          }
        }

        a {
          @extend %app-container;
          grid-template-columns: auto auto;

          .dca-pin {
            background-image: url("gooru/pin-white.png");
            background-repeat: no-repeat;
            width: 25px;
            height: 20px;
            background-size: 20px 20px;
            display: block;
            justify-self: center;
          }

          .add-info {
            @extend %app-info-container;
          }
        }
        &.grade-range-0-59 {
          &.vactive {
            a {
              color: $font-white-100-white-500;
              .class-activity-tab {
                .dca-pin {
                  background: url("gooru/images/teacher-landing-class-activities.svg");
                  background-repeat: no-repeat;
                  background-size: 20px 20px;
                }
              }
            }
          }
        }
      }

      .course-map,
      .study-player {
        border-right: 1px solid $white;
        height: 48px;

        .course-map-tab {
          display: grid;
          grid-template-columns: auto auto;
          align-items: center;
          justify-content: center;
          justify-items: center;

          i {
            width: 30px;
          }
        }

        &.grade-range-not-started {
          background: $table-bg;

          &.vactive {
            background-color: $sky-400;
            a {
              color: $font-dark-500-white-500;
            }
          }
        }

        i {
          justify-self: center;
        }

        &.class-setup-incomplete.premium-course {
          pointer-events: none;
          cursor: default;
        }

        a {
          @extend %app-container;
          grid-template-columns: auto auto;
        }

        .add-info {
          @extend %app-info-container;
        }
        &.grade-range-60-69 {
          &.vactive {
            a {
              color: $font-white-100-white-500;
            }
          }
        }
        &.grade-range-0-59 {
          &.vactive {
            a {
              color: $font-white-100-white-500;
            }
          }
        }
      }

      .profile-prof {
        border-right: 1px solid $white;
        height: 48px;
        justify-content: center;
        align-items: center;

        i {
          justify-self: center;
        }

        a {
          grid-template-columns: auto auto;
          @extend %app-container;
        }

        .add-info {
          @extend %app-info-container;
        }

        &.vactive {
          background-color: $sky-400;
          a {
            color: $gray-base;
          }
        }
      }

      .dashboard {
        border-right: 1px solid $white;
        height: 48px;
        justify-content: center;
        align-items: center;

        i {
          justify-self: center;
        }

        a {
          grid-template-columns: auto auto;
          @extend %app-container;
        }

        .add-info {
          @extend %app-info-container;
        }

        &.vactive {
          background-color: $sky-400;
          a {
            color: $gray-base;
          }
        }
      }

      .studyplayer-title {
        display: grid;
        margin: auto;
        font-size: 16px;
        font-weight: 700;
        height: 48px;
        padding: 10px;
        align-items: center;
        pointer-events: none;
        color: $white;

        span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .suggestion-tab {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $white;
        position: relative;

        .suggestion-explore {
          width: 20px;
          border-radius: 50%;
          background: $suggestion-explore-bg;
          height: 20px;
          box-shadow: 0 0 5px 1px $explore-box-shadow;
          margin-top: 2px;
          color: $white;
          position: relative;
          cursor: pointer;

          .explore {
            transform: rotate(90deg);
            margin-top: -2px;
            margin-left: -2px;
            color: #f88c49;
          }

          .suggestion-count {
            width: 16px;
            border-radius: 50%;
            background: $white;
            position: absolute;
            line-height: 14px;
            top: -5px;
            height: 16px;
            left: 14px;
            color: $gray-4a;
            border: 1px solid $gray-4a;
            font-size: 9px;
            text-indent: 4px;
          }
        }
      }

      .notification-tab {
        display: grid;
        height: 48px;
        align-items: center;
        cursor: pointer;
        justify-content: center;
        border-right: 1px solid $white;
        border-left: 1px solid $white;

        div {
          .active-study {
            i {
              vertical-align: middle;
            }
          }
        }
      }

      .performance-summary {
        border-right: 1px solid $gray-80;
        height: 48px;
        text-align: center;
        font-weight: bold;
        font-size: 16px;
        line-height: 1;
        min-width: 50px;
        cursor: pointer;

        &:not(.has-competency-stats) {
          pointer-events: none;
        }

        .no-completion-score {
          padding-top: 16px;
          display: block;
        }

        .numerator {
          margin-bottom: 6px;
          display: block;
          margin-top: 2px;
        }

        .denominator {
          border-top: 2px solid $font-dark-500-white-500;
        }

        &.has-competency-stats {
          background-color: $sky-400;
          color: $font-dark-500-white-500;
          padding-top: 5px;
        }
      }

      .class-info {
        border-right: 1px solid $white;
        height: 48px;

        a {
          @extend %app-container;
          justify-content: center;
        }
      }
    }
  }
}

div.student.class {
  div.header.clearfix {
    > div.go-back-container {
      display: none;
    }
  }
}

.hide-classroom-information {
  display: none;
}
