.content.etl.ps-etl-second {
  .etl-seconds {
    margin-top: 10px;
    display: flex;

    &.computed-etl {
      padding-left: 30px;
    }

    label {
      margin: 0;

      span {
        margin: 11px 10px 0px 0px;
        color: $dark-night;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }

      .ps-etl-color {
        color: $ps-grey-74;
      }

      .etl-label {
        display: grid;
        margin-bottom: 20px;

        &.duration-hours {
          span {
            margin-bottom: 20px;
          }
        }

        .col-sm-12 {
          display: flex;
          padding: 0px;
        }

        .title-label {
          display: grid;

          &.estimated-minutes {
            margin-left: -30px;
          }

          span {
            //text-transform: lowercase;
            color: $dark-night;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            padding-left: 0px;
          }

          .gru-input {
            border-bottom: none;
            margin-bottom: 12px;

            .form-group {
              margin-bottom: 0;

              input {
                min-width: 70px !important;
                height: 32px;
                margin: 11px 10px 0px 0px;
                border: 1px solid $link-water;
                border-radius: 4px;
                padding-left: 5% !important;
                max-width: 95px !important;
              }
            }
          }
        }

        .error-messages {
          .error {
            color: $red-400;
            margin-top: 0px;
            padding-left: 0px;
          }
        }
      }
    }
  }

  .ps-etl-top-margin {
    margin-top: -10px;
  }

  .fluency {
    .flu-label {
      display: block;
    }

    .dropdown-toggle {
      margin-left: 1px;
    }
  }
}
