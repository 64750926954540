.class-performance-chart {
  // TODO need to increase the width and height once the cluster data ready
  width: 110px;
  height: 110px;
  margin: auto;

  svg {
    width: 100%;
    height: 100%;
  }
  .performance-score {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    text-align: center;
    color: $white;
    font-size: 30px;
    line-height: 100px;
    font-weight: bold;
  }
}
