.app-readonly-mode {
  overflow: hidden;
}

.frame-app-readyonly {
  .gru-study-navbar .header-panel .nav-body .app-close {
    background-color: $breadcrumb-color;
    pointer-events: none;
  }

  .colllection-play-btn {
    visibility: hidden !important;

    &:hover {
      visibility: hidden !important;
    }
  }

  .share-class-data {
    .toogle-options {
      .toggle {
        pointer-events: none !important;
        opacity: 0.5;
      }
    }
  }

  .ca-play-container,
  .colllection-play-btn,
  .competency-level-container .proficiency-info,
  .diagnosis-of-knowledge-panel .footer,
  .panel-body.notfication-container,
  .play-btn,
  .play-container,
  .setup-in-complete-panel .back,
  .suggestion-panel .suggestion-header .suggestion .action {
    pointer-events: none !important;
    opacity: 0.6;
  }
}

.student-impersonate {
  .back-drop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $dark-500;
    opacity: 0.7;
    z-index: 1000;
  }

  #impersonate-header {
    height: 50px;
    background: $white;
    border-bottom: 1px solid $close-color;
    display: flex;
    align-items: center;

    .student-info-container {
      width: calc(100% - 60px);
      display: grid;
      grid-template-columns: 50px 1fr;
      align-items: center;

      .student-icon {
        background-size: contain;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        margin: auto;
      }
    }

    .impersonate-close {
      border-left: 1px solid $breadcrumb-color;
      height: 50px;
      display: flex;

      .impersonate-close-btn {
        align-self: center;
        text-indent: 17px;
      }

      i {
        color: $close-color;
      }
    }
  }

  .close-btn {
    float: right;
    bottom: 35px;
    font-size: 35px;
    color: $white;
    position: relative;
    z-index: 1000;
    right: 150px;
    cursor: pointer;

    .material-icons {
      font-size: 45px;
    }
  }

  .iframe-panel {
    z-index: 1000;
    position: fixed;
    width: fit-content;
    margin: 0 auto;

    iframe {
      width: 100vw;
      height: 89vh;
    }
  }
}
