.course-competency-report {
  .domain-list {
    .domain {
      margin-bottom: 30px;
      .domain-header {
        display: flex;
        border-bottom: dashed 2px $nobel-gray;
        padding-bottom: 5px;
        .title {
          font-size: 18px;
          font-weight: bold;
          color: $dark-800;
          width: 83%;
          cursor: pointer;
        }
        .info {
          width: calc(100% - 83%);
          font-size: 15px;
          color: $dark-800;
          text-align: right;
          opacity: 0.7;
        }
      }
      .domain-body {
        margin-top: 15px;
        max-width: 760px;
        // overflow-x: auto;
        position: relative;
        display: flex;
        .student-competencies {
          display: flex;
          width: 645px;
          overflow-x: auto;
          .student-competency {
            width: calc(100% / 8);
            min-width: 50px;
            .competency-chart {
            }
            .student-thumbnail {
              border-radius: 50%;
              width: 38px;
              height: 38px;
              background-size: cover;
              margin: 10px auto auto;
            }
          }
          &::-webkit-scrollbar {
            background: $table-bg;
            width: 0px;
          }
        }
        .arrow {
          height: 250px;
          i {
            font-size: 40px;
            line-height: 250px;
            cursor: pointer;
          }
          &.previous {
          }
          &.next {
            position: absolute;
            right: 0px;
          }
          &.disabled {
            opacity: 0.5;
            pointer-events: none;
          }
        }
      }
    }
  }
}
