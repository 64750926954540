.student-independent-learning {
  .container-fluid {
    .student-independent {
      padding-top: 30px;
      background-color: $dark-400;

      > * {
        margin: 0 auto;
        width: 97%;
        @media (min-width: $screen-sm) {
          width: 100%;
        }
        @media (min-width: $screen-md) {
          width: 100%;
        }
      }

      .title {
        > * {
          display: inline-block;
        }
      }
    }
  }

  .student-independent {
    .student-left-panel {
      max-width: 965px;
      float: $tab-float-dir;

      .greetings {
        span {
          color: $white;
          font-size: $font-size-lead;
        }

        p {
          font-weight: lighter;
          @media (min-width: $screen-sm) {
            font-size: $font-navigator;
          }
        }

        .featured-courses {
          margin-bottom: 10px;
        }
      }

      .panels {
        .panel {
          &.featured-courses,
          &.join-class {
            min-height: 235px;
          }

          &.join-class {
            .panel-body {
              .legend {
                height: 115px;
              }
            }
          }

          .panel-heading {
            padding: 10px 18px 0;
            font-size: $font-size-lead;
            color: $gray-base;
          }

          .panel-body {
            padding: 0 15px 10px 18px;

            .legend {
              font-size: $font-size-lead;
            }

            .actions {
              margin: 22px 0 0;

              button {
                font-size: $font-size-base;
              }
            }

            .will-disappear {
              color: $light-400;
              line-height: $font-size-base;
            }
          }
        }
      }

      .tab-content {
        padding: 10px 0 0;
        clear: both;
        @include transition(width 0.3s ease-out);
        @media (min-width: $screen-sm) {
          width: 100%;
        }
        @media (min-width: $screen-md) {
          width: 100%;
        }

        .tab-pane {
          margin: 0;
        }
      }
    }
  }

  .gru-independent-learning-navigation,
  .student-navigator {
    clear: both;

    .nav-tabs {
      box-shadow: inset 0 -1px 0 $gray-light;

      li {
        margin-right: 15px;

        a {
          padding: 10px 0 3px;
        }
      }
    }

    li {
      height: 43px;
      margin-bottom: 0;
      @media (min-width: $screen-sm) {
        display: block;
      }

      &.active {
        display: block;

        a {
          box-shadow: none;
          padding-bottom: 0;
          border-bottom: solid 4px $white;
          padding-right: 10px;
          color: $white;
        }
      }

      &:not(.active) {
        a {
          box-shadow: none;
        }
      }

      a {
        color: $light-400;

        &:hover {
          color: $white;
        }

        span {
          font-size: $font-navigator;
          font-weight: 600;
        }
      }
    }
  }

  .gru-independent-learning-navigation {
    .nav-tabs {
      li {
        a {
          span {
            font-weight: normal;
            font-size: $font-navigator;
            vertical-align: middle;
          }
        }

        i {
          display: inline-block;
          width: 25px;
          height: 25px;
          vertical-align: middle;
          margin-right: 2px;
          background-repeat: no-repeat;
          background-position: center center;
        }

        &.courses i {
          background-image: url("gooru/images/add_course.svg");
        }

        &.collections i {
          background-image: url("gooru/images/add_collection.svg");
        }

        &.assessments i {
          background-image: url("gooru/images/add_assessment.svg");
        }
      }
    }
  }

  .content {
    margin: 22px 0;

    .panel.bookmarks {
      border-radius: 5px;
      padding: 7px 0 10px 30px;
      min-height: 175px;
      position: relative;

      .panel-heading {
        font-size: $font-navigator;
        line-height: $font-navigator;
        color: $gray-base;
        padding: 0;
      }

      .panel-body.bookmarks-list {
        padding: 0;

        &.collapsed {
          height: 125px;
          overflow: hidden;
        }

        &.expanded {
          height: 263px;
          overflow-y: auto;
        }

        .add-bookmark {
          float: $tab-float-dir;
          border: 1px dashed $light-500;
          padding: 20px;
          border-radius: 6px;
          color: $gray;
          text-align: center;
          height: 100px;
          width: 85px;
          margin: 20px 21px 0 0;
          cursor: pointer;

          &:before {
            content: "+ ";
            font-size: $font-add-bookmark;
            line-height: $font-add-bookmark;
          }
        }

        .collapse-expand {
          color: $light-500;
          margin: 0 10px;
          position: absolute;
          right: 10px;

          &.less {
            top: 0;
          }

          &.more {
            top: 145px;
          }

          i {
            float: $tab-float-dir;
            clear: left;
            display: inline-block;
            -webkit-transform: scale(2, 1);
            -moz-transform: scale(2, 1);
            -ms-transform: scale(2, 1);
            -o-transform: scale(2, 1);
            transform: scale(2, 1);

            &:nth-child(2) {
              position: relative;
              top: -19px;
            }
          }
        }

        .show-more {
          text-align: center;
          display: block;
          clear: both;
          padding: 10px 0 0;
        }
      }
    }
  }

  .bookmark-content {
    background-color: $white;
    margin-top: 25px;
    margin-bottom: 30px;
    border-radius: 4px;
    box-shadow: 0 0 6px 1px $table-box-shadow-07;
    padding: 0;

    .title {
      border-bottom: solid 1px $light-200;
      height: 40px;
      line-height: 40px;

      .bookmark-section {
        height: 0;
        display: flex;

        .section-header {
          color: $dark-500;
          font-size: 16px;
          font-weight: bold;
          margin-left: 15px;
        }

        .add-bookmark {
          line-height: 4.5;
          cursor: pointer;

          i {
            margin-left: 15px;
          }
        }

        .no-bookmark {
          font-size: 19.5px;
          color: $gray;
          text-align: center;
          margin-top: 12%;
          font-weight: 300;
        }
      }

      .show-more {
        float: $menu-float-dir;
        margin-right: 15px;
        color: $sky-400;
        font-size: 13px;
        text-transform: uppercase;
        cursor: pointer;
      }
    }

    .content-results {
      &.not-expanded {
        height: 245px;
        overflow: hidden;
      }

      &.expanded {
        overflow-y: auto;
        height: 540px;
      }

      .show-more-results {
        width: 30%;
        padding: 5px;
        margin-left: 35%;
      }
    }

    .expanded::-webkit-scrollbar {
      width: 4px;
      border-radius: 3px;
    }

    .expanded::-webkit-scrollbar-thumb {
      background: $gray-light;
      border: 1px solid $gray-light;
      border-radius: 5px;
    }
  }

  .new-gru-bookmark-card {
    display: grid;
    justify-content: center;
    margin: 15px 0 0;
  }

  .no-bookmarks {
    text-align: center;

    .explore-img {
      background: url("gooru/images/independent-learning-null.png") no-repeat;
      height: 250px;
      width: 250px;
      background-size: 216px 216px;
      margin: 25px auto -15px;
    }

    .no-independent-results {
      font-size: 19.5px;
      font-weight: 300;
      color: $gray;
    }

    .btn {
      margin-top: 15px;
      margin-bottom: 15px;
      font-size: 13px;
    }
  }
}
