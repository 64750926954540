.proficiency.proficiency-matrix {
  margin: 0 auto;
  background-color: $white;

  .three-bounce-spinner {
    position: absolute;
    left: 33%;
    z-index: 9;
  }

  .block-container {
    position: absolute;
    display: block;
    background: $navbar-default-toggle-icon-bar-bg;
    width: 100%;
    height: 100%;
    z-index: 1;
    cursor: default;

    .selected-competency {
      box-shadow: 0 0 6px 5px $explore-box-shadow;
      position: relative;

      &.background0 {
        background-color: $gray-no-started;
      }
      &.background1 {
        background-color: $sky-400;
      }
      &.background2,
      &.background3,
      &.background4,
      &.background5 {
        background-color: #006eb5;
      }
    }
  }

  .clear {
    clear: both;
  }

  .fillArea0 {
    fill: $gray-no-started;
  }
  .fillArea1 {
    fill: $sky-400;
  }
  .fillArea2,
  .fillArea3,
  .fillArea4,
  .fillArea5 {
    fill: #006eb5;
  }

  .competency-chart-tab-container {
    padding-top: 20px;

    .courses {
      width: 220px;
      margin-right: 30px;

      ul {
        padding: 0;
        list-style: none;
        font-size: 15px;
        width: 100%;
        margin: 0;
      }

      li {
        width: 100%;
        line-height: 18px;
        font-size: 12px;
        text-align: left;
        margin-bottom: 15px;
      }

      li:last-child {
        border: none;
      }
    }

    .matrix-chart-container {
      .domain-list {
        display: inline-block;

        .domain-counts {
          float: left;
          display: inline-block;
          width: 35px;
          text-align: center;
          margin-top: 0;
        }
      }
    }

    .scroll-view {
      width: 455px;
      overflow-x: auto;
    }

    .no-data {
      width: 100%;
      display: inline-block;
      text-align: center;
      font-size: 19px;
      color: $light-400;
    }

    .matrix-chart-container::-webkit-scrollbar {
      height: 6px;
      width: 8px;
    }

    .matrix-chart-container::-webkit-scrollbar-thumb {
      background: $gray-light;
      border: 1px solid $gray-light;
      border-radius: 5px;
    }

    #render-proficiency-matrix {
      margin-bottom: 20px;
      position: relative;
      transform: rotateX(180deg);
      -webkit-transition: -webkit-transform 0.6s ease-in;
      transition: transform 0.6s ease-in;
      -webkit-transform-style: preserve-3d;
      transform-style: preserve-3d;
      height: 450px;

      .competency {
        stroke: $snow-gray;
        stroke-width: 1;
        shape-rendering: crispedges;
        stroke-opacity: 0.4;
        cursor: pointer;

        &.competency-more-cells {
          cursor: default;
        }
      }

      line {
        stroke: $white;
        stroke-width: 6px;
      }

      .skyline {
        transition-property: visibility, opacity;
        transition-duration: 0s, 1s;

        &.disable-skyline {
          opacity: 0;
          visibility: hidden;
          transition-property: opacity, visibility;
          transition-duration: 1s, 0s;
          transition-delay: 0s, 1s;
        }
      }
    }
  }
}
