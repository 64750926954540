.gru-rubric-info {
  .categories {
    color: $white;
    ul {
      list-style: none;
      padding: 0;
      max-height: 325px;
      overflow: auto;
      li {
        font-size: $font-size-small;
        padding: 10px 30px;
        margin: 8px 0;
        padding: 3px 14px;
        border: 1px solid $dark-300;
        &.total {
          font-size: $font-size-base;
          margin: 20px 0 10px;
          padding: 0 14px;
          border: 0;
        }
      }
    }
  }
  .rubric-preview {
    padding: 10px;
    height: 100%;
    .gru-preview-url {
      iframe {
        height: 100%;
      }
    }
  }
}
