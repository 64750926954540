.proficiency.proficiency-course-matrix {
  margin: 0 auto;
  background-color: $white;

  .clear {
    clear: both;
  }

  .block-container {
    position: absolute;
    display: block;
    background: $navbar-default-toggle-icon-bar-bg;
    width: 100%;
    height: 100%;
    z-index: 1;
    cursor: default;

    .selected-competency {
      box-shadow: 0 0 6px 5px $explore-box-shadow;
      position: relative;
    }
  }

  .course-matrix-container {
    padding-top: 20px;

    .courses {
      width: 226px;
      border-right: 1px solid $gray-80;

      ul {
        padding: 0;
        list-style: none;
        font-size: 15px;
        width: 100%;
        margin: 0;
      }

      li {
        padding-left: 20px;
        width: 100%;
        padding-right: 10px;
        line-height: 18px;
        height: 30px;
        text-align: right;
        font-size: 12px;
      }

      li:last-child {
        border: none;
      }
    }

    .matrix-container {
      width: 440px;
    }

    .scroll-view {
      width: 440px;
      overflow-x: auto;
    }
    .no-data {
      width: 100%;
      display: inline-block;
      text-align: center;
      font-size: 19px;
      color: $light-400;
    }

    .matrix-container::-webkit-scrollbar {
      height: 6px;
      width: 8px;
    }

    .matrix-container::-webkit-scrollbar-thumb {
      background: $gray-light;
      border: 1px solid $gray-light;
      border-radius: 5px;
    }

    #render-proficiency-course-matrix {
      margin-left: 20px;
      margin-bottom: 20px;
      position: relative;

      .competency {
        stroke: $snow-gray;
        stroke-width: 1;
        shape-rendering: crispedges;
        cursor: pointer;
      }
    }
  }
}
