.gru-student-class-join-card {
  display: grid;
  .gru-class-join-card-container {
    &.guest-account .class-join-card-panel {
      position: relative;
      &::before {
        display: flex;
        align-items: flex-end;
        position: absolute;
        background-color: $join-class-room-bg-color;
        z-index: 100;
        width: 100%;
        height: 100%;
        left: 0;
        color: $gray-base;
        top: 0;
        content: attr(data-before);
        justify-content: center;
        padding-bottom: 7px;
        align-items: center;
        text-align: center;
      }
    }

    .class-join-card-panel {
      margin-bottom: 0%;
      background-color: $white;
      width: 210px;
      border-radius: 5px;
      overflow: hidden;
      @media screen and (max-width: 600px) {
        width: 300px;
      }
      .class-join-card-head {
        height: 320px;
        background-color: $green-light;
        color: $white;
        padding: 20px 10px;
        display: grid;
        align-items: center;
        height: auto;

        .class-join-text {
          span {
            display: block;
            line-height: 1;
            &.class-head-title {
              font-size: 44px;
              font-weight: bold;
            }
            &.class-code {
              font-size: 18px;
              font-weight: bold;
            }
            &.class-hidden {
              visibility: hidden;
            }
          }
        }
      }
      .class-input-section {
        padding: 10px;
        line-height: 1;
        .sign-in-title {
          display: block;
          font-weight: 100px;
          text-transform: uppercase;
          &.class-hidden {
            visibility: hidden;
          }
        }
        input {
          width: 100%;
        }
        ::placeholder {
          color: $dark-400;
          font-size: 14px;
        }
        .instruction-title {
          font-size: 12px;
          display: block;
          max-width: 82%;
          line-height: 1.2;
          &.class-hidden {
            visibility: hidden;
          }
        }

        .validation span.error,
        span.validation.error {
          color: #f46360;
          display: inline-block;
          font-size: 12px;
          margin-top: 0px;
          max-width: 79%;
          line-height: 1.2;
        }
      }
      .join-class-action {
        padding: 10px;
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: center;

        .go-icon {
          display: grid;
          align-items: center;
        }

        .join-class-btn {
          font-size: 15px;
          font-weight: bold;
          color: $sky-400;
          text-transform: uppercase;
        }
      }
    }
  }
}
