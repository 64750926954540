.teacher-ca-student-list-pull-up {
  position: fixed;
  bottom: -100%;
  z-index: 1031;
  width: 468px;
  left: 0;
  right: 0;
  margin: auto;
  color: $suggestion-panel-color;
  @media screen and (max-width: $screen-xs) {
    width: 100%;
  }

  .loading-spinner {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background: $loading-spinner-bg-color;
  }

  .backdrop {
    background-color: $backdrop-color;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    cursor: pointer;
    opacity: 0.8;
    background-color: $gray-base;
  }

  .backdrop-pull-ups {
    .backdrop {
      background: none;
    }
  }

  .ca-student-list-container {
    background: $white-bold;
    width: 468px;
    max-height: 90vh;
    height: 90vh;
    @media screen and (max-width: $screen-xs) {
      width: 100%;
    }

    &.past-activity {
      .student-list-container {
        .user-profile-details {
          pointer-events: none;
          cursor: default;
          opacity: 0.6;
        }
      }

      .action-buttons {
        .save-users {
          pointer-events: none;
          cursor: default;
          opacity: 0.6;
        }
      }
    }

    .ca-student-list-header {
      height: 50px;
      background: #3d5f68;
      display: inline-flex;
      width: 100%;

      .ca-student-selected-ctn {
        width: 150px;
        margin-left: 15px;
        display: flex;
        align-items: center;
        font-size: 15px;
        color: $white;
        font-weight: bold;
        @media screen and (max-width: $screen-xs) {
          display: none;
        }
        span {
          padding-left: 5px;
        }
      }

      .search-student-container {
        width: 285px;
        display: flex;
        justify-content: flex-end;
        @media screen and (max-width: $screen-xs) {
          width: 100%;
        }

        .search-input-container {
          display: flex;
          position: relative;
          border: none;
          @media screen and (max-width: $screen-xs) {
            width: 100%;
            padding-left: 5px;
            padding-right: 5px;
          }

          #student-search {
            -webkit-appearance: none;
            outline: none;
            border-radius: 5px;
            width: 265px;
            text-indent: 10px;
            margin-top: 4px;
            margin-bottom: 3px;
            box-shadow: inset 0 0 7px 1px #3d5f68;
            cursor: text;
            @media screen and (max-width: $screen-xs) {
              width: 100%;
            }
          }

          .search-icon {
            width: 38px;
            height: 48px;
            color: #3d5f68;
            cursor: pointer;
            position: absolute;
            right: 0;

            .search {
              font-size: 24px;
              margin-top: 16px;
            }
          }
        }
      }
    }

    .ca-student-list-select-ctn {
      height: 30px;
      width: 100%;
      box-shadow: 0 2px 4px 0 $date-picker-box-shadow;

      .de-select,
      .select-all {
        padding: 4px;
        border-radius: 0;
      }
    }

    .student-list-container {
      max-height: calc(90vh - 130px);
      height: calc(90vh - 130px);
      overflow-y: auto;
      width: 100%;

      .user-profile-details {
        display: grid;
        padding-top: 10px;
        padding-left: 15px;
        border-bottom: 1px solid $breadcrumb-color;
        grid-template-columns: 50px 1fr;
        padding-bottom: 10px;
        align-items: center;

        .user-profile-img {
          width: 43px;
          position: relative;
          cursor: pointer;

          .selected-user-for-class-activity {
            width: 29px;
            background: $tan-hide;
            border-radius: 50%;
            line-height: 19px;
            box-shadow: 0 0 7px 0 $box-shadow-bg-color;
            color: $white;
            height: 29px;
            margin-left: 6px;
            position: absolute;

            .done_all {
              position: relative;
              top: 3px;
              left: 3px;
            }

            .de-select-user {
              position: relative;
              top: -29px;
              width: 29px;
              height: 29px;
              background: $box-shadow-bg-color;
              border-radius: 50%;
              line-height: 19px;
              visibility: hidden;
              opacity: 0;
              transition: opacity 0.3s, visibility 0.3s;

              .close {
                font-size: 16px;
                margin-right: 6px;
                margin-top: 3px;
              }
            }

            &:hover {
              .de-select-user {
                visibility: visible;
                opacity: 1;
                transition: opacity 0.3s, visibility 0.3s;
              }
            }
          }

          img {
            width: 29px;
            border-radius: 50%;
            margin-left: 7px;
            border-color: $table-bg;
            height: 29px;

            &.selected {
              visibility: hidden;
            }
          }
        }

        .username {
          width: 200px;
          @include ellipsis(
            $font-size: 13px,
            $line-height: 1.2,
            $lines-to-show: 1
          );
        }
      }
    }

    .action-buttons {
      height: 100px;
      width: 100%;
      text-align: center;

      button {
        margin: 5px;
        font-size: 14px;
      }
    }
  }
}
