.player.gru-suggest-test {
  box-shadow: 0 0 7px 1px $orange-400;
  padding-top: 288px;
  @media (min-width: $screen-sm) {
    padding-top: 170px;
  }
  @media (min-width: $screen-md) {
    padding-top: 155px;
  }
  width: 70%;
  margin: 0 auto;

  .panel {
    margin: 0 auto;
    box-shadow: none;
    border-radius: 4px;
    .panel-body {
      @include flexbox;
      @include flex-direction(column);

      .lead {
        color: $gray-base;
      }
      .description {
        font-size: $font-navigator;
      }
      .bottom-info {
        @include flexbox;
        @include justify-content(space-between);
        margin: 15px 0 0;
        .resource-info {
          @include flexbox;
          height: 50px;
          width: 220px;
          border: 2px solid $gray-lighter;
          border-radius: 6px;
          padding: 5px 10px;
          margin-top: 5px;
          background-color: $light-100;
          .info {
            @include flexbox;
            @include flex-direction(column);
            margin-left: 10px;
            line-height: 1.2;
            width: 150px;
            .title {
              display: inline-block;
              width: 100%;
              color: $text-color;
              font-size: 13px;
              font-weight: bold;
              margin: 4px 5px 2px 0;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
            .format {
              span {
                color: $text-color;
                font-size: 11px;
                margin: 0 5px 5px 0;
              }
            }
          }
          .image {
            @include flex-shrink(0);
            display: inline-block;
            vertical-align: top;
          }
          i {
            display: block;
            height: 36px;
            width: 28px;
            margin: 0 auto;
            vertical-align: top;
            margin: 5px 0 0;
            &.video-icon {
              background: url("gooru/images/video-resource-green.svg");
              -webkit-background-size: 31px 30px;
              background-size: 31px 30px;
              height: 30px;
              width: 31px;
            }
            &.webpage-icon {
              background: url("gooru/images/website-resource-green.svg");
              -webkit-background-size: 29px 30px;
              background-size: 29px 30px;
              height: 30px;
              width: 29px;
            }
            &.interactive-icon {
              background: url("gooru/images/interactive-resource-green.svg");
              -webkit-background-size: 34px 30px;
              background-size: 34px 30px;
              height: 30px;
              width: 34px;
              vertical-align: top;
              margin: 5px 0 0 0;
            }
            &.question-icon {
              background: url("gooru/images/question-resource-green.svg");
              -webkit-background-size: 30px 30px;
              background-size: 30px 30px;
              height: 30px;
              width: 30px;
            }
            &.image-icon {
              background: url("gooru/images/image-resource-green.svg");
              -webkit-background-size: 30px 30px;
              background-size: 30px 30px;
              height: 30px;
              width: 30px;
            }
            &.text-icon {
              background: url("gooru/images/text-resource-green.svg");
              -webkit-background-size: 31px 30px;
              background-size: 31px 30px;
              height: 30px;
              width: 31px;
            }
            &.audio-icon {
              background: url("gooru/images/audio-resource-green.svg");
              -webkit-background-size: 34px 30px;
              background-size: 34px 30px;
              height: 30px;
              width: 34px;
            }
          }
        }
        .assessment-info {
          @include flexbox;
          > * {
            margin: 0 10px 0;
            @include align-self(center);
          }
          .image {
            display: inline-block;
            margin: 0 5px;
            img {
              border-top-left-radius: 4px;
              border-bottom-left-radius: 4px;
              height: 74px;
              width: 74px;
            }
          }
          .collection {
            background-image: url("gooru/images/add_collection.svg");
          }
          .assessment {
            background-image: url("gooru/images/add_assessment.svg");
          }
          .descriptions {
            @include flexbox;
            @include flex-direction(column);
            @include align-self(baseline); // Until we have the resources and question count
            .title {
              font-size: $font-size-large;
              color: $gray-base;
            }
            .resource {
              font-size: $font-size-h6;
              padding-right: 5px;
            }
            .bars {
              border-left: 2px solid $gray-base;
              margin-right: 5px;
            }
          }
        }
        .actions {
          @include align-self(flex-end);
          margin: 20px 0 0 auto;
          .btn-no {
            border: 2px solid $light-300;
          }
          .btn-suggestion,
          .btn-backfill {
            height: 37px;
          }
        }
      }
    }
  }
}
