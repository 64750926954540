.student-weekly-search-container {
  width: 26%;
  padding-left: 10px;
  margin-left: 10px;
}
.tooltip-inner {
  color: $white !important;
  text-transform: initial !important;
  background-color: #4d5557;
  opacity: 100%;
}
.weekly-report.student-weekly-report {
  padding: 30px;
  width: 100%;
  height: 100%;
  padding-top: 15px;

  a {
    color: $font-dark-200-gray-dark-400;
    text-decoration: none;
  }
  .dropdown {
    .dropdown-toggle .material-icons {
      font-size: 24px;
    }
    .dropdown-menu {
      padding: 5px 15px;
    }
  }

  .weekly-report-container {
    background-color: $white;

    &.individual-student-report {
      .body-container {
        height: calc(100vh - 200px);
      }
    }

    .go-back-to-peformance {
      display: flex;
      align-items: center;
      padding: 5px;
      background-color: $font-gray-light-dark-400;
      border-radius: 50%;
      margin: 0 10px;
      color: $white;
    }

    .report-period-selector {
      position: relative;
      padding-right: 15px;
      grid-gap: 5px;
      align-items: center;
      width: 100%;
      display: flex;

      .showHelpIcon {
        align-self: auto;

        .material-icons {
          font-size: 17px;
          color: $font-default-gray-dark-400;
        }
      }

      @media screen and (max-width: $screen-568) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      @media screen and (max-width: 370px) {
        .custom-range-shower {
          display: none;
        }
      }

      .search-student-container {
        width: 100%;

        .search-input-container {
          display: flex;
          align-items: center;
          padding: 5px 10px;
          min-width: 400px;

          @media screen and (max-width: 850px) {
            min-width: auto;
          }

          input {
            width: 100%;
            box-shadow: none;
            border-bottom: 1px solid $gray-80;
            height: 40px;

            &::placeholder {
              font-style: italic;
            }
          }

          .search-icon {
            display: flex;
            align-items: center;
            position: relative;
            left: -18px;
            cursor: pointer;
          }
        }
      }

      .center-gap {
        width: 100%;
      }

      .custom-range-shower {
        padding: 0 10px;
      }

      .active-period {
        height: 50px;
        line-height: 50px;
        justify-content: flex-end;
        cursor: pointer;
        grid-gap: 3px;
        display: flex;
        width: 100%;
        white-space: nowrap;
        max-width: 300px;

        @media screen and (max-width: $screen-568) {
          justify-content: end;
        }

        .report-period-text {
          font-size: 14px;

          @media screen and (max-width: $screen-320) {
            font-size: 13px;
          }

          font-weight: bold;
          color: $bg-report-period;
        }

        .next-cal-arrow,
        .prev-cal-arrow {
          display: flex;
          align-items: center;

          &.disable {
            pointer-events: none;
            opacity: $opacity-light;
          }
        }

        .showHelpIcon {
          .material-icons {
            font-size: 17px;
            color: $font-default-gray-dark-400;
          }
        }

        .datepicker {
          display: grid;
          align-items: center;
          grid-template-columns: repeat(4, auto);
          column-gap: 5px;

          i {
            display: flex;
            font-size: 20px;
          }
        }
      }

      .date-range-picker-container {
        display: none;
        position: absolute;
        z-index: 15;
        background-color: $white;
        right: 15px;
        top: 50px;

        @media screen and (max-width: $screen-320) {
          right: 0px;
        }

        .date-range-selector {
          display: flex;
          justify-content: space-around;
          padding: 5px;
          background-color: $white;

          @media screen and (max-width: $screen-320) {
            margin-left: 10px;
          }

          span {
            &.active,
            &:hover {
              color: $gray-base;
              cursor: pointer;
            }

            &.disabled {
              pointer-events: none;
            }
          }
        }

        &.open {
          display: block;
        }

        .ca-month-picker {
          background-color: $white;

          .ca-monthpicker-container {
            #ca-monthpicker {
              .datepicker {
                .table-condensed {
                  tbody {
                    tr {
                      @media screen and (max-width: $screen-sm) {
                        td {
                          grid-template-columns: repeat(3, 1fr);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .ca-date-picker-container,
        .ca-month-picker,
        .ca-week-picker-container {
          box-shadow: 2px 2px 3px $date-picker-box-shadow,
            -2px 2px 3px $date-picker-box-shadow;

          .backdrop {
            opacity: 0.5;
          }
          .ca-date-nav-container,
          .ca-week-nav-container {
            height: 40px;
            z-index: 2;
            background-color: $white;

            .ca-date-picker-toggle {
              pointer-events: none;
              font-size: 20px;

              i {
                display: none;
              }
            }
          }

          .ca-datepicker-container,
          .ca-weekpicker-container {
            display: grid;
            padding-bottom: 0;

            .ca-datepicker-today {
              height: 40px;
              box-shadow: 0 2px 4px 0 $box-shadow-bg-color;
              background-color: $has-active-color;
            }

            #ca-datepicker,
            #ca-weekpicker {
              grid-row-start: 1;
              padding: 10px 20px 20px;

              //NOTE overwritting style for enabling all days to be clickable
              .datepicker .table-condensed tr td.no-activities {
                pointer-events: none;
              }

              &.weekly {
                .datepicker {
                  .table-condensed {
                    tbody {
                      .tooltip {
                        z-index: 9999;
                        visibility: visible;
                      }
                    }

                    tbody tr {
                      &.no-activities {
                        td {
                          pointer-events: none;
                        }
                      }

                      &.no-activities:hover {
                        pointer-events: all;
                      }

                      &.week-active,
                      &:hover {
                        border-radius: 50px;
                        background-color: $has-active-bg-color;

                        td {
                          color: $has-active-color;

                          &.active {
                            background: none;
                            width: auto;
                            height: auto;

                            &:before {
                              content: "";
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }

              .datepicker-inline {
                .datepicker-days {
                  .table-condensed {
                    thead {
                      tr:nth-child(2) {
                        display: grid;
                        width: 100%;
                        justify-content: center;
                        grid-template-columns: repeat(7, 1fr);

                        th.dow {
                          height: 40px;
                          width: 40px;
                          justify-self: center;
                        }
                      }
                    }

                    tbody {
                      tr {
                        display: grid;
                        width: 100%;
                        justify-content: center;
                        grid-template-columns: repeat(7, 1fr);

                        td {
                          height: 36px;
                          width: auto;
                          justify-self: center;
                          margin: auto;

                          &.has-activities:before {
                            position: relative;
                            left: 50%;
                            transform: translateX(-50%);
                          }

                          &.active {
                            background-color: $has-active-bg-color;
                            background-image: -webkit-linear-gradient(
                              top,
                              $has-active-bg-color,
                              $has-active-bg-color
                            );
                            border-radius: 50%;
                            display: flex;
                            margin: auto;
                            align-items: center;
                            justify-content: center;
                            color: $has-active-color;
                            width: 40px;
                            height: 40px;

                            &:before {
                              content: none;
                            }
                          }

                          &.today {
                            background-color: $has-active-color;
                            background-image: -webkit-linear-gradient(
                              top,
                              $has-active-color,
                              $has-active-color
                            );
                            border-radius: 50%;
                            display: flex;
                            margin: auto;
                            align-items: center;
                            justify-content: center;
                            color: $white;
                            width: 40px;
                            height: 40px;
                            pointer-events: auto;

                            &:before {
                              content: none;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .ca-daterange-picker {
          position: relative;

          .backdrop {
            opacity: 0.5;
            background-color: $gray-base;
          }
          .content-thumbnail {
            display: none;
          }
          .ca-datepicker-body {
            position: relative;
            transform: none;
            left: 0;
            top: 0;

            .date-info {
              display: none;
            }
          }
        }

        .all-time {
          .backdrop {
            position: fixed;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: -9;
            cursor: pointer;
            opacity: 0.5;
            background-color: $gray-base;
          }

          .date-range {
            height: 40px;
            z-index: 2;
            background-color: $white;
            width: 320px;
            display: grid;
            grid-template-columns: 1fr 20px 1fr;
            justify-self: center;
            position: relative;
            display: grid;
            align-items: center;
            justify-items: center;
            box-shadow: 0 2px 4px 0 $date-picker-box-shadow;
          }
        }
      }

      .report-download {
        @media screen and (max-width: $screen-568) {
          margin-left: 10px;
        }

        justify-content: center;
        display: flex;
        border-radius: 50px;
        cursor: pointer;
        color: $font-blue-50-black-500;

        .report-download-icon {
          display: flex;

          .class-progress-report-preview,
          .individual-student-report-preview {
            display: flex;

            .class-report-container,
            .individual-report-container {
              display: flex;
            }
          }

          .print-preview {
            display: flex;
            align-items: center;
          }
        }
      }

      .report-container {
        display: none;
      }
    }

    .header-container {
      display: grid;
      grid-template-columns: 300px 1fr;
      justify-content: left;
      border-bottom: 1px solid $gray-ultra-light;
      border-top: 1px solid $gray-ultra-light;

      .header-left {
        font-size: 13px;
        font-weight: bold;
        color: $gray-24b;
        text-transform: uppercase;
        padding-left: 10px;
        justify-self: left;
        align-self: center;
        display: flex;
        align-items: center;

        .go-back-to-peformance {
          display: none;
        }
      }

      .header-right {
        display: grid;
        grid-template-columns: repeat(11, 1fr);
        padding-right: 10px;

        .label-text {
          font-size: 14px;
          font-weight: bold;
          text-transform: uppercase;
          cursor: pointer;
        }

        .competencies-label {
          display: grid;
          grid-column-start: 1;
          grid-column-end: 6;
          border-right: 1px solid $gray-ultra-light;

          .label-text {
            justify-self: center;
            font-size: 14px;
            font-weight: bold;
            color: $dark-400;
            cursor: pointer;

            .showHelpIcon {
              @media only screen and (max-width: $screen-sm) {
                padding-left: 0px;
              }

              .material-icons {
                font-size: 17px;
                color: $font-default-gray-dark-400;
              }
            }
          }

          .sub-header-section {
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            justify-content: center;

            div {
              justify-self: center;
              font-size: 12px;
              color: $gray-24b;
              text-transform: uppercase;
              text-align: center;
              cursor: pointer;
            }
          }
        }

        .timespent-label {
          display: grid;
          grid-column-start: 6;
          grid-column-end: 9;
          border-right: 1px solid $gray-ultra-light;

          .label-text {
            justify-self: center;
            color: $gray-24b;

            .showHelpIcon {
              @media only screen and (max-width: $screen-sm) {
                padding-left: 0px;
              }

              .material-icons {
                font-size: 17px;
                color: $font-default-gray-dark-400;
              }
            }
          }

          .sub-header-section {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            justify-content: center;
            mar: 280px;

            div {
              justify-self: center;
              font-size: 12px;
              color: $gray-24b;
              text-transform: uppercase;
            }
          }
        }

        .average-score-label,
        .badge-earned-label,
        .diagnostic-label,
        .suggestion-taken-label {
          font-size: 13px;
          font-weight: bold;
          color: $gray-24b;
          align-self: center;
          text-align: center;

          .showHelpIcon {
            @media only screen and (max-width: $screen-sm) {
              padding-left: 0px;
            }

            .material-icons {
              font-size: 17px;
              color: $font-default-gray-dark-400;
            }
          }
        }
      }

      &.student-competencies-activate {
        grid-template-columns: 200px 1fr;
        padding: 10px 0;

        .header-left {
          padding: 0;

          .go-back-to-peformance {
            display: flex;
          }
        }

        .report-period-selector {
          justify-content: flex-end;

          .report-download {
            display: none;
          }
        }

        .header-right {
          grid-template-columns: 235px 1fr;
          display: none;

          .competencies-label {
            grid-column-end: 1;
            border-right: 0;

            .label-text {
              justify-self: center;
            }

            .sub-header-section {
              grid-template-columns: repeat(4, 1fr);
              justify-content: left;
              text-align: left;

              .gained-header,
              .inprogress-header {
                justify-self: left;
                padding-right: 10px;
              }
            }
          }

          .average-score-label,
          .badge-earned-label,
          .diagnostic-label,
          .suggestion-taken-label,
          .timespent-label {
            display: none !important;
          }
        }

        .student-info-container {
          display: none;
        }
      }

      .student-info-container {
        display: grid;
        grid-template-columns: 32px 1fr;
        justify-content: left;
        column-gap: 8px;
        align-items: center;

        .student-thumbnail {
          width: 32px;
          height: 32px;
          background-size: 32px;
          display: block;
          border-radius: 50%;
        }

        .student-name {
          font-size: 13px;
          color: $gray-base;
          text-transform: capitalize;
          display: grid;
          height: 40px;
          align-items: center;
        }
      }
    }

    .body-container {
      display: grid;
      grid-template-columns: 1fr;
      height: calc(100vh - 260px);

      &::-webkit-scrollbar {
        height: 6px;
        width: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background: $gray-light;
        border: 1px solid $gray-light;
        border-radius: 5px;
      }

      .body-scroll {
        display: grid;
        grid-template-columns: 300px 1fr;
        height: calc(100vh - 260px);
        overflow: hidden;
        overflow-y: auto;

        &::-webkit-scrollbar {
          height: 6px;
          width: 5px;
        }

        &::-webkit-scrollbar-thumb {
          background: $gray-light;
          border: 1px solid $gray-light;
          border-radius: 5px;
        }

        .body-left {
          .student-info-container {
            height: 45px;
            display: grid;
            grid-template-columns: 32px 1fr;
            justify-content: left;
            column-gap: 8px;
            align-items: center;
            padding-left: 10px;
            border-bottom: 1px solid $gray-ultra-light;
            cursor: pointer;
            pointer-events: none;
            margin: 0;

            &.data-available {
              pointer-events: unset;
            }

            &:last-child {
              border: none;
            }

            .student-thumbnail {
              width: 32px;
              height: 32px;
              background-size: 32px;
              display: block;
              border-radius: 50%;

              .active-student-data {
                display: none;
              }
            }

            .student-name {
              font-size: 13px;
              color: $gray-base;
              text-transform: capitalize;
              display: grid;
              height: 40px;
              align-items: center;
            }

            &.active-student {
              background-color: $gray-f93;

              .student-name {
                color: $white;
              }
            }
          }
        }

        .body-right {
          .disable-student-performance {
            height: 100%;
            justify-content: center;
            display: flex;
            font-size: 15px;
            border-left: 1px solid $gray-ultra-light;
            margin-top: 20px;
          }

          .student-summary-container {
            display: grid;
            grid-template-columns: auto;
            height: 45px;
            line-height: 45px;
            border-bottom: 1px solid $gray-ultra-light;

            @media screen and (min-width: 993px) and (max-width: 1200px) {
              width: 1035px;
            }

            .summary-container {
              display: grid;
              grid-template-columns: repeat(11, 1fr);
              cursor: pointer;
              pointer-events: none;

              @media screen and (max-width: $screen-568) {
                padding-right: 15px;
              }

              &.data-available {
                pointer-events: unset;
              }

              .gained-competencies,
              .inferred-competencies,
              .inprogress-competencies,
              .reinferred-competencies {
                display: grid;
                grid-template-columns: auto auto;
                align-items: center;
                justify-content: center;
                column-gap: 8px;

                .competency-status {
                  width: 8px;
                  height: 8px;
                  border-radius: 50%;
                  display: block;
                }

                .competency-count {
                  font-size: 13px;
                  color: $gray-base;
                }

                &.active-student {
                  background-color: $gray-f93;

                  .competency-count {
                    color: $white;
                    font-weight: bold;
                  }
                }
              }

              .assessment-timespent,
              .collection-timespent,
              .total-timespent {
                justify-self: center;

                .time-spent {
                  font-size: 13px;
                  color: $gray-base;
                }
              }

              .diagnostic {
                justify-self: center;
                color: $nobel-gray;
                text-align: center;
                font-size: 13px;
              }

              .badge-earned {
                justify-self: center;

                .badge {
                  background-color: $yellow-e34;
                  width: auto;
                  height: auto;
                  border-radius: 50%;
                  text-align: center;
                  line-height: 24px;
                  padding: 0;

                  .badge-count {
                    font-size: 13px;
                    color: $white;
                    padding: 8px;
                  }
                }
              }

              .average-score {
                justify-self: center;

                .score {
                  font-size: 16px;
                  font-weight: bold;

                  &.score-range-not-started {
                    color: $black-bold;
                  }

                  &.score-range-0-59 {
                    color: $red-400;
                  }

                  &.score-range-60-69 {
                    color: $orange-400;
                  }

                  &.score-range-70-79 {
                    color: $grade-range-70-79-bg;
                  }

                  &.score-range-80-89 {
                    color: $grade-range-80-89-bg;
                  }

                  &.score-range-90-100 {
                    color: $green-700;
                  }
                }
              }

              .suggestion-taken {
                justify-self: center;

                @media screen and (max-width: $screen-568) {
                  margin-right: 23px;
                }

                .suggestion-count {
                  font-size: 16px;
                  font-weight: bold;
                  color: $orange-e1b;
                }
              }
            }

            &:last-child {
              border: none;
            }
          }
        }
      }

      .student-competency-activity-container {
        padding: 5px 15px 15px;
        overflow-y: auto;
        background-color: $gray-f93;

        &::-webkit-scrollbar {
          height: 6px;
          width: 8px;
        }

        &::-webkit-scrollbar-thumb {
          background: $gray-light;
          border: 1px solid $gray-light;
          border-radius: 5px;
        }

        .student-competency-info {
          border-bottom: 2px solid $white;

          &:last-child {
            border-bottom: 0;
          }

          .report-date {
            padding: 10px;
            font-size: 13px;
            color: $white;
            font-weight: bold;
            cursor: pointer;
            display: grid;
            grid-template-columns: 35px 1fr 100px;
          }

          .competency-info {
            display: inline-flex;
            flex-wrap: wrap;

            .competency-card-panel {
              display: grid;
              width: 360px;
              margin: 8px;
              border-radius: 4px;
              background-color: $white;
              grid-template-rows: auto 1fr;
              box-shadow: 0 2px 4px 0 $navbar-default-toggle-icon-bar-bg;
              height: max-content;

              .code-info {
                display: grid;
                column-gap: 4px;
                align-items: center;
                padding: 8px 8px 0;
                height: max-content;
                padding-bottom: 10px;

                .display-code {
                  display: grid;
                  grid-column: 1;
                  font-size: 11px;
                  color: $dark-400;

                  .code {
                    font-weight: bold;
                    display: grid;
                    grid-template-columns: auto 1fr;
                    grid-gap: 10px;

                    .taxonomy-container {
                      .gru-taxonomy-tag-list {
                        .gru-taxonomy-tag {
                          display: none;
                        }

                        .non-visible-tags {
                          width: 30px;
                          height: 23px;
                          border-radius: 12px;
                          background-color: $light-400;
                          padding: 0;
                          display: flex;
                          align-items: center;
                          justify-content: center;

                          span {
                            font-weight: bold;
                            font-size: 11px;
                          }
                        }

                        .all-tags {
                          .gru-taxonomy-tag {
                            display: block;
                          }
                        }
                      }
                    }
                  }

                  .title {
                    font-size: 12px;
                    color: $dark-400;
                  }
                }
              }

              .competency-desc {
                padding: 0 8px 8px;
                color: $dark-400;
                font-size: 12px;
              }

              .activity-row {
                display: grid;
                height: 48px;
                border-top: 1px solid $gray-ultra-light;
                grid-template-columns: 48px 1fr 48px 48px 48px;
                cursor: pointer;

                .type {
                  background-repeat: no-repeat;
                  width: 20px;
                  height: 20px;
                  background-size: 20px 20px;
                  display: block;
                  margin: auto;

                  &.assessment,
                  .assessment-external {
                    background-image: url("gooru/images/add_assessment.svg");
                  }

                  &.collection,
                  .collection-external {
                    background-image: url("gooru/images/add_collection.svg");
                  }

                  &.offline-activity {
                    background-image: url("gooru/images/offline.svg");
                  }
                }

                .title {
                  display: grid;
                  align-items: center;

                  .title-text {
                    font-size: 12px;
                    color: $dark-400;
                  }
                }

                .assessment-external-icons,
                .collection-external-icons {
                  width: 20px;
                  height: 20px;
                  background-size: 20px 20px;
                  opacity: 0.8;
                  justify-self: center;
                  background-image: url("gooru/share.png");
                  background-repeat: no-repeat;
                  margin: auto 10px auto auto;
                }

                .timespent {
                  background-color: $gray-submarine;
                  text-align: center;
                  cursor: pointer;
                  color: $dark-400;
                  line-height: 1;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 12px;
                  font-weight: bold;

                  &.no-performance {
                    pointer-events: none;
                  }
                }

                .percentage {
                  padding-top: 15px;
                  text-align: center;
                  cursor: pointer;
                  color: $white;
                  font-size: 13px;
                  font-weight: bold;

                  &.grade-range-not-started,
                  &.score-range-not-started {
                    color: $dark-400;
                    pointer-events: none;
                  }
                }
              }

              .activity-content {
                border-left: 10px solid $breadcrumb-color;
                display: none;

                .resource-info {
                  display: grid;
                  height: 48px;
                  border-top: 1px solid $gray-ultra-light;
                  grid-template-columns: 48px 1fr 50px;
                  align-items: center;

                  .resource-icon {
                    background-repeat: no-repeat;
                    width: 20px;
                    height: 20px;
                    background-size: 20px 20px;
                    display: block;
                    margin: auto;

                    &.question {
                      background: url("gooru/images/question-resource.svg");
                      background-repeat: no-repeat;
                      background-size: 20px 20px;
                      width: 20px;
                      height: 20px;
                    }

                    &.video_resource {
                      background-image: url("gooru/images/video-resource.svg");
                      background-repeat: no-repeat;
                      background-size: 20px 20px;
                      width: 20px;
                      height: 20px;
                    }

                    &.webpage_resource {
                      background-image: url("gooru/images/website-resource.svg");
                      background-repeat: no-repeat;
                      background-size: 20px 20px;
                      width: 20px;
                      height: 20px;
                    }

                    &.interactive_resource {
                      background-image: url("gooru/images/interactive-resource.svg");
                      background-repeat: no-repeat;
                      background-size: 20px 20px;
                      width: 20px;
                      height: 20px;
                    }

                    &.image_resource {
                      background-image: url("gooru/images/image-resource.svg");
                      background-repeat: no-repeat;
                      background-size: 20px 20px;
                      width: 20px;
                      height: 20px;
                    }

                    &.text_resource {
                      background-image: url("gooru/images/text-resource.svg");
                      background-repeat: no-repeat;
                      background-size: 20px 20px;
                      width: 20px;
                      height: 20px;
                    }

                    &.audio_resource {
                      background: url("gooru/images/audio-resource.svg");
                      background-repeat: no-repeat;
                      background-size: 20px 20px;
                      width: 20px;
                      height: 20px;
                    }
                  }

                  .timespent {
                    justify-content: center;
                    display: flex;
                    font-size: 12px;
                  }

                  .title {
                    display: grid;
                    align-items: center;

                    .title-text {
                      font-size: 12px;
                      color: $dark-400;
                    }
                  }
                }
              }
            }
          }
        }

        .column-one,
        .column-two {
          display: flex;
          flex-flow: row wrap;
          width: 50%;
          display: none;
        }

        .competencies-panel-header {
          cursor: pointer;
          color: $white-bold;
          padding: 20px 10px 10px;
          font-size: 13px;
          font-weight: bold;
          display: grid;
          grid-template-columns: 35px 1fr;
        }

        .competency-list {
          color: $white-bold;
          padding: 10px 45px;
          font-size: 13px;
          font-weight: bold;

          .header {
            display: grid;
            grid-template-columns: 10% 90%;

            @media only screen and (max-width: $screen-sm) {
              grid-template-columns: 100px 1fr;
            }

            .students-summary-report-data,
            .sub-header-section {
              display: grid;
              grid-gap: 10px;
              grid-template-rows: max-content;
              min-width: 100px;

              .summary-list {
                .gru-taxonomy-tag-list {
                  @media screen and (max-width: $screen-568) {
                    display: flex;
                  }

                  .gru-taxonomy-tag {
                    margin-right: 5px;
                  }
                }
              }
            }
          }
        }
      }

      &.student-competencies-activate {
        grid-template-columns: 400px 1fr;
        overflow: hidden;

        .body-scroll {
          grid-template-columns: 200px 1fr;
          height: calc(100vh - 200px);

          .body-right {
            .student-summary-container {
              grid-template-columns: 1fr;

              .summary-container {
                grid-template-columns: repeat(4, 1fr);
                color: $white;
                font-weight: bold;
                display: none;

                .gained-competencies,
                .inferred-competencies,
                .inprogress-competencies,
                .reinferred-competencies {
                  justify-content: center;
                }
              }

              .active-student-data {
                justify-self: center;
                text-align: right;
                height: 45px;
                background-color: $gray-f93;
                width: 100%;
                cursor: pointer;
                color: $white;
                padding: 0 10px;

                i.clear {
                  line-height: 45px;
                }
              }
            }

            .assessment-timespent,
            .average-score,
            .badge-earned,
            .collection-timespent,
            .diagnostic,
            .suggestion-taken,
            .total-timespent {
              display: none;
            }
          }
        }
      }
    }

    .warning-message {
      display: flex;
      height: calc(100vh - 260px);
      padding: 10px;
      justify-content: center;
      font-size: 20px;
      line-height: 1.5;
      color: $orange-800;
      column-gap: 5px;
      align-items: center;
    }
  }

  @media screen and (max-width: $screen-1024) {
    .weekly-report-container {
      .body-container {
        .body-scroll {
          grid-template-columns: 200px 1fr;
        }
      }

      .header-container {
        grid-template-columns: 200px 1fr;
      }
    }
  }

  @media screen and (max-width: $screen-lg) {
    .weekly-report-container {
      .body-container {
        .body-scroll {
          grid-template-columns: 200px 1fr;
        }
      }

      .header-container {
        grid-template-columns: 200px 1fr;
      }
    }
  }

  @media screen and (max-width: $screen-lg) {
    padding: 0 8px;

    .weekly-report-container {
      overflow: hidden;

      .header-container {
        grid-template-columns: 120px 1fr;

        .header-left {
          font-size: 12px;
        }

        .header-right {
          width: calc(100vw - 150px);
          overflow-x: hidden;

          .competencies-label,
          .timespent-label {
            grid-column: auto;

            .sub-header-section .header-data {
              font-size: 10px;
            }
          }

          .competencies-label {
            padding-right: 5px;
          }

          .header-data {
            min-width: 80px;
            font-size: 11px;
            padding: inherit;
            margin-right: 15px;

            &.total-header {
              text-align: center;
            }
          }

          .label-text {
            font-size: 12px;

            &.badge-earned-label {
              @media only screen and (max-width: $screen-sm) {
                margin-left: 10px;
              }

              min-width: auto;
            }
          }
        }

        &.student-competencies-activate {
          grid-template-columns: 200px 1fr;

          @media only screen and (max-width: $screen-sm) {
            grid-template-columns: 1fr;
          }

          .header-right {
            .competencies-label {
              display: none;
            }
          }
        }
      }

      .body-container {
        .body-scroll {
          grid-template-columns: 120px 1fr;
          width: 100vw;

          .body-left {
            .student-info-container {
              &.active-student {
                .student-thumbnail {
                  position: relative;
                  background-image: unset !important;

                  .active-student-data {
                    display: block;
                    position: absolute;
                    width: 32px;
                    height: 32px;
                    text-align: center;

                    i {
                      line-height: 32px;
                    }
                  }
                }
              }
            }
          }

          .body-right {
            width: calc(100vw - 150px);
            overflow-x: auto;

            @media only screen and (min-width: $screen-sm) and (max-width: $screen-sm) {
              width: calc(100vh - 150px);
            }

            .student-summary-container {
              .column-data {
                min-width: 80px;
                text-align: center;
              }
            }
          }
        }

        &.student-competencies-activate {
          grid-template-columns: 200px 1fr;

          .body-scroll {
            grid-template-columns: 200px 1fr;
            height: calc(100vh - 200px);

            .body-right {
              display: none;
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: $screen-lg) {
    padding: 0 8px;
  }

  @media screen and (max-width: $screen-md) {
    padding: 0 8px;

    .weekly-report-container {
      overflow: hidden;

      .header-container {
        grid-template-columns: 120px 1fr;

        .header-left {
          font-size: 12px;
        }

        .header-right {
          width: calc(100vw - 150px);
          grid-template-columns: repeat(5, 1fr);
          overflow-x: hidden;

          .competencies-label,
          .timespent-label {
            grid-column: auto;

            .sub-header-section .header-data {
              font-size: 10px;
            }
          }

          .competencies-label {
            padding-right: 5px;
          }

          .header-data {
            min-width: 73px;
            font-size: 11px;
            padding: inherit;
            margin-right: 3px;

            &.total-header {
              text-align: center;
            }
          }

          .label-text {
            font-size: 12px;

            &.badge-earned-label {
              @media only screen and (max-width: $screen-sm) {
                margin-left: 10px;
              }

              min-width: auto;
            }
          }
        }

        &.student-competencies-activate {
          grid-template-columns: 200px 1fr;

          @media only screen and (max-width: $screen-sm) {
            grid-template-columns: 1fr;
          }

          .header-right {
            .competencies-label {
              display: none;
            }
          }
        }
      }

      .body-container {
        .body-scroll {
          grid-template-columns: 120px 1fr;
          width: 100vw;

          .body-left {
            .student-info-container {
              .student-name {
                display: grid;
                line-height: 15px;
              }
            }
          }

          .body-right {
            width: calc(100vw - 150px);
            overflow-x: auto;

            @media only screen and (min-width: $screen-sm) and (max-width: $screen-sm) {
              width: calc(100vh - 150px);
            }

            .student-summary-container {
              .column-data {
                min-width: 80px;
                text-align: center;
              }
            }
          }
        }

        &.student-competencies-activate {
          grid-template-columns: 200px 1fr;

          .body-scroll {
            width: 100%;
            grid-template-columns: 200px 1fr;

            .body-right {
              display: none;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: $screen-xs) {
    .weekly-report-container {
      .body-container {
        &.student-competencies-activate {
          grid-template-columns: 150px 1fr;

          .body-scroll {
            grid-template-columns: 150px 1fr;
          }
        }

        .body-left {
          .student-info-container {
            &.active-student {
              .student-thumbnail {
                position: relative;
                background-image: unset !important;

                .active-student-data {
                  display: block;
                  position: absolute;
                  width: 32px;
                  height: 32px;
                  text-align: center;

                  i {
                    line-height: 32px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
