.gru-featured-courses {
  @include flexbox;
  @include align-items(center);
  @include flex-direction(column);
  margin: 0 auto;

  .course-list {
    overflow-y: scroll;
  }

  .navbar {
    box-shadow: 0 2px 4px 0 $box-shadow-bg-color;
    margin: 0;
    min-height: 50px;
    width: 100%;
    z-index: 2;

    &.affix {
      background-color: $dark-400;
      left: 0;
      top: 55px;
      @media (min-width: $screen-md-min) {
        top: 63px;
      }
    }
  }

  .subject-filters {
    @include flexbox;
    float: none;
    font-size: 12px;
    margin: 0;
    min-height: 50px;
    width: 95%;
    @media (min-width: $screen-sm-min) {
      font-size: 13px;
      margin: 0 auto;
      width: 75%;
    }

    .subject-menu-option {
      &.selected {
        a {
          background: none;
        }
      }

      a {
        background: none;
        color: $green-300;

        &:hover {
          color: $green-300;
        }
      }
    }
  }

  .subject-menu-option {
    @media (min-width: $screen-sm-min) {
      margin-right: 40px;
    }

    &.selected {
      a {
        background: none;
        border-bottom: 2px solid $green-300;
        color: $green-300;
        font-size: $font-size-h6;
        height: 100%;
      }
    }

    a {
      background: $table-bg;
      line-height: 1.3;
      padding: 7px 10px;
    }

    .category {
      display: inline-block;
      min-width: 30px;
    }
  }

  .affix {
    .subject-menu-option {
      &.selected {
        a {
          border-bottom-color: $white;
          color: $white;
        }
      }

      a {
        color: $white;

        &:hover {
          color: $white;
        }
      }
    }
  }

  .content-container {
    width: 95%;
    @media (min-width: $screen-sm-min) {
      width: 75%;
    }
  }

  .subject-container {
    display: inline-block;
    margin-bottom: 20px;
    width: 100%;

    &:nth-of-type(1) {
      padding-top: 50px;
    }

    .subject-title {
      border-bottom: 1px solid $light-200;
      color: $gray-base;
      font-size: $font-size-h5;
      padding: 0;
    }

    .course-container {
      @include flexbox;
      @include flex-wrap(wrap);
      @include justify-content(center);

      @media (min-width: $screen-md-min) {
        @include justify-content(space-between);
      }

      > * {
        margin-bottom: 10px;
        margin-right: 10px;
      }
    }
  }
}
