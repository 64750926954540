.student-data-by-standard {
  width: 90%;
  margin: 0 auto;
  max-width: 1300px;

  a {
    color: $dark-200;
    text-decoration: none;
  }
  .student-data-by-standard-container {
    display: grid;
    grid-template-columns: 30% 1fr;
    margin-top: 20px;
    grid-column-gap: 10px;

    @media screen and (max-width: 800px) {
      grid-template-columns: 1fr 1fr;

      .student-list-container {
        min-width: 300px;
      }
    }

    @media print {
      grid-template-columns: 1fr;
      .student-data-by-standard-panel {
        display: none;
      }
    }

    .student-data-by-standard-panel {
      background-color: $white;
      border-radius: 5px;
      .domain-data-panel-header {
        box-shadow: 0 2px 3px $light-200;
        .class-framework-details-card {
          display: grid;
          grid-template-columns: 40px 1fr 40px;
          align-items: center;
          padding: 10px;
          grid-column-gap: 10px;

          .fwk-code {
            font-weight: bold;
            text-shadow: 0 0 4px $light-200;
            text-align: center;
            font-size: 12px;
          }
          .category-grade {
            span {
              display: block;
              line-height: 1.4;
              &.category-title {
                font-weight: bold;
                font-size: 12px;
              }
            }
          }
          .edit-btn {
            display: grid;
            align-items: center;
            justify-content: center;
            .dropdown {
              #domain-dropdown {
                font-size: 30px;
                padding: 5px;
                cursor: pointer;
                border: none;
                background: none;
              }
              .dropdown-menu {
                padding: 10px;
                max-height: 300px;
                overflow-y: auto;

                .dropdown-item {
                  display: grid;
                  grid-template-columns: 25px 1fr;
                  align-items: center;
                  cursor: pointer;
                  padding: 5px;
                  width: 300px;
                  input {
                    pointer-events: none;
                    margin: 0;
                  }

                  span {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                  }
                }
              }
            }
          }
        }
      }
      .domain-data-panel-body {
        .search-domains {
          padding: 5px;
          display: flex;
          align-items: center;
          border-bottom: 2px solid $light-200;
          margin: 0 20px;
          input {
            border: none;
            width: 100%;
            box-shadow: none;

            &::placeholder {
              font-style: italic;
              color: $font-light-400-dark-500;
            }
          }
          i {
            font-size: 20px;
            color: $font-light-400-dark-500;
          }
        }
        .domain-accordion-panel {
          padding: 10px 0;
          .header-panel {
            display: grid;
            grid-template-columns: 40px 1fr 40px;
            align-items: center;
            padding: 5px;

            .card-title {
              span {
                display: block;
                line-height: 1.4;

                &.count {
                  font-weight: bold;
                  font-size: 12px;
                }
                &.title {
                  @include ellipsis(
                    $font-size: 14px,
                    $line-height: 1.4,
                    $lines-to-show: 1
                  );
                }
              }
            }

            .icon-tag {
              display: grid;
              align-items: center;
              justify-content: center;

              &.count-icon {
                border-radius: 50%;
                color: $white;
                width: 30px;
                height: 30px;
                margin: 0 auto;
                font-weight: bold;
                font-size: 12px;
                position: relative;

                &::before {
                  content: "";
                  position: absolute;
                  width: 30px;
                  height: 30px;
                  border-radius: 50%;
                  border: 2px solid $white;
                  left: 0;
                  top: 0;
                  transform: scale(0.9);
                }

                &.domain-count {
                  background-color: $dark-200;
                }
                &.topic-count {
                  background-color: $font-light-400-dark-500;
                }
                &.comp-count {
                  background-color: $blue-400;
                }
              }
            }
          }
          .domain-panel-card {
            .domain-panel-card-body {
              padding-left: 10px;
              .topic-panel-card-body {
                padding-left: 10px;
                .competency-card-panel {
                  cursor: pointer;
                  .comp-close-icon {
                    visibility: hidden;
                  }
                  &.active {
                    box-shadow: 0 0 3px $light-200;

                    .comp-close-icon {
                      visibility: visible;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .competency-details-views-panel {
      background-color: $white;
      border-radius: 5px;
      height: fit-content;
      overflow-x: auto;
      .competency-description-header {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        padding: 10px 5px;

        .competency-content-card {
          display: grid;
          grid-template-columns: 40px 1fr 40px;
          align-items: center;

          .comp-count {
            text-align: center;
            width: 30px;
            height: 30px;
            font-weight: bold;
            background-color: $blue-400;
            color: $white;
            display: grid;
            align-items: center;
            border-radius: 50%;
            font-size: 14px;
            margin: 0 auto;
            position: relative;

            &::before {
              content: "";
              position: absolute;
              width: 30px;
              height: 30px;
              border-radius: 50%;
              border: 2px solid $white;
              left: 0;
              top: 0;
              transform: scale(0.9);
            }
          }

          .description {
            span {
              font-weight: bold;
              font-size: 12px;
              display: grid;
              grid-template-columns: auto 1fr;
              align-items: center;
              grid-column-gap: 5px;

              .competency-name {
                @include ellipsis(
                  $font-size: 12px,
                  $line-height: 1.4,
                  $lines-to-show: 1
                );
              }
            }
            p {
              line-height: 1.4;
            }
          }
          .action-buttons {
            margin-bottom: auto;

            span {
              display: block;
              text-align: center;
              align-items: baseline;
              margin-bottom: 20px;
              &.print-icon {
                cursor: pointer;
              }
              &.suggestion-icon {
                position: relative;
                i {
                  color: $orange-500;
                }
                .sugg-count {
                  position: absolute;
                  width: 20px;
                  height: 20px;
                  border-radius: 50%;
                  border: 1px solid $font-orange-49-dark-400;
                  color: $font-orange-49-dark-400;
                  font-size: 12px;
                  background-color: $white;
                  top: -5px;
                  right: 4px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
              }
            }
          }
        }
        .performance-chart-container {
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
        }
      }
      .student-list-container {
        .student-list-panel {
          .student-list-header {
            display: grid;
            grid-template-columns: 1fr 1fr;
            align-items: center;
            font-weight: bold;
            box-shadow: 0 0 10px $light-200;
            padding: 10px;
            span {
              &.student-head {
                margin-left: 40px;
              }
              &:last-child {
                text-align: right;
              }
            }
          }
          .student-list-body {
            .student-detail-card {
              display: grid;
              grid-template-columns: 50px 1fr 50px 60px;
              align-items: center;
              box-shadow: 0 3px 4px $light-200;
              height: 50px;
              .profile-icons {
                width: 30px;
                height: 30px;
                margin: 0 auto;
                display: flex;
                align-items: center;
                img {
                  width: 100%;
                  height: 100%;
                  border-radius: 50%;
                }
              }
              .suggestion-icon {
                position: relative;
                i {
                  color: $orange-500;
                }
                .sugg-count {
                  position: absolute;
                  width: 20px;
                  height: 20px;
                  border-radius: 50%;
                  border: 1px solid $font-orange-49-dark-400;
                  color: $font-orange-49-dark-400;
                  font-size: 12px;
                  background-color: $white;
                  top: -5px;
                  right: 10px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
              }
              .performance {
                font-weight: bold;
                text-align: center;
                width: 100%;
                height: 100%;
                display: grid;
                align-items: center;
              }
            }
          }
        }
      }
    }
  }
}
