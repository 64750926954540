.taxonomy.gru-preview-taxonomy-tag-list,
.taxonomy.gru-taxonomy-tag-list {
  .non-visible-tags-container {
    position: relative;
    display: inline-flex;
    .non-visible-tags {
      display: inline-block;
      padding: 5px 0;
      cursor: pointer;
      background-color: $table-bg;
      border: 0 none;

      &:active {
        outline: 0 none;
      }
    }
    &.tag-container {
      color: $white;
      background-color: $dark-200;
      border-radius: 4px;
      height: 22px;
      align-items: center;
      padding: 0 5px;
      margin-left: 5px;
      font-size: smaller;
    }

    &.show-description {
      max-height: 230px;
      overflow-y: auto;

      .gru-taxonomy-tag {
        max-width: 100%;
      }
      .description-tag {
        margin: 5px 0;

        .panel-body {
          padding: 5px 10px;
          min-height: inherit;
          height: auto;
        }
      }
    }

    .all-tags {
      display: none;
      position: absolute;
      top: 30px;
      width: fit-content;
      max-width: 100px;
      background-color: $light-200;
      padding: 10px;
      border-radius: 2px;
      z-index: 100;

      .close {
        display: block;
        width: 100%;
        text-align: right;
        position: relative;
        margin-bottom: 10px;
        height: 10px;

        i {
          position: absolute;
          width: fit-content;
          top: -5px;
          right: 0;
          font-size: 17px;
          cursor: pointer;
          left: auto;
          padding: 2px;
        }
      }
    }
  }
}
