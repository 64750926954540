.teacher-suggestion-pull-up {
  position: fixed;
  top: 100%;
  z-index: 1031;
  width: 768px;
  left: 0;
  right: 0;
  margin: auto;
  color: $suggestion-panel-color;

  .ca-datepicker-schedule-container {
    position: absolute;
    z-index: 1000;
    display: none;
  }

  .loading-spinner {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background: $loading-spinner-bg-color;
  }

  .backdrop {
    background-color: $backdrop-color;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    cursor: pointer;
    opacity: 0.8;
    background-color: $gray-base;
  }

  .backdrop-pull-ups {
    .backdrop {
      background: none;
    }
  }

  .suggestion-container {
    background: $white-bold;
    width: 768px;
    max-height: 90vh;
    height: 90vh;

    .competency-report-header {
      display: grid;
      grid-template-columns: 30px 1fr;
      align-items: center;
      border-bottom: 1px solid $gray-80;
      box-shadow: 0 0 5px $gray-80;
      padding: 10px;
      cursor: pointer;

      .competency-report-header-panel {
        .competency-report-title {
          font-size: 15px;
          font-weight: bold;
          display: grid;
          grid-template-columns: 1fr;

          span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .competency-report-sub-titles {
          display: inline-flex;
          list-style: none;
          margin: 0;
          line-height: 1;
          padding: 0;
        }

        .header-title {
          display: grid;
          grid-template-columns: 50px 1fr;
          height: 100%;

          .icon {
            width: 20px;
            height: 20px;
            background-size: 20px 20px;
            opacity: 0.8;
            justify-self: center;
            align-self: center;
            background-repeat: no-repeat;

            &.assessment-external-icon,
            &.collection-external-icon {
              background-image: url("gooru/share.png");
            }

            &.assessment {
              background-image: url("gooru/images/add_assessment.svg");
            }

            &.assessment-external {
              background-image: url("gooru/images/add_assessment.svg");
            }

            &.collection-external {
              background-image: url("gooru/images/add_collection.svg");
            }

            &.collection {
              background-image: url("gooru/images/add_collection.svg");
            }
          }

          .title {
            align-items: center;
            display: flex;
          }
        }
      }
    }

    .suggestion-container-student-list-panel {
      height: calc(90vh - 45px);
      overflow-y: auto;

      .suggestion-report-student-list-panel {
        display: grid;
        grid-template-columns: 1fr 40px 40px;
        align-items: center;
        cursor: pointer;

        .sc-report-student-list {
          .sc-report-student-profile {
            list-style: none;
            display: inline-flex;
            align-items: center;
            padding: 0;
            margin: 0;

            li {
              margin: 10px;
              border: $gray-80;
              display: grid;
              align-items: center;
              text-align: center;

              img {
                width: 40px;
                height: 40px;
                border-radius: 50%;
              }
            }
          }
        }

        .sc-report-student-count {
          font-size: 16px;
          text-align: center;
        }

        .sc-report-student-list-icon {
          display: grid;
          align-items: center;
          justify-content: center;

          i {
            font-size: 30px;
          }
        }
      }

      .sc-student-dropdown-list-container {
        display: none;

        &.active {
          display: block;
        }

        .sc-student-dropdown-list {
          display: grid;
          grid-template-columns: 70px 1fr 70px;
          align-items: center;
          padding: 5px 20px;
          font-weight: bold;
          font-size: 15px;

          .sc-student-profile-thumbnail-container {
            .sc-student-thumbnail {
              width: 40px;
              height: 40px;
              background-color: $gray-80;
              border-radius: 50%;

              img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
              }
            }
          }
        }
      }

      .suggestion-header {
        height: 50px;
        background: $tan-hide;
        display: inline-flex;
        width: 100%;

        .suggestion-pullup-close {
          width: 70px;

          .arrow_downward {
            font-size: 26px;
            color: $white;
            margin-top: 13px;
            margin-left: 22px;
            cursor: pointer;
          }
        }

        .suggestion-back {
          display: flex;
          justify-content: center;
          align-items: center;

          .arrow_back {
            font-size: 26px;
            color: $white;
            cursor: pointer;
          }
        }

        .search-filter-container {
          width: auto;
          padding: 0 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 15px;
          color: #3c4452;
          font-weight: bold;
          position: relative;

          span {
            height: 24px;
            color: $white;
          }

          .arrow_drop_down {
            padding-top: 3px;
            padding-left: 2px;
            cursor: pointer;
          }
        }

        .search-filter-container-list {
          position: absolute;
          background: $white;
          top: 0px;
          z-index: 100;
          box-shadow: 0 2px 7px 2px $disabled-bg-color;
          visibility: hidden;
          opacity: 0;
          transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s,
            z-index 0s linear 0.01s;

          p {
            padding: 0;
            margin: 0;
            font-weight: normal;
            font-size: 15px;
            padding: 5px 20px;

            &.selected {
              background-color: $dark-50;
              font-weight: bold;
            }

            &:hover {
              background-color: $dark-50;
              cursor: pointer;
            }
          }

          &.active {
            visibility: visible;
            opacity: 1;
            transition-delay: 0s, 0s, 0.3s;
          }
        }

        .suggestion-explore-icon {
          width: 42px;
          height: 42px;
          border-radius: 50%;
          background: $suggestion-explore-bg;
          margin-top: 4px;
          box-shadow: 0 0 10px 2px $explore-box-shadow;

          .explore {
            font-size: 50px;
            color: $white;
            margin-left: -4px;
            margin-top: -4px;
            transform: rotate(90deg);
          }
        }

        .suggestion-filter-container {
          width: 150px;
          margin-left: 15px;
          display: flex;
          align-items: center;
          font-size: 15px;
          color: $white;
          font-weight: bold;
        }

        .suggestion-right-menu {
          width: 100%;
          display: flex;
          padding: 0 10px 0 0;

          .search-input-container {
            grid-template-columns: auto 190px 40px;
            display: grid;
            position: relative;
            border: none;
            background: $white;
            outline: none;
            border-radius: 5px;
            width: 100%;
            margin: 2px 0;

            .filter-items {
              display: flex;
              align-items: center;
              margin-right: 10px;
              justify-content: flex-end;

              .more-pointer {
                color: $gray-light;
                cursor: pointer;
              }

              .popover-content {
                .filter-item {
                  margin: 5px;
                  width: 80px;
                }
              }

              .filter-item {
                border-radius: 9px;
                justify-content: space-around;
                display: flex;
                height: 20px;
                width: 80px;
                padding: 10px;
                margin-right: 5px;
                align-items: center;
                background-color: #898b8e;

                .name {
                  font-size: 12px;
                  font-weight: bold;
                  line-height: 1.25;
                  text-align: right;
                  color: $white-bold;
                }

                .clear {
                  height: auto;
                  margin-left: 5px;

                  i {
                    height: 100%;
                    font-size: 12px;
                    color: $white;
                    opacity: 1;
                  }
                }
              }
            }

            #suggestion-search {
              outline: none;
              border-radius: 5px;
              width: 100%;
              text-indent: 10px;
              margin-top: 4px;
              margin-bottom: 3px;
              cursor: text;
              box-shadow: none;
            }

            .search-icon {
              width: 38px;
              height: 50px;
              color: #fa9053;
              cursor: pointer;
              position: absolute;
              right: 0;

              .search {
                font-size: 24px;
                margin-top: 10px;
              }
            }
          }

          .search-filter-icon {
            color: $white;
            width: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }
        }
      }

      .suggestion-content-type-filter {
        height: 50px;
        background-color: $white-bold;
        box-shadow: 0 2px 4px 0 $disabled-bg-color;
        position: relative;
        width: 100%;

        .search-by-filter {
          z-index: 11;
        }

        .filter-content-types {
          display: flex;
          align-items: center;

          .filter-item {
            width: 100px;
            display: grid;
            justify-items: center;
            text-align: center;
            margin-top: 11px;
            margin-right: 15px;
            cursor: pointer;
            color: $breadcrumb-color;
            border-bottom-width: 2px;
            border-color: $white;
            border-bottom-style: solid;
            transition: border-width 0.1s ease-in-out;

            i {
              width: 20px;
              height: 15px;
              background-repeat: no-repeat;
            }

            span {
              font-size: 12px;
              font-weight: bold;
              display: block;
              width: 100px;
            }

            &.collection {
              i {
                background-image: url("../assets/gooru/images/navigation_collection.svg");
              }
            }

            &.assessment {
              i {
                background-image: url("../assets/gooru/images/navigation_assessment.svg");
              }
            }

            &.active,
            &:hover {
              border-bottom: 2px solid #fa9053;
              color: #fa9053;

              &.collection {
                i {
                  background-image: url("/assets/gooru/images/collection-orange.svg");
                }
              }

              &.assessment {
                i {
                  background-image: url("/assets/gooru/images/assessment-orange.svg");
                }
              }
            }
          }
        }
      }

      .selected-tenant-library {
        display: grid;
        grid-template-columns: 42px max-content 1fr;
        align-items: center;
        justify-content: center;
        box-shadow: 0 2px 4px 0 $disabled-bg-color;
        margin-bottom: 1px;
        height: 45px;

        .back {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }

        .library-name {
          font-weight: bold;
          font-size: 14px;
          padding-left: 5px;
        }

        img {
          width: 35px;
          height: 35px;
          border-radius: 50px;
        }
      }

      .search-list-container {
        width: 100%;
        padding-top: 30px;
        padding-bottom: 30px;

        .search-list {
          display: grid;
          grid-template-columns: repeat(3, 220px);
          grid-gap: 20px;
          margin: 0 auto;
          width: 700px;

          .cards.no-suggest-result-found {
            grid-column-start: 2;
          }
        }
      }

      .tenant-libraries {
        display: flex;
        width: 100%;
        overflow-y: auto;
        margin: auto;

        .libraries-container {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          margin: auto;
          grid-gap: 10px;

          .loading-spinner {
            left: 0;
          }
        }
      }
    }
  }
}
