.qz-resource-viewer {
  @include flexbox;
  @include flex-direction(row);
  @include justify-content(space-between);
  width: 100%;
  height: 100%;

  .linkout {
    height: 100vh;
    background-image: url("gooru/images/Pattern.svg");
  }

  .qz-resource-viewer-panel {
    width: 100%;
    .h5p-content {
      width: 100%;
      height: 100%;
    }
  }

  .btn-next {
    width: 85px;
    height: 35px;
    margin-left: 10px;
    margin-top: 20px;
    @media (max-width: $screen-xs-max) {
      width: 60px;
    }
  }

  .narration {
    .panel-body {
      padding: 10px 15px;
    }

    span {
      display: table-cell;
      vertical-align: middle;
    }
  }

  .not-iframe {
    overflow-y: auto;
    height: 100%;

    .panel-header {
      text-align: center;
      padding-top: 70px;
    }

    p.description {
      line-height: $font-size-base;
    }
    .resource-icon {
      transform: translateY(50%);
      display: flex;
      justify-content: center;
      margin-top: -70px;
      .video-icon-card,
      .video_resource-icon-card {
        background: url("gooru/images/video.svg");
      }
      .webpage-icon-card,
      .webpage_resource-icon-card {
        background-image: url("gooru/images/webpage.svg");
      }
      .interactive-icon-card,
      .interactive_resource-icon-card {
        background: url("gooru/images/Interactive.svg");
      }
      .question-icon-card {
        background: url("quizzes-addon/quizzes/images/question-resource-green.svg");
      }
      .image-icon-card,
      .image_resource-icon-card {
        background: url("quizzes-addon/quizzes/images/image.svg");
      }
      .text-icon-card,
      .text_resource-icon-card {
        background: url("gooru/images/Text.svg");
      }
      .html-icon-card {
        background: url("gooru/images/html.svg");
      }
      .audio-icon-card,
      .audio_resource-icon-card {
        background: url("quizzes-addon/quizzes/images/audio.svg");
      }
      .presentation-icon-card {
        background: url("quizzes-addon/quizzes/images/presentation.svg");
      }
      .file-icon-card {
        background: url("quizzes-addon/quizzes/images/file_download.svg");
      }
      .instructional-icon-card {
        background: url("quizzes-addon/quizzes/images/instructional.svg");
      }
      .video-icon-card,
      .webpage-icon-card,
      .interactive-icon-card,
      .question-icon-card,
      .image-icon-card,
      .audio-icon-card,
      .text-icon-card,
      .html-icon-card,
      .presentation-icon-card,
      .file-icon-card,
      .instructional-icon-card,
      .audio_resource-icon-card,
      .video_resource-icon-card,
      .webpage_resource-icon-card,
      .interactive_resource-icon-card,
      .image_resource-icon-card,
      .text_resource-icon-card {
        height: 140px;
        width: 140px;
        z-index: 99999;
        background-size: 140px;
        background-repeat: no-repeat;
        position: relative;
      }
    }
    p.title {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 36px;
      text-align: center;
      color: $black-333333;
    }

    .panel-footer {
      text-align: center;
      background-color: $white;
      margin: 5px 0 0;
      .a {
        margin-left: 40%;
      }
    }

    .panel-body {
      display: flex;
      justify-content: center;
      $line-height: 1.2;
      text-decoration: none;
      color: $text-color;
      width: 100%;
      padding: 0;
      .cards.qz-resource-card {
        margin: 0 auto;
        .panel-heading {
          padding: 12px 15px 0;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          margin-top: 20px;

          .header {
            padding: 0;
            margin: 0;
            border: 0;
            display: flex;
            justify-content: center;
            flex-direction: column;
          }
        }

        .panel-body {
          padding: 5px 15px 0;
          display: block;

          .publisher {
            a {
              color: $blue-400;
            }
          }
        }

        .panel-footer {
          .play-btn {
            width: 137px;
            height: 32px;
            align-content: center;
            .gru-icon {
              font-size: 20px;
            }
          }
          .gru-icon {
            font-size: 20px;
          }
        }
      }
    }
    &.launchExternalLink {
      overflow-y: initial;
      background-color: $sky-400;
      margin-bottom: 0;
      .panel-body {
        .qz-resource-viewer {
          .linkout {
            .not-iframe {
              overflow-y: initial;
              box-shadow: none;
            }
          }
        }
      }
    }
  }
  .linkout {
    .panel-body {
      .cards.qz-resource-card {
        margin: unset;
        height: 30vh;
        width: 440px;
        .panel {
          height: 45vh;
          display: flex;
          flex-direction: column;
          min-height: 50vh;
          .panel-body {
            display: flex;
            padding-top: 3%;
            .externalMsg {
              height: 10vh;
              font-size: 1.55vh;
              color: $gray-base;
            }
          }
        }
      }
      .external-browser {
        background-size: contain;
        height: 40vh;
        width: 30vw;
        margin: 1%;
        margin-left: 6%;

        background-repeat: no-repeat;
        position: relative;

        .msg-container {
          position: absolute;
          top: 9vh;
          left: 2.8vw;

          display: flex;
          justify-content: center;
          width: 94%;
          height: 100%;
          flex-direction: column;
          align-items: center;
          .external-res-msg1,
          .external-res-msg2 {
            color: $white;
            font-size: 1.8vw;
          }
          .external-res-msg1 {
            font-size: 2.2vw;
            line-height: 11px;
          }
          .external-res-msg3 {
            font-size: 1vw;
            color: $gray-base;
          }
          .btn-dark {
            background-color: $gray-base;
            font-size: 0.9vw;
            width: 8vw;
            color: $white;
          }
        }
      }

      .external-browser-img {
        @extend .external-browser;
      }
    }
  }
}

.resource-player {
  .qz-resource-viewer-panel {
    height: 85%;
    margin-top: 20px;
    &.external-panel {
      height: 100vh;
      margin: 0;
      padding: 0;
    }
  }
  .iframe-player {
    .qz-resource-viewer-panel {
      margin-top: 0px;
    }
  }
}
