.content.modals.ca-mastery-accrual-confirmation {
  max-width: 455px;
  border-radius: 10px;
  box-shadow: 0 0 4px 3px $constant-modal;
  background: $white;
  border: none;

  .modal-body {
    padding: 15px;
    background: none;
    border: none;

    p {
      line-height: 1.3;
      font-weight: normal;
      margin-top: 10px;
      margin-bottom: 10px;
      font-size: 14px;

      &.ca-title {
        display: flex;
        align-items: center;
        i {
          color: $ornge-03;
          padding: 10px;
        }
      }

      &.question {
        font-weight: bold;
      }

      &.note {
        font-size: 12px;
      }
    }
  }

  .actions {
    margin-top: 40px;
    text-align: center;
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 50px;
    border-top: solid 1px $gray-light;

    .cancel-btn {
      font-size: 15px;
      font-weight: bold;
      color: $gray-light;
      text-transform: capitalize;
      border-right: solid 1px $gray-light;
      width: 100%;
      text-align: center;
      padding-top: 13px;
      cursor: pointer;
    }

    .proceed-btn {
      font-size: 15px;
      font-weight: bold;
      color: $white;
      text-transform: capitalize;
      padding-top: 13px;
      cursor: pointer;
      background: $blue-400;
      height: 49px;
      border-bottom-right-radius: 10px;
    }

    .gru-spinner-button {
      display: inline;
      background: $blue-400;
      border-bottom-right-radius: 10px;

      button {
        background: none;
        border-radius: 0;
        color: $white;
        font-size: 16px;
        margin-top: 4px;
      }

      .has-spinner {
        width: auto;
      }
    }
  }
}
