.student.class {
  width: 960px;
  margin: 0 auto;
  height: 100%;

  .alert-player-error {
    height: 50px;
    line-height: 50px;
    border-radius: 4px;
    @include box-shadow(0 0 7px $table-border-color);
    background-color: $brand-danger;

    .alert-status {
      padding-left: 10px;
      color: $white;
    }
  }

  .gru-take-tour {
    right: 80px;
    top: 125px;
  }

  .content {
    height: 100%;

    .bar-charts {
      width: 100%;
      height: 40px;
      position: fixed;
      z-index: 99;
      top: 52px;
      @media screen and (max-width: 567px) {
        top: 102px;
      }
      left: 0;
      background-color: $white;
      display: none;
      box-shadow: 0 2px 4px 0 $navbar-default-toggle-icon-bar-bg;

      .completion-chart {
        display: grid;
        cursor: pointer;
        align-items: center;
        height: 40px;
        grid-template-columns: 22px 1fr 20px;

        .destination-point {
          i {
            float: right;
          }
        }

        .start-point {
          i {
            float: left;
          }
        }

        .destination-point,
        .start-point {
          height: 24px;

          i {
            color: $blue-400;
          }
        }
      }

      .charts.gru-x-bar-chart {
        background-color: $light-300;
        height: 10px;
      }

      .msaddonTop {
        position: absolute;
        width: 22px;
        height: 22px;
        object-fit: contain;
        background: url("gooru/images/hover-show-up.svg");
        background-size: 100%;
      }

      .msaddonTop:hover {
        background: url("gooru/images/close-down.svg");
        cursor: pointer;
      }

      .completion-chart:hover .msaddonTop {
        background: url("gooru/images/close-down.svg");
        cursor: pointer;
      }

      .completion-chart {
        cursor: pointer;
      }

      &.non-clickable {
        .gru-study-navbar {
          pointer-events: none;
        }
      }

      .popover {
        color: $dark-400;
      }
    }

    .no-course-content {
      font-size: 20px;
      align-items: center;
      height: 85vh;
      display: flex;
      justify-content: center;
    }
  }

  .header {
    .go-back-container {
      .back-to {
        font-size: $font-size-lead;
        font-weight: 300;

        i {
          font-size: $font-size-arrow-icon;
          height: 30px;
          vertical-align: middle;
          width: 25px;
        }
      }

      h1 {
        color: $dark-500;
        font-size: $font-size-lead;
        font-weight: 300;
        display: inline-block;
        margin: 0 200px;
      }
    }

    .panel.classroom-information {
      margin: 10px 0 0;
      width: 100%;

      .panel-body {
        padding: 0;
        height: 60px;
        display: grid;
        @media screen and (max-width: 567px) {
          margin-top: 42px;
        }
        grid-template-columns: 60px 1fr max-content 150px 200px 200px 112px 117px;
        @media only screen and (min-width: 768px) and (max-width: $screen-in-1024) {
          overflow-x: auto;
          grid-template-columns: 89px repeat(7, auto);
          width: 100%;
        }

        &.no-taxomony {
          grid-template-columns: 90px 1fr max-content 150px 180px 90px 75px;

          .taxonomy-standards {
            display: none;
          }
        }

        &::before,
        ::after {
          content: none;
        }

        .course-image {
          border-right: $border-right-side $light-300;
          border-left: $border-left-side $light-300;
          height: 60px;

          img {
            height: 100%;
            width: 89px;
            margin-right: 10px;
          }

          .no-course-image {
            background: url("gooru/course-default-medium.png");
            background-size: 90px 60px;
            width: 89px;
            height: 60px;
          }
        }

        .course-name {
          line-height: 1.4;
          margin-top: 18px;
          text-overflow: ellipsis;
          text-align: center;
          overflow: hidden;
          white-space: nowrap;
          display: block;
        }

        .counts-container {
          padding: 6px 0 0;

          .count {
            height: 20px;
            margin: 2px 0 0;
            width: 100%;

            i {
              font-size: $font-size-units-icon;
              line-height: 1.1;
            }

            span {
              vertical-align: top;
              margin-left: 4px;
            }
          }
        }

        .teacher-profile {
          grid-template-columns: auto auto;
          display: grid;
          align-items: center;
          justify-content: center;
          grid-gap: 5px;

          img {
            border-radius: 50px;
            display: inline-block;
            height: 40px;
            width: 43px;
          }

          .owner-name {
            line-height: 1.4;
            justify-self: center;
          }
        }

        .taxonomy-standards {
          min-width: 100px;
          margin: 0 0 0 20px;
          padding: 12px 0 0;

          .taxonomy.gru-taxonomy-tag {
            background-color: $light-300;
            border: none;
            max-width: 135px;

            .content {
              b,
              span {
                color: $font-dark-500-white-500;
              }
            }
          }
        }

        .class-code {
          border-left: $border-right-side;
          border-right: $border-left-side;
          margin-left: 10px;
          height: 60px;
          padding: 14px 0 6px;
          text-align: center;

          div {
            line-height: 1;
            text-transform: uppercase;
            color: $dark-400;
            width: 100%;

            &.title {
              opacity: $opacity-light;
              font-size: 11px;
              font-weight: normal;
            }

            &.code {
              font-size: $font-size-small;
              font-weight: bold;
              margin: 3px 0 0;
            }
          }
        }

        .share-class-data {
          display: flex;
          align-items: center;
          color: $black-bold;
          font-size: 15px;
          justify-content: center;

          .toogle-options {
            .gru-switch {
              .toggle.btn {
                width: 43px !important;
                height: 18px !important;
                border-radius: 20px;
              }

              .toggle-group {
                .toggle-off,
                .toggle-on {
                  top: -1px;
                  font-style: italic;
                  font-size: 13px;
                  line-height: 4px !important;
                }

                .toggle-off {
                  left: 35px;
                }

                .toggle-on {
                  left: -14px;
                }

                .toggle-handle {
                  top: -6px;
                  left: -2px;
                  border-radius: 20px;
                }
              }
            }
          }

          .share-text-lable {
            margin: 0;
          }
        }

        .performance-container {
          cursor: pointer;

          &.not-started {
            pointer-events: none;
          }

          .percentage {
            cursor: pointer;
            margin: 12px 0;
            font-size: $font-size-h3;
          }

          .completion-bar {
            cursor: pointer;
            width: 80%;
          }
        }
      }
    }
  }

  .content {
    .analytics-performance-student {
      .student-actions,
      .tab-content {
        padding: 0;
      }
    }
  }
}

.student_class.student_class_course-map {
  .app-container {
    padding-top: 90px;
  }
}
