.i2d-message-dialog {
  max-width: 455px;
  border-radius: 10px;
  box-shadow: 0 0 4px 3px $constant-modal;
  background: $white;
  border: none;
  height: 100%;
  width: 100%;

  .modal-container {
    .modal-body {
      padding: 15px;
      background: 0 0;
      border: none;

      .success-container {
        line-height: 1;
        margin-bottom: 15px;

        i {
          color: $green-80;
          font-size: 60px;
          text-align: center;
          width: 100%;
        }

        .success-text {
          display: block;
          text-align: center;
          color: $green-80;
          font-size: 16px;
          text-transform: uppercase;
          font-weight: bold;
        }
      }

      p {
        line-height: 1.3;
        font-weight: normal;
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 14px;

        &.question {
          font-weight: bold;
        }
      }
    }

    .actions {
      margin-top: 40px;
      text-align: center;
      display: grid;
      grid-template-columns: 1fr 1fr;
      height: 50px;
      border-top: solid 1px $gray-c9;

      .cancel-btn {
        font-size: 15px;
        font-weight: bold;
        color: $gray-c9;
        text-transform: capitalize;
        border-right: solid 1px $gray-c9;
        width: 100%;
        text-align: center;
        padding-top: 13px;
        cursor: pointer;
      }

      .proceed-btn {
        font-size: 15px;
        font-weight: bold;
        color: $white;
        text-transform: capitalize;
        padding-top: 13px;
        cursor: pointer;
        background: $blue-400;
        height: 49px;
        border-bottom-right-radius: 10px;
      }
    }
  }
}
