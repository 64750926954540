.player .gru-navigator {
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  background-color: $dark-400;
  .navigator-header {
    height: auto;
    width: 100%;
    background-color: $dark-500;
    padding: 12px;
    display: table;
    @include justify-content(space-between);
    @include align-items(center);
    line-height: 28px;
    @media (max-width: $screen-md) {
      display: table !important;
    }
    span {
      color: $white;
      -webkit-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-justify-content: flex-start;
      justify-content: flex-start;
      -webkit-align-items: center;
      align-items: center;
      -webkit-align-content: stretch;
      align-content: stretch;
      display: -webkit-flex;
      display: flex;
      margin-bottom: 0;
      cursor: pointer;
    }
    p {
      color: $white;
      margin: 0 0 0 25px;
    }
    .lead {
      cursor: pointer;
    }
    div {
      height: 25px;
    }
    .hamburger-icon {
      font-size: 2.45rem;
      line-height: 2.45rem;
      color: $gray;
      cursor: pointer;
      margin: 0 10px 0 0;
      height: 25px;
      background-color: $dark-500;
      border-radius: 0;
      vertical-align: top;
      i {
        vertical-align: top;
        background-color: $dark-500;
        height: 25px;
        &:hover,
        &:focus {
          background-color: $dark-500;
        }
      }
    }
  }

  .navigator-subheader {
    color: $white;
    padding: 7px 10px 10px 22px;
    p {
      margin: 0;
    }
  }

  .resources {
    padding: 0;
    position: relative;
    .bgd-line {
      display: block;
      position: absolute;
      content: "";
      top: 10px;
      left: 24px;
      width: 5px;
      height: 90%;
      background-color: $gray-lighter;
      z-index: 1;
    }
    li {
      list-style: none;
      border-radius: 0;
      border: 0;
      background: $li-background;
      min-height: 65px;
      overflow: hidden;
      padding: 10px 5px;
      display: block;
      width: 100%;
      cursor: pointer;
      margin: 1px 0 0 0;
      -webkit-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-justify-content: flex-start;
      justify-content: flex-start;
      -webkit-align-items: center;
      align-items: center;
      -webkit-align-content: stretch;
      align-content: stretch;
      display: -webkit-flex;
      display: flex;
      /*
        This class is for when we have data on whether a resource is completed or not, for now, all resources will look as though they have not been completed.
      */
      &.completed {
        background: $completed-background;
      }
      .resources-info {
        position: relative;
        -webkit-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-justify-content: flex-start;
        justify-content: flex-start;
        -webkit-align-items: center;
        align-items: center;
        -webkit-align-content: stretch;
        align-content: stretch;
        display: -webkit-flex;
        display: flex;
        width: 100%;
        padding: 0 5px;

        span.number {
          width: 40px;
          text-align: center;
          color: $white;
        }

        i {
          -webkit-order: 0;
          order: 0;
          -webkit-flex-grow: 0;
          flex-grow: 0;
          -webkit-flex-shrink: 0;
          flex-shrink: 0;
          -webkit-flex-basis: auto;
          flex-basis: auto;
          -webkit-align-self: auto;
          align-self: auto;
        }
        .bubble-type {
          background: url("gooru/resource-collection-icons.png") no-repeat
            scroll 0 0;
          background-size: 300px 228px;
          height: 29px;
          width: 30px;
          z-index: 2;
          display: inline-block;
        }
        .video {
          background: url("gooru/images/video-resource.svg");
          -webkit-background-size: 31px 30px;
          background-size: 31px 30px;
          height: 30px;
          width: 31px;
        }
        .webpage {
          background: url("gooru/images/website-resource.svg");
          -webkit-background-size: 29px 30px;
          background-size: 29px 30px;
          height: 30px;
          width: 29px;
        }
        .interactive {
          background: url("gooru/images/interactive-resource.svg");
          -webkit-background-size: 34px 30px;
          background-size: 34px 30px;
          height: 30px;
          width: 34px;
        }
        .question {
          background: url("gooru/images/question-resource.svg");
          -webkit-background-size: 30px 30px;
          background-size: 30px 30px;
          height: 30px;
          width: 30px;
        }
        .image {
          background: url("gooru/images/image-resource.svg");
          -webkit-background-size: 30px 30px;
          background-size: 30px 30px;
          height: 30px;
          width: 30px;
        }
        .text {
          background: url("gooru/images/text-resource.svg");
          -webkit-background-size: 31px 30px;
          background-size: 31px 30px;
          height: 30px;
          width: 31px;
        }
        .audio {
          background: url("gooru/images/audio-resource.svg");
          -webkit-background-size: 34px 30px;
          background-size: 34px 30px;
          height: 30px;
          width: 34px;
        }
        .title {
          margin: 0 0 0 18px;
          color: $white;
          overflow: hidden;
          max-width: 60%;
          p {
            margin: 0;
          }
          max-width: 136px;
        }
      }
      &.started {
        background: #00458a; /* Old browsers */
        background: -moz-linear-gradient(
          left,
          $blue-600 0%,
          $blue-500 100%
        ); /* FF3.6-15 */
        background: -webkit-linear-gradient(
          left,
          $blue-600 0%,
          $blue-500 100%
        ); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(-90deg, $blue-600 0%, $blue-500 100%);
        color: $white;
      }
      &.selected {
        background: #0050a2; /* Old browsers */
        background: -moz-linear-gradient(
          left,
          $blue-500 0%,
          $blue-400 100%
        ); /* FF3.6-15 */
        background: -webkit-linear-gradient(
          left,
          $blue-500 0%,
          $blue-400 100%
        ); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(-90deg, $blue-500 0%, $blue-400 100%);
        color: $white;
      }
    }
    &.disabled li {
      cursor: default;
    }
  }
  .submit-all {
    text-align: center;
    margin: 0 0 20px 0;
  }
  .see-usage-report {
    cursor: pointer;
    i {
      float: $menu-float-dir;
    }
  }
}
