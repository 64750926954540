.card.student-activity {
  width: 100%;
  height: 100%;
  box-shadow: 0 2px 5px 0 $disabled-bg-color;
  background-color: $white;
  display: grid;
  grid-template-rows: max-content max-content auto;
  border-radius: 4px;
  padding: 1px;

  .card-container {
    width: inherit;
    height: inherit;

    .collection-img {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      border-radius: 8px 8px 0 0;
      height: 167px;
    }
    .no-resource {
      text-align: center;
      padding: 8px;
      height: 56vh;
      display: flex;
      align-items: center;

      span {
        font-size: 18px;
      }
    }

    .collection-performance {
      height: 50px;
      width: 100%;
      box-shadow: 0 2px 5px 0 $disabled-bg-color;
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid $collection-border-bottom;

      .icon-assessment,
      .icon-assessment-external {
        width: 20%;
        background-image: url("gooru/images/add_assessment.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 54%;
        opacity: 0.8;
      }

      .icon-collection,
      .icon-collection-external {
        width: 20%;
        background-image: url("gooru/images/add_collection.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 54%;
        opacity: 0.8;
      }

      .icon-offline-activity {
        width: 20%;
        background-image: url("gooru/images/offline.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 37%;
        opacity: 0.8;
      }

      .title {
        display: flex;
        align-items: center;
        width: calc(100% - 40%);

        span {
          width: 157px;
          height: 20px;
          font-size: 15px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #5d5d5d;
        }
      }

      .performance {
        width: 20%;
        height: 51px;
        display: flex;
        cursor: pointer;

        &.disable {
          pointer-events: none;
        }

        .score,
        .time-spent {
          font-weight: bold;
          line-height: normal;
          margin: auto;
          color: $white;
        }

        .score {
          font-size: 14px;
        }

        .time-spent {
          font-size: 14px;
        }
      }
    }

    .collections {
      max-height: 155px;
      border-radius: 8px;
      overflow-y: auto;
      width: 100%;

      &::-webkit-scrollbar {
        height: 6px;
        width: 5px;
        background-color: $white;
      }

      &::-webkit-scrollbar-thumb {
        background: $gray-light;
        border: 1px solid $gray-light;
        border-radius: 8px;
      }

      .resource {
        background-color: $white;
        width: 100%;
        display: flex;
        flex-direction: row;
        height: 50px;
        box-shadow: 0 2px 5px 0 $disabled-bg-color;
        border-bottom: 1px solid $collection-border-bottom;

        .question-img {
          width: 20%;
          background-image: url("gooru/images/collection-question-gray.svg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: 40%;
          opacity: 0.5;
        }

        %image-container {
          background-repeat: no-repeat;
          background-position: center;
          background-size: 40%;
          width: 20%;
          opacity: 0.5;
        }

        .task-img {
          background: url("gooru/images/text-resource-dark.svg");
          @extend %image-container;
          background-repeat: no-repeat;
          background-position: center;
          background-size: 40%;
        }

        .resource-img {
          &.video {
            background-image: url("gooru/images/video-resource-dark.svg");
            @extend %image-container;
          }

          &.question {
            background-image: url("gooru/images/collection-question-gray.svg");
            @extend %image-container;
          }

          &.webpage {
            background: url("quizzes-addon/quizzes/images/website-resource-dark.svg");
            @extend %image-container;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 40%;
          }

          &.interactive {
            background: url("quizzes-addon/quizzes/images/interactive-resource-dark.svg");
            @extend %image-container;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 40%;
          }

          &.image {
            background: url("quizzes-addon/quizzes/images/image-resource-dark.svg");
            @extend %image-container;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 40%;
          }

          &.text {
            background: url("quizzes-addon/quizzes/images/text-resource-dark.svg");
            @extend %image-container;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 40%;
          }

          &.audio_resource {
            background: url("quizzes-addon/quizzes/images/audio-resource-dark.svg");
            @extend %image-container;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 40%;
          }
        }

        .collection-info {
          width: 80%;
          margin-top: auto;

          .title {
            width: 80%;
            height: 17px;
            margin: 0;
            padding: 3px;
            font-size: 12px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #4c5667;
          }

          .type {
            width: 69px;
            height: 13px;
            opacity: 0.5;
            padding: 3px;
            font-size: 11px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.456 !important;
            letter-spacing: normal;
            color: #5d5d5d;
          }
        }
      }
    }
  }
}
