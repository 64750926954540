.student-class-search-container {
  background: white;
  width: 242px;
  display: flex;
  border-radius: 5px;
}

i#ember6854 {
  font-size: 14px;
}

.class-container {
  display: flex;
  justify-content: center;
}

.custom-class-icon {
  margin-top: 5px;
}

.student-class-proficiency-search {
  outline: none;
  border: none;
  margin-left: 10px;
}

.inspect.student-class-proficiency {
  background-color: $light-ps;
  min-height: calc(100vh - 51px);

  .class-proficiency-container {
    .header-container {
      .proficiency-info {
        display: grid;
        grid-template-columns: auto auto;

        .subject {
          font-size: 30px;
          color: $white;
          margin-left: 15px;
        }

        .students-count {
          margin-top: 10px;
          font-size: 15px;
          font-weight: bold;
          color: $white;
          justify-self: right;
          display: grid;
          grid-template-columns: auto 30px;
          justify-content: center;
          align-items: center;
          margin-right: 44px;

          .toggle-view {
            display: grid;
            justify-content: right;
            cursor: pointer;
            margin-top: 5px;
          }
        }
      }
    }

    .body-container {
      padding: 10px 50px;

      .proficiency-cards-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .proficiency-card-container {
          background-color: $white;
          border-radius: 5px;
          margin: 5px;
          min-width: 230px;
          cursor: pointer;

          .card-header {
            margin: 10px;
            border-bottom: 1px solid $nobel-gray;
            display: grid;
            grid-template-columns: 55px auto;
            padding-bottom: 10px;

            .thumbnail-container {
              width: 47px;
              height: 47px;
              background-size: 47px 47px;
              display: block;
              border-radius: 50%;
              border: none;
            }

            .student-name {
              .first-name,
              .last-name {
                text-transform: capitalize;
                opacity: 0.9;
                font-size: 13px;
                color: $black-bold;
              }
            }
          }

          .card-body {
            padding: 5px 10px;

            &.disable-student-performance {
              .proficiency-view {
                svg {
                  .fill-0,
                  .fill-1,
                  .fill-2,
                  .fill-3,
                  .fill-4,
                  .fill-5 {
                    fill: $gray-no-started;
                  }

                  #skyline-group {
                    polyline {
                      display: none;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    @media screen and (max-width: $screen-481) {
      .header-container {
        padding: 5px;

        .proficiency-info {
          .subject {
            font-size: 24px;
          }

          .students-count {
            margin-right: 0px;

            .count-container {
              display: none;
            }
          }
        }
      }

      .body-container {
        padding: 5px;

        .proficiency-cards-container {
          .proficiency-card-container {
            margin: 5px auto;
          }
        }
      }
    }
  }
}

.search-input-container {
  display: flex;
  position: relative;
  border: none;

  @media screen and (max-width: $screen-xs) {
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
  }

  #studentWeeklyLocal {
    -webkit-appearance: none;
    outline: none;
    width: 280px;
    height: 38px;
    text-indent: 10px;
    margin-top: 4px;
    border-radius: 5px;
    margin-bottom: 3px;
    margin-left: 5px;
    cursor: text;
    font-size: inherit;

    @media screen and (max-width: $screen-xs) {
      width: 100%;
    }
  }

  .custom-search-icon {
    width: 16px;
    height: 17px;
    color: $gray;
    cursor: pointer;
    position: absolute;
    right: 0;
    margin-top: -6px;
    margin-right: 15px;

    .search {
      font-size: 24px;
      margin-top: 16px;
    }
  }
}

.student-class-proficiency-download-pdf {
  margin: 10px;
}

@media print {
  .class-container {
    display: none !important;
  }

  .proficiency-card-container {
    margin-top: 10vw !important;
  }
}
