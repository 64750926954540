.gru-view-layout-picker {
  line-height: 1;
  .view-layout-list {
    display: inline-block;
    border-radius: 4px;
    div {
      cursor: pointer;
      display: block;
      float: $tab-float-dir;
      padding-left: 5px;
      padding-right: 5px;
      div {
        i {
          font-size: 25px;
          margin-top: 2px;
        }
      }
      .nav-title {
        text-transform: uppercase;
        line-height: 2.2;
      }
    }
    a {
      color: $gray-80;
      opacity: 0.5;
    }
    div.active a {
      color: $white;
      opacity: 1;
    }
  }
}
