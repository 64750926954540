.reports.class-assessment.gru-student-performance-box {
  width: 155px;
  margin-right: 5px;

  .panel {
    margin-bottom: 5px;
    .panel-heading {
      padding: 5px;
      cursor: pointer;
      color: $white;
      line-height: $font-size-h6;
      height: 26px;
      .name {
        max-width: 90px;
        display: inline-block;
      }
      .score {
        float: $menu-float-dir;
      }
      .material-icons {
        font-size: $in-progress-icon-size;
      }
      &.anonymous {
        font-size: $font-size-anonymous;
        line-height: $font-size-anonymous;
        height: 40px;
        padding: 0;
        .name {
          max-width: 100%;
        }
      }
      &.not-started {
        background-color: $dark-300;
        .name {
          max-width: 100%;
        }
      }
      .question-in-progress {
        float: $menu-float-dir;
        position: relative;
        top: -5px;
      }
    }

    .panel-body {
      padding: 5px 0;
    }

    .questions {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      span {
        &:not(.not-started) {
          cursor: pointer;
        }
        width: 22px;
        height: 22px;
        margin-left: 7px;
        margin-bottom: 5px;
        border-radius: 4px;
        &.skipped,
        &.not-started {
          background-color: $light-200;
        }
        &.correct {
          background-color: $brand-success;
        }
        &.incorrect {
          background-color: $brand-incorrect;
        }
        &.open-ended {
          background-color: $blue-400;
        }
      }
    }
  }
}
