.change-password-cocoon-container {
  .gru-pull-up {
    width: 30vw;
    min-width: 300px;
  }
  .change-password-cocoon {
    .panel-default {
      font-size: 16px;

      .title-top {
        display: flex;
        align-items: center;
        .password-icon {
          padding: 15px;
          height: 52px;
          // color: dodgerblue;
        }
      }
      .reset-password-form {
        width: 100%;
      }
      #changePasswordForm {
        .panel-body {
          width: unset;

          .change-password-form {
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            align-items: left;

            .form-group {
              .formLabel {
                display: inline-block;
                max-width: 100%;
                margin-bottom: 5px;
                font-weight: normal;
              }
            }
          }
          .button {
            font-size: 13px;
          }
        }
      }
    }
  }

  .gru-pull-up .pullup-close {
    background-color: unset;
    top: 3%;
  }
}
