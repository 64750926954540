.qz-player-footer {
  position: fixed;
  bottom: 0;
  height: 40px;
  background: $white;
  font-family: $font-family-ps-roboto;
  right: 0;
  z-index: 20;
  width: 100%;
  background-color: $white;
  box-shadow: 0 -1px 4px 0 $navbar-default-toggle-icon-bar-bg;
  padding: 0 10px;
  @media screen and (max-width: 850px) {
    height: 80px;
  }

  .nav-player-container {
    display: grid;
    grid-template-columns: 368px 74px auto;
    height: 100%;

    @media screen and (max-width: 850px) {
      grid-template-rows: auto;
      grid-template-columns: auto;
    }
    &.disabled {
      opacity: $opacity-light;
      pointer-events: none;
    }

    .title-container {
      display: grid;
      grid-template-columns: auto 56px;
      @media screen and (max-width: 320px) {
        grid-template-columns: 270px auto;
      }

      .title-info {
        display: grid;
        grid-template-columns: 56px auto;
        overflow: hidden;

        .icon {
          background-size: 25px 25px;
          background-position: center;
          background-repeat: no-repeat;

          &.collection {
            background-image: url("gooru/images/add_collection.svg");
          }
          &.assessment {
            background-image: url("gooru/images/add_assessment.svg");
          }

          &.video_resource {
            background-image: url("quizzes-addon/quizzes/images/video-resource-dark.svg");
          }

          &.html_resource {
            background-image: url("quizzes-addon/quizzes/images/html-icon.png");
          }

          &.webpage_resource {
            background-image: url("quizzes-addon/quizzes/images/website-resource-dark.svg");
          }

          &.interactive_resource {
            background-image: url("quizzes-addon/quizzes/images/interactive-resource-dark.svg");
          }

          &.question {
            background-image: url("quizzes-addon/quizzes/images/question-resource-dark.svg");
          }

          &.image_resource {
            background-image: url("quizzes-addon/quizzes/images/image-resource-dark.svg");
          }

          &.text_resource {
            background-image: url("quizzes-addon/quizzes/images/text-resource-dark.svg");
          }

          &.audio_resource {
            background-image: url("quizzes-addon/quizzes/images/audio-resource-dark.svg");
          }

          &.h5p_interactive_slide {
            background-image: url("gooru/images/interactive-slides.png");
          }
          &.h5p_interactive_video {
            background-image: url("gooru/images/interactive-video.png");
          }
          &.h5p_drag_and_drop_resource {
            background-image: url("gooru/images/drag-and-drop-icon.png");
          }
          &.h5p_interactive_personality_quiz {
            background-image: url("gooru/images/personality-quiz-icon.png");
          }
        }

        .title {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          color: $dark-400;
          span {
            font-size: 12px;
            @include ellipsis(
              $font-size: 12px,
              $line-height: 1.3,
              $lines-to-show: 1
            );
          }
        }
      }

      .close-button {
        width: 56px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }

    .feedback-container {
      display: flex;
      justify-content: space-between;
    }

    .information-container {
      display: grid;
      grid-template-columns: 1fr auto;

      &.show-all-cards {
        ul li {
          padding: 1px 10px;
          height: 30px;
          @media screen and (max-width: 350px) {
            padding: 1px 5px;
          }
        }
      }

      .instruction-footer {
        display: flex;
        padding: 0px;
        border: none;
        background-color: transparent;
        align-items: center;
        margin: auto;

        .info-icon-div {
          background-color: $white;
          color: $blue-bg;
          border: none;
          padding: 0px;
        }

        .text-div {
          padding: 1px 5px;
          border: none;
          color: $blue-bg;
          display: flex;
          align-items: center;
          justify-content: center;
          white-space: nowrap;
          cursor: pointer;
          background-color: transparent;
          font-family: "Arial";
          font-size: 14px;
          font-weight: bold;
          margin: 1px;
        }
      }

      @media screen and (max-width: 787px) {
        grid-template-columns: auto auto;
        width: fit-content;
        margin: 0px auto;
      }
      @media screen and (min-width: 768px) and (max-width: 768px) {
        grid-template-columns: 1fr 140px;
      }
      .show-div {
        @media screen and (min-width: 1081px) {
          display: none;
        }
      }
      .hide-div {
        @media screen and (max-width: 1080px) {
          display: none;
        }
      }
      @media screen and (max-width: 851px) {
        .show-div {
          display: none;
        }
        .hide-div {
          display: flex;
        }
      }
      @media screen and (max-width: 600px) {
        .show-div {
          display: block;
        }
        .hide-div {
          display: none;
        }
      }
      ul {
        list-style: none;
        height: 40px;
        padding-left: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0px;
        overflow-x: scroll;

        li {
          padding: 1px 20px;
          border-radius: 12px;
          border: solid 1px $light-400;
          color: $white;
          display: flex;
          align-items: center;
          justify-content: center;
          white-space: nowrap;
          margin: 0px 8px;
          cursor: pointer;
          background-color: $dark-400;
          font-family: "Montserrat";
          font-size: 12px;
          font-weight: bold;

          @media screen and (max-width: 850px) {
            padding: 1px 10px;
          }
          &.active {
            opacity: 0.3;
          }

          .active-icon {
            width: 10px;
            height: 10px;
            background-color: $timer-active-icon;
            border-radius: 50%;
            margin-left: 5px;
            border: 1px solid $white;

            &.blink {
              animation: blink 1s ease-in infinite;
            }
          }
        }
      }
      ul::-webkit-scrollbar {
        display: none;
      }
    }
    .resource-button {
      @media screen and (max-width: 768px) {
        grid-template-columns: 1fr 100px;
        ul {
          li {
            padding: 1px 10px;
            @media screen and (max-width: 320px) {
              padding: 1px 5px;
            }
          }
        }
        .button-container {
          justify-content: space-around;
          .prev {
            width: 40px;
            margin: 0;
          }
          .next {
            width: 40px;
            margin: 0;
          }
        }
      }
    }

    .button-container {
      display: flex;
      justify-content: center;
      align-items: center;

      .prev {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 96px;
        height: 25px;
        border-radius: 4px;
        background: $white-bold;
        border: 1px solid $btn-border-grey;
        color: $blue-bg;
        cursor: pointer;
        @media screen and (max-width: 768px) {
          width: max-content;
          height: 28px;
          margin: 0px 8px;
        }
        &.disabled {
          cursor: default;
          pointer-events: none;
          opacity: $opacity-lighter;
        }
      }

      .prev:hover {
        background-color: $btn-background;
        border: 1px solid $btn-border-grey;
      }

      .prev:focus {
        background-color: $btn-background;
        box-shadow: 0px 0px 0px 1px $white-bold, 0px 0px 0px 2px $blue-bg,
          0px 0px 0px 4px $blue-pale;
      }

      .next {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 88px;
        height: 25px;
        border-radius: 4px;
        margin-left: 8px;
        background-color: $blue-bg;
        color: $white;
        cursor: pointer;
        @media screen and (max-width: 768px) {
          width: 50px;
          height: 28px;
          margin: 0px 8px;
        }

        &.disabled {
          cursor: default;
          pointer-events: none;
          opacity: 0.3;
        }
      }

      .next:hover {
        background-color: $blue-hover-bg;
      }

      .next:focus {
        box-shadow: 0px 0px 0px 1px $white-backgraound, 0px 0px 0px 2px $blue-bg,
          0px 0px 0px 4px $blue-pale;
        background-color: $blue-focus-bg;
      }
    }
  }
}

@keyframes blink {
  from,
  to {
    opacity: 1;
  }
  0% {
    opacity: 0;
  }
}
