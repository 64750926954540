.competency-meta-data {
  $line-height: 1.2;
  $lines-to-show: 4;

  .heading {
    font-size: 14px;
    font-weight: bold;
    padding: 20px 10px 10px;
    color: $white-bold;
  }

  .no-data {
    font-size: 16px;
    margin: auto;
    padding-left: 10px;
  }

  .micro-competencies {
    background-color: $white;
    min-height: 40px;
    display: grid;

    .mc-panel-header {
      cursor: pointer;
      height: 40px;
      box-shadow: 0 1px 3px 0 $navbar-box-shadow;
      background-color: $white-bold;
      display: grid;
      grid-template-columns: 1fr 20px;

      .code {
        font-size: 12px;
        font-weight: bold;
        color: $dark-400;
        height: auto;
        padding: 0 0 0 10px;
        align-items: center;
        display: flex;
      }

      .icon {
        align-items: center;
        display: flex;

        i {
          font-size: 16px;
          color: $font-gray-dark-500;
        }
      }

      &[aria-expanded="true"] {
        .icon.arrow_up {
          display: none;
        }

        .icon.arrow_down {
          display: flex;
        }
      }

      .icon.arrow_up {
        display: flex;
      }

      .icon.arrow_down {
        display: none;
      }
    }

    .panel-content {
      .description {
        padding: 10px;
        text-align: justify;
        display: block;
      }
    }
  }

  .alt-concepts {
    color: $dark-700;
    display: flex;
    font-size: 16px;
    padding: 10px;
    justify-content: center;
    background-color: $white;
  }

  .prequisite-competencies {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 15px;
    justify-content: center;
    background-color: $white;
    grid-gap: 10px;
    min-height: 40px;

    &.no-competency {
      grid-template-columns: none;
    }

    .panel-container {
      max-width: 200px;
      height: 142px;
      box-shadow: 0 2px 4px 0 $navbar-default-toggle-icon-bar-bg;
      background-color: $white;
      margin: auto;

      .panel-body {
        padding: 10px;

        .competency-description {
          font-size: 13px;
          @include ellipsis(
            $font-size: $font-size-base,
            $line-height: $line-height,
            $lines-to-show: $lines-to-show,
            $excerpt-bg: $white
          );
        }
      }

      .panel-header {
        border-bottom: 3px solid $nobel-gray;
        padding: 15px;
        display: grid;
        grid-template-columns: auto auto;

        .title {
          height: 27px;
          width: auto;
          font-size: 15px;
          color: $dark-400;
        }

        .competency-status {
          width: 24px;
          height: 24px;
          justify-self: end;

          &.status-0 {
            background-color: $gray-no-started;
          }

          &.status-1 {
            background-color: $blue-in-progress;
          }

          &.status-2,
          &.status-3,
          &.status-4,
          &.status-5 {
            background-color: $blue-mastery;
          }
        }
      }
    }
  }
}
