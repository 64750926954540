.reports.class-assessment.gru-student-view {
  .sort-section {
    margin-top: -5px;

    .btn-group button {
      -webkit-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-justify-content: flex-start;
      justify-content: flex-start;
      -webkit-align-items: center;
      align-items: center;
      -webkit-align-content: stretch;
      align-content: stretch;
      display: -webkit-flex;
      display: flex;
      i {
        vertical-align: middle;
        padding: 0 10px 0 0;
        font-size: $font-size-h4;
      }
    }
  }
  .student-performance-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 35px;
  }
}

.analytics-performance-teacher-collection .reports.class-assessment.gru-student-view {
  .student-performance-list {
    margin-left: -45px;
    @media (max-width: $screen-xs-max) {
      margin-left: 0;
    }
  }
}
