.course-search-pull-up {
  position: fixed;
  top: 100%;
  z-index: 1031;
  width: 768px;
  left: 0;
  right: 0;
  margin: auto;
  background-color: $white;
  overflow-y: auto;

  .search-backdrop {
    background-color: $backdrop-color;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    cursor: pointer;
    opacity: 0.9;
  }

  .course-search-container {
    .search-header {
      height: 50px;
      display: inline-flex;
      border-bottom: 1px solid $breadcrumb-color;
      align-items: center;
      width: 100%;
      background-color: $white;

      .search-pullup-close {
        width: 70px;
        height: 22px;

        .arrow_downward {
          font-size: 26px;
          color: #3c4452;
          margin-left: 22px;
          cursor: pointer;
        }
      }

      .gooru-logo {
        height: 18px;

        img {
          height: 12px;
        }
      }

      .search-filter-container {
        width: 150px;
        margin-left: 15px;
        display: flex;
        align-items: center;
        font-size: 15px;
        color: #3c4452;
        font-weight: bold;
        position: relative;

        span.down-arrow {
          height: 24px;
        }

        .arrow_drop_down {
          padding-top: 3px;
          padding-left: 2px;
          cursor: pointer;
        }

        .selected-catalog {
          margin-top: 4px;
          $font-size: 11px;
          $line-height: 1.2;
          $lines-to-show: 2;
          @include ellipsis(
            $font-size: $font-size,
            $line-height: $line-height,
            $lines-to-show: $lines-to-show
          );
        }

        .search-filter-container-list {
          position: absolute;
          background: $white;
          top: -3px;
          z-index: 17;
          left: -30px;
          box-shadow: 0 2px 7px 2px $disabled-bg-color;
          visibility: hidden;
          opacity: 0;
          transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s,
            z-index 0s linear 0.01s;
          max-height: 300px;
          overflow-y: auto;

          p {
            padding: 0;
            margin: 0;
            font-weight: normal;
            font-size: 15px;
            padding: 5px 20px;

            &.selected {
              background-color: $dark-50;
              font-weight: bold;
            }

            &:hover {
              background-color: $dark-50;
              cursor: pointer;
            }
          }

          &.active {
            visibility: visible;
            opacity: 1;
            transition-delay: 0s, 0s, 0.3s;
          }
        }
      }

      .search-right-menu {
        width: 532px;
        display: flex;
        justify-content: flex-end;

        .search-input-container {
          display: flex;
          position: relative;
          border: none;

          #search-content {
            -webkit-appearance: none;
            outline: none;
            border-radius: 5px;
            width: 446px;
            text-indent: 10px;
            margin-top: 4px;
            margin-bottom: 3px;
            box-shadow: inset 0 0 7px 1px $breadcrumb-color;
            cursor: text;
          }

          .search-icon {
            width: 38px;
            height: 40px;
            color: #3c4452;
            cursor: pointer;
            position: absolute;
            right: 0;

            .search {
              font-size: 24px;
              margin-top: 13px;
            }

            &.active {
              color: #fa9053;
            }
          }
        }

        .search-filter-icon {
          color: $white;
          width: 30px;

          .filter_list {
            margin-top: 14px;
            margin-left: -6px;
            visibility: hidden;
          }
        }
      }
    }

    .pull-up-body-container {
      max-height: calc(100vh - 116px);
      height: calc(90vh - 100px);
      overflow-y: auto;
      width: 100%;
      padding-top: 30px;
      padding-bottom: 30px;
      background: #f1f3f7;
      .cards-container {
        display: grid;
        grid-template-columns: auto auto auto;
        padding-left: 25px;
        .course-card-container {
          .card-panel-container {
            height: 340px;
            margin-bottom: 15px;
          }
        }
      }
      .course-not-found {
        text-align: center;
        font-size: 18px;
      }
    }
  }

  @media screen and (max-width: 992px) {
    .course-search-container {
      .pull-up-body-container {
        .cards-container {
          .course-card-container {
            margin: 0 auto;
            grid-template-columns: auto auto;
            padding-left: 0px;
            padding-right: 15px;
          }
        }
      }
    }
  }
  @media screen and (max-width: $screen-568 - 1) {
    .course-search-container {
      .course-cards-container {
        display: grid;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .course-search-container {
      .pull-up-body-container {
        width: 100vw;
        .cards-container {
          display: grid;
          grid-template-columns: auto auto;
          justify-content: center;
          grid-gap: 20px;
          padding-left: 5px;
          .course-card-container {
          }
        }
      }
    }
  }
  @media screen and (max-width: 481px) {
    .course-search-container {
      .pull-up-body-container {
        .cards-container {
          display: grid;
          grid-template-columns: auto;
          .course-card-container {
            margin: auto 45px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) and (min-width: 568px) {
  .course-search-container {
    .pull-up-body-container {
      .cards-container {
        grid-template-columns: auto auto;
      }
    }
  }
}
