.gru-multiple-choice {
  .answer-choices {
    .radio label {
      .text {
        overflow: auto;
        word-wrap: break-word;
      }
      @include flexbox;
      > * {
        @include inline-flex;
        margin-right: 0.5em;
      }
      input {
        margin-right: 1em;
      }
      @media (max-width: $screen-xs-max) {
        /* only for phone */
        input {
          margin-top: 3px;
        }
      }
    }
  }
}
