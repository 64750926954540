.inspect-competency.student-domain-performance {
  .performance-container {
    background-color: $light-ps;
    min-height: calc(100vh - 40px);

    .performance-header-container {
      min-height: 200px;

      .proficiency-info {
        display: grid;
        grid-template-columns: auto auto;
        height: 64px;

        .subject {
          font-size: 30px;
          color: $white;
          margin-left: 15px;
        }

        .students-count {
          font-size: 15px;
          font-weight: bold;
          color: $white;
          justify-self: right;
          display: grid;
          grid-template-columns: auto 30px;
          justify-content: center;
          align-items: center;
          margin-right: 44px;

          .toggle-view {
            display: grid;
            justify-content: right;
            cursor: pointer;
            margin-top: 3px;
          }
        }
      }

      .competency-coverage-container {
        display: grid;
        grid-template-columns: 228px auto;
        position: relative;
        position: relative;

        .scroll-controller {
          position: absolute;
          width: 100%;

          .left,
          .right {
            position: absolute;
            top: 75px;
            cursor: pointer;
            width: 32px;
            background-color: $scroll-left-back-ground;
            border-radius: 50%;
            height: 32px;

            i {
              font-size: 25px;
              color: $white;
              text-align: center;
              line-height: 32px;
            }

            &.disabled {
              cursor: default;
              opacity: 0.5;
            }
          }

          .left {
            left: 238px;
            margin: 0 4px;
          }

          .right {
            right: 16px;
            margin-left: 4px;
          }
        }

        .course-coverage-container {
          width: 224px;
          padding: 16px 15px;
          background-color: $white;
          margin-bottom: 8px;

          .title {
            color: $dark-600;
            text-transform: capitalize;
            font-size: 15px;
            line-height: 1.07;
            height: 33px;
            margin-bottom: 9px;
          }

          .course-competency-metrics {
            line-height: 15px;

            .students-metric {
              font-size: 12px;
              font-weight: 600;
              color: $dark-600;
              text-transform: uppercase;
              height: 24px;
              line-height: 24px;
            }

            .metrics-title {
              font-size: 12px;
              font-weight: bold;
              color: $dark-600;
            }

            .metrics {
              .metric-count {
                font-size: 12px;
                font-weight: bold;
                text-transform: uppercase;
              }

              .mastered {
                color: #0c70bb;
              }

              .in-progress {
                color: #24ade6;
              }

              .not-started {
                color: $dark-600;
                opacity: 0.5;
              }
            }
          }
        }

        .domains-coverage-container {
          display: flex;
          overflow-x: hidden;

          .domain-coverage-count {
            min-width: 224px;
            padding: 16px 15px;
            background-color: $white;
            margin: 0 4px 8px;
            cursor: pointer;
            .domain-name {
              $font-size: 15px;
              $line-height: 1.07;
              $lines-to-show: 2;
              text-transform: capitalize;
              height: 50px;
              margin-bottom: 10px;
              color: $dark-600;
              @include ellipsis(
                $font-size: $font-size,
                $line-height: $line-height,
                $lines-to-show: $lines-to-show
              );
              max-height: 33.1px;
              margin-bottom: 16px;
            }

            .coverage-count {
              .count-container {
                display: flex;
                width: 191px;
                height: 24px;
                margin-bottom: 8px;

                &.disable-student-performance {
                  .mastered-count,
                  .inprogress-count,
                  .notstarted-count {
                    background-color: $gray-no-started;
                  }
                }

                span {
                  height: inherit;
                }

                //Comp
                .mastered-count {
                  background-color: $blue-mastery;
                }

                .inprogress-count {
                  background-color: $blue-in-progress;
                }

                .notstarted-count {
                  background-color: $gray-no-started;
                }
              }

              .competency-metrics {
                line-height: 15px;

                .title {
                  font-size: 12px;
                  font-weight: bold;
                  text-transform: uppercase;
                  color: $dark-600;
                }

                .metrics {
                  .metric-count {
                    font-size: 12px;
                    font-weight: bold;
                    text-transform: uppercase;
                  }
                  //Comp
                  .mastered {
                    color: $blue-mastery;
                  }

                  .in-progress {
                    color: $blue-in-progress;
                  }

                  .not-started {
                    color: $dark-600;
                    opacity: 0.5;
                  }
                }
              }
            }
          }

          &.scrollable-margin {
            margin-right: 8px;
          }
        }
      }
    }

    .performance-body-container {
      display: grid;
      grid-template-columns: 228px auto;
      position: relative;
      padding-bottom: 8px;
      max-height: calc(100vh - 280px);
      overflow-y: auto;

      .tab-container {
        min-height: 48px;
        line-height: 48px;
      }

      .students-container {
        padding-left: 15px;
        width: 224px;
        background-color: $white;

        .student-container {
          display: grid;
          grid-template-columns: 35px auto;
          align-items: center;
          cursor: pointer;

          .thumbnail-container {
            width: 30px;
            height: 30px;
            background-size: 30px 30px;
            border: none;
            display: block;
            border-radius: 50%;
            margin: 5px 0;
          }
          @media screen and (max-width: $screen-md) {
            .student-name {
              $font-size: 12px;
            }
          }

          .student-name {
            color: $dark-400;
            $font-size: 14px;
            $line-height: 1.3;
            text-transform: capitalize;
            $lines-to-show: 2;
            @include ellipsis(
              $font-size: $font-size,
              $line-height: $line-height,
              $lines-to-show: $lines-to-show
            );
          }
        }
      }

      .domains-performance-container {
        overflow: hidden;
        // margin: 0 97px;

        .domain-performance-container {
          display: flex;

          .tab-container {
            min-width: 224px;
            margin: 0 4px;
            display: grid;
            background-color: $white;
            height: 48px;
            padding: 0 15px;
            grid-template-columns: 134px auto;
            align-items: center;
          }

          .performance-chart-container {
            margin-right: 4px;

            &.disable-student-performance-chart {
              .student-domain-performance {
                .chart {
                  svg {
                    .fill-0,
                    .fill-1,
                    .fill-2,
                    .fill-3,
                    .fill-4,
                    .fill-5 {
                      fill: $gray-no-started;
                    }
                  }
                }
              }
            }

            .student-domain-performance {
              .chart {
                display: grid;
                align-items: center;
                grid-template-columns: auto;
              }
            }
          }

          .competency-count-container {
            font-size: 12px;
            font-weight: bold;
            line-height: 48px;
            height: inherit;
            //comp
            .mastered {
              color: $blue-mastery;
            }

            .in-progress {
              color: $blue-in-progress;
            }

            .not-started {
              color: $dark-600;
              opacity: 0.5;
            }
          }
        }
      }

      .scroll-controller {
        .left,
        .right {
          position: absolute;
          top: 0;
          cursor: pointer;
          width: 32px;
          background-color: $scroll-left-back-ground;
          border-radius: 50%;
          height: 32px;

          i {
            font-size: 25px;
            color: $white;
            text-align: center;
            line-height: 32px;
          }

          &.disabled {
            cursor: default;
            opacity: 0.5;
          }
        }

        .left {
          left: 228px;
          margin: 0 4px;
        }

        .right {
          right: 8px;
          margin-left: 4px;
        }
      }
      &::-webkit-scrollbar {
        height: 6px;
        width: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background: $gray-light;
        border: 1px solid $gray-light;
        border-radius: 5px;
      }
    }
    @media screen and (max-width: $screen-639) {
      .performance-header-container {
        min-height: unset;

        .proficiency-info {
          .subject {
            font-size: 24px;
            margin-top: 6px;
          }

          .students-count {
            margin-right: 0;

            .count-container {
              display: none;
            }
          }
        }

        .competency-coverage-container {
          grid-template-columns: auto;

          .course-coverage-container {
            display: none;
          }

          .domains-coverage-container {
            margin: 0;
            position: relative;

            .domain-coverage-count {
              min-width: 100vw;
              margin: 0;
              padding: 15px 50px;

              .domain-name {
                font-size: 15px;
                font-weight: bold;
                line-height: 1.07;
                color: $dark-600;
                text-align: center;
              }

              .coverage-count {
                .count-container {
                  margin: auto;
                }
              }

              .coverage-count {
                .competency-metrics {
                  display: none;
                }
              }
            }

            .scroll-controller {
              position: fixed;

              .left,
              .right {
                position: absolute;
                top: 40px;
                cursor: pointer;

                i {
                  font-size: 30px;
                  color: #405d66;
                  text-align: center;
                  line-height: 32px;
                }

                &.disabled {
                  cursor: default;
                  opacity: 0.5;
                }
              }

              .left {
                left: 8px;
              }

              .right {
                left: 88vw;
              }
            }
          }
        }
      }

      .performance-body-container {
        grid-template-columns: 150px auto;

        .students-container {
          width: 150px;

          .student-container {
            .student-name {
              font-size: 12px;
              font-weight: bold;
              line-height: 1.2;
              color: $placeholder-color;
            }
          }
        }

        .domains-performance-container {
          margin: 0;

          .domain-performance-container {
            .tab-container {
              min-width: calc(100vw - 150px);
            }
          }
        }
      }
    }
    @media screen and (min-width: $screen-1035) {
      .performance-header-container {
        .competency-coverage-container {
          .domains-coverage-container {
            width: 924px;
            justify-self: auto;
          }
        }
      }

      .performance-body-container {
        .domains-performance-container {
          width: 100%;
          justify-self: auto;
        }
      }
    }
    @media screen and (min-width: $screen-569) and (max-width: $screen-in-1024) {
      .performance-header-container {
        .competency-coverage-container {
          .domains-coverage-container {
            width: 100%;
            margin: auto !important;
          }
        }
      }

      .performance-body-container {
        .domains-performance-container {
          margin: auto !important;
        }
      }
    }
    @media screen and (min-width: $screen-md + 1) and (max-width: $screen-in-1024) {
      .performance-header-container {
        .competency-coverage-container {
          .domains-coverage-container {
            width: 698px;
          }
        }
      }

      .performance-body-container {
        .domains-performance-container {
          width: 100%;
        }
      }
    }
    @media screen and (min-width: $screen-669) and (max-width: $screen-md) {
      .performance-header-container {
        .competency-coverage-container {
          .domains-coverage-container {
            width: 464px;
          }
        }
      }

      .performance-body-container {
        .domains-performance-container {
          width: 100%;
        }
      }
    }
    @media screen and (min-width: $screen-569) and (max-width: $screen-sm) {
      .performance-header-container {
        .competency-coverage-container {
          .domains-coverage-container {
            width: 232px;
          }
        }
      }

      .performance-body-container {
        .domains-performance-container {
          width: 100%;
        }
      }
    }
    @media screen and (max-width: $screen-568) {
      .performance-body-container {
        overflow-x: hidden;
      }
    }
    @media screen and (max-width: $screen-320) {
      .performance-body-container {
        .domains-performance-container {
          .domain-performance-container {
            .tab-container {
              .competency-count-container {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
