.gru-rich-text-editor {
  .toolbar-container {
    position: relative;
    display: grid;
    .btn-toolbar,
    .upload-toolbar {
      position: relative;
      .btn.btn-math {
        margin-left: 8px;
        color: $white;
        background-color: $bg-blue-300-blue-500;
        border-right: 1px solid $white;
        font-weight: bold;
        font-size: $font-size-base;
        min-width: 35px;
        @include border-bottom-radius(0);
        padding: 4px 7px;
        &:last-of-type {
          border-right: 0;
        }
        &.wysihtml5-command-active,
        &.active {
          background-color: $blue-600;
        }
        .gru-icon {
          font-size: $font-size-large;
        }
      }
      .expressions-panel {
        clear: both;
        display: none;
        background-color: $white;
        margin-left: 5px;
        @include clearfix();
        &.show {
          display: block;
        }
        .equation-editor {
          @include flexbox;
          .equation-wrapper {
            display: inline-block;
            width: 38%;
            padding: 20px 10px 10px 10px;
            text-align: center;
            background-color: $blue-100;
            .advanced-equation-wrapper {
              .advanced-text-editor {
                width: 100%;
                a {
                  padding: 10px;
                }
              }
            }
            .math-editor {
              width: 100%;
              height: 56px;
              padding: 5px;
              text-align: $tab-float-dir;
              border-color: $gray-lighter;
              outline: none;
              background-color: $white;
              .mq-empty {
                background: $sky-100;
              }
              .mq-root-block {
                padding: 8px 2px 2px 2px;
              }
            }
            .hide-editor {
              visibility: hidden;
            }
            .actions {
              text-align: $menu-float-dir;
              padding: 15px 5px 5px 5px;
              &.invalid-symbol-text {
                display: flex;
                justify-content: space-between;
                float: none !important;
                color: red;
              }
              .btn {
                float: none;
                height: min-content;
              }
            }
          }
          .nav {
            white-space: nowrap;
            font-size: $font-size-small;
            border-bottom: 1px solid $gray-lighter;
            border-top: 1px solid $gray-lighter;
            li {
              &.active {
                a {
                  background-color: $gray-lighter;
                }
              }
              a {
                padding: 4px 10px;
                @include box-shadow(none);
                @include border-bottom-radius(0);
                @include border-top-radius(0);
              }
            }
          }
          .tab-content {
            display: inline-block;
            width: 65%;
            background-color: $blue-100;
            .tab-pane {
              border-left: 1px solid $white;
              margin: 10px 0px;
              .toolbar-2 {
                display: flex;
                align-items: center;
              }
              div {
                min-height: 50px;
                margin: 0 5px;
                a {
                  display: inline-block;
                  line-height: 1;
                }
              }
            }
            .fraction {
              background: url("gooru/images/math-icons/fraction.svg");
              background-size: 50px 50px;
              height: 50px;
              width: 50px;
            }
            .sqrt {
              background: url("gooru/images/math-icons/square-root.svg");
              background-size: 50px 50px;
              height: 50px;
              width: 50px;
            }
            .sqrtn {
              background: url("gooru/images/math-icons/square-root-magnitude.svg");
              background-size: 50px 50px;
              height: 50px;
              width: 50px;
            }
            .subscript {
              background: url("gooru/images/math-icons/sub-script.svg");
              background-size: 50px 50px;
              height: 50px;
              width: 50px;
            }
            .superscript {
              background: url("gooru/images/math-icons/super-script.svg");
              background-size: 50px 50px;
              height: 50px;
              width: 50px;
            }

            .overline {
              background: url("gooru/images/math-icons/overline.svg");
              background-size: 50px 50px;
              height: 50px;
              width: 50px;
            }
            .sum {
              background: url("gooru/images/math-icons/sum-sub-super.svg");
              background-size: 50px 50px;
              height: 50px;
              width: 50px;
            }
            .plus {
              background: url("gooru/images/math-icons/plus.svg");
              background-size: 45px 45px;
              height: 45px;
              width: 40px;
            }
            .dollar {
              width: 40px;
              display: block;
              text-align: center;
              color: $gray-dove;
              opacity: 0.8;
            }
            .minus {
              background: url("gooru/images/math-icons/minus.svg");
              background-size: 45px 45px;
              height: 45px;
              width: 40px;
            }
            .div {
              background: url("gooru/images/math-icons/div.svg");
              background-size: 45px 45px;
              height: 45px;
              width: 40px;
            }
            .mult {
              background: url("gooru/images/math-icons/mult.svg");
              background-size: 45px 45px;
              height: 45px;
              width: 30px;
            }
            .cdot {
              background: url("gooru/images/math-icons/cdot.svg");
              background-size: 45px 45px;
              height: 45px;
              width: 30px;
            }
            .not-equal {
              background: url("gooru/images/math-icons/not-equal.svg");
              background-size: 45px 45px;
              height: 45px;
              width: 40px;
            }
            .greater-equal {
              background: url("gooru/images/math-icons/greater-equal-than.svg");
              background-size: 45px 45px;
              height: 45px;
              width: 40px;
            }
            .less-equal {
              background: url("gooru/images/math-icons/less-equal-than.svg");
              background-size: 45px 45px;
              height: 45px;
              width: 40px;
            }
            .greater {
              background: url("gooru/images/math-icons/greater-than.svg");
              background-size: 45px 45px;
              height: 45px;
              width: 40px;
            }
            .less {
              background: url("gooru/images/math-icons/less-than.svg");
              background-size: 45px 45px;
              height: 45px;
              width: 40px;
            }
            .sim {
              background: url("gooru/images/math-icons/sim.svg");
              background-size: 45px 45px;
              height: 45px;
              width: 40px;
            }
            .approx {
              background: url("gooru/images/math-icons/approx.svg");
              background-size: 45px 45px;
              height: 45px;
              width: 30px;
            }
            .alpha {
              background: url("gooru/images/math-icons/alpha.svg");
              background-size: 45px 45px;
              height: 45px;
              width: 30px;
            }
            .pmatrix {
              background: url("gooru/images/math-icons/pmatrix.svg");
              background-size: 50px 50px;
              height: 50px;
              width: 40px;
            }
            .Bmatrix {
              background: url("gooru/images/math-icons/Bmatrix.svg");
              background-size: 50px 50px;
              height: 50px;
              width: 40px;
            }
            .vmatrix {
              background: url("gooru/images/math-icons/vmatrix.svg");
              background-size: 50px 50px;
              height: 50px;
              width: 40px;
            }
            .angle {
              background: url("gooru/images/math-icons/angle.svg");
              background-size: 50px 50px;
              height: 50px;
              width: 40px;
            }
            .measuredangle {
              background: url("gooru/images/math-icons/measured-angle.svg");
              background-size: 50px 50px;
              height: 50px;
              width: 40px;
            }
            .infinity {
              background: url("gooru/images/math-icons/infinity.svg");
              background-size: 50px 50px;
              height: 50px;
              width: 40px;
            }
            .bot {
              background: url("gooru/images/math-icons/bot.svg");
              background-size: 50px 50px;
              height: 50px;
              width: 40px;
            }
            .parallel {
              background: url("gooru/images/math-icons/parallel.svg");
              background-size: 50px 50px;
              height: 50px;
              width: 40px;
            }
            .sigma {
              background: url("gooru/images/math-icons/sigma.svg");
              background-size: 50px 50px;
              height: 50px;
              width: 40px;
            }
            .theta {
              background: url("gooru/images/math-icons/theta.svg");
              background-size: 50px 50px;
              height: 50px;
              width: 40px;
            }
            .pi {
              background: url("gooru/images/math-icons/pi.svg");
              background-size: 50px 50px;
              height: 50px;
              width: 40px;
            }
          }
        }
      }
      .dialog-create-link {
        display: grid;
        position: absolute;
        background-color: $white;
        top: 30px;
        left: 137px;
        box-shadow: 1px 1px 1px 1px;
        .input-container {
          display: grid;
          grid-template-columns: auto auto;
          align-items: center;
          column-gap: 5px;
          margin: 3px;
          input {
            outline: none;
            border: none;
            border-bottom: 1px solid gray;
            &:focus {
              outline: none;
              border: none;
              border-bottom: 1px solid $blue-300;
            }
          }
        }
        .action-container {
          margin: 3px;
          display: grid;
          grid-template-columns: auto auto;
          align-items: center;
          justify-content: space-around;
          a {
          }
        }
      }
    }
    .upload-toolbar {
      position: absolute;
      left: 405px;
      width: 250px;
      p {
        margin: 0;
      }
    }
  }
  .wysihtml-editor-lable {
    color: $blue-400;
    font-weight: 600;
    margin-top: 5px;
    margin-left: 14px;
  }
  .rich-editor {
    min-height: 250px;
    border: 1px solid $gray-lighter;
    padding: 10px;
    outline: none;
    overflow-y: auto;
    word-wrap: break-word;
    &:focus {
      box-shadow: inset 0 -2px 0 $blue-500;
    }
    .gru-math-expression {
      .katex {
        cursor: pointer;
      }
    }
    &.not-editable {
      .gru-math-expression {
        .katex {
          cursor: text;
        }
      }
    }
  }
  .border-bottom {
    border-bottom: 1px solid $white;
  }
}
