.student-comptency-progress {
  .completed {
    width: 12px;
    background-color: #5d93d9;
  }

  .in-progress {
    width: 12px;
    background-color: #a8d4e4;
  }

  .not-started {
    width: 12px;
    background-color: #cdd2d6;
  }
}
