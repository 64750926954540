.teacher {
  .content {
    .course-map {
      .course-map-container {
        .course-map-body {
          .render-course-map {
            .student-coursemap {
              .gru-learner-accordion-unit {
                .gru-learner-accordion-lesson {
                  .panel-body {
                    .collections {
                      .assessment,
                      .collection {
                        .content-details-view {
                          .attempt-head {
                            .attempt-content {
                              .attempt {
                                display: grid;
                                padding: 10px 0 10px 15px;
                                width: 93% !important;
                                border-bottom: 1px solid $info-icon-bg;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.gru-learner-accordion-lesson {
  $on-air-icon: 25px;
  $collection-icon-size: 48px;
  height: auto;
  list-style-type: none;
  background: $white;
  margin-top: 0;

  &:first-child {
    .panel-heading {
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
    }
  }

  &:last-child {
    .panel-heading {
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }

  .img-rounded {
    display: inline-block;
    width: 47px;
    height: 36px;
    border-radius: 5px;
  }

  .panel-heading {
    box-shadow: 0 2px 5px 0 $disabled-bg-color;
    display: inline-block;
    width: 100%;
    height: 67px;
    background: $white;
    border-radius: 0;
    padding: 0;

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }

    .controls {
      width: 95px;
      padding: 15px 0;
      margin: 0 20px 0 10px;
      display: inline-block;
    }

    .panel-title {
      width: 55%;
      float: $tab-float-dir;
      font-size: $font-size-large;
      color: $gray;
      padding: 12px 8px;

      .title {
        vertical-align: middle;
        margin: 0;
        padding: 0;

        .lesson-prefix {
          border-right: solid 1px $light-300;
          margin: 0 20px 0 0;
          padding: 5px 20px 0 0;
          color: $gray-base;
          font-weight: bold;
          height: inherit;
          display: inline-block;
          position: relative;
          &.lesson-status {
            &::before {
              content: "";
              position: absolute;
              right: -11px;
              width: 8px;
              top: 5px;
              height: 8px;
              border-radius: 50%;
            }
            &.status-0::before {
              background-color: $not-started;
            }
            &.status-1::before {
              background-color: $blue-in-progress;
            }
            &.status-2::before,
            &.status-3::before,
            &.status-4::before,
            &.status-5::before {
              background-color: $blue-mastery;
            }
          }
        }
      }

      span {
        font-size: 16px;
      }

      .lessonPlan {
        font-size: 13px;
        padding-left: 90px;
        margin-left: 14px;

        .guiding-lable {
          margin-top: 8px;
        }
      }

      .unavailable-content {
        font-size: 13px;
        margin-left: 13px;
      }
    }

    .info {
      float: $menu-float-dir;
      height: inherit;

      .content-count {
        font-size: $font-navigator;
        margin: 0 10px 0 0;
        color: $light-400;
        display: inline-block;
        padding: 11px;

        span {
          vertical-align: middle;

          i {
            display: inline-block;
            margin: 0 10px 0 0;
            vertical-align: middle;
            color: $light-200;
            font-size: $font-unBookmark;
            cursor: default;
          }

          &.question-count {
            i.icon {
              background: url("gooru/question-icon.png");
              -webkit-background-size: 25px 25px;
              background-size: 25px 25px;
              height: 25px;
              width: 25px;
            }
          }

          &.assessment-count {
            margin: 0 0 0 15px;

            i.icon {
              background: url("gooru/images/navigation_assessment.svg");
              -webkit-background-size: 25px 25px;
              background-size: 25px 25px;
              height: 25px;
              width: 25px;
            }
          }

          &.collection-count {
            i.icon {
              background: url("gooru/images/navigation_collection.svg");
              -webkit-background-size: 25px 25px;
              background-size: 25px 25px;
              height: 25px;
              width: 25px;
            }
          }
        }
      }

      .play-btn {
        border-left: 1px solid $light-100;
        display: inline-block;
        height: 67px;
        width: 60px;

        .btn.study-now {
          background-color: $white;
          font-size: $font-size-h6;
          height: 45px;
          margin: 10px 0;
          vertical-align: middle;

          i {
            font-size: $font-size-h4;
            color: $dark-300;
          }
        }
      }

      .performance-container {
        width: 76px;
        display: flex;
        height: 67px;
        vertical-align: middle;
        align-items: center;
        cursor: pointer;

        &.not-started {
          pointer-events: none;
        }

        .container-box {
          height: 67px;

          .percentage {
            cursor: pointer;
          }
        }
        .timespend {
          border-left: 1px solid $light-100;
          display: inline-block;
          height: 67px;
          width: 88px;
          margin: 0;
          vertical-align: middle;
          padding: 10px 5px 0;
          text-align: center;
          font-weight: 900;
          float: $menu-float-dir;
          line-height: normal;
          font-size: larger;

          .total-time-spent {
            font-size: 10px;
          }
        }
      }
    }

    .assessment-external-icons,
    .collection-external-icons {
      width: 17px;
      height: 17px;
      background-size: 17px 17px;
      opacity: 0.8;
      justify-self: center;
      background-image: url("../assets/gooru/share.png");
      background-repeat: no-repeat;
      margin: auto 15px;
    }

    .class-info {
      margin-top: 20px;
      position: relative;
      right: 10px;

      &.disable-tag {
        pointer-events: none;
        opacity: 0.5;
        a {
          color: $light-400;
          opacity: 0.5;
        }
      }

      .close {
        font-size: 24px;
      }
      @media screen and (max-width: $screen-sm) {
        display: none;
      }
    }
  }

  .panel-collapse.collapse.in {
    .collections {
      list-style-type: none;
      padding-left: 0;

      > .panel {
        margin: 0;
        padding: 0;
        border-bottom: 1px solid $light-100;
        height: 67px;
        &.content-details {
          height: auto;
        }
        button.on-air {
          display: inline-block;
          width: $on-air-icon;
          height: $on-air-icon;
          vertical-align: middle;
          background-image: url("gooru/on-air-icon.png");
          background-size: $on-air-icon $on-air-icon;
          background-color: $white;
          cursor: pointer;
          @include text-hide();
        }

        &:last-child {
          border-bottom: 0 none;

          .panel-heading {
            border-bottom: 0 none;
            border-radius: 0 0 5px 5px;
          }

          &.attempt-head {
            border-radius: 0;
          }
        }

        &:first-child {
          .panel-heading {
            -webkit-box-shadow: inset 0 2px 5px 0 $disabled-bg-color;
            -moz-box-shadow: inset 0 2px 5px 0 $disabled-bg-color;
            box-shadow: inset 0 2px 5px 0 $disabled-bg-color;
          }
        }

        &.resource {
          .panel-heading {
            background-color: $sky-50;
            height: 67px;

            .panel-title {
              width: 80%;

              .resource-icon-type {
                padding-left: 32px;
              }

              .icon {
                line-height: 67px;

                i {
                  width: 36px;
                  height: 30px;
                  background-size: 30px;
                  background: no-repeat;
                  display: inline-block;
                  vertical-align: middle;
                }
              }

              .icon {
                .website_resource {
                  background-image: url("gooru/images/website-resource-green.svg");
                }

                .video_resource {
                  background-image: url("gooru/images/video-resource-green.svg");
                }

                .interactive_resource {
                  background-image: url("gooru/images/interactive-resource-green.svg");
                }

                .image_resource {
                  background-image: url("gooru/images/image-resource-green.svg");
                }

                .text_resource {
                  background: url("gooru/images/text-resource-green.svg")
                    no-repeat;
                  width: 30px;
                  height: 30px;
                  background-size: 30px;
                  display: inline-block;
                  vertical-align: middle;
                }

                .audio-resource {
                  background-image: url("gooru/images/audio-resource-green.svg");
                }
              }

              .title {
                padding-left: 5px;
              }
            }
          }
        }
      }

      .panel-heading {
        width: 100%;
        height: 67px;
        display: inline-block;
        border-radius: 0;

        &.disabled {
          pointer-events: none;
          opacity: 0.5;
        }

        &.attempt-head {
          height: auto;

          .attempt-content {
            border-radius: 0;
            box-shadow: none;
            display: flex;
            border-bottom: 1px solid $white;
            cursor: pointer;
          }

          .info {
            background-color: $info-icon-bg;

            .performance {
              padding: 0;
              justify-content: center;
              display: grid;
              align-items: center;
              height: 55px;
              border-bottom: 1px solid $white;
            }
          }
          .info-assessment {
            text-align: center;
            display: grid;
            align-items: center;
            width: 70px;
          }
        }

        .controls {
          width: 95px;
          padding: 15px 0;
          margin: 0 20px 0 10px;
          display: inline-block;

          .switch {
            width: 50px;
            display: inline-block;
          }

          .disabled {
            opacity: 0.5 !important;
            pointer-events: none;
          }

          .go-live-cnt {
            width: 25px;
            margin: 0 15px 0 0;
            display: inline-block;
          }
        }

        .icon-container {
          height: inherit;

          i {
            font-size: $collection-icon-size;
            vertical-align: bottom;
          }

          .score {
            margin: 5px 0 10px;

            + i {
              display: none;
              width: $on-air-icon;
              height: $on-air-icon;
              vertical-align: middle;
              margin-left: 15px;
              background-image: url("gooru/images/on-air-icon.svg");
              background-size: $on-air-icon $on-air-icon;
              @include text-hide();
            }
          }

          a.score {
            padding: 0;
          }
        }

        .panel-title {
          width: 65%;
          float: $tab-float-dir;
          height: inherit;
          color: $gray;
          padding: 0;
          font-size: $font-size-large;

          &.attempt {
            display: grid;
            padding: 10px 0 10px 15px;
            width: 92%;
            border-bottom: 1px solid $info-icon-bg;

            .attempt-title {
              font-size: 11px;
              font-weight: 700;
              font-weight: bold;
            }

            .attempt-date {
              font-size: 14px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.14;
              letter-spacing: normal;
            }
          }

          .collection-controls {
            height: 100%;
            text-align: center;

            .row {
              display: table-cell;
              height: 100%;

              & > [class*="col-"] {
                display: table-cell;
                height: 100%;
                padding: 0;

                .gru-switch {
                  position: relative;
                  top: 50%;
                  -webkit-transform: translateY(-50%);
                  -ms-transform: translateY(-50%);
                  transform: translateY(-50%);
                }
              }
            }
          }

          .icon-container {
            padding: 0;
            width: 95px;
            height: inherit;
            display: inline-block;
            position: relative;

            &.lesson-status {
              &::before {
                content: "";
                position: absolute;
                right: -15px;
                width: 8px;
                top: 0px;
                height: 8px;
                border-radius: 50%;
              }
              &.status-0::before {
                background-color: $not-started;
              }
              &.status-1::before {
                background-color: $blue-in-progress;
              }
              &.status-2::before,
              &.status-3::before,
              &.status-4::before,
              &.status-5::before {
                background-color: $blue-mastery;
              }
            }

            img {
              width: inherit;
              height: inherit;
            }
          }

          .title {
            vertical-align: middle;
            margin: 0;
            padding: 5px 0 0 5px;
            max-width: 75%;

            .type {
              color: $light-500;
              font-size: $font-navigator;
              padding: 5px 0 0;
            }

            i {
              font-size: 28px;
              margin-left: 5px;

              &.teacher_suggestions {
                background-image: url("gooru/images/account-cirlce-orange.svg");
                width: 28px;
                height: 28px;
                background-size: 28px;
              }
            }

            &.system_suggestions,
            &.teacher_suggestions {
              display: inline-flex;
              color: $orange-400;
            }
          }

          .typeCollection {
            margin-top: -15px;
          }

          .trophy {
            vertical-align: bottom;
            margin: 0 0 0 15px;

            i {
              background: url("gooru/trophy.png");
              background-size: 22px 25px;
              height: 25px;
              width: 22px;
              display: inline-block;
              vertical-align: middle;
            }
          }
        }

        .check {
          color: $green-400;
        }

        .disabled {
          opacity: 0.5 !important;
          pointer-events: none;
        }

        .info {
          float: $menu-float-dir;
          height: inherit;
          display: flex;
          .attempt-count {
            margin: 20px;
            cursor: pointer;
          }

          .share {
            width: 18px;
            height: 15px;
            object-fit: contain;
          }

          .onshareenabled {
            padding-right: 32px;
          }

          .onshareenabledadddca {
            padding-left: 65px;
          }

          .content-count {
            font-size: $font-navigator;
            margin: 0;
            color: $light-400;
            display: inline-block;

            span {
              vertical-align: middle;
              margin: 0 5px 0 0;

              i {
                display: inline-block;
                margin: 0 10px 0 0;
                vertical-align: middle;
                color: $light-200;
                font-size: $font-unBookmark;
                cursor: default;
              }

              &.question-count {
                i.icon {
                  background: url("gooru/images/navigation_question.svg");
                  -webkit-background-size: 25px 25px;
                  background-size: 25px 25px;
                  height: 25px;
                  width: 25px;
                }
              }

              &.assessment-count {
                i.icon {
                  background: url("gooru/images/navigation_assessment.svg");
                  -webkit-background-size: 25px 25px;
                  background-size: 25px 25px;
                  height: 25px;
                  width: 25px;
                }
              }

              &.collection-count {
                margin: 0 0 0 15px;

                i.icon {
                  background: url("gooru/images/navigation_collection.svg");
                  -webkit-background-size: 25px 25px;
                  background-size: 25px 25px;
                  height: 25px;
                  width: 25px;
                }
              }
            }
          }

          .play-btn {
            border-left: 1px solid $light-100;
            display: inline-block;
            height: 67px;
            width: 60px;

            .btn.study-now {
              background-color: $white;
              font-size: $font-size-h6;
              height: 45px;
              margin: 10px 0;
              vertical-align: middle;

              i {
                font-size: $font-size-h4;
                color: $dark-300;
              }
            }
          }

          .performance {
            border-left: 1px solid $light-100;
            display: inline-block;
            height: 67px;
            width: 75px;
            margin: 0;
            vertical-align: middle;
            padding: 20px 5px 0;
            text-align: center;
            font-weight: 900;
            cursor: pointer;

            .grading-icon {
              background-image: url("../assets/gooru/images/grade-pending.png");
              width: 22px;
              height: 22px;
              background-size: 22px;
              background-position: center;
              background-repeat: no-repeat;
              display: block;
              align-self: center;
              margin: auto;
              cursor: pointer;
            }

            &.performance-not-started {
              cursor: default;
            }

            span {
              vertical-align: middle;
            }

            .score {
              font-size: $font-size-large;
              line-height: $font-size-large;
            }

            .state {
              color: $dark-300;
              font-size: $font-size-xxxsmall;
              display: block;
            }

            .time-spent {
              font-size: $font-size-base;
              color: $light-450;
            }

            .not-started {
              color: $light-450;
              font-size: $font-size-small;
            }

            .not-started-external {
              font-size: 18px;
            }

            &.non-clickable {
              pointer-events: none;
            }
          }
        }
      }

      .content-details-view {
        box-shadow: 0 0 11px 3px $green-400;
        z-index: 9;
        .attempt-head {
          &.no-attempt {
            padding-bottom: 5px;
          }
        }
        .item-details {
          display: flex;

          .count-details {
            display: none;
            @media screen and (max-width: $screen-sm) {
              display: flex;
            }

            .content-count {
              font-size: $font-navigator;
              margin: 10px 10px 0 0;
              color: $light-400;
              display: flex;
              align-items: center;

              span {
                vertical-align: middle;
                color: $gray-dove;

                i {
                  display: inline-block;
                  margin: 0 10px 0 0;
                  vertical-align: middle;
                  color: $gray-dove;
                  font-size: $font-unBookmark;
                  cursor: default;
                }

                &.question-count {
                  i.icon {
                    background: url("gooru/question-icon.png");
                    -webkit-background-size: 25px 25px;
                    background-size: 25px 25px;
                    height: 25px;
                    width: 25px;
                  }
                }

                &.assessment-count {
                  margin: 0 0 0 15px;

                  i.icon {
                    background: url("gooru/images/navigation_assessment.svg");
                    -webkit-background-size: 25px 25px;
                    background-size: 25px 25px;
                    height: 25px;
                    width: 25px;
                  }
                }

                &.collection-count {
                  i.icon {
                    background: url("gooru/images/navigation_collection.svg");
                    -webkit-background-size: 25px 25px;
                    background-size: 25px 25px;
                    height: 25px;
                    width: 25px;
                  }
                }
              }
            }
          }

          .taxonomy-container {
            display: none;

            .gru-taxonomy-tag {
              min-width: 90px;
              max-width: 100px;
              height: 23px;
              border-radius: 12px;
              background-color: $dark-400;
              display: inline-block;
              border: 1px solid $gray-light;
              padding: 0 3px 2px;

              .standard-tag {
                text-align: center;
                padding: 0;

                .tag-label {
                  font-size: 12px;
                  color: $white;
                  line-height: 21px;
                  justify-content: center;
                  align-items: center;
                  display: block;
                }
              }
            }
          }
        }

        .description {
          p {
            font-size: 14px;
            font-weight: normal;
            line-height: 1.33;
            letter-spacing: normal;
            margin: 20px 15px 5px;
            color: $dark-400;
          }
        }
      }

      .no-content {
        margin: 5px 10px 10px;
      }
    }
  }

  &.expanded {
    height: auto;

    .panel-collapse.collapse.in {
      margin: -6px 0;

      .panel-body {
        padding: 0;
        margin-bottom: 14px;
      }

      .collections {
        .selected,
        .study-active {
          .panel-heading {
            -webkit-box-shadow: 0 0 11px 3px $green-box-shadow-75;
            -moz-box-shadow: 0 0 11px 3px $green-box-shadow-75;
            box-shadow: 0 0 11px 3px $green-box-shadow-75;
            z-index: 9;

            &.attempt-head {
              height: auto;
            }

            .attempt-content {
              box-shadow: none;
              border-radius: 0;
              display: flex;
              border-bottom: 1px solid $white;
              cursor: pointer;

              .info {
                background-color: $info-icon-bg;

                .performance {
                  padding: 0;
                  justify-content: center;
                  display: grid;
                  align-items: center;
                }
              }
              .info-assessment {
                text-align: center;
                display: grid;
                align-items: center;
                width: 70px;
              }
            }

            .info {
              .btn.study-now {
                i {
                  font-size: $font-size-anonymous;
                  color: $green-400;
                }
              }
            }
          }
        }
      }
    }

    &:last-child .panel-heading {
      border-radius: 0;
    }
  }
}

.panel {
  margin-top: 0 !important;
}
