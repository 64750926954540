.library {
  .container {
    padding-top: 50px;
    background: $light-ps;
    width: 100%;
    min-height: calc(100vh - 50px);

    .card-container {
      display: grid;
      grid-template-columns: auto auto;
      grid-gap: 20px;
      justify-content: center;

      &.cab-container {
        grid-template-columns: auto auto auto;
      }

      .card-panel {
        width: 200px;
        height: 190px;
        border-radius: 4px;
        background-color: $white-bold;
        text-align: center;
        padding: 10px;
        justify-content: center;
        align-items: center;
        display: grid;

        .title {
          font-size: 14px;
          font-weight: bold;
          line-height: 0.94;
          text-align: center;
          color: $black-bold;
        }

        .image {
          background-repeat: no-repeat;
          background-size: contain;
          width: 155px;
          height: 100px;
          background-position: center;
          margin-left: auto;
          margin-right: auto;

          &.my-content {
            background-image: url("gooru/my-content.png");
          }

          &.catalog {
            background-image: url("gooru/images/ps-contentNav-sm-logo.svg");
          }
        }
      }
    }

    .partner-libraries {
      .heading {
        color: $black-bold;
        padding-left: 35px;
        font-size: 16px;
        text-transform: uppercase;
        font-weight: bold;
      }

      .libraries-container {
        display: grid;
        grid-gap: 20px;
        grid-template-columns: repeat(3, 1fr);
      }

      .no-record {
        margin-top: 25px;
        text-align: center;
        font-size: 15px;
        font-weight: bold;
        color: $white;
      }

      .show-more-button {
        padding: 10px 0;
        text-align: center;
        .show-more {
          font-size: 16px;
          color: $white;
          cursor: pointer;
          font-weight: bold;
        }
      }
    }
  }
}
