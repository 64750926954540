.new-cards.gru-activity-grading-card {
  &:last-child {
    box-shadow: 0 0 6px 1px $table-box-shadow-07;
    border-bottom: 0px;
  }
  &:first-child {
    box-shadow: 0 0 6px 1px $table-box-shadow-07;
  }
  display: flex;
  justify-content: center;
  width: 100%;
  border-bottom: 2px solid $breadcrumb-color;
  .activity-grading-card {
    display: grid;
    background-color: $white;
    width: 100%;
    grid-template-rows: auto auto;
    &.content-not-found {
      display: none;
    }
    .grading-header-container {
      cursor: pointer;
      display: grid;
      grid-template-columns: 40px 1fr auto;
      align-items: center;
      .content-icon {
        display: grid;
        width: 100%;
        justify-content: center;
        .grading-icon {
          width: 22px;
          height: 22px;
          background-size: 22px;
          display: block;
          background-position: center;
          background-repeat: no-repeat;
          display: grid;
          align-self: center;
        }
      }
      .content-title {
        font-size: 13px;
      }
      .grading-info {
        display: grid;
        grid-template-columns: 50px 50px;
        grid-gap: 3px;
        .class-list {
          display: flex;
          align-items: center;
          cursor: pointer;
        }
        .content-info {
          padding: 0 5px;
          align-items: center;
          display: flex;
          .question-info {
            display: grid;
            grid-template-columns: auto 1fr;
            column-gap: 5px;
            align-items: center;
            .content-title {
              font-size: 14px;
              font-weight: bold;
            }
          }
          .task-info {
            display: grid;
            grid-template-columns: auto 1fr;
            column-gap: 5px;
            align-items: center;
            .task-thumbnail {
              width: 22px;
              height: 22px;
              background-size: 22px;
              display: block;
              background-position: center;
              background-repeat: no-repeat;
              background-image: url("../assets/gooru/images/text-resource-dark.svg");
            }
          }
        }
        .content-thumbnail {
          width: 50px;
          height: 50px;
          background-color: $table-bg;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
      &.disable-header {
        pointer-events: none;
      }
    }
    .grading-body-container {
      border-top: 1px solid $border-box-shadow;
      border-left: 10px solid $border-box-shadow;
      .grading-classes-container {
        .grading-class-container {
          padding: 5px;
          border-bottom: 1px solid $border-box-shadow;
          display: grid;
          grid-template-columns: 1fr 45px auto;
          column-gap: 5px;
          align-items: center;
          .class-title {
            color: $dark-400;
            padding-left: 5px;
            font-size: 14px;
            line-height: 1.3;
          }
          .student-count {
            display: grid;
            grid-template-columns: auto auto;
            align-items: center;
            column-gap: 2px;
          }
        }
      }
    }
    .grading-thumbnail {
      width: 25px;
      height: 25px;
      background-size: 25px;
      display: block;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url("../assets/gooru/images/add_rubric.svg");
    }
    &.question-grading {
      .grading-header-container {
        .content-icon {
          .grading-icon {
            background-image: url("../assets/gooru/images/add_rubric.svg");
          }
        }
      }
    }
    &.offline-activity-grading {
      .grading-header-container {
        .content-icon {
          .grading-icon {
            background-image: url("../assets/gooru/images/offline.svg");
          }
        }
      }
    }

    .activation-date {
      float: right;
    }
  }
}
