.dca-teacher-external-collection-report-list-view {
  position: relative;

  .list-view-container {
    .table-fixed-left {
      border-collapse: collapse;
      width: 356px;
      float: left;
      overflow-x: auto;
      white-space: nowrap;
      text-align: left;
      z-index: 2;

      table {
        width: 100%;
        table-layout: fixed;
        word-break: break-all;

        th {
          font-weight: normal;
          border-right: 1px dashed $gray-80;
          border-left: 1px solid $gray-80;
          height: 40px;
          border-bottom: 1px solid $gray-80;
          overflow: hidden;

          &.tabular-header {
            position: relative;
            background-color: $white;
            border-bottom: 0;
          }

          .header-filters {
            height: 40px;
            display: flex;
            border-bottom: 1px solid $gray-80;

            .name-filter {
              height: 40px;
              width: 305px;
              padding-top: 9px;
              display: flex;
              margin-left: 45px;

              .first-header,
              .last-header,
              .name-header {
                margin-right: 10px;
              }

              .first-header,
              .last-header {
                display: flex;
                opacity: 0.5;
                cursor: pointer;

                &.active {
                  opacity: 1;
                }
              }
            }

            .timeSpent {
              width: 70px;
              height: 40px;
              padding-top: 4px;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              opacity: 0.5;
              &.active {
                opacity: 1;
              }
              i {
                margin-right: 10px;
              }
            }
          }
        }

        .user-profile-summary {
          display: flex;
          height: 45px;

          .user-profile-details {
            width: 305px;
            display: flex;
            align-items: center;
            cursor: pointer;

            &.not-started {
              pointer-events: none;
            }

            .user-profile-img {
              width: 43px;

              img {
                width: 29px;
                border-radius: 50%;
                margin-left: 7px;
                border-color: $table-bg;
                height: 29px;
              }
            }

            .username {
              width: 200px;
              @include ellipsis(
                $font-size: 13px,
                $line-height: 1.2,
                $lines-to-show: 1
              );
            }
          }
        }

        .timeSpent-details {
          width: 70px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .external-collection-link {
    text-align: left;
    width: 360px;
    font-size: 15px;
    margin: 60px 0;
    float: right;
    color: $dark-400;
    .note {
      margin-bottom: 8px;
    }
    .link {
      color: $link-blue-color;
    }
  }
}
