.modals.gru-user-consent-modal {
  border-radius: 10px;
  box-shadow: 0 0 4px 3px $constant-modal;
  background: $white;
  border: none;
  width: 75%;
  margin: 30px auto;

  .modal-body {
    padding: 30px 20px;
    background: none;
    border: none;

    p {
      line-height: 1.3;
      font-weight: normal;
      margin-top: 10px;
      margin-bottom: 10px;
      font-size: 14px;

      &.personal-info {
        font-size: 16px;
        color: $gray;
      }
    }
  }

  .user-agree {
    text-align: center;
    height: 50px;
    border-top: solid 1px $gray-light;

    .proceed-msg {
      display: grid;
      grid-template-columns: 1fr 1fr;
      .proceed-btn {
        font-size: 15px;
        font-weight: bold;
        color: $white;
        text-transform: capitalize;
        padding-top: 13px;
        cursor: pointer;
        height: 51px;

        &.agree {
          border-bottom-right-radius: 10px;
          background: $blue-400;
        }
        &.not-agree {
          border-bottom-left-radius: 10px;
          color: $gray-light;
        }
      }
    }

    .confirm-msg {
      display: block;
      .confirm-btn {
        font-size: 15px;
        font-weight: bold;
        color: $white;
        text-transform: capitalize;
        padding-top: 13px;
        cursor: pointer;
        background: $blue-400;
        height: 51px;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
      }
    }
  }
}
