.ps-secondary-button {
  background: $white-bold;
  border: 1px solid $ps-blue-db;
  border-radius: 4px;
  padding: 4px 12px;
  color: $ps-blue-ef;
  box-shadow: none;
  &:hover {
    background: $ps-white-fd;
    color: $ps-blue-a2;
  }
  &:focus {
    background: $ps-white-fd;
    color: $ps-blue-a2;
    box-shadow: 0px 0px 0px 1px $white-bold, 0px 0px 0px 2px $ps-blue-ef,
      0px 0px 0px 4px $ps-blue-ff;
  }
  &:disabled {
    background: $ps-grey-74;
    color: $ps-grey-74;
  }
}

.ps-primary-button {
  background: $ps-blue-ef;
  border: 1px solid $ps-grey-74;
  border-radius: 4px;
  padding: 4px 12px;
  color: $white-bold;
  &:hover {
    box-shadow: none;
    background: $ps-blue-a2;
    color: $white-bold;
  }
  &:focus {
    background: $ps-blue-a2;
    color: $white-bold;
    box-shadow: 0px 0px 0px 1px $white-bold, 0px 0px 0px 2px $ps-blue-ef,
      0px 0px 0px 4px $ps-blue-ff;
  }
  &:disabled {
    box-shadow: none;
    background: $ps-grey-74;
    color: $ps-grey-74;
  }
}
