.questions.answers.gru-say-out-loud {
  padding: 10px 0;
  margin-top: -15px;
  position: relative;
  .answer-legend {
    position: absolute;
    bottom: 100%;
    right: 0;
    font-size: $font-size-large;
    text-align: center;
    span {
      display: inline-block;
      width: 2.7rem;
      @media (min-width: $screen-xs) {
        width: 3.5rem;
      }
      @media (min-width: $screen-sm) {
        width: 4.4rem;
      }
    }
  }
  .panel {
    margin-bottom: 15px !important;
    .answer-content {
      @include flexbox();
      @include justify-content(space-between);
      @include align-items(center);
      > div,
      button {
        display: inline-block;
      }
      .letter-container {
        margin: 0 20px 0 0;
        min-width: 4em;
        text-align: center;
        @include align-self(center);
        border-right: 1px solid $gray-light;

        h3 {
          padding: 0;
        }
      }
      .text-write-container {
        width: 100%;
        display: flex;
      }
      .text-area-container {
        @include flex();
        width: auto;
        margin-top: 5px;
        align-items: center;
        display: grid;
        .btn-toolbar {
          max-width: 40em;
          @media (min-width: $screen-sm-min) {
            max-width: 43em;
          }
          @media (min-width: $screen-lg-min) {
            max-width: 59em;
          }
        }
        .gru-rich-text-editor {
          .rich-editor {
            min-height: 3.2em;
            max-height: 6.9em;
            margin: 10px 20px 10px 0;
            border-left: 0;
            border-right: 0;
            border-top: 0;
            max-width: 24em;
            @media (min-width: $screen-sm-min) {
              max-width: 26em;
            }
            @media (min-width: $screen-lg-min) {
              max-width: 43em;
            }
            &.editable {
              max-width: 40em;
              @media (min-width: $screen-sm-min) {
                max-width: 43em;
              }
              @media (min-width: $screen-lg-min) {
                max-width: 59em;
              }
            }
          }
        }
        p {
          margin: 10px 0 10px 10px;
        }
      }
      .second-text-edit {
        align-items: center;
        display: flex;
        margin-bottom: 10px;
        letter-spacing: 12px;
        p {
          font-size: 21px;
          margin: 10px 0 0px 10px;
        }
      }
      .delete {
        outline: none;
        padding: 10px;
        cursor: pointer;
        border: 0;
        background: $white;
      }
      &.view-mode {
        @include justify-content(flex-start);
        .answer-text {
          overflow: auto;
          word-wrap: break-word;
          .gru-math-text {
            padding: 10px 0;
            display: block;
          }
        }
      }
    }
    .serp-hl-text-remove {
      position: absolute;
      width: 15px;
      height: 15px;
      top: -9px;
      right: 0;
      border: 1px solid;
      border-radius: 50%;
      background-color: $black-bold;
      i {
        font-size: 12px;
        color: $white;
        position: absolute;
        text-align: center;
        line-height: 15px;
        font-weight: bold;
      }
    }
  }
  .max-length-error {
    * {
      color: $orange-400;
    }
  }
  .add-answer {
    margin-bottom: 10px;
    a {
      cursor: pointer;
      border: 2px dashed $gray-light;
      border-radius: 6px;
      margin-top: 10px;
      color: $gray;
      display: block;
      width: 100%;
      padding: 20px;
      text-align: center;
    }
  }

  .serp-hl-text {
    position: relative;
    z-index: 99;
    cursor: default;
    &.underline {
      border-bottom: 1px solid gray;
      font-weight: bold;
      margin-right: 10px;
      .serp-hl-text-remove {
        bottom: 17px;
        right: 0;
        top: unset;
      }
    }
  }
}
