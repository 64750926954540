.content.questions.gru-question-play {
  iframe {
    height: 100vh;
  }
  .player {
    margin: 200px 10px 10px 10px;
    .gru-question-viewer {
      .question-panel,
      .answers-panel {
        width: 100%;
        margin-left: 0;
        @media (min-width: $screen-md) {
          width: 437px;
        }
      }
      .answers-panel {
        .actions,
        p.instructions {
          display: none;
        }
        @media (min-width: $screen-md) {
          margin-left: 24px;
        }
      }
      @media (min-width: $screen-md) {
        @include flexbox();
        @include justify-content(center);
      }
    }
  }
}
