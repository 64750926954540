.offline-activity-player.gru-offline-activity-submission {
  height: 100%;
  .offline-activity-submission-container {
    padding: 30px;
    @media screen and (max-width: $screen-sm - 1) {
      padding: 5px;
      padding-bottom: 30px;
    }
    .left-panel {
      .offline-activity-info-container {
        background-color: $white;
        padding: 15px;
        box-shadow: 0 2px 4px 0 $navbar-default-toggle-icon-bar-bg;
        margin-bottom: 15px;

        .offline-activity-title-container {
          display: grid;
          grid-template-columns: 30px 1fr;

          .icon-container {
            width: 20px;
            height: 20px;
            background-size: 20px;
            background-image: url("./gooru/images/offline.svg");
            display: block;
          }

          .title-container {
            font-size: 12px;
            color: $dark-400;
          }
        }

        .offline-activity-description-container {
          font-size: 14px;
          color: $placeholder-color;
        }
      }

      .oa-references-container {
        background-color: $white;
        margin-bottom: 15px;
        padding: 15px;
      }

      .offline-activity-timespent {
        background-color: $white;
        margin-bottom: 15px;
        padding: 15px;
        .timespent-info-container {
          display: grid;
          grid-template-columns: 30px 1fr 48px;
          cursor: pointer;
          .icon-container {
            i.schedule {
              line-height: 48px;
            }
          }
          .label-container {
            display: grid;
            align-items: center;
            .title-container {
              font-size: 14px;
              color: $placeholder-color;
              font-weight: bold;
            }
            .description-container {
              font-size: 12px;
              color: $placeholder-color;
            }
          }
          .toggle-container {
            cursor: pointer;
            width: 48px;
            height: 48px;
            justify-self: center;
            text-align: center;
          }
        }
        .timespent-container {
          display: grid;
          grid-template-columns: auto 96px;
          .gru-timepicker-card {
            .timepicker-container {
              .hour-picker,
              .minute-picker {
                .up-arrow {
                  i {
                    font-size: 40px;
                  }
                }
                .down-arrow {
                  display: none;
                }
                .value-container {
                  height: 60px;
                  line-height: 60px;
                  input.time-value {
                    font-size: 50px;
                  }
                }
                .placeholder {
                  font-size: 20px;
                }
              }
              .picker-separator {
                height: 60%;
                font-size: 50px;
              }
            }
          }
          .save-timespent-action {
            align-self: end;
            justify-self: right;
            cursor: pointer;
            width: 96px;
            height: 36px;
            background-color: $blue-400;
            color: $white;
            text-align: center;
            line-height: 36px;
            text-transform: uppercase;
            &.disable-event {
              opacity: 0.5;
            }
          }
          @media screen and (max-width: $screen-xs) {
            display: grid;
            grid-template-columns: 100%;
          }
          @media screen and (max-width: $screen-380) {
            .gru-timepicker-card {
              .timepicker-container {
                .hour-picker,
                .minute-picker {
                  .value-container {
                    input.time-value {
                      max-width: 89px;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .complete-submission-container,
      .self-grading-button-container {
        width: 250px;
        margin: auto;
        .complete-submit-btn,
        .self-grade-btn {
          background-color: $blue-400;
          color: $white;
          height: 40px;
          line-height: 40px;
          border-radius: 4px;
          text-align: center;
          font-size: 15px;
          cursor: pointer;
        }
        &.disable-event {
          opacity: 0.5;
        }
      }

      .completion-confirmation-popup {
        background-color: $white;
        max-width: 500px;
        margin: auto;
        display: grid;
        padding: 15px;
        row-gap: 8px;
        border-radius: 5px;
        .action-container {
          display: grid;
          grid-template-columns: auto auto;
          justify-content: right;
          column-gap: 8px;

          .action-btn {
            width: 96px;
            height: 36px;
            cursor: pointer;
            text-align: center;
            line-height: 36px;
            border-radius: 4px;

            &.confirm-btn {
              background-color: $blue-400;
              color: $white;
            }

            &.cancel-btn {
              border: solid 1px $nobel-gray;
              color: $elephant-dark;
            }
          }
        }
        @media screen and (max-width: $screen-sm) {
          left: unset;
          width: 100%;
        }
      }
    }

    .right-panel {
    }

    &.grading-enabled {
      display: grid;
      grid-template-columns: auto auto;
      column-gap: 15px;

      @media screen and (min-width: $screen-md - 1) {
        max-height: calc(100vh - 205px);
        overflow: hidden;
        > .left-panel,
        > .right-panel {
          max-height: calc(100vh - 205px);
          overflow-y: auto;
          &::-webkit-scrollbar {
            height: 6px;
            width: 8px;
          }

          &::-webkit-scrollbar-thumb {
            background: $gray-light;
            border: 1px solid $gray-light;
            border-radius: 5px;
          }
        }
      }

      @media screen and (max-width: $screen-md) {
        display: block;
      }
    }
  }
  @media screen and (max-width: $screen-sm) {
    padding: 8px;
  }
  @media screen and (max-width: $screen-sm - 1) {
    width: 100%;
  }
  &.player-preview,
  &.oa-completed {
    .offline-activity-submission-container {
      .offline-activity-timespent {
        .timespent-container {
          pointer-events: none;
          .save-timespent-action {
            display: none;
          }
        }
      }
      .task-submissions-container {
        .gru-offline-activity-task-submission {
          .task-submission-container {
            .task-details-container {
              pointer-events: none;
              .save-task-submission {
                display: none;
              }
            }
          }
        }
      }

      .complete-submission-container {
        display: none;
      }
    }
  }

  .loading-spinner {
    cursor: progress;
    .three-bounce-spinner {
      position: absolute;
      top: 45%;
      left: 45%;
    }
  }
}
