.qz-true-false {
  .answer-choices {
    .radio {
      span {
        margin-right: 0.5em;
      }
      @media (max-width: $screen-xs-max) {
        /* only for phone */
        input {
          margin-top: 3px;
        }
      }
    }
  }
}
