.controller.analytics.collection.student {
  padding: unset;
  @media (min-width: $screen-md) and (max-width: $screen-lg) {
    padding: unset;
  }

  .navigation {
    padding: 10px 25px;
    @media (min-width: $screen-xs) {
      padding: 10px 15px;
    }
    @media (min-width: $screen-md) {
      padding: 15px;
    }
  }
}
