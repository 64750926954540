.milestone-report-pull-up {
  position: fixed;
  top: 100%;
  z-index: 1031;
  left: 0;
  right: 0;
  margin: auto;
  background-color: $white;
  height: 100%;
  width: 768px;

  @media screen and (max-width: $screen-md) {
    width: 100%;
  }

  .backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    cursor: pointer;
    opacity: 0.8;
    background-color: $gray-base;
  }

  .milestone-report-container {
    background-color: $white;
    .milestone-report-header-container {
      display: grid;
      grid-template-columns: 1fr 50px;
      justify-content: center;
      align-items: center;
      height: 50px;
      box-shadow: 0 1px 3px 0 $navbar-box-shadow;
      .title-container {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: bold;
        padding-left: 50px;
        justify-self: right;
        width: 100%;
        color: #5d5d5d;
      }

      .close-container {
        cursor: pointer;
        justify-self: center;
      }
    }
  }
}
