.teacher-class-search-content-pull-up {
  position: fixed;
  top: 100%;
  z-index: 1031;
  width: 768px;
  left: 0;
  right: 0;
  margin: auto;

  .loading-spinner {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background: $loading-spinner-bg-color;
  }

  .search-backdrop {
    background-color: $backdrop-color;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    cursor: pointer;
    opacity: 0.5;
  }

  .backdrop-pull-ups {
    .backdrop {
      background: none;
    }
  }

  .search-container {
    background: $white-bold;
    width: 768px;
    max-height: 90vh;
    height: 90vh;

    .search-header {
      height: 50px;
      display: grid;
      grid-template-columns: 50px 130px 1fr;
      border-bottom: 1px solid $breadcrumb-color;
      align-items: center;
      width: 100%;
      background-color: $dark-400;
      color: $white;

      .search-pullup-close {
        width: auto;
        height: 22px;

        .arrow_downward {
          font-size: 26px;
          color: #3c4452;
          margin-left: 22px;
          cursor: pointer;
          color: $white;
        }
      }

      .gooru-logo {
        height: 18px;

        img {
          height: 12px;
        }
      }

      .search-filter-container {
        width: 130px;
        display: flex;
        align-items: center;
        font-size: 15px;
        color: #3c4452;
        font-weight: bold;
        position: relative;

        span {
          height: 24px;
          color: $white;
        }

        .arrow_drop_down {
          padding-top: 3px;
          padding-left: 2px;
          cursor: pointer;
        }

        .search-filter-container-list {
          position: absolute;
          background: $white;
          top: -3px;
          z-index: 100;
          left: -30px;
          box-shadow: 0 2px 7px 2px $disabled-bg-color;
          visibility: hidden;
          opacity: 0;
          transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s,
            z-index 0s linear 0.01s;

          p {
            padding: 0;
            margin: 0;
            font-weight: normal;
            font-size: 15px;
            padding: 5px 20px;

            &.selected {
              background-color: $dark-50;
              font-weight: bold;
            }

            &:hover {
              background-color: $dark-50;
              cursor: pointer;
            }
          }

          &.active {
            visibility: visible;
            opacity: 1;
            transition-delay: 0s, 0s, 0.3s;
          }
        }
      }

      .search-right-menu {
        display: grid;
        grid-template-columns: auto max-content;

        .search-input-container {
          grid-template-columns: auto 190px auto;
          display: grid;
          position: relative;
          border: none;
          background: $white;
          outline: none;
          border-radius: 5px;
          width: 100%;

          .filter-items {
            display: flex;
            align-items: center;
            margin-right: 10px;
            justify-content: flex-end;

            .more-pointer {
              color: $gray-light;
              cursor: pointer;
            }

            .popover-content {
              .filter-item {
                margin: 5px;
                width: 80px;
              }
            }

            .filter-item {
              border-radius: 9px;
              justify-content: space-around;
              display: flex;
              height: 20px;
              width: 80px;
              padding: 10px;
              margin-right: 5px;
              align-items: center;
              background-color: #898b8e;

              .name {
                font-size: 12px;
                font-weight: bold;
                line-height: 1.25;
                text-align: right;
                color: $white-bold;
              }

              .clear {
                height: auto;
                margin-left: 5px;

                i {
                  height: 100%;
                  font-size: 12px;
                  color: $white;
                  opacity: 1;
                }
              }
            }
          }

          #search-content {
            -webkit-appearance: none;
            outline: none;
            border-radius: 5px;
            width: 300px;
            text-indent: 10px;
            margin-top: 4px;
            box-shadow: inset 0 0 7px 1px $breadcrumb-color;
            cursor: text;
            box-shadow: none;
            color: #3c4452;
          }

          .search-icon {
            width: 38px;
            height: 40px;
            color: #3c4452;
            cursor: pointer;
            justify-self: end;

            .search {
              font-size: 24px;
              margin-top: 13px;
            }

            &.active {
              color: #fa9053;
            }
          }
        }

        .search-filter-icon {
          color: $white;
          cursor: pointer;
          width: 50px;
          display: flex;
          margin: auto;
          justify-content: center;
        }
      }
    }

    .tenant-libraries {
      max-height: calc(90vh - 50px);
      display: flex;
      width: 100%;
      overflow-y: auto;
      margin: auto;
      background-color: #f1f3f7;

      .libraries-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        margin: auto;
        grid-gap: 10px;

        .loading-spinner {
          left: 0;
        }
      }
    }

    .selected-tenant-library {
      display: grid;
      grid-template-columns: 42px max-content 1fr;
      align-items: center;
      justify-content: center;
      box-shadow: 0 2px 4px 0 $disabled-bg-color;
      margin-bottom: 1px;
      height: 45px;

      .back {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      .library-name {
        font-weight: bold;
        font-size: 14px;
        padding-left: 5px;
      }

      img {
        width: 35px;
        height: 35px;
        border-radius: 50px;
      }
    }

    .search-content-type-filter {
      height: auto;
      background-color: $white-bold;
      box-shadow: 0 2px 4px 0 $disabled-bg-color;
      display: grid;
      width: 100%;
      position: relative;

      .search-by-filter {
        z-index: 99;

        .search-panel {
          .search-filter {
            display: none;
          }
        }
      }

      .filter-content-types {
        display: flex;
        align-items: center;
        padding-left: 40px;

        .filter-item {
          width: 100px;
          display: grid;
          justify-items: center;
          text-align: center;
          margin-right: 15px;
          margin-top: 11px;
          cursor: pointer;
          color: $breadcrumb-color;
          border-bottom-width: 2px;
          border-color: $white;
          border-bottom-style: solid;
          transition: border-width 0.1s ease-in-out;

          i {
            width: 24px;
            height: 24px;
            background-repeat: no-repeat;
          }

          span {
            font-size: 12px;
            font-weight: bold;
            display: block;
            width: 100px;
            text-transform: capitalize;
          }

          &.collection {
            i {
              background-image: url("../assets/gooru/images/collection_gray.svg");
            }
          }

          &.assessment {
            i {
              background-image: url("../assets/gooru/images/assessment-gray.svg");
            }
          }

          &.offline-activity {
            i {
              background-image: url("../assets/gooru/images/offline.svg");
            }
          }

          &.active,
          &:hover {
            border-bottom: 2px solid #2ea9e9;
            color: #2ea9e9;

            &.collection {
              i {
                background-image: url("/assets/gooru/images/collection-blue.svg");
              }
            }

            &.assessment {
              i {
                background-image: url("/assets/gooru/images/assessment-blue.svg");
              }
            }

            &.offline-activity {
              i {
                background-image: url("/assets/gooru/images/offline-blue.svg");
              }
            }
          }
        }

        .create-offline-activity {
          cursor: pointer;
        }
      }
    }

    .search-list-container {
      max-height: calc(90vh - 100px);
      height: 100%;
      overflow-y: auto;
      height: 100%;
      width: 100%;
      padding-top: 30px;
      padding-bottom: 30px;
      background: #f1f3f7;

      &.show-tenant-library {
        max-height: calc(90vh - 145px);
      }

      .search-list {
        display: grid;
        grid-template-columns: repeat(3, 220px);
        grid-gap: 20px;
        margin: 0 auto;
        width: 700px;
      }

      &::-webkit-scrollbar {
        height: 6px;
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background: $gray-light;
        border: 1px solid $gray-light;
        border-radius: 5px;
      }
    }
  }

  .ca-datepicker-schedule-container {
    background: $white;
    position: absolute;
    z-index: 10;
    width: inherit;
    box-shadow: 0 13px 16px 0 $date-picker-box-shadow;
    top: 50px;
    width: auto;
    display: none;
  }
  @media screen and (max-width: 992px) {
    .schedule-ca-datepicker-container {
      left: calc((100% - 250px) / 2) !important;

      .ca-datepicker-orientation-top {
        .ca-datepicker-top {
          display: none;
        }
      }

      .ca-datepicker-orientation-bottom {
        .ca-datepicker-bottom {
          display: none;
        }
      }
    }
  }
  @media screen and (max-width: 992px) and (orientation: portrait) {
    .schedule-ca-datepicker-container {
      top: 49px !important;
    }
  }
  @media screen and (max-width: 992px) and (orientation: landscape) {
    .schedule-ca-datepicker-container {
      top: 0 !important;
    }
  }
  @media screen and (max-width: 992px) {
    .search-container {
      .search-header {
        .search-filter-container {
          .search-filter-container-list {
            p {
              padding: 10px;
            }
          }
        }
      }

      .search-list-container {
        .search-list {
          grid-template-columns: auto;
          width: 100%;
          grid-gap: 0;

          .cards.dca-search-collection-card {
            .card-panel-container {
              width: 100%;
              height: 100%;
              display: grid;
              grid-template-columns: 60px auto 30px;
              column-gap: 5px;
              min-height: 60px;
              box-shadow: none;

              .md-collection-thumbnail {
                width: 60px;
                height: 60px;
                grid-column: 1;
                grid-row: 1;
              }

              .card-panel-header {
                background-image: unset !important;
                width: 100%;
                height: 100%;
                grid-row: 1;
                grid-column: 2;
                row-gap: 5px;

                .info {
                  position: relative;
                  color: gray;

                  .title {
                    margin-top: 5px;
                    margin-bottom: 5px;
                    $line-height: 1.5;
                    $lines-to-show: 1;
                    font-weight: normal;
                    @include ellipsis(
                      $font-size: 15px,
                      $line-height: $line-height,
                      $lines-to-show: $lines-to-show
                    );
                  }
                }

                .collection-standards {
                  grid-row-start: 1;
                  display: grid;
                  align-items: center;
                  padding-left: 8px;

                  .gru-taxonomy-tag-list {
                    .gru-taxonomy-tag {
                      min-width: 90px;
                      max-width: 100px;
                      height: 23px;
                      border-radius: 12px;
                      background-color: $dark-gray;

                      .standard-tag {
                        text-align: center;
                        padding: 0;

                        .tag-label {
                          font-size: 14px;
                          font-weight: bold;
                          color: $white;
                          line-height: 21px;
                        }

                        .tag-caption {
                          display: none;
                        }
                      }
                    }

                    .non-visible-tags {
                      width: 81px;
                      height: 23px;
                      border-radius: 12px;
                      background-color: $dark-gray;
                      color: $white;
                      padding: 0;

                      span {
                        font-weight: bold;
                        font-size: 14px;
                        margin-left: 10px;
                      }
                    }

                    .popover {
                      .popover-content {
                        .gru-taxonomy-tag {
                          min-width: 90px;
                          max-width: 100px;
                          height: 23px;
                          border-radius: 12px;
                          background-color: $dark-gray;

                          .standard-tag {
                            text-align: center;
                            padding: 0;
                            width: max-content;

                            .tag-label {
                              font-size: 14px;
                              font-weight: bold;
                              color: $white;
                              line-height: 21px;
                            }

                            .tag-caption {
                              display: none;
                            }
                          }
                        }

                        .description-tag {
                          display: none;
                        }
                      }
                    }
                  }
                }

                &:after {
                  display: none;
                }
              }

              .content-count-container {
                display: none;
              }

              .collection-info-container {
                height: auto;
                padding: 0;
                grid-column-start: 1;
                grid-column-end: 3;
                display: grid;

                .collection-user-info {
                  display: none;
                }

                .collection-description {
                  height: auto;
                  margin-left: 30px;
                  $font-size: 12px;
                  $lines-to-show: 4;
                  $line-height: 1.5;
                  margin-left: 15px;
                  @include ellipsis(
                    $font-size: $font-size,
                    $line-height: $line-height,
                    $lines-to-show: $lines-to-show
                  );
                  margin-bottom: 5px;
                }
                //hide collection standards inside info section
                .collection-standards {
                  display: none;
                }
              }

              .card-panel-footer {
                width: 30px;
                position: relative;
                grid-column: 3;

                .buttons-container {
                  display: grid;
                  width: inherit;
                  height: auto;
                  grid-template-columns: auto;
                  align-items: center;
                  justify-content: center;
                  position: absolute;
                  bottom: 0;

                  .play-btn {
                    display: none;
                  }

                  .schedule-dca-content {
                    order: 2;
                  }

                  .dca-add-btn {
                    order: 1;

                    &.added {
                      i.add {
                        display: none;
                      }
                    }
                  }

                  .dca-add-btn,
                  .schedule-dca-content {
                    height: 30px;
                    width: 30px;
                    border-top: 1px solid $gray-ultra-light;
                    justify-self: center;
                    display: grid;
                    align-items: center;
                    justify-content: center;

                    i {
                      margin: 0;
                      position: relative;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
