.gru-class-activities-adding-layout {
  margin: 8px 0;

  @media screen and (max-width: 768px) {
    position: fixed;
    width: 100%;
    top: 93%;
    transition: 0.4s;
    background-color: $white;
    z-index: 99;

    &.show-content {
      top: 12%;

      @media screen and (min-height: 250px) and (max-height: 650px) {
        top: 20%;
      }
    }
  }

  @media screen and (min-height: 250px) and (max-height: 450px) {
    top: 86%;
  }
  @media screen and (min-height: 450px) and (max-height: 700px) {
    top: 91%;
  }

  .panel-layout-section {
    padding: 10px;

    ::-webkit-scrollbar {
      width: 8px;
    }
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px $navbar-box-shadow;
    }

    ::-webkit-scrollbar-thumb {
      background-color: $light-500;
    }
    .layout-title {
      padding: 3px 0;
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
      display: flex;
      i {
        display: none;
      }
      @media screen and (max-width: 768px) {
        text-align: center;
        background-color: $white;
        border: 1px solid $light-200;
        cursor: pointer;
        justify-content: center;
        i {
          display: block;
        }
      }
    }
    .gru-class-activities-adding-layout-container {
      height: calc(100vh - 120px);
      background-color: $light-200;
      box-shadow: 0 0 5px $light-200;
      margin-top: 10px;
      position: relative;
      overflow-y: auto;
      background-image: linear-gradient(
        to top,
        $layout-container,
        $adding-layout
      );
      @media only screen and (max-width: $screen-sm) {
        overflow-y: auto;
        height: calc(100vh - 180px);
      }
      .switching-section {
        padding: 5px 10px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        .scope-and-sequence-section {
          position: relative;
          .panel-header {
            align-items: center;
            font-size: 13px;
            font-style: italic;
            font-weight: bold;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            padding: 0;
            display: flex;
            background-color: $table-bg;
            i {
              font-size: 20px;
            }
          }
          .dropdown-menu {
            width: 100%;
            background-color: $white;
            max-height: 200px;
            overflow-y: auto;

            span {
              padding: 2px 5px;
              cursor: pointer;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              transition: 0.3s;
              width: 100%;
              display: block;

              &.active {
                background-color: $blue-300;
                color: $white;
                font-weight: bold;
              }
            }
          }
        }
        .change-component {
          font-size: 15px;
          text-align: right;
          font-style: italic;
          cursor: pointer;
          color: $font-blue-400-dark-400;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
      .gru-class-activities-content-section {
      }
    }
  }
}
