.login-main-container {
  box-shadow: 0 5px 15px 0 $navbar-default-toggle-icon-bar-bg;
  border-radius: 5px;
  background-color: $white;
  width: 100%;
  margin: 0px auto;
  margin-top: 125px;
  padding: 0;
  float: none;
  max-width: 400px;
  height: fit-content;
  position: relative;
  @media screen and (max-height: 700px) {
    height: auto !important;
  }
  @media (min-width: $screen-sm-min) {
    width: 400px;
    margin: 125px auto 15px;
  }

  .tenant-container {
    height: calc(100% - 120px);
    .back {
      display: grid;
      grid-template-columns: 48px auto;
      padding-top: 24px;
      width: 100%;
      .icon {
        width: 100%;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        i {
          width: 24px;
          height: 24px;
          object-fit: contain;
          cursor: pointer;
        }
      }
      .title {
        display: flex;
        flex-direction: column;
        text-align: center;
        font-size: 20px;
        line-height: 1.4;
        color: $dark-500;
        span {
          font-size: 12px;
          color: $gray-dark;
        }
      }
    }
    .tenant-list {
      padding: 16px;
      display: block;
      align-items: center;
      justify-content: center;
      height: calc(100% - 72px);
      flex-direction: column;
      overflow: auto;

      .tenant-info {
        width: 100%;
        height: 64px;
        border-bottom: solid 1px $tenant-info-border-color;
        display: grid;
        grid-template-columns: 60px auto;
        align-items: center;
        cursor: pointer;
        .tenant-log {
          display: flex;
          justify-content: center;
          .log {
            width: 40px;
            height: 40px;
          }
          span {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: $orange-aob;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $white;
            font-size: 15px;
            font-weight: bold;
          }
        }
        .tenant-name {
          color: $dark-400;
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
  }

  .form-group {
    margin-bottom: 0px;
    .error-messages {
      margin-bottom: 24px;
    }
  }
  .form-container {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    height: calc(100% - 125px);

    label {
      width: 317px;
      input {
        text-align: left;
        padding-left: 15px;
        height: 51px;
      }
    }

    .login-card-title {
      margin: 39px 0px 24px 0px;
      font-weight: 400;
      font-size: 18px;
      color: #000;
      line-height: 27px;
    }

    .error-message {
      color: $red-400;
      display: inline-block;
      font-size: 12px;
    }
    .submit-btn {
      width: 314px;
      height: 44px;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 46px;
    }

    .img-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .gooru-logo {
        background: url("gooru/images/gooru-logo.svg");
        height: 32px;
        padding: 0;
        width: 111px;
        transform: scale(0.6, 0.6);
        justify-self: center;
      }
      .tenant-logo {
        margin-top: 54px;
        background: url("gooru/images/learningNav-logo-with-text.svg");
        height: 50px;
        padding: 0;
        width: 100%;
        max-width: 220px;
        justify-self: center;
        background-size: contain;
        background-repeat: no-repeat;
      }
    }

    .sinup-link {
      font-size: 14px;
      font-weight: bold;
    }
  }

  .footer-container {
    height: 120px;
    border-top: solid 1px $white-ff4;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 15px;
    position: absolute;
    @media screen and (max-height: 700px) {
      position: inherit;
    }
    bottom: 0;
    width: 100%;
  }
}

.login-wrapper {
  .copyright-information {
    position: absolute;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    bottom: 0;
    @media screen and (max-height: 650px) {
      margin-top: 10px;
      position: relative;
    }
  }
}

.tenant-list-page {
  margin: 15px auto;
  height: calc(100vh - 90px);
  @media (min-width: $screen-sm-min) {
    margin: 25px auto 15px;
  }
}
