.filter.gru-gut-filter {
  .gut-selector-container {
    display: grid;
    .category-selector {
      .category-header-container {
        color: $dark-400;
        font-size: 15px;
        font-weight: 700;
        text-transform: uppercase;
        opacity: 0.8;
        padding-bottom: 4px;
      }
      .categories-container {
        display: flex;
        flex-wrap: wrap;
        .category-container {
          opacity: 0.5;
          color: $dark-400;
          background: $white;
          border-radius: 3px;
          border: 1px solid $dark-400;
          height: 30px;
          line-height: 30px;
          padding: 0 8px;
          margin: 0 4px;
          cursor: pointer;
          width: max-content;
          &:hover,
          &.active {
            opacity: 1;
            color: $white;
            background-color: $dark-400;
          }
        }
      }
    }
    .slide-level-selector {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      position: relative;
      .level-selector {
        max-width: 300px;
        .level-header-container {
          color: $dark-400;
          font-size: 15px;
          font-weight: 700;
          text-transform: uppercase;
          opacity: 0.8;
          padding-bottom: 4px;
        }
        .level-items-container {
          height: 200px;
          max-height: 300px;
          overflow-y: auto;
          border: 1px solid;
          .level-item {
            height: 30px;
            line-height: 30px;
            color: $dark-400;
            background-color: $white;
            padding-left: 8px;
            white-space: nowrap;
            cursor: pointer;
            &:hover,
            &.active {
              color: $white;
              background-color: $dark-400;
            }
          }
          &::-webkit-scrollbar {
            height: 6px;
            width: 8px;
          }

          &::-webkit-scrollbar-thumb {
            background: $gray-light;
            border: 1px solid $gray-light;
            border-radius: 5px;
          }
        }
      }
      .loading-spinner {
        position: absolute;
        margin: auto;
        width: 100%;
      }

      @media screen and (max-width: $screen-sm) {
        grid-template-columns: auto;

        .level-selector {
          max-width: unset;
          width: 100%;
          .level-items-container {
            height: auto;
          }
        }
      }
    }
  }
}
