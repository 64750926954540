.qz-html-resource {
  height: 100%;
  width: 100%;
  border: 1px solid $light-200;

  .player-content {
    height: 100%;
    width: 100%;
    background-color: $white;
    overflow: auto;
  }
}
