.qz-preview-url {
  height: 100%;
  .preview {
    width: 100%;
    height: 100%;
    iframe {
      width: 100%;
      height: 100%;
    }
  }
  .preview.show-legend {
    @include flexbox;
    @include justify-content(center);
    @include align-items(center);
    color: $gray-light;
    border: 2px dashed $gray-light;
  }
}
