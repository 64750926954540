.lessons-container {
  margin: 17px 0 0 0;

  .panel {
    box-shadow: none;
  }
  > div {
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-align-content: center;
    align-content: center;
    display: -webkit-flex;
    display: flex;
    > .gru-lesson-performance-container {
      width: 100%;
      min-height: 70px;
      border: 0;
      border-radius: 0 !important;
      padding: 0;
      @include respond-to(xs) {
        min-height: 50px;
      }
      > a {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        color: $gray;
        height: 70px;
        -webkit-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-align-items: center;
        align-items: center;
        -webkit-align-content: center;
        align-content: center;
        display: -webkit-flex;
        display: flex;
        text-decoration: none;
        @include respond-to(xs) {
          height: 50px;
        }
        > * {
          -webkit-order: 0;
          order: 0;
          -webkit-flex-grow: 1;
          flex-grow: 1;
          -webkit-flex-shrink: 1;
          flex-shrink: 1;
          -webkit-flex-basis: auto;
          flex-basis: auto;
          -webkit-align-self: auto;
          align-self: auto;
          height: 100%;
          width: 100%;
          padding: 0;
        }
        > ul {
          border-bottom: 1px solid $gray-lighter;
          > li {
            &.lesson-performance-content {
              -webkit-flex-direction: row;
              flex-direction: row;
              -webkit-flex-wrap: nowrap;
              flex-wrap: nowrap;
              -webkit-justify-content: flex-start;
              justify-content: flex-start;
              -webkit-align-items: center;
              align-items: center;
              -webkit-align-content: center;
              align-content: center;
              display: -webkit-flex;
              display: flex;
              height: 100%;
              width: 100%;
              @include respond-to(xs) {
                -webkit-justify-content: center;
                justify-content: center;
              }
              > * {
                -webkit-order: 0;
                order: 0;
                -webkit-flex-grow: 0;
                flex-grow: 0;
                -webkit-flex-shrink: 1;
                flex-shrink: 1;
                -webkit-flex-basis: auto;
                flex-basis: auto;
                -webkit-align-self: auto;
                align-self: auto;
                margin: 0;
              }
            }
            &:last-child {
              @include respond-to(xs) {
                border-left: 1px solid $gray-lighter;
              }
            }
          }
        }
      }
      div.collections-container {
        > ul {
          @include flexbox;
          @include flex-direction(column);
          list-style: none;
          padding: 0;
          li ul {
            padding-bottom: 20px;
            height: 100px;
            @include align-items(flex-end);
            &:after {
              border-top: 1px solid $gray-lighter;
              width: 96%;
              position: relative;
              left: 0;
              top: 31px;
              @include respond-to(xs) {
                top: 0;
                bottom: 0;
              }
            }
            &:last-child {
              &:after {
                border: none;
              }
            }
            li {
              &.title {
                @include flex-direction(column);
                @include align-items(flex-start);
                .section-title {
                  padding: 5px;
                  &.disabled {
                    font-style: italic;
                    color: $dark-100;
                  }
                }
              }
              > span {
                i {
                  display: none;
                }
              }
              p {
                margin: 0 0 5px;
              }
            }
          }
        }
      }
    }
    > * {
      width: 100%;
    }
  }
}
